import React, { Fragment, useState, useEffect, useRef } from 'react';
import { isEmptyString } from '../../../utils/generalFunctions';

// context
import { useCoapplicant } from '../../../context/CoapplicantContext';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Link,
  useToast,
} from '@chakra-ui/react';

const CoapplicantStepThree = ({
  setActiveStep,
  stepThreeCreditFirstName,
  setStepThreeCreditFirstName,
  stepThreeCreditMiddleName,
  setStepThreeCreditMiddleName,
  stepThreeCreditLastName,
  setStepThreeCreditLastName,
  stepThreeCreditStreetAddress,
  setStepThreeCreditStreetAddress,
  stepThreeCreditZipCode,
  setStepThreeCreditZipCode,
  stepThreeCreditSsn,
  setStepThreeCreditSsn,
  handleNext,
  loading,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { coapplicantData, isHomebuyer } = useCoapplicant();

  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);

  let autoComplete;

  const loadScript = (url, callback) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: 'us' } }
    );
    autoComplete.setFields(['address_components', 'formatted_address']);
    autoComplete.addListener('place_changed', () =>
      handlePlaceSelect(updateQuery)
    );
  };

  const handlePlaceSelect = async updateQuery => {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    setStepThreeCreditStreetAddress(query);

    const addressArray = query.split(',');
    const addressZip = addressArray[2].trim().split(' ')[1];

    if (addressZip) {
      setStepThreeCreditZipCode(addressZip);
    }
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${'AIzaSyCGbiIGIjtPn66vJ-x4d8PspahDferywpw'}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  return (
    <Fragment>
      <Stack maxW="xl" spacing="5">
        <Card bg="white" w="100%" textAlign="left">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md" mb="1">
                    We need to perform credit verification
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    This won’t affect{' '}
                    {isHomebuyer
                      ? coapplicantData
                        ? coapplicantData.name.firstName + `'s `
                        : ''
                      : 'your '}
                    credit score. <br /> We will use this information to check{' '}
                    {isHomebuyer
                      ? coapplicantData
                        ? coapplicantData.name.firstName + `'s `
                        : ''
                      : 'your '}
                    credit profile and help estimate income.
                  </Text>
                </Box>

                <Stack spacing="5">
                  <FormControl>
                    <FormLabel color="gray">Legal First Name</FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={stepThreeCreditFirstName}
                      onChange={e => {
                        setStepThreeCreditFirstName(e.target.value.trim());
                      }}
                    />
                    <FormHelperText color="gray.400">
                      Must be{' '}
                      {isHomebuyer
                        ? coapplicantData
                          ? `your Co-Buyer's `
                          : ''
                        : 'your '}
                      legal First Name, like the one{' '}
                      {isHomebuyer
                        ? coapplicantData
                          ? `on ${coapplicantData.name.firstName}'s `
                          : ''
                        : 'on your '}
                      Driver’s License
                    </FormHelperText>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">Legal Middle Name</FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={stepThreeCreditMiddleName}
                      onChange={e => {
                        setStepThreeCreditMiddleName(e.target.value.trim());
                      }}
                    />
                    <FormHelperText color="gray.400">
                      Must be{' '}
                      {isHomebuyer
                        ? coapplicantData
                          ? `your Co-Buyer's `
                          : ''
                        : 'your '}
                      actual legal Middle Name (if they have one), like the one{' '}
                      {isHomebuyer
                        ? coapplicantData
                          ? `on ${coapplicantData.name.firstName}'s `
                          : ''
                        : 'on your '}
                      Driver’s License
                    </FormHelperText>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">Legal Last Name</FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={stepThreeCreditLastName}
                      onChange={e => {
                        setStepThreeCreditLastName(e.target.value.trim());
                      }}
                    />
                    <FormHelperText color="gray.400">
                      Must be{' '}
                      {isHomebuyer
                        ? coapplicantData
                          ? `your Co-Buyer's `
                          : ''
                        : 'your '}
                      actual legal Last Name, like the one{' '}
                      {isHomebuyer
                        ? coapplicantData
                          ? `on ${coapplicantData.name.firstName}'s `
                          : ''
                        : 'on your '}
                      Driver’s License
                    </FormHelperText>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">Street Address</FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      ref={autoCompleteRef}
                      onChange={event => {
                        setQuery(event.target.value);
                      }}
                      placeholder={stepThreeCreditStreetAddress}
                      value={query}
                    />
                  </FormControl>

                  {/* <FormControl>
                    <FormLabel color="gray">Street Address</FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={stepThreeCreditStreetAddress}
                      onChange={e => {
                        setStepThreeCreditStreetAddress(e.target.value);
                      }}
                    />
                  </FormControl> */}

                  <FormControl>
                    <FormLabel color="gray">Zip Code</FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="number"
                      value={stepThreeCreditZipCode}
                      onChange={e => {
                        if (e.target.value.length <= 5) {
                          setStepThreeCreditZipCode(e.target.value);
                        }
                      }}
                    />
                    <FormHelperText color="gray.400">
                      Enter 5-digit number
                    </FormHelperText>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">
                      Social Security Number or ITIN
                    </FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="number"
                      value={stepThreeCreditSsn}
                      onChange={e => {
                        if (e.target.value.length <= 9) {
                          if (
                            e.target.value.length !== 0 &&
                            isNaN(e.target.value[e.target.value.length - 1])
                          ) {
                            let newString = e.target.value.substring(
                              0,
                              e.target.value.length - 1
                            );
                            setStepThreeCreditSsn(newString);
                          } else {
                            setStepThreeCreditSsn(e.target.value);
                          }
                        }
                      }}
                    />
                    <FormHelperText color="gray.400">
                      Enter 9-digit number
                    </FormHelperText>
                  </FormControl>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          {/* <Button
            onClick={() => {
              setActiveStep(2);
            }}
          >
            Prev
          </Button> */}
          <Button
            colorScheme="blue"
            onClick={() => {
              if (stepThreeCreditFirstName.length === 1) {
                toast({
                  title: 'Error',
                  description: 'First name has to be greater than 1 letter',
                  position: 'top-right',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              } else if (stepThreeCreditLastName.length === 1) {
                toast({
                  title: 'Error',
                  description: 'Last name has to be greater than 1 letter',
                  position: 'top-right',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              } else if (stepThreeCreditStreetAddress.length === 1) {
                toast({
                  title: 'Error',
                  description: 'Street Address has to be greater than 1 letter',
                  position: 'top-right',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              } else {
                onOpen();
              }
            }}
            isLoading={loading}
            isDisabled={
              isEmptyString(stepThreeCreditFirstName) ||
              isEmptyString(stepThreeCreditLastName) ||
              isEmptyString(stepThreeCreditStreetAddress) ||
              isEmptyString(stepThreeCreditZipCode) ||
              isEmptyString(stepThreeCreditSsn) ||
              stepThreeCreditZipCode.length !== 5 ||
              stepThreeCreditSsn.length !== 9
            }
          >
            Next
          </Button>
        </Stack>
      </Stack>

      <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) " />
        <ModalContent>
          <ModalHeader>
            <Text fontWeight="700" fontSize="xl">
              Soft credit check and employment & income verification
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="5" mb="10">
              <Text fontWeight="500" fontSize="md" textAlign="justify">
                This will not affect{' '}
                {isHomebuyer
                  ? coapplicantData
                    ? coapplicantData.name.firstName + `'s `
                    : ''
                  : 'your '}
                credit score.
              </Text>
              <Text fontWeight="500" fontSize="md" textAlign="justify">
                You understand that by clicking on the "I Agree" button below,
                you are providing "written instructions" to Revex Technologies
                Inc., its affiliates, subsidiaries, and third-party partners
                (collectively, "Utopia") under the Fair Credit Reporting Act and
                thereby authorize Utopia to obtain information from your
                personal credit profile and other information about you from one
                or more consumer reporting agencies (including, but not limited
                to, TransUnion, Experian, and/or Equifax), on an ongoing and/or
                recurring basis.
              </Text>
              <Text fontWeight="500" fontSize="md" textAlign="justify">
                By clicking "I Agree," you also allow Utopia to obtain
                information about your job, including, but not limited to, your
                salary, through our trusted partners, and agree to Utopia's{' '}
                <Link
                  color="blue.500"
                  onClick={() => {
                    window.open('https://www.utopiahomes.us/terms', '_blank');
                  }}
                >
                  Terms and Conditions
                </Link>{' '}
                and{' '}
                <Link
                  color="blue.500"
                  onClick={() => {
                    window.open('https://www.utopiahomes.us/privacy', '_blank');
                  }}
                >
                  Privacy Policy
                </Link>
                .
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              onClick={() => {
                onClose();
              }}
              mr="4"
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={async () => {
                onClose();
                await handleNext();
              }}
            >
              I Agree
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default CoapplicantStepThree;
