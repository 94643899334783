import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useAuth } from '../../../../context/AuthContext';
import { getNormalizedError } from '../../../../utils/generalFunctions';

const ReferralChangeButton = ({ value, onSuccess, codeId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [updatedCode, setUpdatedCode] = useState();

  const [error, setError] = useState(null);
  const { getReferralByCode, addNewReferral, authUser } = useAuth();

  const toast = useToast();

  const validate = async () => {
    try {
      const existingReferral = await await getReferralByCode(updatedCode);

      if (updatedCode?.length === 0) {
        setError('Referral code cannot be empty');
        return false;
      } else if (updatedCode?.length < 2) {
        setError('Referral code must be atleast 2 characters long');
        return false;
      } else if (value === updatedCode) {
        setError(
          'Referral code cannot be the same as previous. Please enter a new one.'
        );
        return false;
      } else if (
        Boolean(existingReferral) &&
        existingReferral.userId !== authUser.uid
      ) {
        setError('Referral code already taken. Please choose a new one.');
        return false;
      }

      setError(null);
      return true;
    } catch (err) {
      console.log('🚀 ~ file: index.js:42 ~ validate ~ err:', err);
      setError('something went wrong.');

      return false;
    }
  };

  const handleSubmit = async () => {
    setButtonLoading(true);

    try {
      if (!(await validate())) {
        setButtonLoading(false);

        return;
      }

      setButtonLoading(true);
      await addNewReferral(updatedCode, codeId);

      toast({
        title: 'Successful',
        description: `Referral Code Added Successfully`,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
      setButtonLoading(false);
      onSuccess && onSuccess();
      onClose();
    } catch (err) {
      const error = getNormalizedError(err);

      toast({
        title: 'Request Failed',
        description: error
          ? error
          : 'Unable to add referral code at the moment, please try again later',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });

      setButtonLoading(false);
    }
  };

  useState(() => {
    setUpdatedCode(value);
  }, [isOpen]);

  return (
    <>
      <Button
        textColor={'#1895D7'}
        fontSize="sm"
        minW={20}
        px={'10'}
        variant="outline"
        borderColor="gray.300"
        onClick={onOpen}
        width="25%"
      >
        Update Code
      </Button>

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={{ base: 'full', md: 'lg' }}
      >
        <ModalOverlay />
        <ModalContent pb={5}>
          <ModalHeader>Change referral code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={5}>
              When you change your referral code it will update both your client
              and agent referral links. All previously created referral links
              will still work.
            </Text>

            <Input
              size="md"
              borderRadius="md"
              value={updatedCode}
              onChange={val => {
                setUpdatedCode(val.target.value);
              }}
              disabled={buttonLoading}
              borderColor="black"
              borderWidth={1}
            />
            <Text color="red" mt={2}>
              {error}
            </Text>

            <Flex mt={'10%'} flexDirection="column">
              <Button
                backgroundColor={'#1C2430'}
                textColor={'white'}
                minW={30}
                isDisabled={buttonLoading}
                onClick={handleSubmit}
                _hover={{
                  bg: 'black',
                  cursor: 'pointer',
                }}
              >
                {buttonLoading ? 'Updating...' : 'Update Code'}
              </Button>

              <Button onClick={onClose} mt={2}>
                Cancel
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReferralChangeButton;
