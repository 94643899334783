import React, { Fragment, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// context
import { useCoapplicant } from '../../../context/CoapplicantContext';

import {
  isEmptyString,
  atLeast18,
  atLeast18_v2,
  isIos,
  isSafari,
} from '../../../utils/generalFunctions.js';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Select,
  Spinner,
  Spacer,
  Checkbox,
} from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';

const CoapplicantStepThirteen = ({
  setActiveStep,
  // stepOnePhoneNumber,
  // setStepOnePhoneNumber,
  // stepTwoMonthlyIncome,
  // setStepTwoMonthlyIncome,
  // stepTwoRent,
  // setStepTwoRent,
  stepThreeCreditFirstName,
  setStepThreeCreditFirstName,
  stepThreeCreditMiddleName,
  setStepThreeCreditMiddleName,
  stepThreeCreditLastName,
  setStepThreeCreditLastName,
  stepThreeCreditStreetAddress,
  setStepThreeCreditStreetAddress,
  stepThreeCreditZipCode,
  setStepThreeCreditZipCode,
  stepThreeCreditSsn,
  setStepThreeCreditSsn,
  stepFourPrimaryIncome,
  setStepFourPrimaryIncome,
  stepFiveEmployerName,
  setStepFiveEmployerName,
  stepFiveStartDate,
  setStepFiveStartDate,
  stepFiveEmployerCity,
  setStepFiveEmployerCity,
  stepFiveEmployerState,
  setStepFiveEmployerState,
  stepSixPayPeriod,
  setStepSixPayPeriod,
  stepSixPreTaxIncome,
  setStepSixPreTaxIncome,
  stepSevenIncomeDocOne,
  setStepSevenIncomeDocOne,
  stepSevenIncomeDocTwo,
  setStepSevenIncomeDocTwo,
  stepSevenIncomeDocThree,
  setStepSevenIncomeDocThree,
  stepEightBankStatementOne,
  setStepEightBankStatementOne,
  stepEightBankStatementTwo,
  setStepEightBankStatementTwo,
  stepEightBankStatementThree,
  setStepEightBankStatementThree,
  stepNineHasRaise,
  setStepNineHasRaise,
  stepTenMoreThanOneSalary,
  setStepTenMoreThanOneSalary,
  stepTenAdditionalYearlySalary,
  setStepTenAdditionalYearlySalary,
  stepElevenIdentityDoc,
  setStepElevenIdentityDoc,
  stepTwelveBackgroundCheckEviction,
  setStepTwelveBackgroundCheckEviction,
  stepTwelveBackgroundCheckDOB,
  setStepTwelveBackgroundCheckDOB,
  stepThirteenReviewApp,
  setStepThirteenReviewApp,
  handleNext,
  loading,
  setLoading,
}) => {
  const { coapplicantData, isHomebuyer } = useCoapplicant();

  const format = val => `$` + val;
  const parse = val => val.replace(/^\$/, '');

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      borderWidth="2px"
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
      w="100%"
      size="lg"
      variant="ghost"
    >
      <Flex w="100%" align="center">
        {value === null || value === '' ? (
          <Text color="gray.400">mm/dd/yyyy</Text>
        ) : (
          <Text>{value}</Text>
        )}
        <Spacer />
        <CalendarIcon />
      </Flex>
    </Button>
  ));

  return (
    <Fragment>
      <Stack maxW="2xl" spacing="5">
        <Card bg="white" w="100%" textAlign="left">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md" mb="1">
                    Review{' '}
                    {isHomebuyer
                      ? coapplicantData
                        ? `your Co-Buyer's `
                        : ''
                      : 'your '}
                    Information
                  </Heading>
                  <Text
                    fontSize="md"
                    fontWeight="600"
                    color="gray"
                    textAlign="justify"
                  >
                    Before you submit this application, please make sure the
                    answers you have put in, are truthful and accurate to the
                    best of your knowledge. If not, please edit before you
                    submit.
                  </Text>
                </Box>

                <Box>
                  <TableContainer mb="8">
                    <Table variant="simple" size="sm">
                      <Thead>
                        <Tr>
                          <Th>Question</Th>
                          <Th>Answer</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {/* <Tr>
                          <Td>
                            <Box>
                              <Text fontSize={'xs'}>
                                What is your phone number?
                              </Text>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepOnePhoneNumber}
                                  onChange={e => {
                                    const value = e.target.value.replace(
                                      /\D/g,
                                      ''
                                    );
                                    setStepOnePhoneNumber(value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr> */}

                        {/* <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Please share your financial situation:
                                </Text>
                                <Text fontSize={'xs'}>
                                  Your gross monthly income:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <NumberInput
                                  size="xs"
                                  value={format(stepTwoMonthlyIncome)}
                                  onChange={valueString =>
                                    setStepTwoMonthlyIncome(parse(valueString))
                                  }
                                  min={3500}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr> */}

                        {/* <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Please share your financial situation:
                                </Text>
                                <Text fontSize={'xs'}>
                                  Your rent right now:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <NumberInput
                                  size="xs"
                                  value={format(stepTwoRent)}
                                  onChange={valueString =>
                                    setStepTwoRent(parse(valueString))
                                  }
                                  min={0}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr> */}

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  We need to perform credit verification
                                </Text>
                                <Text fontSize={'xs'}>Legal First Name:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepThreeCreditFirstName}
                                  onChange={e => {
                                    setStepThreeCreditFirstName(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  We need to perform credit verification
                                </Text>
                                <Text fontSize={'xs'}>Legal Middle Name:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepThreeCreditMiddleName}
                                  onChange={e => {
                                    setStepThreeCreditMiddleName(
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'xs'} color={'gray.400'}>
                                  We need to perform credit verification
                                </Text>
                                <Text>Legal Last Name:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepThreeCreditLastName}
                                  onChange={e => {
                                    setStepThreeCreditLastName(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  We need to perform credit verification
                                </Text>
                                <Text fontSize={'xs'}>Street Address:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepThreeCreditStreetAddress}
                                  onChange={e => {
                                    setStepThreeCreditStreetAddress(
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  We need to perform credit verification
                                </Text>
                                <Text fontSize={'xs'}>Zip Code:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  type="number"
                                  value={stepThreeCreditZipCode}
                                  onChange={e => {
                                    setStepThreeCreditZipCode(e.target.value);
                                  }}
                                  maxLength="5"
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  We need to perform credit verification
                                </Text>
                                <Text fontSize={'xs'}>
                                  Social Security Number or ITIN:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  type="number"
                                  value={stepThreeCreditSsn}
                                  onChange={e => {
                                    if (e.target.value.length <= 9) {
                                      if (
                                        e.target.value.length !== 0 &&
                                        isNaN(
                                          e.target.value[
                                            e.target.value.length - 1
                                          ]
                                        )
                                      ) {
                                        let newString =
                                          e.target.value.substring(
                                            0,
                                            e.target.value.length - 1
                                          );
                                        setStepThreeCreditSsn(newString);
                                      } else {
                                        setStepThreeCreditSsn(e.target.value);
                                      }
                                    }
                                  }}
                                  maxLength="9"
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'xs'}>Your primary income</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Select
                                  size="xs"
                                  value={stepFourPrimaryIncome}
                                  onChange={e => {
                                    setStepFourPrimaryIncome(e.target.value);
                                  }}
                                >
                                  <option value="Salary / hourly">
                                    Salary / hourly
                                  </option>
                                  <option value="Independent Contractor">
                                    Independent Contractor
                                  </option>
                                  <option value="Self-employed">
                                    Self-employed
                                  </option>
                                </Select>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Employer's details:
                                </Text>
                                <Text fontSize={'xs'}>Employer's name:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepFiveEmployerName}
                                  onChange={e => {
                                    setStepFiveEmployerName(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Employer's details:
                                </Text>
                                <Text fontSize={'xs'}>Start date:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                {/* {isIos() ? (
                                  <DatePicker
                                    selected={stepFiveStartDate}
                                    onChange={date =>
                                      setStepFiveStartDate(date)
                                    }
                                    minDate={'1950-01-01T04:49:20.366Z'}
                                    maxDate={new Date()}
                                    placeholderText="mm/dd/yyyy"
                                    customInput={<DateCustomInput />}
                                  />
                                ) : (
                                  <Input
                                    id="startDateDateInput"
                                    borderWidth="2px"
                                    size="lg"
                                    type="date"
                                    placeholder="mm/dd/yyyy"
                                    min="1950-01-01"
                                    max={new Date().toISOString().split('T')[0]}
                                    onKeyDown={e => e.preventDefault()}
                                    value={stepFiveStartDate}
                                    onChange={e => {
                                      setStepFiveStartDate(e.target.value);
                                    }}
                                  />
                                )} */}
                                <Input
                                  id="startDateDateInput"
                                  borderWidth="2px"
                                  size="lg"
                                  type="date"
                                  placeholder="mm/dd/yyyy"
                                  min="1950-01-01"
                                  max={new Date().toISOString().split('T')[0]}
                                  onKeyDown={e => e.preventDefault()}
                                  value={stepFiveStartDate}
                                  onChange={e => {
                                    setStepFiveStartDate(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Employer's details:
                                </Text>
                                <Text fontSize={'xs'}>
                                  Employer location City:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepFiveEmployerCity}
                                  onChange={e => {
                                    setStepFiveEmployerCity(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Employer's details:
                                </Text>
                                <Text fontSize={'xs'}>
                                  Employer location State:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepFiveEmployerState}
                                  onChange={e => {
                                    setStepFiveEmployerState(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Payment information for job
                                </Text>
                                <Text fontSize={'xs'}>
                                  Average pre-tax income per paycheck:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Select
                                  size="xs"
                                  value={stepSixPayPeriod}
                                  onChange={e => {
                                    setStepSixPayPeriod(e.target.value);
                                  }}
                                >
                                  <option value="Bi-weekly">Bi-weekly</option>
                                  <option value="Semi-monthly">
                                    Semi-monthly
                                  </option>
                                  <option value="Monthly">Monthly</option>
                                  <option value="Other">Other</option>
                                </Select>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Payment information for job
                                </Text>
                                <Text fontSize={'xs'}>
                                  Enter pre-tax income per paycheck:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <NumberInput
                                  size="xs"
                                  value={format(stepSixPreTaxIncome)}
                                  onChange={valueString =>
                                    setStepSixPreTaxIncome(parse(valueString))
                                  }
                                  min={3500}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'xs'}>
                                  Have you received a raise within the last 6
                                  months?
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Select
                                  size="xs"
                                  value={stepNineHasRaise}
                                  onChange={e => {
                                    setStepNineHasRaise(e.target.value);
                                  }}
                                >
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </Select>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Additional Salary
                                </Text>
                                <Text fontSize={'xs'}>
                                  Do you receive paychecks from more than one
                                  salaried or hourly job:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Select
                                  size="xs"
                                  value={stepTenMoreThanOneSalary}
                                  onChange={e => {
                                    setStepTenMoreThanOneSalary(e.target.value);
                                  }}
                                >
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </Select>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        {stepTenMoreThanOneSalary == 'yes' && (
                          <Fragment>
                            <Tr>
                              <Td>
                                <Box>
                                  <Stack spacing={3}>
                                    <Text fontSize={'2xs'} color={'gray.400'}>
                                      Additional Salary
                                    </Text>
                                    <Text fontSize={'xs'}>
                                      Your additional yearly salary:
                                    </Text>
                                  </Stack>
                                </Box>
                              </Td>
                              <Td>
                                <Box>
                                  <Stack>
                                    <NumberInput
                                      size="xs"
                                      value={format(
                                        stepTenAdditionalYearlySalary
                                      )}
                                      onChange={valueString =>
                                        setStepTenAdditionalYearlySalary(
                                          parse(valueString)
                                        )
                                      }
                                      min={0}
                                    >
                                      <NumberInputField />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                  </Stack>
                                </Box>
                              </Td>
                            </Tr>
                          </Fragment>
                        )}

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Background Check?
                                </Text>
                                <Text fontSize={'xs'}>
                                  Has an eviction been filed against you within
                                  the last 12 months?
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Select
                                  size="xs"
                                  value={stepTwelveBackgroundCheckEviction}
                                  onChange={e => {
                                    setStepTwelveBackgroundCheckEviction(
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </Select>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Background Check?
                                </Text>
                                <Text fontSize={'xs'}>Date of birth:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                {/* {isIos() ? (
                                  <DatePicker
                                    selected={stepTwelveBackgroundCheckDOB}
                                    onChange={date =>
                                      setStepTwelveBackgroundCheckDOB(date)
                                    }
                                    maxDate={new Date(atLeast18_v2())}
                                    placeholderText="mm/dd/yyyy"
                                    customInput={<DateCustomInput />}
                                    size="sm"
                                  />
                                ) : (
                                  <Input
                                    id="dobDateInput"
                                    borderWidth="2px"
                                    size="lg"
                                    type="date"
                                    placeholder="mm/dd/yyyy"
                                    value={stepTwelveBackgroundCheckDOB}
                                    max={atLeast18()}
                                    onKeyDown={e => e.preventDefault()}
                                    onChange={e => {
                                      setStepTwelveBackgroundCheckDOB(
                                        e.target.value
                                      );
                                    }}
                                  />
                                )} */}
                                <Input
                                  id="dobDateInput"
                                  borderWidth="2px"
                                  size="lg"
                                  type="date"
                                  placeholder="mm/dd/yyyy"
                                  value={stepTwelveBackgroundCheckDOB}
                                  max={atLeast18()}
                                  onKeyDown={e => e.preventDefault()}
                                  onChange={e => {
                                    setStepTwelveBackgroundCheckDOB(
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <Flex textAlign="left" justify="left">
                    <Checkbox
                      isChecked={stepThirteenReviewApp}
                      onChange={e => setStepThirteenReviewApp(e.target.checked)}
                    >
                      <Text fontSize="xs" fontWeight="800">
                        By checking this, I acknowledge that this information is
                        accurate to the best of my knowledge.
                      </Text>
                    </Checkbox>
                  </Flex>
                </Box>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(10);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="green"
            onClick={async () => {
              setLoading(true);

              // const applicationResponses = {
              //   uid: authUser.uid,
              //   stepOne: [
              //     {
              //       question: 'What is your phone number?',
              //       answer: stepOnePhoneNumber,
              //     },
              //   ],
              //   stepTwo: [
              //     {
              //       question: 'When do you plan to move?',
              //       answer: stepTwoMovePlan,
              //     },
              //   ],
              //   stepThree: [
              //     {
              //       question: 'Where do you plan to live? Metro Area:',
              //       answer: stepThreeMetroArea,
              //     },
              //     {
              //       question: 'Where do you plan to live? Cities:',
              //       answer: stepThreeCities,
              //     },
              //   ],
              //   stepFour: [
              //     {
              //       question:
              //         'Are you currently working with a real estate agent?',
              //       answer: stepFourRealEstateAgent,
              //     },
              //     {
              //       question:
              //         'Please enter the details of your real estate agent - First Name:',
              //       answer: stepFourRealEstateAgentFirstName,
              //     },
              //     {
              //       question:
              //         'Please enter the details of your real estate agent - Last Name:',
              //       answer: stepFourRealEstateAgentLastName,
              //     },
              //     {
              //       question:
              //         'Please enter the details of your real estate agent - Email:',
              //       answer: stepFourRealEstateAgentEmail,
              //     },
              //     {
              //       question:
              //         'Please enter the details of your real estate agent - Phone Number:',
              //       answer: stepFourRealEstateAgentPhone,
              //     },
              //     {
              //       question:
              //         'Can we connect you with our Partner real estate agent?',
              //       answer: stepFourConnectToRealEstateAgent,
              //     },
              //   ],
              //   stepFive: [
              //     {
              //       question: 'Do you have a Co-Applicant?',
              //       answer: stepFiveCoApplicant,
              //     },
              //     {
              //       question:
              //         'Please enter the details of your Co-Applicant - First Name:',
              //       answer: stepFiveCoApplicantFirstName,
              //     },
              //     {
              //       question:
              //         'Please enter the details of your Co-Applicant - Last Name:',
              //       answer: stepFiveCoApplicantLastName,
              //     },
              //     {
              //       question:
              //         'Please enter the details of your Co-Applicant - Email:',
              //       answer: stepFiveCoApplicantEmail,
              //     },
              //     {
              //       question:
              //         'Please enter the details of your Co-Applicant - Phone Number:',
              //       answer: stepFiveCoApplicantPhone,
              //     },
              //   ],
              //   stepSix: [
              //     {
              //       question: 'Your gross monthly income:',
              //       answer: stepSixMonthlyIncome,
              //     },
              //     {
              //       question: 'Your rent right now:',
              //       answer: stepSixRent,
              //     },
              //     {
              //       question: 'Your preferred price for your future home:',
              //       answer: stepSixHomePreferredPrice,
              //     },
              //     {
              //       question: 'Your available savings for a down payment:',
              //       answer: stepSixDownPaymentSavings,
              //     },
              //   ],
              //   stepSeven: [
              //     {
              //       question: 'Credit verification - Legal First Name:',
              //       answer: stepSevenCreditFirstName,
              //     },
              //     {
              //       question: 'Credit verification - Legal Middle Name:',
              //       answer: stepSevenCreditMiddleName,
              //     },
              //     {
              //       question: 'Credit verification - Legal Last Name:',
              //       answer: stepSevenCreditLastName,
              //     },
              //     {
              //       question: 'Credit verification - Street Address:',
              //       answer: stepSevenCreditStreetAddress,
              //     },
              //     {
              //       question: 'Credit verification - Zip Code:',
              //       answer: stepSevenCreditZipCode,
              //     },
              //     {
              //       question: 'Credit verification - Social Security:',
              //       answer: stepSevenCreditSsn,
              //     },
              //   ],
              //   stepEight: [
              //     {
              //       question: 'Your pre-qualified amount',
              //       answer: stepEightPrequalifiedAmount,
              //     },
              //   ],
              //   stepNine: [
              //     {
              //       question: 'Your primary income',
              //       answer: stepNinePrimaryIncome,
              //     },
              //   ],
              //   stepTen: [
              //     {
              //       question: 'Employer details - Employer name:',
              //       answer: stepTenEmployerName,
              //     },
              //     {
              //       question: 'Employer details - Start date:',
              //       answer: stepTenStartDate,
              //     },
              //     {
              //       question: 'Employer details - Employer location - City:',
              //       answer: stepTenEmployerCity,
              //     },
              //     {
              //       question: 'Employer details - Employer location - State:',
              //       answer: stepTenEmployerState,
              //     },
              //   ],
              //   stepEleven: [
              //     {
              //       question: 'Average pre-tax income per paycheck:',
              //       answer: stepElevenPayPeriod,
              //     },
              //     {
              //       question: 'Enter pre-tax income per paycheck:',
              //       answer: stepElevenPreTaxIncome,
              //     },
              //   ],
              //   stepFourteen: [
              //     {
              //       question:
              //         'Have you received a raise within the last 6 months?',
              //       answer: stepFourteenHasRaise,
              //     },
              //   ],
              //   stepFifteen: [
              //     {
              //       question:
              //         'Do you receive paychecks from more than one salaried or hourly job?',
              //       answer: stepFifteenMoreThanOneSalary,
              //     },
              //     {
              //       question: 'Your additional yearly salary:',
              //       answer: stepFifteenAdditionalYearlySalary,
              //     },
              //   ],
              //   stepSeventeen: [
              //     {
              //       question:
              //         'Background Check - Has an eviction been filed against you within the last 12 months?',
              //       answer: stepSeventeenBackgroundCheckEviction,
              //     },
              //     {
              //       question: 'Background Check - Date of birth',
              //       answer: stepSeventeenBackgroundCheckDOB,
              //     },
              //   ],
              //   legalName: {
              //     firstName: stepSevenCreditFirstName,
              //     lastName: stepSevenCreditLastName,
              //   },
              // };
              // const docsToUpload = {
              //   stepTwelve: {
              //     stepTwelveIncomeDocOne,
              //     stepTwelveIncomeDocTwo,
              //     stepTwelveIncomeDocThree,
              //   },
              //   stepThirteen: {
              //     stepThirteenBankStatementOne,
              //     stepThirteenBankStatementTwo,
              //     stepThirteenBankStatementThree,
              //   },
              //   stepSixteen: stepSixteenIdentityDoc,
              // };
              // await handleSubmitDocuments(applicationResponses, docsToUpload);
              await handleNext();

              setLoading(false);
            }}
            isLoading={loading}
            isDisabled={
              loading ||
              // stepOnePhoneNumber.length !== 10 ||
              stepThreeCreditZipCode.length !== 5 ||
              stepThreeCreditSsn.length !== 9 ||
              stepThreeCreditFirstName.length <= 1 ||
              stepThreeCreditLastName.length <= 1 ||
              stepThirteenReviewApp !== true
            }
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default CoapplicantStepThirteen;
