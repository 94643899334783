import { PhoneIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, Icon, Link, Text } from '@chakra-ui/react';
import { BsFillChatLeftTextFill } from 'react-icons/bs';
import { HiMail } from 'react-icons/hi';
import AgentDashboardSidebar from '../../../Sidebar';

const ContactUs = () => {
  const handleClick = () => {
    window.location.href = 'mailto:support@utopia.cx';
  };

  return (
    <AgentDashboardSidebar showHeader={false}>
      <Flex align="center" justify="center" minH="100vh" p={{ base: 4, md: 8 }}>
        <Box
          width={{ base: '90%', md: '60%' }}
          bg="white"
          p={8}
          borderRadius={12}
          boxShadow="lg"
        >
          <Heading as="h2" size="xl" mb={8} color="gray.800" textAlign="center">
            Need Assistance?
          </Heading>

          <Text fontSize="lg" mb={8} textAlign="center" color="gray.600">
            Reach out to our team to learn more about our program or if you have
            any questions. We are here to help you.
          </Text>

          <Flex
            direction={{ base: 'column', md: 'row' }}
            alignItems={'center'}
            justifyContent={'space-between'}
            minW={'80%'}
            mx="auto"
          >
            <Link href="mailto:support@utopia.cx" mb={{ base: 4, md: 0 }}>
              <Flex alignItems={'center'} onClick={handleClick}>
                <Icon
                  mr={3}
                  as={HiMail}
                  width="30px"
                  height="30px"
                  color={'inherit'}
                />
                <Text>support@utopia.cx</Text>
              </Flex>
            </Link>

            <Link href="tel:844-957-3700" mb={{ base: 4, md: 0 }}>
              <Flex alignItems={'center'}>
                <PhoneIcon boxSize={6} mr={3} />
                <Text>844-957-3700</Text>
              </Flex>
            </Link>

            <Link href="sms:844-957-3700">
              <Flex alignItems={'center'}>
                <Icon
                  mr={3}
                  as={BsFillChatLeftTextFill}
                  width="25px"
                  height="25px"
                  color={'inherit'}
                />
                <Text>844-957-3700</Text>
              </Flex>
            </Link>
          </Flex>
        </Box>
      </Flex>
    </AgentDashboardSidebar>
  );
};

export default ContactUs;
