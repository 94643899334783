import React, { Fragment } from 'react';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
} from '@chakra-ui/react';

const StartAgentApplication = ({ setApplicationStarted }) => {
  return (
    <Fragment>
      <Card align="center" bg="white" size="md">
        <CardHeader>
          <Image
            boxSize="100px"
            objectFit="cover"
            src="https://i.imgur.com/O73TMKn.png"
            alt="emails"
          />
        </CardHeader>
        <CardBody align="center">
          <Heading as="h4" size="md" mb="1">
            Begin Application
          </Heading>
          <Text fontSize="md" fontWeight="600" color="gray" textAlign="justify">
            Become a partner agent by joining Utopia Agent Network (UAN). Our
            Partner agents earn full commission, close more deals, get more
            clients from us and help those who are not yet mortgage ready to
            then become homeowners.
          </Text>
        </CardBody>
        <CardFooter w="100%">
          <Button
            colorScheme="blue"
            size="lg"
            w="100%"
            onClick={() => {
              setApplicationStarted(true);
            }}
          >
            Start
          </Button>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default StartAgentApplication;
