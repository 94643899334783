import React, { Fragment } from 'react';

// context
import { useAuth } from '../../context/AuthContext';

// chakra
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';

const VerifyEmail = () => {
  const { handleResendEmailVerification } = useAuth();

  return (
    <Fragment>
      <Card align="center" bg="white" size="md">
        <CardHeader>
          <Image
            boxSize="100px"
            objectFit="cover"
            src="https://i.imgur.com/sqZQUYR.png"
            alt="emails"
          />
        </CardHeader>
        <CardBody align="center">
          <Heading as="h4" size="md" mb="3">
            Verify your Email
          </Heading>
          <Text fontSize="md" fontWeight="600" color="gray" mb="1">
            We just sent you an email. Please go to your inbox and verify your
            account to proceed with your application.
          </Text>
          <Text fontSize="md" fontWeight="700" color="gray">
            Please refresh your browser if you have already verified your email
            and yet the changes are not reflected here.
          </Text>
        </CardBody>
        <CardFooter>
          <Button
            colorScheme="blue"
            size="lg"
            onClick={() => {
              handleResendEmailVerification();
            }}
          >
            Resend Email
          </Button>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default VerifyEmail;
