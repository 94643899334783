import { Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import GenericTable from '../../../../components/GenericTable';
import { useAgentAuth } from '../../../../context/AgentContext';
import AgentDashboardSidebar from '../../../Sidebar';

function Rejected() {
  const [loading, setLoading] = useState(false);

  const { updateAllApplicationsData, searchResults, rejectedApplications } =
    useAgentAuth();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 150,
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Application Started',
        accessor: 'applicationStartDate',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Notes',
        accessor: 'interaction',
        minWidth: 150,
        disableSortBy: true,
        isInteraction: true,
      },
    ],
    []
  );

  useEffect(() => {
    setLoading(true);
    updateAllApplicationsData(() => {
      setLoading(false);
    });
  }, []);

  return (
    <AgentDashboardSidebar>
      <Heading size="lg">Rejected ({rejectedApplications?.length})</Heading>
      <Text fontSize={'lg'}>
        These applications have been Rejected. For privacy reasons, we can't
        share the reasons. However, feel free to connect with your clients who
        can share that information.
      </Text>
      <GenericTable
        columns={columns}
        data={searchResults ?? rejectedApplications ?? []}
        loading={loading}
      />
    </AgentDashboardSidebar>
  );
}

export default Rejected;
