import { Flex, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import GenericTable from '../../../../components/GenericTable';
import { useAgentAuth } from '../../../../context/AgentContext';
import AgentDashboardSidebar from '../../../Sidebar';
import moment from 'moment';

function AgentInviteListing() {
  const { searchResults, invitedAgents, getAgentInvitesData } = useAgentAuth();
  const [loading, setLoading] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 150,
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Invite Sent',
        accessor: 'createdAt',
        minWidth: 150,

        Cell: ({ value }) => moment(value).fromNow('DD/MM/YYYY'),
        sortType: (a, b) => {
          const dateA = a.values.createdAt
            ? new Date(a.values.createdAt)
            : undefined;
          const dateB = b.values.createdAt
            ? new Date(b.values.createdAt)
            : undefined;
          return dateA && dateB ? dateA - dateB : 0;
        },
      },
      {
        Header: 'Last login',
        accessor: 'lastLogin',
        minWidth: 150,
        // disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'applicationStatus',
        minWidth: 150,
        // disableSortBy: true,
      },

      // {
      //   Header: 'Notes',
      //   accessor: 'interaction',
      //   minWidth: 150,
      //   disableSortBy: true,
      //   isInteraction: true,
      // },
      {
        Header: 'Actions',
        accessor: 'action',
        minWidth: 150,
        disableSortBy: true,
        isAction: true,
      },
    ],
    []
  );

  useEffect(() => {
    setLoading(true);

    getAgentInvitesData(() => {
      setLoading(false);
    });
  }, []);

  return (
    <AgentDashboardSidebar>
      <Flex>
        <Heading size="lg">Invited Agents ({invitedAgents?.length})</Heading>
      </Flex>
      <GenericTable
        columns={columns}
        data={searchResults ? searchResults : invitedAgents}
        loading={loading}
        isAgent={true}
        detailsDisabled={true}
      />
    </AgentDashboardSidebar>
  );
}

export default AgentInviteListing;
