import { Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import GenericTable from '../../../../components/GenericTable';
import { useAgentAuth } from '../../../../context/AgentContext';
import AgentDashboardSidebar from '../../../Sidebar';

function ConditionallyApproved() {
  const [loading, setLoading] = useState(false);

  const {
    updateAllApplicationsData,
    conditionallyApprovedApplications,
    searchResults,
  } = useAgentAuth();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 150,
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Application Started',
        accessor: 'applicationStartDate',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Notes',
        accessor: 'interaction',
        minWidth: 150,
        disableSortBy: true,
        isInteraction: true,
      },
    ],
    []
  );

  useEffect(() => {
    setLoading(true);
    updateAllApplicationsData(() => {
      setLoading(false);
    });
  }, []);
  return (
    <AgentDashboardSidebar>
      <Heading size="lg">
        Conditionally Approved ({conditionallyApprovedApplications?.length})
      </Heading>
      <Text fontSize={'lg'}>
        Almost there! You can now submit an offer on the clients' dream home or
        Utopia.
      </Text>
      <GenericTable
        columns={columns}
        data={searchResults ?? conditionallyApprovedApplications ?? []}
        loading={loading}
      />
    </AgentDashboardSidebar>
  );
}

export default ConditionallyApproved;
