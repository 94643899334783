import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// context
import { useLandlord } from '../../../../context/LandlordContext';

// components
import AgentDashboardSidebar from '../../../Sidebar';
import AddPropertyApplication from './AddPropertyApplication/AddPropertyApplication';

// chakra
import { Container, Box, Spinner } from '@chakra-ui/react';

const AddProperty = () => {
  const { id } = useParams();
  const {
    setPropertyApplication,
    currentApplicationForm,
    setCurrentApplicationForm,
  } = useLandlord();
  const navigate = useNavigate();

  const [applicationCompleted, setApplicationCompleted] = useState(null);
  const [applicationLoaded, setApplicationLoaded] = useState(false);

  useEffect(() => {
    const fetchApplicationData = async () => {
      try {
        const applicationResponse = await setPropertyApplication(id);

        if (applicationResponse.success) {
          setApplicationLoaded(true);
        } else {
          setApplicationLoaded(true);
          setCurrentApplicationForm(null);
          navigate('/agents/properties');
        }
      } catch (err) {
        console.error('Error fetching property application:', err);
        setApplicationLoaded(true);
        navigate('/agents/properties');
      }
    };

    fetchApplicationData();
  }, [id]);

  useEffect(() => {
    if (applicationLoaded && currentApplicationForm) {
      if (currentApplicationForm?.data?.applicationCompleted) {
        setApplicationCompleted(true);
        setCurrentApplicationForm(null);
        navigate(`/agents/properties`);
      } else {
        setApplicationCompleted(false);
      }
    }
  }, [currentApplicationForm]);

  return (
    <AgentDashboardSidebar showHeader={false}>
      <Container maxW="container.sm">
        {applicationLoaded && currentApplicationForm ? (
          applicationCompleted ? (
            <Box>Application Finished</Box>
          ) : (
            <AddPropertyApplication
              currentApplication={currentApplicationForm}
            />
          )
        ) : (
          <Spinner />
        )}
      </Container>
    </AgentDashboardSidebar>
  );
};

export default AddProperty;
