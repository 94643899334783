import { HTTP_CLIENT } from '../interceptor';

const documentsEmailToAgent = async params => {
  const response = await HTTP_CLIENT.post(
    'email/additional_docs_email',
    params
  );
  return response;
};

const alertAdminOfListingInterestUnregisteredUser = async params => {
  const response = await HTTP_CLIENT.post(
    'email/landlord/client_interest_support',
    params
  );
  return response;
};

const alertAdminOfListingInterestPrequalifiedUser = async params => {
  const response = await HTTP_CLIENT.post(
    'email/landlord/client_interest_support',
    params
  );
  return response;
};

export {
  documentsEmailToAgent,
  alertAdminOfListingInterestUnregisteredUser,
  alertAdminOfListingInterestPrequalifiedUser,
};
