import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
  Text,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import { PersonalInfoUpdateSchema } from '../../../../validations/PersonalInfoUpdateSchema';

import { useAgentAuth } from '../../../../context/AgentContext';
import { useAuth } from '../../../../context/AuthContext';

const PersonalInfoUpdateButton = ({
  firstName,
  lastName,
  phone,
  brokerageInfo,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);

  const toast = useToast();
  const { updateUserFields, updateUserData, authUser } = useAuth();

  const { updateApplicationFields, changeUserProfileImage } = useAgentAuth();

  const handleSubmit = async values => {
    try {
      setButtonLoading(true);

      let avatar = authUser?.data?.avatar;

      if (profileImage) {
        avatar = await changeUserProfileImage(profileImage.data);
      }

      await updateUserFields({
        legalName: {
          firstName: values.firstName,
          lastName: values.lastName,
        },
        avatar,
      });

      if (values.phone) {
        let payload = {
          stepOne: [
            {
              question: 'Agent Phone',
              answer: values.phone,
            },
            {
              question: 'Agent License Number:',
              answer: brokerageInfo[1]?.answer,
            },
            {
              question: 'Agent Brokerage:',
              answer: brokerageInfo[2]?.answer,
            },
          ],
        };

        await updateApplicationFields(payload);
      }

      window.location.reload();

      toast({
        title: 'Profile Updated',
        description: `Info Update Successfully`,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
      setButtonLoading(false);

      onClose();
    } catch (err) {
      toast({
        title: 'Request Failed',
        description:
          'Unable to update profile at the moment, please try again later',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });

      setButtonLoading(false);
    }
  };

  return (
    <>
      <Button
        textColor={'blue.500'}
        mt={2}
        background="white"
        variant={'unstyled'}
        onClick={onOpen}
      >
        Edit
      </Button>

      <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                firstName,
                lastName,
                phone,
              }}
              validationSchema={PersonalInfoUpdateSchema}
              onSubmit={async values => {
                handleSubmit(values);
              }}
            >
              {formik => (
                <>
                  <FormControl
                    isInvalid={
                      formik.errors.firstName && formik.touched.firstName
                    }
                    mt={2}
                  >
                    <FormLabel>First Name</FormLabel>
                    <Field
                      as={Input}
                      borderWidth="2px"
                      id="firstName"
                      name="firstName"
                      size="md"
                      borderRadius="md"
                      disabled={buttonLoading}
                    />
                    <FormErrorMessage>
                      {formik.errors.firstName}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={
                      formik.errors.lastName && formik.touched.lastName
                    }
                    mt={2}
                  >
                    <FormLabel>Last Name</FormLabel>
                    <Field
                      as={Input}
                      borderWidth="2px"
                      id="lastName"
                      name="lastName"
                      size="md"
                      borderRadius="md"
                      disabled={buttonLoading}
                    />
                    <FormErrorMessage>
                      {formik.errors.lastName}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={formik.errors.phone && formik.touched.phone}
                    mt={2}
                  >
                    <FormLabel>Phone #</FormLabel>
                    <Field
                      as={Input}
                      borderWidth="2px"
                      id="phone"
                      name="phone"
                      size="md"
                      borderRadius="md"
                      type="email"
                      disabled={buttonLoading}
                    />
                    <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
                  </FormControl>

                  <FormLabel mt={2}>Profile Photo</FormLabel>

                  <input
                    type="file"
                    id="userProfileImage"
                    name="userProfileImage"
                    // accept="image/jpeg,image/gif,image/png,image/x-eps"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={e => {
                      if (e.target.files[0].size > 5242880) {
                        alert('File is too big! Must be under 5mb');
                      } else if (e.target.files[0].size < 5000) {
                        alert(
                          'This file quality is not that great; Please attach a better resolution file.'
                        );
                      } else {
                        const fileName = e.target.files[0].name;
                        setProfileImage({
                          name: fileName,
                          data: e.target.files[0],
                        });
                      }
                    }}
                  />

                  <Button as="label" for="userProfileImage">
                    {profileImage ? (
                      <Text>{profileImage.name}</Text>
                    ) : (
                      <Text>Select a File</Text>
                    )}
                  </Button>

                  <Flex mt={'10%'} justifyContent="flex-end">
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                      onClick={() => formik.handleSubmit()}
                      backgroundColor={'#1C2430'}
                      textColor={'white'}
                      minW={30}
                      ml={2}
                      isDisabled={buttonLoading}
                      _hover={{
                        bg: 'black',
                        cursor: 'pointer',
                      }}
                    >
                      {buttonLoading ? <Spinner /> : ' Update'}
                    </Button>
                  </Flex>
                </>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PersonalInfoUpdateButton;
