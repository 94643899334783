import { Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import GenericTable from '../../../../components/GenericTable';
import { useAgentAuth } from '../../../../context/AgentContext';

import AgentDashboardSidebar from '../../../Sidebar';
import moment from 'moment';

function ApplicationStarted() {
  const [loading, setLoading] = useState(false);

  const { updateAllApplicationsData, startedApplications, searchResults } =
    useAgentAuth();
  console.log(
    '🚀 ~ file: index.js:13 ~ ApplicationStarted ~ startedApplications:',
    startedApplications
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 150,
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 150,
      },

      // {
      //   Header: 'Application Started',
      //   accessor: 'createdAt',
      //   minWidth: 150,

      //   Cell: ({ value }) => (value ? moment(value).fromNow('DD/MM/YYYY') : ''),
      //   sortType: (a, b) => {
      //     const dateA = a.values.createdAt
      //       ? new Date(a.values.createdAt)
      //       : undefined;
      //     const dateB = b.values.createdAt
      //       ? new Date(b.values.createdAt)
      //       : undefined;
      //     return dateA && dateB ? dateA - dateB : 0;
      //   },
      // },

      {
        Header: 'Application Started',
        accessor: 'startDate',
        minWidth: 150,

        Cell: ({ value }) => (value ? moment(value).fromNow('DD/MM/YYYY') : ''),
        sortType: (a, b) => {
          const dateA = a.values.startDate
            ? new Date(a.values.startDate)
            : undefined;
          const dateB = b.values.startDate
            ? new Date(b.values.startDate)
            : undefined;
          return dateA && dateB ? dateA - dateB : 0;
        },
        // disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'action',
        minWidth: 150,
        disableSortBy: true,
        isAction: true,
      },

      {
        Header: 'Notes',
        accessor: 'interaction',
        minWidth: 150,
        disableSortBy: true,
        isInteraction: true,
      },
    ],
    []
  );

  useEffect(() => {
    setLoading(true);
    updateAllApplicationsData(() => {
      setLoading(false);
    });
  }, []);

  return (
    <AgentDashboardSidebar>
      <Flex flexDirection={'column'}>
        <Heading size="lg">
          Started Applications ({startedApplications?.length})
        </Heading>
        <Text fontSize={'lg'}>
          Your clients just signed up with Utopia. Please help them get
          pre-qualified to receive their home buying budget.
        </Text>
      </Flex>
      <GenericTable
        columns={columns}
        data={searchResults ? searchResults : startedApplications}
        loading={loading}
      />
    </AgentDashboardSidebar>
  );
}

export default ApplicationStarted;
