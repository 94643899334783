import React, { Fragment } from 'react';

// chakra
import {
  Box,
  Button,
  Container,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const ApplicationSubmitted = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Container maxW="container.sm">
        <Box
          w="100%"
          px={6}
          py={10}
          bg="white"
          rounded="md"
          align="center"
          textAlign="center"
        >
          <Stack spacing="8" align="center" textAlign="center">
            <Image
              boxSize="100px"
              objectFit="cover"
              src="https://i.imgur.com/NwzpGQ9.png"
              alt="image"
            />
            <Box>
              <Heading as="h3" size="lg" mb="3">
                Thank you for joining Utopia Agent Network.
              </Heading>
              <Text fontSize="md" color="gray.500" mb="6">
                Start inviting clients who are not yet mortgage ready for our
                innovative "Rent, Earn, and Own" Program. Your clients can move
                in to their dream home as early as 21 days and you earn your
                full commissions.
              </Text>
              <Button
                colorScheme="blue"
                size="lg"
                onClick={() => navigate('/agents')}
              >
                Invite Clients
              </Button>
            </Box>
          </Stack>
        </Box>
      </Container>
    </Fragment>
  );
};

export default ApplicationSubmitted;
