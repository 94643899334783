import { Fragment, useState, useEffect } from 'react';
import { isEmptyString } from '../../../../utils/generalFunctions';

// utils
import {
  states,
  otherStates,
  metroAreas,
} from '../../../../utils/statesAndMetros.js';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Spinner,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
} from '@chakra-ui/react';

const HomebuyerStepThree = ({
  setActiveStep,
  stepThreeState,
  setStepThreeState,
  stepThreeMetroArea,
  setStepThreeMetroArea,
  stepThreeCities,
  setStepThreeCities,
  handleNext,
  loading,
  updateApplicationFields,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [stateValue, setStateValue] = useState('');
  const [otherStateValue, setOtherStateValue] = useState('');
  const [metroValue, setMetroValue] = useState('');
  const [otherMetroValue, setOtherMetroValue] = useState('');
  const [citiesValue, setCitiesValue] = useState('');

  useEffect(() => {
    setStateValue('');
    setOtherStateValue('');
    setMetroValue('');
    setOtherMetroValue('');
    setCitiesValue('');

    if (stepThreeState?.includes('Other - ')) {
      setStateValue('Other States');
      setOtherStateValue(stepThreeState.replace('Other - ', ''));
    } else {
      setStateValue(stepThreeState);
      setOtherStateValue('');
    }

    if (stepThreeMetroArea?.includes('Other - ')) {
      setMetroValue('Other');
      setOtherMetroValue(stepThreeMetroArea.replace('Other - ', ''));
    } else {
      setMetroValue(stepThreeMetroArea);
      setOtherMetroValue('');
    }

    setCitiesValue(stepThreeCities);
  }, []);

  const handleSubmit = async () => {
    console.log('Running handleSubmit()');
    try {
      if (
        stateValue === '' ||
        (stateValue === 'Other States' && otherStateValue === '') ||
        metroValue === '' ||
        (metroValue === 'Other' && otherMetroValue === '')
      ) {
        return;
      }

      let finalStateValue = '';
      let finalMetroValue = '';
      let finalCityValue = '';

      if (stateValue === 'Other States') {
        finalStateValue = `Other - ${otherStateValue}`;
      } else {
        finalStateValue = stateValue;
      }

      if (metroValue === 'Other') {
        finalMetroValue = `Other - ${otherMetroValue}`;
      } else {
        finalMetroValue = metroValue;
      }

      finalCityValue = citiesValue;

      setStepThreeState(finalStateValue);
      setStepThreeMetroArea(finalMetroValue);
      setStepThreeCities(finalCityValue);

      if (stateValue === 'Other States') {
        await updateApplicationFields({
          stepThree: [
            {
              question: 'Where do you plan to live? Metro Area:',
              answer: finalMetroValue,
            },
            {
              question: 'Where do you plan to live? Cities:',
              answer: finalCityValue,
            },
            {
              question: 'Where do you plan to live? State:',
              answer: finalStateValue,
            },
          ],
          currentStep: 3,
        });
        onOpen();
      } else {
        await updateApplicationFields({
          stepThree: [
            {
              question: 'Where do you plan to live? Metro Area:',
              answer: finalMetroValue,
            },
            {
              question: 'Where do you plan to live? Cities:',
              answer: finalCityValue,
            },
            {
              question: 'Where do you plan to live? State:',
              answer: finalStateValue,
            },
          ],
          currentStep: 3,
        });
        await handleNext();
      }
    } catch (err) {
      console.log('handleSubmit() Error: ', err);
    }
  };

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(3 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Heading as="h4" size="md" mb="5">
              Where do you plan to live?
            </Heading>
            <Stack spacing="5">
              <FormControl>
                <FormLabel color="gray">State</FormLabel>
                <Select
                  placeholder="Select a State"
                  value={stateValue}
                  onChange={e => {
                    setStateValue(e.target.value);

                    setOtherStateValue('');
                    setMetroValue('');
                    setOtherMetroValue('');
                    setCitiesValue('');
                  }}
                >
                  {states?.map(state => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {stateValue === 'Other States' && (
                <FormControl>
                  <FormLabel color="gray">Other State</FormLabel>
                  <Select
                    placeholder="Select a State"
                    value={otherStateValue}
                    onChange={e => {
                      setOtherStateValue(e.target.value);

                      setStateValue('Other States');
                      setMetroValue('');
                      setOtherMetroValue('');
                      setCitiesValue('');
                    }}
                  >
                    {otherStates?.map(state => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}

              {stateValue !== '' && stateValue !== 'Other States' && (
                <FormControl>
                  <FormLabel color="gray">Metro Area of Interest</FormLabel>
                  <Select
                    placeholder="Select a Metro Area"
                    value={metroValue}
                    onChange={e => {
                      setMetroValue(e.target.value);

                      setOtherMetroValue('');
                      setCitiesValue('');
                    }}
                  >
                    {metroAreas[stateValue]?.map(metroArea => (
                      <option key={metroArea} value={metroArea}>
                        {metroArea}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}

              {((stateValue === 'Other States' && otherStateValue !== '') ||
                (stateValue !== '' &&
                  stateValue !== 'Other States' &&
                  metroValue === 'Other')) && (
                <FormControl>
                  <FormLabel color="gray">Metro Area of Interest</FormLabel>
                  <Textarea
                    borderWidth="2px"
                    size="lg"
                    type="text"
                    placeholder={`Enter the metro area you're interested in living in`}
                    value={otherMetroValue}
                    onChange={e => {
                      setOtherMetroValue(e.target.value);

                      setMetroValue('Other');
                      setCitiesValue('');
                    }}
                  />
                </FormControl>
              )}

              {((stateValue !== '' && stateValue !== 'Other States') ||
                (stateValue === 'Other States' && otherStateValue !== '')) &&
                ((metroValue !== '' && metroValue !== 'Other') ||
                  (metroValue === 'Other' && otherMetroValue !== '')) && (
                  <FormControl>
                    <FormLabel color="gray">
                      Cities of Interest <i>(Optional)</i>:
                    </FormLabel>
                    <Textarea
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={citiesValue}
                      onChange={e => {
                        setCitiesValue(e.target.value);
                      }}
                    />
                  </FormControl>
                )}
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(2);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={async () => {
              await handleSubmit();
            }}
            isDisabled={
              stateValue === '' ||
              (stateValue === 'Other States' && otherStateValue === '') ||
              metroValue === '' ||
              (metroValue === 'Other' && otherMetroValue === '')
            }
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Market currently not served</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              We are currently not serving the <b>{otherStateValue}</b> market.
              We will let you know when are are expanding into the region that
              you would like to buy a house in.
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default HomebuyerStepThree;
