import { Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import GenericTable from '../../../../components/GenericTable';
import { useAgentAuth } from '../../../../context/AgentContext';
import AgentDashboardSidebar from '../../../Sidebar';

function OfferAccepted() {
  const [loading, setLoading] = useState(false);

  const { updateAllApplicationsData, offersData, searchResults } =
    useAgentAuth();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 150,
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Application Started',
        accessor: 'applicationStartDate',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Notes',
        accessor: 'interaction',
        minWidth: 150,
        disableSortBy: true,
        isInteraction: true,
      },
    ],
    []
  );

  useEffect(() => {
    setLoading(true);
    updateAllApplicationsData(() => {
      setLoading(false);
    });
  }, []);

  return (
    <AgentDashboardSidebar>
      <Heading size="lg">
        Offer Accepted ({offersData?.offerAccepted?.length})
      </Heading>
      <Text fontSize={'lg'}>
        Congratulations, these offers have been accepted! Now it’s time for us
        to start the inspection negotiation process.
      </Text>

      <GenericTable
        columns={columns}
        data={searchResults ?? offersData?.offerAccepted ?? []}
        loading={loading}
      />
    </AgentDashboardSidebar>
  );
}

export default OfferAccepted;
