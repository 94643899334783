import React, { Fragment, forwardRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import { atLeast18, isEmptyString } from '../../../../utils/generalFunctions';

// chakra
import { CalendarIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Progress,
  Select,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Link,
} from '@chakra-ui/react';

const HomebuyerStepEighteen = ({
  setActiveStep,
  stepOnePhoneNumber,
  setStepOnePhoneNumber,
  stepTwoMovePlan,
  setStepTwoMovePlan,
  stepThreeState,
  setStepThreeState,
  stepThreeMetroArea,
  setStepThreeMetroArea,
  stepThreeCities,
  setStepThreeCities,
  stepFourRealEstateAgent,
  setStepFourRealEstateAgent,
  stepFourRealEstateAgentFirstName,
  setStepFourRealEstateAgentFirstName,
  stepFourRealEstateAgentLastName,
  setStepFourRealEstateAgentLastName,
  stepFourRealEstateAgentEmail,
  setStepFourRealEstateAgentEmail,
  stepFourRealEstateAgentPhone,
  setStepFourRealEstateAgentPhone,
  stepFourConnectToRealEstateAgent,
  setStepFourConnectToRealEstateAgent,
  stepFiveCoApplicant,
  setStepFiveCoApplicant,
  stepFiveCoApplicantFirstName,
  setStepFiveCoApplicantFirstName,
  stepFiveCoApplicantLastName,
  setStepFiveCoApplicantLastName,
  stepFiveCoApplicantEmail,
  setStepFiveCoApplicantEmail,
  stepFiveCoApplicantPhone,
  setStepFiveCoApplicantPhone,
  stepSixMonthlyIncome,
  setStepSixMonthlyIncome,
  stepSixRent,
  setStepSixRent,
  stepSixHomePreferredPrice,
  setStepSixHomePreferredPrice,
  stepSixDownPaymentSavings,
  setStepSixDownPaymentSavings,
  stepSixCoApplicantMonthlyIncome,
  setStepSixCoApplicantMonthlyIncome,
  stepSevenCreditFirstName,
  setStepSevenCreditFirstName,
  stepSevenCreditMiddleName,
  setStepSevenCreditMiddleName,
  stepSevenCreditLastName,
  setStepSevenCreditLastName,
  stepSevenCreditStreetAddress,
  setStepSevenCreditStreetAddress,
  stepSevenCreditZipCode,
  setStepSevenCreditZipCode,
  stepSevenCreditSsn,
  setStepSevenCreditSsn,
  stepEightPrequalifiedAmount,
  stepNinePrimaryIncome,
  setStepNinePrimaryIncome,
  stepTenEmployerName,
  setStepTenEmployerName,
  stepTenStartDate,
  setStepTenStartDate,
  stepTenEmployerCity,
  setStepTenEmployerCity,
  stepTenEmployerState,
  setStepTenEmployerState,
  stepElevenPayPeriod,
  setStepElevenPayPeriod,
  stepElevenPreTaxIncome,
  setStepElevenPreTaxIncome,
  stepTwelveIncomeDocOne,
  stepTwelveIncomeDocTwo,
  stepTwelveIncomeDocThree,
  stepThirteenBankStatementOne,
  stepThirteenBankStatementTwo,
  stepThirteenBankStatementThree,
  stepFourteenHasRaise,
  setStepFourteenHasRaise,
  stepFifteenMoreThanOneSalary,
  setStepFifteenMoreThanOneSalary,
  stepFifteenAdditionalYearlySalary,
  setStepFifteenAdditionalYearlySalary,
  stepSixteenIdentityDoc,
  stepSeventeenBackgroundCheckEviction,
  setStepSeventeenBackgroundCheckEviction,
  stepSeventeenBackgroundCheckDOB,
  setStepSeventeenBackgroundCheckDOB,
  stepEighteenReviewApp,
  setStepEighteenReviewApp,
  authUser,
  handleAppFinished,
  handleSubmitApplication,
  handleNext,
  loading,
  setLoading,
  handleSubmitDocuments,
}) => {
  const {
    isOpen: isOpenRealEstate,
    onOpen: onOpenRealEstate,
    onClose: onCloseRealEstate,
  } = useDisclosure();
  const {
    isOpen: isOpenCoApplicant,
    onOpen: onOpenCoApplicant,
    onClose: onCloseCoApplicant,
  } = useDisclosure();
  const {
    isOpen: isOpenError,
    onOpen: onOpenError,
    onClose: onCloseError,
  } = useDisclosure();
  const {
    isOpen: isOpenIncome,
    onOpen: onOpenIncome,
    onClose: onCloseIncome,
  } = useDisclosure();

  const format = val => `$` + val;
  const parse = val => val.replace(/^\$/, '');

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      borderWidth="2px"
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
      w="100%"
      size="lg"
      variant="ghost"
    >
      <Flex w="100%" align="center">
        {value === null || value === '' ? (
          <Text color="gray.400">mm/dd/yyyy</Text>
        ) : (
          <Text>{value}</Text>
        )}
        <Spacer />
        <CalendarIcon />
      </Flex>
    </Button>
  ));

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="green"
          rounded="full"
          size="md"
          value={(18 / 18) * 100}
          bg="gray.200"
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md" mb="1">
                    Review your Application
                  </Heading>
                  <Text
                    fontSize="md"
                    fontWeight="600"
                    color="gray"
                    textAlign="justify"
                  >
                    Before you submit your application please make sure the
                    answers you have put in, are truthful and accurate to the
                    best of your knowledge. If not, please edit before you
                    submit.
                  </Text>
                </Box>

                <Box>
                  <TableContainer mb="8">
                    <Table variant="simple" size="sm">
                      <Thead>
                        <Tr>
                          <Th>Question</Th>
                          <Th>Answer</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>
                            <Box>
                              <Text fontSize={'xs'}>
                                What is your phone number?
                              </Text>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepOnePhoneNumber}
                                  onChange={e => {
                                    const value = e.target.value.replace(
                                      /\D/g,
                                      ''
                                    );
                                    setStepOnePhoneNumber(value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'xs'}>
                                  When do you plan to move?
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Select
                                  size="xs"
                                  value={stepTwoMovePlan}
                                  onChange={e => {
                                    setStepTwoMovePlan(e.target.value);
                                  }}
                                >
                                  <option value="As soon as possible">
                                    As soon as possible
                                  </option>
                                  <option value="In 1 - 2 months">
                                    In 1 - 2 months
                                  </option>
                                  <option value="In 3 - 4 months">
                                    In 3 - 4 months
                                  </option>
                                  <option value="In over 4 months">
                                    In over 4 months
                                  </option>
                                  <option value="Don't know yet">
                                    Don't know yet
                                  </option>
                                </Select>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Where do you plan to live
                                </Text>
                                <Text fontSize={'xs'}>State:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepThreeState}
                                  onChange={e => {
                                    setStepThreeState(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Where do you plan to live
                                </Text>
                                <Text fontSize={'xs'}>Metro Area:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepThreeMetroArea}
                                  onChange={e => {
                                    setStepThreeMetroArea(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Where do you plan to live
                                </Text>
                                <Text fontSize={'xs'}>
                                  Cities of Interest <i>(Optional)</i>:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Textarea
                                  size="xs"
                                  value={stepThreeCities}
                                  onChange={e => {
                                    setStepThreeCities(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Real estate agent
                                </Text>
                                <Text fontSize={'xs'}>
                                  Are you currently working with a real estate
                                  agent?
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Select
                                  size="xs"
                                  value={stepFourRealEstateAgent}
                                  onChange={e => {
                                    if (
                                      stepFourRealEstateAgent === 'yes' &&
                                      e.target.value === 'no' &&
                                      (!isEmptyString(
                                        stepFourRealEstateAgentFirstName
                                      ) ||
                                        !isEmptyString(
                                          stepFourRealEstateAgentLastName
                                        ) ||
                                        !isEmptyString(
                                          stepFourRealEstateAgentEmail
                                        ) ||
                                        !isEmptyString(
                                          stepFourRealEstateAgentPhone
                                        ))
                                    ) {
                                      onOpenRealEstate();
                                    } else {
                                      setStepFourRealEstateAgent(
                                        e.target.value
                                      );
                                    }
                                  }}
                                >
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </Select>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        {stepFourRealEstateAgent == 'yes' ? (
                          <Fragment>
                            <Tr>
                              <Td>
                                <Box>
                                  <Stack spacing={3}>
                                    <Text fontSize={'2xs'} color={'gray.400'}>
                                      Real estate agent
                                    </Text>
                                    <Text fontSize={'xs'}>
                                      Please enter the details of your real
                                      estate agent - First Name?
                                    </Text>
                                  </Stack>
                                </Box>
                              </Td>
                              <Td>
                                <Box>
                                  <Stack>
                                    <Input
                                      size="xs"
                                      value={stepFourRealEstateAgentFirstName}
                                      onChange={e => {
                                        setStepFourRealEstateAgentFirstName(
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </Td>
                            </Tr>

                            <Tr>
                              <Td>
                                <Box>
                                  <Stack spacing={3}>
                                    <Text fontSize={'2xs'} color={'gray.400'}>
                                      Real estate agent
                                    </Text>
                                    <Text fontSize={'xs'}>
                                      Please enter the details of your real
                                      estate agent - Last Name?
                                    </Text>
                                  </Stack>
                                </Box>
                              </Td>
                              <Td>
                                <Box>
                                  <Stack>
                                    <Input
                                      size="xs"
                                      value={stepFourRealEstateAgentLastName}
                                      onChange={e => {
                                        setStepFourRealEstateAgentLastName(
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </Td>
                            </Tr>

                            <Tr>
                              <Td>
                                <Box>
                                  <Stack spacing={3}>
                                    <Text fontSize={'2xs'} color={'gray.400'}>
                                      Real estate agent
                                    </Text>
                                    <Text fontSize={'xs'}>
                                      Please enter the details of your real
                                      estate agent - Email?
                                    </Text>
                                  </Stack>
                                </Box>
                              </Td>
                              <Td>
                                <Box>
                                  <Stack>
                                    <Input
                                      size="xs"
                                      value={stepFourRealEstateAgentEmail}
                                      onChange={e => {
                                        setStepFourRealEstateAgentEmail(
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </Td>
                            </Tr>

                            <Tr>
                              <Td>
                                <Box>
                                  <Stack spacing={3}>
                                    <Text fontSize={'2xs'} color={'gray.400'}>
                                      Real estate agent
                                    </Text>
                                    <Text fontSize={'xs'}>
                                      Please enter the details of your real
                                      estate agent - Phone Number?
                                    </Text>
                                  </Stack>
                                </Box>
                              </Td>
                              <Td>
                                <Box>
                                  <Stack>
                                    <Input
                                      size="xs"
                                      value={stepFourRealEstateAgentPhone}
                                      onChange={e => {
                                        const value = e.target.value.replace(
                                          /\D/g,
                                          ''
                                        );
                                        setStepFourRealEstateAgentPhone(value);
                                      }}
                                      maxLength="10"
                                    />
                                  </Stack>
                                </Box>
                              </Td>
                            </Tr>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Tr>
                              <Td>
                                <Box>
                                  <Stack spacing={3}>
                                    <Text fontSize={'2xs'} color={'gray.400'}>
                                      Real estate agent
                                    </Text>
                                    <Text fontSize={'xs'}>
                                      Can we connect you with our Partner real
                                      estate agent?
                                    </Text>
                                  </Stack>
                                </Box>
                              </Td>
                              <Td>
                                <Box>
                                  <Stack>
                                    <Select
                                      size="xs"
                                      value={stepFourConnectToRealEstateAgent}
                                      onChange={e => {
                                        setStepFourConnectToRealEstateAgent(
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option value="yes">Yes</option>
                                      <option value="no">No</option>
                                    </Select>
                                  </Stack>
                                </Box>
                              </Td>
                            </Tr>
                          </Fragment>
                        )}

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Co-Buyer
                                </Text>
                                <Text fontSize={'xs'}>
                                  Do you have a Co-Buyer?
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Select
                                  size="xs"
                                  value={stepFiveCoApplicant}
                                  onChange={e => {
                                    if (
                                      stepFiveCoApplicant === 'yes' &&
                                      e.target.value === 'no' &&
                                      (!isEmptyString(
                                        stepFiveCoApplicantFirstName
                                      ) ||
                                        !isEmptyString(
                                          stepFiveCoApplicantLastName
                                        ) ||
                                        !isEmptyString(
                                          stepFiveCoApplicantEmail
                                        ) ||
                                        !isEmptyString(
                                          stepFiveCoApplicantPhone
                                        ))
                                    ) {
                                      onOpenCoApplicant();
                                    } else {
                                      setStepFiveCoApplicant(e.target.value);
                                    }
                                  }}
                                >
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </Select>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        {stepFiveCoApplicant == 'yes' && (
                          <Fragment>
                            <Tr>
                              <Td>
                                <Box>
                                  <Stack spacing={3}>
                                    <Text fontSize={'2xs'} color={'gray.400'}>
                                      Co-Buyer
                                    </Text>
                                    <Text fontSize={'xs'}>
                                      Please enter the details of your Co-Buyer
                                      - First Name?
                                    </Text>
                                  </Stack>
                                </Box>
                              </Td>
                              <Td>
                                <Box>
                                  <Stack>
                                    <Input
                                      size="xs"
                                      value={stepFiveCoApplicantFirstName}
                                      onChange={e => {
                                        setStepFiveCoApplicantFirstName(
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </Td>
                            </Tr>

                            <Tr>
                              <Td>
                                <Box>
                                  <Stack spacing={3}>
                                    <Text fontSize={'2xs'} color={'gray.400'}>
                                      Co-Buyer
                                    </Text>
                                    <Text fontSize={'xs'}>
                                      Please enter the details of your Co-Buyer
                                      - Last Name?
                                    </Text>
                                  </Stack>
                                </Box>
                              </Td>
                              <Td>
                                <Box>
                                  <Stack>
                                    <Input
                                      size="xs"
                                      value={stepFiveCoApplicantLastName}
                                      onChange={e => {
                                        setStepFiveCoApplicantLastName(
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </Td>
                            </Tr>

                            <Tr>
                              <Td>
                                <Box>
                                  <Stack spacing={3}>
                                    <Text fontSize={'2xs'} color={'gray.400'}>
                                      Co-Buyer
                                    </Text>
                                    <Text fontSize={'xs'}>
                                      Please enter the details of your co-buyer
                                      - Email?
                                    </Text>
                                  </Stack>
                                </Box>
                              </Td>
                              <Td>
                                <Box>
                                  <Stack>
                                    <Input
                                      size="xs"
                                      value={stepFiveCoApplicantEmail}
                                      onChange={e => {
                                        setStepFiveCoApplicantEmail(
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </Td>
                            </Tr>

                            <Tr>
                              <Td>
                                <Box>
                                  <Stack spacing={3}>
                                    <Text fontSize={'2xs'} color={'gray.400'}>
                                      Co-Buyer
                                    </Text>
                                    <Text fontSize={'xs'}>
                                      Please enter the details of your co-buyer
                                      - Phone Number?
                                    </Text>
                                  </Stack>
                                </Box>
                              </Td>
                              <Td>
                                <Box>
                                  <Stack>
                                    <Input
                                      size="xs"
                                      value={stepFiveCoApplicantPhone}
                                      onChange={e => {
                                        const value = e.target.value.replace(
                                          /\D/g,
                                          ''
                                        );
                                        setStepFiveCoApplicantPhone(value);
                                      }}
                                      maxLength="10"
                                    />
                                  </Stack>
                                </Box>
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>
                                <Box>
                                  <Stack spacing={3}>
                                    <Text fontSize={'2xs'} color={'gray.400'}>
                                      Co-Buyer
                                    </Text>
                                    <Text fontSize={'xs'}>
                                      Please enter the details of your Co-Buyer
                                      - Monthly Gross Income?
                                    </Text>
                                  </Stack>
                                </Box>
                              </Td>
                              <Td>
                                <Box>
                                  <Stack>
                                    <NumberInput
                                      size="xs"
                                      value={format(
                                        stepSixCoApplicantMonthlyIncome
                                      )}
                                      onChange={valueString =>
                                        setStepSixCoApplicantMonthlyIncome(
                                          parse(valueString)
                                        )
                                      }
                                      min={0}
                                      // min={3500}
                                    >
                                      <NumberInputField />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                  </Stack>
                                </Box>
                              </Td>
                            </Tr>
                          </Fragment>
                        )}

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Please share your financial situation:
                                </Text>
                                <Text fontSize={'xs'}>
                                  Your gross monthly income:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <NumberInput
                                  size="xs"
                                  value={format(stepSixMonthlyIncome)}
                                  onChange={valueString =>
                                    setStepSixMonthlyIncome(parse(valueString))
                                  }
                                  min={0}
                                  // min={3500}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Please share your financial situation:
                                </Text>
                                <Text fontSize={'xs'}>
                                  Your rent right now:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <NumberInput
                                  size="xs"
                                  value={format(stepSixRent)}
                                  onChange={valueString =>
                                    setStepSixRent(parse(valueString))
                                  }
                                  min={0}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Please share your financial situation:
                                </Text>
                                <Text fontSize={'xs'}>
                                  Your preferred price for your future home:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <NumberInput
                                  size="xs"
                                  value={format(stepSixHomePreferredPrice)}
                                  onChange={valueString =>
                                    setStepSixHomePreferredPrice(
                                      parse(valueString)
                                    )
                                  }
                                  min={125000}
                                  max={500000}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Please share your financial situation:
                                </Text>
                                <Text fontSize={'xs'}>
                                  Your available savings for a down payment:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <NumberInput
                                  size="xs"
                                  value={format(stepSixDownPaymentSavings)}
                                  onChange={valueString =>
                                    setStepSixDownPaymentSavings(
                                      parse(valueString)
                                    )
                                  }
                                  min={0}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        {/* <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Please share your financial situation -
                                  Co-Buyer:
                                </Text>
                                <Text fontSize={'xs'}>
                                  Please enter the details of your Co-Buyer -
                                  Monthly Gross Income?
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <NumberInput
                                  size="xs"
                                  value={format(
                                    stepSixCoApplicantMonthlyIncome
                                  )}
                                  onChange={valueString =>
                                    setStepSixCoApplicantMonthlyIncome(
                                      parse(valueString)
                                    )
                                  }
                                  min={0}
                                  // min={3500}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr> */}

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  We need to perform credit verification
                                </Text>
                                <Text fontSize={'xs'}>Legal First Name:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepSevenCreditFirstName}
                                  onChange={e => {
                                    setStepSevenCreditFirstName(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  We need to perform credit verification
                                </Text>
                                <Text fontSize={'xs'}>Legal Middle Name:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepSevenCreditMiddleName}
                                  onChange={e => {
                                    setStepSevenCreditMiddleName(
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'xs'} color={'gray.400'}>
                                  We need to perform credit verification
                                </Text>
                                <Text>Legal Last Name:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepSevenCreditLastName}
                                  onChange={e => {
                                    setStepSevenCreditLastName(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  We need to perform credit verification
                                </Text>
                                <Text fontSize={'xs'}>Street Address:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepSevenCreditStreetAddress}
                                  onChange={e => {
                                    setStepSevenCreditStreetAddress(
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  We need to perform credit verification
                                </Text>
                                <Text fontSize={'xs'}>Zip Code:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  type="number"
                                  value={stepSevenCreditZipCode}
                                  onChange={e => {
                                    setStepSevenCreditZipCode(e.target.value);
                                  }}
                                  maxLength="5"
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  We need to perform credit verification
                                </Text>
                                <Text fontSize={'xs'}>
                                  Social Security Number or ITIN:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  type="number"
                                  value={stepSevenCreditSsn}
                                  onChange={e => {
                                    if (e.target.value.length <= 9) {
                                      if (
                                        e.target.value.length !== 0 &&
                                        isNaN(
                                          e.target.value[
                                            e.target.value.length - 1
                                          ]
                                        )
                                      ) {
                                        let newString =
                                          e.target.value.substring(
                                            0,
                                            e.target.value.length - 1
                                          );
                                        setStepSevenCreditSsn(newString);
                                      } else {
                                        setStepSevenCreditSsn(e.target.value);
                                      }
                                    }
                                  }}
                                  maxLength="9"
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'xs'}>Your primary income</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Select
                                  size="xs"
                                  value={stepNinePrimaryIncome}
                                  onChange={e => {
                                    setStepNinePrimaryIncome(e.target.value);
                                  }}
                                >
                                  <option value="Salary / hourly">
                                    Salary / hourly
                                  </option>
                                  <option value="Independent Contractor">
                                    Independent Contractor
                                  </option>
                                  <option value="Self-employed">
                                    Self-employed
                                  </option>
                                </Select>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Employer's details:
                                </Text>
                                <Text fontSize={'xs'}>Employer's name:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepTenEmployerName}
                                  onChange={e => {
                                    setStepTenEmployerName(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Employer's details:
                                </Text>
                                <Text fontSize={'xs'}>Start date:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                {/* {isIos() ? (
                                  <DatePicker
                                    selected={stepTenStartDate}
                                    onChange={date => setStepTenStartDate(date)}
                                    minDate={'1950-01-01T04:49:20.366Z'}
                                    maxDate={new Date()}
                                    placeholderText="mm/dd/yyyy"
                                    customInput={<DateCustomInput />}
                                  />
                                ) : (
                                  <Input
                                    id="startDateDateInput"
                                    borderWidth="2px"
                                    size="lg"
                                    type="date"
                                    placeholder="mm/dd/yyyy"
                                    min="1950-01-01"
                                    max={new Date().toISOString().split('T')[0]}
                                    onKeyDown={e => e.preventDefault()}
                                    value={stepTenStartDate}
                                    onChange={e => {
                                      setStepTenStartDate(e.target.value);
                                    }}
                                  />
                                )} */}
                                <Input
                                  id="startDateDateInput"
                                  borderWidth="2px"
                                  size="lg"
                                  type="date"
                                  placeholder="mm/dd/yyyy"
                                  min="1950-01-01"
                                  max={new Date().toISOString().split('T')[0]}
                                  onKeyDown={e => e.preventDefault()}
                                  value={stepTenStartDate}
                                  onChange={e => {
                                    setStepTenStartDate(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Employer's details:
                                </Text>
                                <Text fontSize={'xs'}>
                                  Employer location City:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepTenEmployerCity}
                                  onChange={e => {
                                    setStepTenEmployerCity(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Employer's details:
                                </Text>
                                <Text fontSize={'xs'}>
                                  Employer location State:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Input
                                  size="xs"
                                  value={stepTenEmployerState}
                                  onChange={e => {
                                    setStepTenEmployerState(e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Payment information for job
                                </Text>
                                <Text fontSize={'xs'}>
                                  Average pre-tax income per paycheck:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Select
                                  size="xs"
                                  value={stepElevenPayPeriod}
                                  onChange={e => {
                                    setStepElevenPayPeriod(e.target.value);
                                  }}
                                >
                                  <option value="Bi-weekly">Bi-weekly</option>
                                  <option value="Semi-monthly">
                                    Semi-monthly
                                  </option>
                                  <option value="Monthly">Monthly</option>
                                  <option value="Other">Other</option>
                                </Select>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Payment information for job
                                </Text>
                                <Text fontSize={'xs'}>
                                  Enter pre-tax income per paycheck:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <NumberInput
                                  size="xs"
                                  value={format(stepElevenPreTaxIncome)}
                                  onChange={valueString =>
                                    setStepElevenPreTaxIncome(
                                      parse(valueString)
                                    )
                                  }
                                  min={0}
                                  // min={3500}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'xs'}>
                                  Have you received a raise within the last 6
                                  months?
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Select
                                  size="xs"
                                  value={stepFourteenHasRaise}
                                  onChange={e => {
                                    setStepFourteenHasRaise(e.target.value);
                                  }}
                                >
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </Select>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Additional Salary
                                </Text>
                                <Text fontSize={'xs'}>
                                  Do you receive paychecks from more than one
                                  salaried or hourly job:
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Select
                                  size="xs"
                                  value={stepFifteenMoreThanOneSalary}
                                  onChange={e => {
                                    setStepFifteenMoreThanOneSalary(
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </Select>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        {stepFifteenMoreThanOneSalary == 'yes' && (
                          <Fragment>
                            <Tr>
                              <Td>
                                <Box>
                                  <Stack spacing={3}>
                                    <Text fontSize={'2xs'} color={'gray.400'}>
                                      Additional Salary
                                    </Text>
                                    <Text fontSize={'xs'}>
                                      Your additional yearly salary:
                                    </Text>
                                  </Stack>
                                </Box>
                              </Td>
                              <Td>
                                <Box>
                                  <Stack>
                                    <NumberInput
                                      size="xs"
                                      value={format(
                                        stepFifteenAdditionalYearlySalary
                                      )}
                                      onChange={valueString =>
                                        setStepFifteenAdditionalYearlySalary(
                                          parse(valueString)
                                        )
                                      }
                                      min={0}
                                    >
                                      <NumberInputField />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                  </Stack>
                                </Box>
                              </Td>
                            </Tr>
                          </Fragment>
                        )}

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Background Check?
                                </Text>
                                <Text fontSize={'xs'}>
                                  Has an eviction been filed against you within
                                  the last 12 months?
                                </Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                <Select
                                  size="xs"
                                  value={stepSeventeenBackgroundCheckEviction}
                                  onChange={e => {
                                    setStepSeventeenBackgroundCheckEviction(
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </Select>
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>
                            <Box>
                              <Stack spacing={3}>
                                <Text fontSize={'2xs'} color={'gray.400'}>
                                  Background Check?
                                </Text>
                                <Text fontSize={'xs'}>Date of birth:</Text>
                              </Stack>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              <Stack>
                                {/* {isIos() ? (
                                  <DatePicker
                                    selected={stepSeventeenBackgroundCheckDOB}
                                    onChange={date =>
                                      setStepSeventeenBackgroundCheckDOB(date)
                                    }
                                    maxDate={new Date(atLeast18_v2())}
                                    placeholderText="mm/dd/yyyy"
                                    customInput={<DateCustomInput />}
                                  />
                                ) : (
                                  <Input
                                    id="dobDateInput"
                                    borderWidth="2px"
                                    size="lg"
                                    type="date"
                                    placeholder="mm/dd/yyyy"
                                    value={stepSeventeenBackgroundCheckDOB}
                                    max={atLeast18()}
                                    onKeyDown={e => e.preventDefault()}
                                    onChange={e => {
                                      setStepSeventeenBackgroundCheckDOB(
                                        e.target.value
                                      );
                                    }}
                                  />
                                )} */}
                                <Input
                                  id="dobDateInput"
                                  borderWidth="2px"
                                  size="lg"
                                  type="date"
                                  placeholder="mm/dd/yyyy"
                                  value={stepSeventeenBackgroundCheckDOB}
                                  max={atLeast18()}
                                  onKeyDown={e => e.preventDefault()}
                                  onChange={e => {
                                    setStepSeventeenBackgroundCheckDOB(
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <Flex textAlign="left" justify="left">
                    <Checkbox
                      isChecked={stepEighteenReviewApp}
                      onChange={e => setStepEighteenReviewApp(e.target.checked)}
                    >
                      <Text fontSize="xs" fontWeight="800">
                        By checking this, I acknowledge that this information is
                        accurate to the best of my knowledge.
                      </Text>
                    </Checkbox>
                  </Flex>
                </Box>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(17);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="green"
            onClick={async () => {
              if (
                (stepFiveCoApplicant == 'yes' &&
                  parseFloat(stepSixMonthlyIncome) +
                    parseFloat(stepSixCoApplicantMonthlyIncome) <
                    3500) ||
                (stepFiveCoApplicant == 'no' &&
                  parseFloat(stepSixMonthlyIncome) < 3500)
              ) {
                onOpenIncome();
              } else {
                // await handleAppFinished();
                setLoading(true);

                const applicationResponses = {
                  uid: authUser.uid,
                  stepOne: [
                    {
                      question: 'What is your phone number?',
                      answer: stepOnePhoneNumber,
                    },
                  ],
                  stepTwo: [
                    {
                      question: 'When do you plan to move?',
                      answer: stepTwoMovePlan,
                    },
                  ],
                  stepThree: [
                    {
                      question: 'Where do you plan to live? Metro Area:',
                      answer: stepThreeMetroArea,
                    },
                    {
                      question: 'Where do you plan to live? Cities:',
                      answer: stepThreeCities,
                    },
                    {
                      question: 'Where do you plan to live? State:',
                      answer: stepThreeState,
                    },
                  ],
                  stepFour: [
                    {
                      question:
                        'Are you currently working with a real estate agent?',
                      answer: stepFourRealEstateAgent,
                    },
                    {
                      question:
                        'Please enter the details of your real estate agent - First Name:',
                      answer: stepFourRealEstateAgentFirstName,
                    },
                    {
                      question:
                        'Please enter the details of your real estate agent - Last Name:',
                      answer: stepFourRealEstateAgentLastName,
                    },
                    {
                      question:
                        'Please enter the details of your real estate agent - Email:',
                      answer: stepFourRealEstateAgentEmail,
                    },
                    {
                      question:
                        'Please enter the details of your real estate agent - Phone Number:',
                      answer: stepFourRealEstateAgentPhone,
                    },
                    {
                      question:
                        'Can we connect you with our Partner real estate agent?',
                      answer: stepFourConnectToRealEstateAgent,
                    },
                  ],
                  stepFive: [
                    {
                      question: 'Do you have a Co-Buyer?',
                      answer: stepFiveCoApplicant,
                    },
                    {
                      question:
                        'Please enter the details of your Co-Buyer - First Name:',
                      answer: stepFiveCoApplicantFirstName,
                    },
                    {
                      question:
                        'Please enter the details of your Co-Buyer - Last Name:',
                      answer: stepFiveCoApplicantLastName,
                    },
                    {
                      question:
                        'Please enter the details of your Co-Buyer - Email:',
                      answer: stepFiveCoApplicantEmail,
                    },
                    {
                      question:
                        'Please enter the details of your Co-Buyer - Phone Number:',
                      answer: stepFiveCoApplicantPhone,
                    },
                  ],
                  stepSix: [
                    {
                      question: 'Your gross monthly income:',
                      answer: stepSixMonthlyIncome,
                    },
                    {
                      question: 'Your rent right now:',
                      answer: stepSixRent,
                    },
                    {
                      question: 'Your preferred price for your future home:',
                      answer: stepSixHomePreferredPrice,
                    },
                    {
                      question: 'Your available savings for a down payment:',
                      answer: stepSixDownPaymentSavings,
                    },
                    {
                      question:
                        'Please enter the details of your Co-Buyer - Monthly Gross Income?',
                      answer: stepSixCoApplicantMonthlyIncome,
                    },
                  ],
                  stepSeven: [
                    {
                      question: 'Credit verification - Legal First Name:',
                      answer: stepSevenCreditFirstName,
                    },
                    {
                      question: 'Credit verification - Legal Middle Name:',
                      answer: stepSevenCreditMiddleName,
                    },
                    {
                      question: 'Credit verification - Legal Last Name:',
                      answer: stepSevenCreditLastName,
                    },
                    {
                      question: 'Credit verification - Street Address:',
                      answer: stepSevenCreditStreetAddress,
                    },
                    {
                      question: 'Credit verification - Zip Code:',
                      answer: stepSevenCreditZipCode,
                    },
                    {
                      question: 'Credit verification - Social Security:',
                      answer: stepSevenCreditSsn,
                    },
                  ],
                  stepEight: [
                    {
                      question: 'Your pre-qualified amount',
                      answer: stepEightPrequalifiedAmount,
                    },
                  ],
                  stepNine: [
                    {
                      question: 'Your primary income',
                      answer: stepNinePrimaryIncome,
                    },
                  ],
                  stepTen: [
                    {
                      question: 'Employer details - Employer name:',
                      answer: stepTenEmployerName,
                    },
                    {
                      question: 'Employer details - Start date:',
                      answer: stepTenStartDate,
                    },
                    {
                      question: 'Employer details - Employer location - City:',
                      answer: stepTenEmployerCity,
                    },
                    {
                      question: 'Employer details - Employer location - State:',
                      answer: stepTenEmployerState,
                    },
                  ],
                  stepEleven: [
                    {
                      question: 'Average pre-tax income per paycheck:',
                      answer: stepElevenPayPeriod,
                    },
                    {
                      question: 'Enter pre-tax income per paycheck:',
                      answer: stepElevenPreTaxIncome,
                    },
                  ],
                  stepFourteen: [
                    {
                      question:
                        'Have you received a raise within the last 6 months?',
                      answer: stepFourteenHasRaise,
                    },
                  ],
                  stepFifteen: [
                    {
                      question:
                        'Do you receive paychecks from more than one salaried or hourly job?',
                      answer: stepFifteenMoreThanOneSalary,
                    },
                    {
                      question: 'Your additional yearly salary:',
                      answer: stepFifteenAdditionalYearlySalary,
                    },
                  ],
                  stepSeventeen: [
                    {
                      question:
                        'Background Check - Has an eviction been filed against you within the last 12 months?',
                      answer: stepSeventeenBackgroundCheckEviction,
                    },
                    {
                      question: 'Background Check - Date of birth',
                      answer: stepSeventeenBackgroundCheckDOB,
                    },
                  ],
                  legalName: {
                    firstName: stepSevenCreditFirstName,
                    lastName: stepSevenCreditLastName,
                  },
                };
                const docsToUpload = {
                  stepTwelve: {
                    stepTwelveIncomeDocOne,
                    stepTwelveIncomeDocTwo,
                    stepTwelveIncomeDocThree,
                  },
                  stepThirteen: {
                    stepThirteenBankStatementOne,
                    stepThirteenBankStatementTwo,
                    stepThirteenBankStatementThree,
                  },
                  stepSixteen: stepSixteenIdentityDoc,
                };
                await handleSubmitDocuments(applicationResponses, docsToUpload);
                await handleNext();
              }
            }}
            isDisabled={
              loading ||
              stepOnePhoneNumber.length !== 10 ||
              stepSevenCreditZipCode.length !== 5 ||
              stepSevenCreditSsn.length !== 9 ||
              stepSevenCreditFirstName.length <= 1 ||
              stepSevenCreditLastName.length <= 1 ||
              (stepFourRealEstateAgent === 'yes' &&
                (stepFourRealEstateAgentPhone.length !== 10 ||
                  stepFourRealEstateAgentFirstName.length <= 1 ||
                  stepFourRealEstateAgentLastName.length <= 1)) ||
              (stepFiveCoApplicant === 'yes' &&
                (stepFiveCoApplicantPhone.length !== 10 ||
                  stepFiveCoApplicantFirstName.length <= 1 ||
                  stepFiveCoApplicantLastName.length <= 1)) ||
              stepEighteenReviewApp !== true
            }
          >
            {loading ? <Spinner /> : 'Submit'}
          </Button>
        </Stack>
      </Stack>
      <Modal isOpen={isOpenIncome} onClose={onCloseIncome}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>At this point of time</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {stepFiveCoApplicant == 'yes' ? (
                <Text fontWeight="500" fontSize="md" textAlign={'justify'}>
                  At this point of time, we are taking in applicants whose
                  combined applicant and co-buyer monthly household income is at
                  or greater than{' '}
                  <span style={{ fontWeight: 800 }}>$3,500</span>. Please apply
                  if you meet this criteria. Thank you for your consideration.
                </Text>
              ) : (
                <Text fontWeight="500" fontSize="md" textAlign={'justify'}>
                  At this point of time, we are taking in applicants with
                  monthly household income of{' '}
                  <span style={{ fontWeight: 800 }}>$3,500</span>. Please apply
                  if you meet this criteria. Thank you for your consideration.
                </Text>
              )}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseIncome}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenError} onClose={onCloseError}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Submitting Error</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text>
                There seems to have been an error when trying to submit your
                application. Try submitting again or if this issue keeps arising
                then contact us at{' '}
                <Link
                  color="teal.500"
                  href="mailto:support@utopia.cx"
                  fontWeight="600"
                >
                  support@utopia.cx
                </Link>
              </Text>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseError}>
              I understand
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenRealEstate} onClose={onCloseRealEstate}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Wait...</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              You selected <b>"No"</b> to the question: “Are you currently
              working with a real estate agent?”.
              <br />
              <br />
              Please proceed only if you want the real estate agent related
              information to be deleted; Otherwise, please cancel.
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onCloseRealEstate}>Cancel</Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                setStepFourRealEstateAgent('no');
                setStepFourRealEstateAgentFirstName('');
                setStepFourRealEstateAgentLastName('');
                setStepFourRealEstateAgentEmail('');
                setStepFourRealEstateAgentPhone('');
                setStepFourConnectToRealEstateAgent('');
                onCloseRealEstate();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenCoApplicant} onClose={onCloseCoApplicant}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Wait...</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              You selected <b>“No”</b> to the question: “Do You have a
              Co-Buyer?”.
              <br />
              <br />
              Please proceed only if you want the Co-Buyer related information
              to be deleted; Otherwise, please cancel.
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onCloseCoApplicant}>Cancel</Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                setStepFiveCoApplicant('no');
                setStepFiveCoApplicantFirstName('');
                setStepFiveCoApplicantLastName('');
                setStepFiveCoApplicantEmail('');
                setStepFiveCoApplicantPhone('');
                onCloseCoApplicant();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default HomebuyerStepEighteen;
