import React, { Fragment } from 'react';
import { isEmptyString } from '../../../../utils/generalFunctions';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Spinner,
} from '@chakra-ui/react';

const HomebuyerStepTwo = ({
  setActiveStep,
  stepTwoMovePlan,
  setStepTwoMovePlan,
  handleNext,
  loading,
}) => {
  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(2 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Heading as="h4" size="md" mb="5">
              User Details
            </Heading>
            <Stack spacing="5">
              <FormControl>
                <FormLabel color="gray">When do you plan to move?</FormLabel>
                <RadioGroup
                  value={stepTwoMovePlan}
                  onChange={setStepTwoMovePlan}
                >
                  <Stack spacing={3}>
                    <Radio
                      size="lg"
                      value="As soon as possible"
                      colorScheme="blue"
                    >
                      <Text fontWeight="600" color="gray.600">
                        As soon as possible
                      </Text>
                    </Radio>
                    <Radio size="lg" value="In 1 - 2 months" colorScheme="blue">
                      <Text fontWeight="600" color="gray.600">
                        In 1 - 2 months
                      </Text>
                    </Radio>
                    <Radio size="lg" value="In 3 - 4 months" colorScheme="blue">
                      <Text fontWeight="600" color="gray.600">
                        In 3 - 4 months
                      </Text>
                    </Radio>
                    <Radio
                      size="lg"
                      value="In over 4 months"
                      colorScheme="blue"
                    >
                      <Text fontWeight="600" color="gray.600">
                        In over 4 months
                      </Text>
                    </Radio>
                    <Radio size="lg" value="Don't know yet" colorScheme="blue">
                      <Text fontWeight="600" color="gray.600">
                        Don't know yet
                      </Text>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(1);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              handleNext();
            }}
            isDisabled={isEmptyString(stepTwoMovePlan)}
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default HomebuyerStepTwo;
