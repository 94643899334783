import React, { Fragment, useEffect } from 'react';
import {
  isEmptyString,
  validateEmail,
} from '../../../../utils/generalFunctions';

// context
import { useAuth } from '../../../../context/AuthContext';

// chakra
import {
  Box,
  Button,
  Card,
  CardBody,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Progress,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

const HomebuyerStepFour = ({
  setActiveStep,
  setStepFourRealEstateAgent,
  stepFourRealEstateAgent,
  stepFourRealEstateAgentFirstName,
  setStepFourRealEstateAgentFirstName,
  stepFourRealEstateAgentLastName,
  setStepFourRealEstateAgentLastName,
  stepFourRealEstateAgentEmail,
  setStepFourRealEstateAgentEmail,
  stepFourRealEstateAgentPhone,
  setStepFourRealEstateAgentPhone,
  stepFourConnectToRealEstateAgent,
  setStepFourConnectToRealEstateAgent,
  handleNext,
  loading,
  applicationData,
}) => {
  const { authUser, agentInfo } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const onYes = () => {
    setStepFourRealEstateAgentFirstName(agentInfo?.legalName?.firstName);
    setStepFourRealEstateAgentLastName(agentInfo?.legalName?.lastName);
    setStepFourRealEstateAgentEmail(agentInfo?.email);
    setStepFourRealEstateAgentPhone(agentInfo?.phone);
  };

  const onNo = () => {
    setStepFourRealEstateAgent('no');
    setStepFourRealEstateAgentFirstName('');
    setStepFourRealEstateAgentLastName('');
    setStepFourRealEstateAgentEmail('');
    setStepFourRealEstateAgentPhone('');
  };

  useEffect(() => {
    if (
      agentInfo &&
      !stepFourRealEstateAgentEmail &&
      !applicationData?.stepFour &&
      !Boolean(stepFourRealEstateAgent)
    ) {
      setStepFourRealEstateAgent('yes');
      onYes();
    }
  }, [agentInfo]);

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(4 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Heading as="h4" size="md" mb="5">
                  User Details
                </Heading>
                <Stack spacing="5">
                  <FormControl>
                    <FormLabel color="gray">
                      Are you currently working with a real estate agent?
                    </FormLabel>
                    <RadioGroup
                      value={stepFourRealEstateAgent}
                      onChange={e => {
                        if (
                          stepFourRealEstateAgent === 'yes' &&
                          e === 'no' &&
                          (!isEmptyString(stepFourRealEstateAgentFirstName) ||
                            !isEmptyString(stepFourRealEstateAgentLastName) ||
                            !isEmptyString(stepFourRealEstateAgentEmail) ||
                            !isEmptyString(stepFourRealEstateAgentPhone))
                        ) {
                          onOpen();
                        } else if (e === 'yes') {
                          onYes();
                          setStepFourRealEstateAgent(e);
                        } else if (e === 'no') {
                          onNo();
                        }
                      }}
                    >
                      <Stack spacing={3}>
                        <Radio size="lg" value="yes" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            Yes
                          </Text>
                        </Radio>
                        <Radio size="lg" value="no" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            No
                          </Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>

              {stepFourRealEstateAgent == 'yes' && (
                <Box>
                  <Heading as="h5" size="sm" mb="3">
                    Sweet, Please enter the details of your real estate agent:
                  </Heading>
                  <Stack spacing="5">
                    <FormControl>
                      <FormLabel color="gray">First Name:</FormLabel>
                      <Input
                        borderWidth="2px"
                        size="lg"
                        type="text"
                        value={stepFourRealEstateAgentFirstName}
                        onChange={e => {
                          setStepFourRealEstateAgentFirstName(e.target.value);
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel color="gray">Last Name:</FormLabel>
                      <Input
                        borderWidth="2px"
                        size="lg"
                        type="text"
                        value={stepFourRealEstateAgentLastName}
                        onChange={e => {
                          setStepFourRealEstateAgentLastName(e.target.value);
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel color="gray">Email:</FormLabel>
                      <Input
                        borderWidth="2px"
                        size="lg"
                        type="email"
                        value={stepFourRealEstateAgentEmail}
                        onChange={e => {
                          if (e.target.value == authUser.email) {
                            toast({
                              position: 'top-right',
                              title: 'Error',
                              description: "Can't use your own email",
                              status: 'error',
                              duration: 3000,
                              isClosable: true,
                            });
                          } else {
                            setStepFourRealEstateAgentEmail(e.target.value);
                          }
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel color="gray">Phone:</FormLabel>
                      <Input
                        borderWidth="2px"
                        size="lg"
                        type="tel"
                        placeholder="222 333 4444"
                        pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                        maxLength="10"
                        value={stepFourRealEstateAgentPhone}
                        onChange={e => {
                          const value = e.target.value.replace(/\D/g, '');
                          setStepFourRealEstateAgentPhone(value.trim());
                        }}
                      />
                      <FormHelperText color="gray.400">
                        Enter 10-digit number
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Box>
              )}

              {stepFourRealEstateAgent == 'no' && (
                <Box>
                  {/* <Heading as="h5" size="sm" mb="3">
                    Can we connect you with our Partner real estate agent?
                  </Heading> */}
                  <Stack spacing="5">
                    <FormControl>
                      <FormLabel color="gray">
                        Can we connect you with our Partner real estate agent?
                      </FormLabel>
                      <RadioGroup
                        value={stepFourConnectToRealEstateAgent}
                        onChange={setStepFourConnectToRealEstateAgent}
                      >
                        <Stack spacing={3}>
                          <Radio size="lg" value="yes" colorScheme="blue">
                            <Text fontWeight="600" color="gray.600">
                              Yes
                            </Text>
                          </Radio>
                          <Radio size="lg" value="no" colorScheme="blue">
                            <Text fontWeight="600" color="gray.600">
                              No
                            </Text>
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                </Box>
              )}
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(3);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              handleNext();
            }}
            isDisabled={
              (stepFourRealEstateAgent == 'yes' &&
                (isEmptyString(stepFourRealEstateAgentFirstName) ||
                  isEmptyString(stepFourRealEstateAgentLastName) ||
                  isEmptyString(stepFourRealEstateAgentEmail) ||
                  isEmptyString(stepFourRealEstateAgentPhone) ||
                  stepFourRealEstateAgentPhone.length !== 10 ||
                  !validateEmail(stepFourRealEstateAgentEmail) ||
                  stepFourRealEstateAgentEmail === authUser.email)) ||
              (stepFourRealEstateAgent == 'no' &&
                isEmptyString(stepFourConnectToRealEstateAgent)) ||
              isEmptyString(stepFourRealEstateAgent)
            }
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Wait...</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              You selected <b>"No"</b> to the question: “Are you currently
              working with a real estate agent?”.
              <br />
              <br />
              Please proceed only if you want the real estate agent related
              information to be deleted; Otherwise, please cancel.
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                setStepFourRealEstateAgent('no');
                setStepFourRealEstateAgentFirstName('');
                setStepFourRealEstateAgentLastName('');
                setStepFourRealEstateAgentEmail('');
                setStepFourRealEstateAgentPhone('');
                setStepFourConnectToRealEstateAgent('');
                onClose();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default HomebuyerStepFour;
