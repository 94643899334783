import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import app from '../utils/firebase';

const CoapplicantContext = createContext();

const CoapplicantProvider = ({ children }) => {
  const db = getFirestore(app);

  const [coapplicantId, setCoapplicantId] = useState(null);
  const [coapplicantData, setCoapplicantData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isHomebuyer, setIsHomebuyer] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const docRef = doc(
          db,
          'coapplicant_application_responses',
          coapplicantId
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const doc = docSnap.data();
          setCoapplicantData(doc);
        } else {
          setCoapplicantData(null);
        }

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, [coapplicantId]);

  const getCoapplicantByHomebuyer = async homebuyer_id => {
    let data = [];
    const clientRef = collection(db, 'coapplicant_application_responses');
    const q = query(clientRef, where('homebuyer', '==', homebuyer_id));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(doc => {
      data.push({ ...doc.data(), uid: doc.id });
    });
    if (data) {
      setCoapplicantData(data[0]);
    }
    return data[0];
  };

  const getCoapplicant = async coapplicantId => {
    try {
      setLoading(true);

      setCoapplicantId(coapplicantId);

      setLoading(false);
    } catch (err) {
      console.log('ERR getCoapplicant: ', err);
      return null;
    }
  };

  const finishCoapplicationApplication = async (
    stepOnePhoneNumber,
    stepTwoMonthlyIncome,
    stepTwoRent,
    stepThreeCreditFirstName,
    stepThreeCreditMiddleName,
    stepThreeCreditLastName,
    stepThreeCreditStreetAddress,
    stepThreeCreditZipCode,
    stepThreeCreditSsn,
    stepFourPrimaryIncome,
    stepFiveEmployerName,
    stepFiveStartDate,
    stepFiveEmployerCity,
    stepFiveEmployerState,
    stepSixPayPeriod,
    stepSixPreTaxIncome,
    stepSevenIncomeDocOne,
    stepSevenIncomeDocTwo,
    stepSevenIncomeDocThree,
    stepEightBankStatementOne,
    stepEightBankStatementTwo,
    stepEightBankStatementThree,
    stepNineHasRaise,
    stepTenMoreThanOneSalary,
    stepTenAdditionalYearlySalary,
    stepElevenIdentityDoc,
    stepTwelveBackgroundCheckEviction,
    stepTwelveBackgroundCheckDOB,
    stepThirteenReviewApp
  ) => {
    try {
      const applicationRef = doc(
        db,
        'coapplicant_application_responses',
        coapplicantId
      );

      await updateDoc(applicationRef, {
        applicationCompleted: true,
        stepOne: [
          {
            question: 'What is your phone number?',
            answer: stepOnePhoneNumber,
          },
        ],
        stepTwo: [
          {
            question: 'Your gross monthly income:',
            answer: stepTwoMonthlyIncome,
          },
          {
            question: 'Your rent right now:',
            answer: stepTwoRent,
          },
        ],
        stepThree: [
          {
            question: 'Credit verification - Legal First Name:',
            answer: stepThreeCreditFirstName,
          },
          {
            question: 'Credit verification - Legal Middle Name:',
            answer: stepThreeCreditMiddleName,
          },
          {
            question: 'Credit verification - Legal Last Name:',
            answer: stepThreeCreditLastName,
          },
          {
            question: 'Credit verification - Street Address:',
            answer: stepThreeCreditStreetAddress,
          },
          {
            question: 'Credit verification - Zip Code:',
            answer: stepThreeCreditZipCode,
          },
          {
            question: 'Credit verification - Social Security:',
            answer: stepThreeCreditSsn,
          },
        ],
        stepFour: [
          {
            question: 'Your primary income',
            answer: stepFourPrimaryIncome,
          },
        ],
        stepFive: [
          {
            question: 'Employer details - Employer name:',
            answer: stepFiveEmployerName,
          },
          {
            question: 'Employer details - Start date:',
            answer: stepFiveStartDate,
          },
          {
            question: 'Employer details - Employer location - City:',
            answer: stepFiveEmployerCity,
          },
          {
            question: 'Employer details - Employer location - State:',
            answer: stepFiveEmployerState,
          },
        ],
        stepSix: [
          {
            question: 'Average pre-tax income per paycheck:',
            answer: stepSixPayPeriod,
          },
          {
            question: 'Enter pre-tax income per paycheck:',
            answer: stepSixPreTaxIncome,
          },
        ],
        stepNine: [
          {
            question: 'Have you received a raise within the last 6 months?',
            answer: stepNineHasRaise,
          },
        ],
        stepTen: [
          {
            question:
              'Do you receive paychecks from more than one salaried or hourly job?',
            answer: stepTenMoreThanOneSalary,
          },
          {
            question: 'Your additional yearly salary:',
            answer: stepTenAdditionalYearlySalary,
          },
        ],
        stepTwelve: [
          {
            question:
              'Background Check - Has an eviction been filed against you within the last 12 months?',
            answer: stepTwelveBackgroundCheckEviction,
          },
          {
            question: 'Background Check - Date of birth',
            answer: stepTwelveBackgroundCheckDOB,
          },
        ],
        stepThirteen: [
          {
            question:
              'By checking this, I acknowledge that this information is accurate to the best of my knowledge.',
            answer: stepThirteenReviewApp,
          },
        ],
      });
      console.log('document uploaded');

      const storage = getStorage();

      if (stepSevenIncomeDocOne !== '' && stepSevenIncomeDocOne !== null) {
        const incomeVerificationDocStorageRef = ref(
          storage,
          `coapplicant_application_files/${coapplicantId}/income_verification/${stepSevenIncomeDocOne.name}`
        );
        await uploadBytes(
          incomeVerificationDocStorageRef,
          stepSevenIncomeDocOne
        );
      }
      if (stepSevenIncomeDocTwo !== '' && stepSevenIncomeDocTwo !== null) {
        const incomeVerificationDocStorageRef = ref(
          storage,
          `coapplicant_application_files/${coapplicantId}/income_verification/${stepSevenIncomeDocTwo.name}`
        );
        await uploadBytes(
          incomeVerificationDocStorageRef,
          stepSevenIncomeDocTwo
        );
      }
      if (stepSevenIncomeDocThree !== '' && stepSevenIncomeDocThree !== null) {
        const incomeVerificationDocStorageRef = ref(
          storage,
          `coapplicant_application_files/${coapplicantId}/income_verification/${stepSevenIncomeDocThree.name}`
        );
        await uploadBytes(
          incomeVerificationDocStorageRef,
          stepSevenIncomeDocThree
        );
      }
      console.log('Income verification upload');

      if (
        stepEightBankStatementOne !== '' &&
        stepEightBankStatementOne !== null
      ) {
        const bankStatementDocStorageRef = ref(
          storage,
          `coapplicant_application_files/${coapplicantId}/bank_statement/${stepEightBankStatementOne.name}`
        );
        await uploadBytes(
          bankStatementDocStorageRef,
          stepEightBankStatementOne
        );
      }
      if (
        stepEightBankStatementTwo !== '' &&
        stepEightBankStatementTwo !== null
      ) {
        const bankStatementDocStorageRef = ref(
          storage,
          `coapplicant_application_files/${coapplicantId}/bank_statement/${stepEightBankStatementTwo.name}`
        );
        await uploadBytes(
          bankStatementDocStorageRef,
          stepEightBankStatementTwo
        );
      }
      if (
        stepEightBankStatementThree !== '' &&
        stepEightBankStatementThree !== null
      ) {
        const bankStatementDocStorageRef = ref(
          storage,
          `coapplicant_application_files/${coapplicantId}/bank_statement/${stepEightBankStatementThree.name}`
        );
        await uploadBytes(
          bankStatementDocStorageRef,
          stepEightBankStatementThree
        );
      }
      console.log('Bank statement upload');

      if (stepElevenIdentityDoc !== '' && stepElevenIdentityDoc !== null) {
        const backgroundCheckStorageRef = ref(
          storage,
          `coapplicant_application_files/${coapplicantId}/background_check/${stepElevenIdentityDoc.name}`
        );
        await uploadBytes(backgroundCheckStorageRef, stepElevenIdentityDoc);
      }
      console.log('Background check upload');

      const applicationSnap = await getDoc(applicationRef);
      if (applicationSnap.exists()) {
        const applicationData = applicationSnap.data();
        const applicationHomebuyerEmail = applicationData.homebuyerEmail;

        if (applicationHomebuyerEmail) {
          await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/email/alert_admin_of_coapplicant_completion`,
            {
              homebuyerEmail: applicationHomebuyerEmail,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
        }
      }
    } catch (err) {
      console.log('ERR finishCoapplicationApplication: ', err);
      return null;
    }
  };

  const createCoapplicant = async (
    coapplicantFirstName,
    coapplicantLastName,
    coapplicantEmail,
    homebuyerId,
    homebuyerFirstName,
    homebuyerLastName,
    homebuyerEmail
  ) => {
    try {
      setLoading(true);

      const docRef = await addDoc(
        collection(db, 'coapplicant_application_responses'),
        {
          applicationCompleted: false,
          email: coapplicantEmail,
          name: {
            firstName: coapplicantFirstName,
            lastName: coapplicantLastName,
          },
          homebuyer: homebuyerId,
          homebuyerEmail: homebuyerEmail,
          homebuyerName: {
            firstName: homebuyerFirstName,
            lastName: homebuyerLastName,
          },
        }
      );

      setLoading(false);

      return docRef.id;
    } catch (err) {
      console.log('ERR createCoapplicant: ', err);
      return null;
    }
  };

  const value = {
    coapplicantData,
    loading,
    getCoapplicant,
    finishCoapplicationApplication,
    createCoapplicant,
    isHomebuyer,
    setIsHomebuyer,
    coapplicantId,
    getCoapplicantByHomebuyer,
  };

  return (
    <CoapplicantContext.Provider value={value}>
      {children}
    </CoapplicantContext.Provider>
  );
};

const useCoapplicant = () => {
  return useContext(CoapplicantContext);
};

export { CoapplicantProvider, useCoapplicant };
