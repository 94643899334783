import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { useState } from 'react';

import { OperatingCitiesUpdateSchema } from '../../../../validations/OperatingCitiesUpdateSchema';

import { useAgentAuth } from '../../../../context/AgentContext';
import { useAuth } from '../../../../context/AuthContext';

const OperatingCitiesUpdateButton = ({
  metro,
  cities,
  OperatingCitiesData,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [buttonLoading, setButtonLoading] = useState(false);
  const toast = useToast();

  const { updateApplicationFields, getUserApplication } = useAgentAuth();
  const { authUser } = useAuth();

  const handleSubmit = async values => {
    try {
      setButtonLoading(true);

      let payload = {
        stepTwo: [
          {
            question: OperatingCitiesData[0]?.question,
            answer: values.metro,
          },
          {
            question: OperatingCitiesData[1]?.question,
            answer: values.cities,
          },
        ],
      };

      await updateApplicationFields(payload);
      // await getUserApplication(authUser.uid);
      window.location.reload();

      toast({
        title: 'Successful',
        description: `Agent Info Updated Successfully`,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
      setButtonLoading(false);

      onClose();
    } catch (err) {
      toast({
        title: 'Request Failed',
        description:
          'Unable to update info at the moment, please try again later',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });

      setButtonLoading(false);
    }
  };

  return (
    <>
      <Button
        textColor={'blue.500'}
        mt={2}
        background="white"
        variant={'unstyled'}
        onClick={onOpen}
      >
        Edit
      </Button>

      <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Agent Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                metro,
                cities,
              }}
              validationSchema={OperatingCitiesUpdateSchema}
              onSubmit={async values => {
                handleSubmit(values);
              }}
            >
              {formik => (
                <>
                  <FormControl
                    isInvalid={formik.errors.metro && formik.touched.metro}
                    mt={2}
                  >
                    <FormLabel>Operating Metro</FormLabel>
                    <Field
                      as={Input}
                      borderWidth="2px"
                      id="metro"
                      name="metro"
                      size="md"
                      borderRadius="md"
                      disabled={buttonLoading}
                    />
                    <FormErrorMessage>{formik.errors.metro}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={formik.errors.cities && formik.touched.cities}
                    mt={2}
                  >
                    <FormLabel>Operating Cities</FormLabel>
                    <Field
                      as={Input}
                      borderWidth="2px"
                      id="cities"
                      name="cities"
                      size="md"
                      borderRadius="md"
                      disabled={buttonLoading}
                    />
                    <FormErrorMessage>{formik.errors.cities}</FormErrorMessage>
                  </FormControl>

                  <Flex mt={'10%'} justifyContent="flex-end">
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                      onClick={() => formik.handleSubmit()}
                      backgroundColor={'#1C2430'}
                      textColor={'white'}
                      minW={30}
                      ml={2}
                      isDisabled={buttonLoading}
                      _hover={{
                        bg: 'black',
                        cursor: 'pointer',
                      }}
                    >
                      {buttonLoading ? <Spinner /> : ' Update'}
                    </Button>
                  </Flex>
                </>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OperatingCitiesUpdateButton;
