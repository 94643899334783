import React, { Fragment, useState } from 'react';
import { Timestamp } from 'firebase/firestore';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import {
  capitalizeFirstLetter,
  getFormattedDate,
} from '../../utils/generalFunctions.js';

// context
import { useHomeBuyerAuth } from '../../context/HomebuyerContext';
import { useCoapplicant } from '../../context/CoapplicantContext';
import { useAuth } from '../../context/AuthContext';

// chakra
import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  NumberInput,
  NumberInputField,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputLeftAddon,
} from '@chakra-ui/react';
import { ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons';

const AddCoapplicantCard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { updateCoBuyerInfo, applicationData } = useHomeBuyerAuth();
  const { createCoapplicant } = useCoapplicant();
  const { authUser } = useAuth();

  const [loading, setLoading] = useState(false);

  const handleAddCoBuyer = async (
    firstName,
    lastName,
    email,
    phoneNumber,
    grossMonthlyIncome
  ) => {
    try {
      setLoading(true);
      await updateCoBuyerInfo(
        firstName,
        lastName,
        email,
        phoneNumber,
        grossMonthlyIncome
      );

      const coapplicantId = await createCoapplicant(
        capitalizeFirstLetter(firstName),
        capitalizeFirstLetter(lastName),
        email,
        authUser.uid,
        capitalizeFirstLetter(applicationData.stepSeven[0].answer),
        capitalizeFirstLetter(applicationData.stepSeven[2].answer),
        authUser.email
      );

      await axios({
        method: 'post',
        url: `${`https://utopia-server.onrender.com`}/api/v1/email/coapplicant_email_from_user`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          coapplicantFirstName: capitalizeFirstLetter(firstName),
          coapplicantLastName: capitalizeFirstLetter(lastName),
          userFirstName: capitalizeFirstLetter(
            applicationData.stepSeven[0].answer
          ),
          userLastName: capitalizeFirstLetter(
            applicationData.stepSeven[2].answer
          ),
          submissionDate: getFormattedDate(
            new Date(Timestamp.now().seconds * 1000)
          ),
          emailTo: email,
          coapplicantLink: `${'https://dash.utopiahomes.us'}/coapplicant/onboarding/${coapplicantId}`,
        }),
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('Error : ', err);
    }
  };

  return (
    <Fragment>
      <Card bg="white" size="md">
        <CardHeader align="center">
          <Heading as="h4" size="md">
            Add a Co-Buyer
          </Heading>
        </CardHeader>
        <CardBody align="center">
          <Button
            colorScheme="blue"
            rightIcon={<ArrowForwardIcon />}
            onClick={async () => {
              onOpen();
            }}
          >
            Add a Co-Buyer
          </Button>
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose}>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            grossMonthlyIncome: '',
          }}
          validationSchema={yup.object({
            firstName: yup.string().required('First name is required'),
            lastName: yup.string().required('Last name is required'),
            email: yup.string().email().required('Email is required'),
            phone: yup.string().required('Phone Number is required').length(10),
            grossMonthlyIncome: yup
              .number()
              .required('Gross Monthly Income is required'),
          })}
          onSubmit={async values => {
            try {
              await handleAddCoBuyer(
                values.firstName,
                values.lastName,
                values.email,
                values.phone,
                values.grossMonthlyIncome
              );
              onClose();
              window.location.reload(false);
            } catch (err) {
              console.log('Error onSubmit(): ', err);
            }
          }}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add a Co-Buyer</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Stack spacing="5">
                    <FormControl
                      isInvalid={
                        formik.errors.firstName && formik.touched.firstName
                      }
                      isDisabled={loading}
                    >
                      <FormLabel>Co-Buyer First Name</FormLabel>

                      <Field
                        as={Input}
                        type="text"
                        id="firstName"
                        name="firstName"
                      />

                      {formik.errors.firstName && formik.touched.firstName && (
                        <FormErrorMessage>
                          {formik.errors.firstName}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      isInvalid={
                        formik.errors.lastName && formik.touched.lastName
                      }
                      isDisabled={loading}
                    >
                      <FormLabel>Co-Buyer Last Name</FormLabel>
                      <Field
                        as={Input}
                        type="text"
                        id="lastName"
                        name="lastName"
                      />
                      {formik.errors.lastName && formik.touched.lastName && (
                        <FormErrorMessage>
                          {formik.errors.lastName}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      isInvalid={formik.errors.email && formik.touched.email}
                      isDisabled={loading}
                    >
                      <FormLabel>Co-Buyer Email</FormLabel>
                      <Field as={Input} type="email" id="email" name="email" />
                      {formik.errors.email && formik.touched.email && (
                        <FormErrorMessage>
                          {formik.errors.email}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      isInvalid={formik.errors.phone && formik.touched.phone}
                      isDisabled={loading}
                    >
                      <FormLabel>Co-Buyer Phone Number</FormLabel>
                      <Field
                        as={Input}
                        id="phone"
                        name="phone"
                        type="tel"
                        placeholder="2223334444"
                        maxLength="10"
                      />
                      {formik.errors.phone && formik.touched.phone && (
                        <FormErrorMessage>
                          {formik.errors.phone}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      isInvalid={
                        formik.errors.grossMonthlyIncome &&
                        formik.touched.grossMonthlyIncome
                      }
                      isDisabled={loading}
                    >
                      <FormLabel>Co-Buyer Gross Monthly</FormLabel>
                      <InputGroup>
                        <InputLeftAddon children="$" />
                        <NumberInput type="number" min={0} w="100%">
                          <Field
                            as={NumberInputField}
                            w="100%"
                            id="grossMonthlyIncome"
                            name="grossMonthlyIncome"
                          />
                        </NumberInput>
                      </InputGroup>

                      {formik.errors.grossMonthlyIncome &&
                        formik.touched.grossMonthlyIncome && (
                          <FormErrorMessage>
                            {formik.errors.grossMonthlyIncome}
                          </FormErrorMessage>
                        )}
                    </FormControl>
                  </Stack>
                </ModalBody>

                <ModalFooter>
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button colorScheme="blue" type="submit" isLoading={loading}>
                    Submit
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          )}
        </Formik>
      </Modal>
    </Fragment>
  );
};

export default AddCoapplicantCard;
