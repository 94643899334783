import React, { Fragment, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  isEmptyString,
  atLeast18,
  atLeast18_v2,
  isIos,
  isSafari,
} from '../../../../utils/generalFunctions';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spacer,
} from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';

const HomebuyerStepSeventeen = ({
  setActiveStep,
  stepSeventeenBackgroundCheckEviction,
  setStepSeventeenBackgroundCheckEviction,
  stepSeventeenBackgroundCheckDOB,
  setStepSeventeenBackgroundCheckDOB,
  handleNext,
  loading,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dateIsValid = date => {
    return date instanceof Date && !isNaN(date);
  };

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      borderWidth="2px"
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
      w="100%"
      size="lg"
      variant="ghost"
    >
      <Flex w="100%" align="center">
        {value === null || value === '' ? (
          <Text color="gray.400">mm/dd/yyyy</Text>
        ) : (
          <Text>{value}</Text>
        )}
        <Spacer />
        <CalendarIcon />
      </Flex>
    </Button>
  ));

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(17 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md" mb="1">
                    Background Check
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    We need to perform a standard background check for tenant
                    screening purposes.
                  </Text>
                </Box>

                <Stack spacing="5">
                  <FormControl>
                    <FormLabel color="gray">
                      Has an eviction been filed against you within the last 12
                      months?
                    </FormLabel>
                    <RadioGroup
                      value={stepSeventeenBackgroundCheckEviction}
                      onChange={setStepSeventeenBackgroundCheckEviction}
                    >
                      <Stack spacing={3}>
                        <Radio size="lg" value="yes" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            Yes
                          </Text>
                        </Radio>
                        <Radio size="lg" value="no" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            No
                          </Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">Date of Birth</FormLabel>
                    {/* {isIos() ? (
                      <DatePicker
                        selected={stepSeventeenBackgroundCheckDOB}
                        onChange={date =>
                          setStepSeventeenBackgroundCheckDOB(date)
                        }
                        maxDate={new Date(atLeast18_v2())}
                        placeholderText="mm/dd/yyyy"
                        customInput={<DateCustomInput />}
                      />
                    ) : (
                      <Input
                        id="dobDateInput"
                        borderWidth="2px"
                        size="lg"
                        type="date"
                        placeholder="mm/dd/yyyy"
                        value={stepSeventeenBackgroundCheckDOB}
                        max={atLeast18()}
                        onKeyDown={e => e.preventDefault()}
                        onChange={e => {
                          setStepSeventeenBackgroundCheckDOB(e.target.value);
                        }}
                      />
                    )} */}
                    <Input
                      id="dobDateInput"
                      borderWidth="2px"
                      size="lg"
                      type="date"
                      placeholder="mm/dd/yyyy"
                      value={stepSeventeenBackgroundCheckDOB}
                      max={atLeast18()}
                      onKeyDown={e => e.preventDefault()}
                      onChange={e => {
                        setStepSeventeenBackgroundCheckDOB(e.target.value);
                      }}
                    />
                  </FormControl>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(16);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={async () => {
              onOpen();
            }}
            isDisabled={
              isEmptyString(stepSeventeenBackgroundCheckEviction) ||
              isEmptyString(stepSeventeenBackgroundCheckDOB) ||
              !dateIsValid(new Date(stepSeventeenBackgroundCheckDOB))
            }
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>

      <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) " />
        <ModalContent>
          <ModalHeader>
            <Text fontWeight="700" fontSize="xl">
              Disclosure Regarding Background Investigation
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="5" mb="10">
              <Text fontWeight="500" fontSize="md">
                Revex Technologies, Inc. (collectively, "Utopia" or the
                "Company") may obtain information about you from a third party
                consumer reporting agency for tenant screening purposes. Thus,
                you may be the subject of a "consumer report" which may include
                information about your character, general reputation, personal
                characteristics, and/or mode of living. These reports may
                contain information regarding your credit history, criminal
                history, motor vehicle records ("driving records"), verification
                of your education or employment history, or other background
                checks. You have the right, upon written request made within a
                reasonable time, to request whether a consumer report has been
                run about you and to request a copy of your report.
              </Text>
              <Text fontWeight="500" fontSize="md">
                I acknowledge and certify that I have read and understand this
                disclosure and I authorize Revex Technologies, Inc. to
                investigate my background and qualifications for purposes of
                evaluating whether I am qualified for the service for which I am
                applying.
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              onClick={() => {
                onClose();
              }}
              mr="4"
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                onClose();
                handleNext();
              }}
            >
              I Agree
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default HomebuyerStepSeventeen;
