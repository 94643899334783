import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import {
  copyToClipboard,
  getNormalizedError,
} from '../../../utils/generalFunctions';
import { remindUser } from '../../../utils/services/agent.service';

const ClientReminderButton = ({ data, title, isAgent }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [buttonLoading, setButtonLoading] = useState(false);
  const { authUser } = useAuth();

  const [emailText, setEmailText] = useState(
    `${
      data?.name ? `Hi ${data?.name}` : 'Hi'
    }, I have invited you to Utopia Homes. So, A gentle reminder: Please start your application with Utopia Homes so that we can get you the home of your choice. The application is totally free and takes less than 5 mins to complete with no impact to your credit score. So, Please click below to get started: \n\n<table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center"><a href="https://dash.utopiahomes.us/signup/homebuyer/" style="color:rgb(255,255,255);text-align:center;background-color:rgb(68,95,220);display:inline-block;width:189px;font-family:Inter,Arial,sans-serif;font-size:13px;line-height:13px;text-decoration-line:none;padding:12px 0px;margin:0px;border-radius:12px" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://dash.utopiahomes.us/signup/homebuyer/&amp;source=gmail&amp;ust=1668181079898000&amp;usg=AOvVaw3-ktLjFQ_QapPPpCTGokmG"><span style="font-size:14px;font-weight:700;line-height:17px">Finish my application</span></a></td></tr></table>\nIf you have any questions, please contact me or check out Utopia's frequently asked questions. \n\nWe are very excited to work with you & make you a Homeowner!\n\nRegards, ${
      authUser?.data?.legalName?.firstName
    } ${authUser?.data?.legalName?.lastName} & Your Utopia Team`
  );

  const toast = useToast();

  const IS_COMPLETED = data?.user?.applicationCompleted;

  const handleSubmit = async values => {
    try {
      setButtonLoading(true);
      let subject = 'Gentle reminder: Start your Utopia Application!';

      if (data?.application) {
        subject = 'Don’t forget to finish up your Utopia application.';
      }

      await remindUser({
        client_email: data?.email,
        email_text: emailText,
        subject,
      });
      toast({
        title: 'Client Invited',
        description: `Reminder email sent successfully`,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
      setButtonLoading(false);

      onClose();
    } catch (err) {
      const error = getNormalizedError(err);

      toast({
        title: 'Request Failed',
        description: error
          ? error
          : 'Unable to invite user at the moment, please try again later',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });

      setButtonLoading(false);
    }
  };

  const handleEmailText = () => {};

  const handleCopy = () => {
    copyToClipboard(emailText);
    toast({
      title: 'Copied To Clipboard',
      // description: `Reminder email sent successfully`,
      status: 'success',
      duration: 9000,
      isClosable: true,
      position: 'top-right',
    });
  };

  useEffect(() => {
    handleEmailText();

    if (data.applicationStatus !== 'invited') {
      setEmailText(
        `${
          data?.name ? `Hi ${data?.name}` : 'Hi'
        }, \n\nThank you for starting your homeownership application with Utopia.\n\nPlease complete the application at the earliest as it takes only a couple of minutes and will set you on a path of becoming an owner of your dream home. \n\nSo, Please click below to finish your application:\n\n<table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center"><a href="https://dash.utopiahomes.us/signup/homebuyer/" style="color:rgb(255,255,255);text-align:center;background-color:rgb(68,95,220);display:inline-block;width:189px;font-family:Inter,Arial,sans-serif;font-size:13px;line-height:13px;text-decoration-line:none;padding:12px 0px;margin:0px;border-radius:12px" target="_blank"><span style="font-size:14px;font-weight:700;line-height:17px">Finish my application</span></a></td></tr></table>\nIf you have any questions, please contact me or check out Utopia's frequently asked questions. \n\nWe are very excited to work with you & make you a Homeowner!\n\nRegards,\n ${
          authUser?.data?.legalName?.firstName
        } ${authUser?.data?.legalName?.lastName} & Your Utopia Team`
      );
    }

    if (isAgent) {
      setEmailText(
        `${
          data?.name ? `Hi ${data?.name}` : 'Hi'
        }, \n\nI have invited you to join the Utopia Agent Network. So, A gentle reminder: Please sign-up as an agent with Utopia Homes and start inviting clients who are not yet mortgage ready, you can close more deals with their all cash offers and convert renters to home buyers and earn a higher commission. \n\nSo, Please click below to get started:\n\n<table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center"><a href="https://dash.utopiahomes.us/signup/agent/" style="color:rgb(255,255,255);text-align:center;background-color:rgb(68,95,220);display:inline-block;width:189px;font-family:Inter,Arial,sans-serif;font-size:13px;line-height:13px;text-decoration-line:none;padding:12px 0px;margin:0px;border-radius:12px" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://dash.utopiahomes.us/signup/agent/&amp;source=gmail&amp;ust=1668181079898000&amp;usg=AOvVaw3-ktLjFQ_QapPPpCTGokmG"><span style="font-size:14px;font-weight:700;line-height:17px">Sign-up as an agent</span></a></td></tr></table>\nIf you have any questions, please contact me or check out Utopia's frequently asked questions. \n\nWe are very excited to work with you!\n\nRegards,\n${
          authUser?.data?.legalName?.firstName
        } ${authUser?.data?.legalName?.lastName} & Your Utopia Team`
      );
    }
  }, [data]);

  return (
    <>
      <Button
        textColor={'#1895D7'}
        fontSize="sm"
        minW={20}
        // ml={5}
        // px={'10'}
        variant="outline"
        onClick={onOpen}
        borderColor="gray.300"
      >
        {isAgent
          ? 'Remind Agent'
          : IS_COMPLETED
          ? 'E-Mail Client'
          : 'Remind Client'}
      </Button>

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={{ base: 'full', md: '2xl' }}
        zIndex={1}
      >
        <ModalOverlay />
        <ModalContent minH="60vh" minW={'55vw'}>
          <ModalHeader>Email Reminder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text size={'sm'} color="gray.500" fontWeight="semibold">
              Sending To
            </Text>

            <Flex
              spacing={5}
              direction="row"
              display={'flex'}
              //   background="green"
              alignItems={'center'}
              // justifyContent="space-between"
              //   pr={'10vw'}
              mt={2}
            >
              <Box>
                <Checkbox size={'lg'} defaultChecked={true} disabled>
                  <Text fontWeight="semibold">{data?.name}</Text>
                </Checkbox>
                <Text ml={6} fontSize="14px">
                  {data?.email}
                </Text>
              </Box>

              <Box ml={10}>
                <Checkbox size={'lg'} defaultChecked disabled>
                  <Text fontWeight="semibold">MySelf</Text>
                </Checkbox>
                <Text ml={6} fontSize="14px">
                  {authUser.data?.email}
                </Text>
              </Box>
              {/* <Checkbox defaultChecked>Checkbox</Checkbox> */}
            </Flex>

            <Heading size={'md'} mt={10}>
              Subject
            </Heading>

            <Text size={'md'} color="gray.500" fontWeight="semibold">
              Reminder: Start your Utopia Homes application
            </Text>

            <Textarea
              disabled={buttonLoading}
              onChange={e => setEmailText(e.target.value)}
              mt={5}
              fontWeight="medium"
              minH={'18vh'}
              //   whiteSpace="pre-wrap"
              value={emailText}
            ></Textarea>
            <Flex alignItems={'center'} mt={5} justifyContent="space-between">
              <Text>This email will be sent from {authUser.data?.email}</Text>
              <Text
                color="teal.500"
                textDecorationLine={'underline'}
                // pointer="cursor"
                cursor="pointer"
                onClick={handleCopy}
              >
                Copy Message
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={handleSubmit}
              backgroundColor={'#1C2430'}
              textColor={'white'}
              minW={30}
              ml={2}
              isDisabled={buttonLoading}
              _hover={{
                bg: 'black',
                cursor: 'pointer',
              }}
            >
              {buttonLoading ? <Spinner /> : 'Send Email'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ClientReminderButton;
