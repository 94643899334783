function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

function numberWithCommas(num, digits) {
  // return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const value = num.toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: digits }
  );

  return value;
}

function isEmptyString(str) {
  return !str || str.length === 0;
}

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

function currencyFormatter(num) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(num);
}

function getFirstDayOfWeek(d) {
  const date = new Date(d);
  const day = date.getDay();
  const diff = date.getDate() - day;

  return new Date(date.setDate(diff));
}

function atLeast18() {
  var dtToday = new Date();

  var month = dtToday.getMonth() + 1; // jan=0; feb=1 .......
  var day = dtToday.getDate();
  var year = dtToday.getFullYear() - 18;
  if (month < 10) month = '0' + month.toString();
  if (day < 10) day = '0' + day.toString();
  var minDate = year + '-' + month + '-' + day;
  var maxDate = year + '-' + month + '-' + day;

  return maxDate;
}

function atLeast18_v2() {
  var dtToday = new Date();

  var month = dtToday.getMonth() + 1; // jan=0; feb=1 .......
  var day = dtToday.getDate();
  var year = dtToday.getFullYear() - 18;
  if (month < 10) month = '0' + month.toString();
  if (day < 10) day = '0' + day.toString();
  var minDate = year + '-' + month + '-' + day;
  var maxDate = year + '-' + month + '-' + day;

  return maxDate + 'T10:46:13.489Z';
}

function makeid(length) {
  var result = '';
  // var characters =
  //   'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var characters = '0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
}

const validateEmail = email => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const getNormalizedError = err => {
  return err?.response?.data?.message || 'Request Failed!';
};

const copyToClipboard = text => {
  navigator.clipboard.writeText(text);
};

const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const isIos = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

const isSafari = () => {
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return isSafari;
};

/*
Radio
value={stepFourRealEstateAgent}
onChange={setStepFourRealEstateAgent}
Input
value={stepThreeMetroArea}
onChange={(e) => {
    setStepThreeMetroArea(e.target.value)
}}
Number Input
value={format(stepSixMonthlyIncome)}
onChange={(valueString) =>
    setStepSixMonthlyIncome(parse(valueString))
}
Select
value={stepTwoMovePlan}
onChange={(e) => {
    setStepTwoMovePlan(e.target.value)
}}
*/

module.exports = {
  sleep,
  numberWithCommas,
  isEmptyString,
  isEmptyObject,
  currencyFormatter,
  getFirstDayOfWeek,
  atLeast18,
  makeid,
  getFormattedDate,
  validateEmail,
  getNormalizedError,
  copyToClipboard,
  capitalizeFirstLetter,
  isIos,
  isSafari,
  atLeast18_v2,
};
