import { Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import GenericTable from '../../../components/GenericTable';
import { useAgentAuth } from '../../../context/AgentContext';
import AgentDashboardSidebar from '../../Sidebar';

function Inactive() {
  const [loading, setLoading] = useState(false);

  const { updateAllApplicationsData, searchResults, inactive } = useAgentAuth();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 150,
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Application Started',
        accessor: 'applicationStartDate',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Notes',
        accessor: 'interaction',
        minWidth: 150,
        disableSortBy: true,
        isInteraction: true,
      },
    ],
    []
  );

  useEffect(() => {
    setLoading(true);
    updateAllApplicationsData(() => {
      setLoading(false);
    });
  }, []);

  return (
    <AgentDashboardSidebar>
      <Heading size="lg">Inactive Applications ({inactive?.length})</Heading>
      <Text fontSize={'lg'}>These applications have been Inactive.</Text>
      <GenericTable
        columns={columns}
        data={searchResults ?? inactive ?? []}
        loading={loading}
      />
    </AgentDashboardSidebar>
  );
}

export default Inactive;
