import * as yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const PersonalInfoUpdateSchema = yup.object({
  firstName: yup.string().required('First Name is required').trim(),
  lastName: yup.string().required('Last Name is required').trim(),
  phone: yup
    .string()
    .required('Phone is required')
    .matches(phoneRegExp, 'Phone is not valid')
    .min(10, 'Phone too short')
    .max(10, 'Phone too long')
    .trim(),
});

export { PersonalInfoUpdateSchema };
