import React, { Fragment, useRef, useState } from 'react';
import { isEmptyString } from '../../../utils/generalFunctions';

// context
import { useCoapplicant } from '../../../context/CoapplicantContext';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  UnorderedList,
  ListItem,
  Spinner,
  useToast,
  IconButton,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { FiTrash2 } from 'react-icons/fi';

const CoapplicantStepEleven = ({
  setActiveStep,
  stepElevenIdentityDoc,
  setStepElevenIdentityDoc,
  handleNext,
  loading,
}) => {
  const stepElevenIdentityDocRef = useRef(null);
  const cancelRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { coapplicantData, isHomebuyer } = useCoapplicant();

  const [alertDialog, setAlertDialog] = useState('');

  return (
    <Fragment>
      <Stack maxW="xl" spacing="5">
        <Card bg="white" w="100%" textAlign="left">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box>
                  <Heading as="h4" size="md" mb="1">
                    Proof of Identity
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray" mb="1">
                    We need to verify{' '}
                    {isHomebuyer
                      ? coapplicantData
                        ? `${coapplicantData.name.firstName}'s `
                        : ''
                      : 'your '}
                    identity in order to complete the application. Acceptable
                    forms of ID are:
                  </Text>
                  <UnorderedList
                    fontSize="md"
                    fontWeight="600"
                    color="gray"
                    mx="9"
                  >
                    <ListItem>Driver's license</ListItem>
                    <ListItem>Passport</ListItem>
                    <ListItem>State ID card</ListItem>
                  </UnorderedList>
                </Box>
              </Box>

              <Box>
                <Box mb="3">
                  <Heading as="h5" size="sm" mb="1">
                    Upload documents manually
                  </Heading>
                  <Text fontSize="sm" fontWeight="600" color="gray">
                    Upload a copy of the document from your device or take a
                    photo and then upload
                  </Text>
                </Box>
                <Stack spacing="5">
                  <Box>
                    <Text fontSize="xs" fontWeight="600" color="gray" mb="1">
                      {isHomebuyer
                        ? coapplicantData
                          ? `${coapplicantData.name.firstName}'s `
                          : ''
                        : 'Your '}
                      photo ID
                    </Text>
                    <input
                      type="file"
                      ref={stepElevenIdentityDocRef}
                      id="stepElevenIdentityDoc"
                      name="stepElevenIdentityDoc"
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/*,.heic"
                      style={{ display: 'none' }}
                      onChange={e => {
                        if (e.target.files[0].size > 5242880) {
                          setAlertDialog('File is too big! Must be under 5mb');
                          onOpen();
                        } else if (e.target.files[0].size < 5000) {
                          setAlertDialog(
                            'This file quality is not that great; Please attach a better resolution file.'
                          );
                          onOpen();
                        } else {
                          const fileName = e.target.files[0].name;
                          // if (
                          //   fileName === stepTwelveIncomeDocOne.name ||
                          //   fileName === stepTwelveIncomeDocTwo.name ||
                          //   fileName === stepTwelveIncomeDocThree.name ||
                          //   fileName === stepThirteenBankStatementOne.name ||
                          //   fileName === stepThirteenBankStatementTwo.name ||
                          //   fileName === stepThirteenBankStatementThree.name
                          // ) {
                          //   toast({
                          //     title: 'File is already being used',
                          //     position: 'top-right',
                          //     status: 'error',
                          //     isClosable: true,
                          //   });
                          // } else {
                          //   setStepElevenIdentityDoc(e.target.files[0]);
                          // }
                          setStepElevenIdentityDoc(e.target.files[0]);
                        }
                      }}
                    />
                    {stepElevenIdentityDoc == '' ? (
                      <Flex align="center">
                        <Button as="label" for="stepElevenIdentityDoc">
                          <Text>Select a File</Text>
                        </Button>
                      </Flex>
                    ) : (
                      <Flex align="center">
                        <Button as="label" for="stepElevenIdentityDoc" mr="2">
                          <Text textOverflow="ellipsis">
                            {stepElevenIdentityDoc.name}
                          </Text>
                        </Button>
                        <IconButton
                          colorScheme="red"
                          aria-label="delete file"
                          icon={<Icon as={FiTrash2} />}
                          onClick={() => {
                            stepElevenIdentityDocRef.current.value = null;
                            setStepElevenIdentityDoc('');
                          }}
                        />
                      </Flex>
                    )}
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(8);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={async () => {
              await handleNext();
            }}
            isLoading={loading}
            isDisabled={
              isEmptyString(stepElevenIdentityDoc) ||
              stepElevenIdentityDoc.size > 5242880
            }
          >
            Next
          </Button>
        </Stack>
      </Stack>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Oops!
            </AlertDialogHeader>

            <AlertDialogBody>{alertDialog}</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="gray" onClick={onClose} ml={3}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Fragment>
  );
};

export default CoapplicantStepEleven;
