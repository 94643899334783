import React, { Fragment } from 'react';

// chakra
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Progress,
  Stack,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { isEmptyString } from '../../../../utils/generalFunctions';

const AgentStepOne = ({
  setActiveStep,
  setPhone,
  setLicenseNumber,
  setBrokerage,
  phone,
  licenseNumber,
  brokerage,
  handleNext,
  loading,
}) => {
  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="green"
          rounded="full"
          size="sm"
          value={(1 / 2) * 100}
          bg="gray.200"
        />
        <Card bg="white" size="md">
          <CardHeader>
            <Heading as="h4" size="md" mb="1">
              Agent Details
            </Heading>
            <Text fontSize="md" fontWeight="600" color="gray">
              Please provide the details about you as an agent. This includes
              things like your Agent Registration License Number and the
              Brokerage that you currently work for.
            </Text>
          </CardHeader>
          <CardBody>
            <Stack spacing="5">
              <FormControl>
                <FormLabel color="gray">Phone Number</FormLabel>
                <Input
                  borderWidth="2px"
                  size="lg"
                  type="tel"
                  placeholder="222 333 4444"
                  pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                  maxLength="10"
                  value={phone}
                  onChange={event => {
                    const value = event.target.value.replace(/\D/g, '');
                    setPhone(value.trim());
                  }}
                />
                <FormHelperText color="gray.400">
                  Enter 10-digit number
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel color="gray">Agent License Number</FormLabel>
                <Input
                  borderWidth="2px"
                  size="lg"
                  type="text"
                  value={licenseNumber}
                  maxLength="15"
                  onChange={event => {
                    setLicenseNumber(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel color="gray">Brokerage</FormLabel>
                <Input
                  borderWidth="2px"
                  size="lg"
                  type="text"
                  value={brokerage}
                  onChange={event => {
                    setBrokerage(event.target.value);
                  }}
                />
                <FormHelperText color="gray.400">
                  What Brokerage do you use as an agent
                </FormHelperText>
              </FormControl>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            colorScheme="blue"
            isDisabled={
              isEmptyString(brokerage) ||
              isEmptyString(phone) ||
              isEmptyString(licenseNumber) ||
              phone.length !== 10
            }
            onClick={() => {
              // setActiveStep(2);
              handleNext();
            }}
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default AgentStepOne;
