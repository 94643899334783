import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// context
import { useLandlord } from '../../../context/LandlordContext';

// components
import SidebarWithHeader from '../../../components/SidebarWithHeader';
import AddPropertyApplication from './AddPropertyApplication/AddPropertyApplication';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  Spacer,
  Badge,
  Box,
  Spinner,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

const AddProperty = () => {
  const { id } = useParams();
  const {
    setPropertyApplication,
    currentApplicationForm,
    setCurrentApplicationForm,
  } = useLandlord();
  const navigate = useNavigate();

  const [applicationCompleted, setApplicationCompleted] = useState(null);
  const [applicationLoaded, setApplicationLoaded] = useState(false);

  useEffect(() => {
    const fetchApplicationData = async () => {
      try {
        const applicationResponse = await setPropertyApplication(id);

        if (applicationResponse.success) {
          setApplicationLoaded(true);
        } else {
          setApplicationLoaded(true);
          setCurrentApplicationForm(null);
          navigate('/landlord/home');
        }
      } catch (err) {
        console.error('Error fetching property application:', err);
        setApplicationLoaded(true);
        navigate('/landlord/home');
      }
    };

    fetchApplicationData();
  }, [id]);

  useEffect(() => {
    if (applicationLoaded && currentApplicationForm) {
      if (currentApplicationForm?.data?.applicationCompleted) {
        setApplicationCompleted(true);
        setCurrentApplicationForm(null);
        navigate(`/landlord/home`);
      } else {
        setApplicationCompleted(false);
      }
    }
  }, [currentApplicationForm]);

  return (
    <Fragment>
      <SidebarWithHeader userMode="home">
        <Container maxW="container.sm">
          {applicationLoaded && currentApplicationForm ? (
            applicationCompleted ? (
              <Box>Application Finished</Box>
            ) : (
              <AddPropertyApplication
                currentApplication={currentApplicationForm}
              />
            )
          ) : (
            <Spinner />
          )}
        </Container>
      </SidebarWithHeader>
    </Fragment>
  );
};

export default AddProperty;
