import React, { Fragment, useEffect, useMemo, useState } from 'react';

// chakra
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Link,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';

// context
import { useAuth } from '../../context/AuthContext';
import { useCoapplicant } from '../../context/CoapplicantContext';
import { useHomeBuyerAuth } from '../../context/HomebuyerContext';
import { APPLICATION_STATUS } from '../../utils/constants';

const CoapplicantApplicationSubmitted = () => {
  const {
    coapplicantData,
    coapplicantId,
    getCoapplicant,
    getCoapplicantByHomebuyer,
  } = useCoapplicant();

  const { getUserApplication } = useHomeBuyerAuth();
  const { getUserByDocId } = useAuth();

  const [prequalifiedAmount, setPrequalifiedAmount] = useState(0);
  const [homebuyerApplication, setHomebuyerApplication] = useState(null);
  const [homebuyer, setHomebuyer] = useState(null);

  const [title, subtitle, bottomText, budget] = useMemo(() => {
    if (!homebuyerApplication) {
      return ['', '', '', ''];
    }

    let atitle = '',
      aSubtitle = '',
      bottomText = '',
      budget = false;

    let isGeneric = false;

    switch (homebuyerApplication?.status) {
      case APPLICATION_STATUS.APPROVED:
      case APPLICATION_STATUS.CONDITIONALLY_APROVED:
      case APPLICATION_STATUS.OFFER_ACCEPTED:
      case APPLICATION_STATUS.OFFER_EXTENDED:
      case APPLICATION_STATUS.OFFER_CLOSED:
      case APPLICATION_STATUS.INSPECTION_COMPLETE:
      case APPLICATION_STATUS.PRECLOSING_COMPLETE:
        atitle = 'Congratulations! You are all set to find your Utopia.';
        aSubtitle = 'Your approved home buying budget is:';

        budget = true;
        budget = homebuyer?.qualifiedAmount;

        bottomText =
          'This budget is based on your self disclosed financial and operational details. If any material differences are discovered now or later then that may impact your home buying budget.';

        break;
      case APPLICATION_STATUS.REJECTED:
      case APPLICATION_STATUS.PAUSED:
      case APPLICATION_STATUS.INACTIVE:
        atitle = 'Important Update!';

        aSubtitle =
          'Please check your registered email inbox as we have sent critical piece of information to you.';
        // isGeneric = true;
        break;

      case APPLICATION_STATUS.MORE_INFO_REQUIRED:
        atitle =
          'Hello from Utopia! We need some more information from you to get you a suitable home buying budget.';
        aSubtitle =
          'Please check your registered email inbox and share the requested documents by email or by uploading them on this portal.';
        break;

      default:
        if (homebuyerApplication?.status) {
          atitle =
            'Hello from Utopia! We are still working on getting you a suitable home buying budget.';
        } else {
          atitle =
            'Thank you for applying! We will get back with you regarding the next steps.';
        }

        isGeneric = true;
    }

    if (isGeneric) {
      aSubtitle = 'Your preliminary home buying budget is:';
      bottomText =
        'Your budget could change once we review your documents or once your co-buyer completes the application.';

      budget = Math.min(4.358 * prequalifiedAmount * 12, 498990);
    }

    return [atitle, aSubtitle, bottomText, budget];
  }, [homebuyerApplication, homebuyer]);

  useEffect(() => {
    if (coapplicantData?.applicationCompleted) {
      const fetchData = async () => {
        const homebuyerData = await getUserApplication(
          coapplicantData?.homebuyer
        );

        setHomebuyerApplication(homebuyerData);

        // const homebuyerSalary =
        //   homebuyerData?.stepSix[0]?.answer === null ||
        //   homebuyerData?.stepSix[0]?.answer === ''
        //     ? 0
        //     : parseInt(homebuyerData?.stepSix[0]?.answer);

        const homebuyerSalary =
          parseInt(homebuyerData.stepSix[0]?.answer) +
          parseInt(homebuyerData.stepSix[4]?.answer);
        const coapplicantSalary =
          coapplicantData?.stepTwo[0]?.answer === null ||
          coapplicantData?.stepTwo[0]?.answer === ''
            ? 0
            : parseInt(coapplicantData?.stepTwo[0]?.answer);

        setPrequalifiedAmount(homebuyerSalary);

        if (coapplicantData) {
          const homebuyerDetails = await getUserByDocId(
            coapplicantData?.homebuyer
          );
          setHomebuyer(homebuyerDetails);
        }
      };

      fetchData().catch(console.error);
    } else {
      getCoapplicantByHomebuyer(coapplicantData?.homebuyer);
    }
  }, [coapplicantData]);

  console.log(
    '🚀 ~ file: CoapplicantApplicationSubmitted.js:146 ~ CoapplicantApplicationSubmitted ~ coapplicantData:',
    coapplicantData
  );

  return (
    <Fragment>
      <Stack maxW="xl" spacing="5">
        <Card align="center" bg="white" size="md" mt="5">
          <CardBody align="center">
            <Heading as="h4" size="md" mb={4}>
              {title}
            </Heading>
            {homebuyerApplication && homebuyerApplication.stepEight[0] && (
              <Stack>
                <Text fontSize="md" fontWeight="600" color="gray">
                  {subtitle}
                </Text>
                {Boolean(budget) && (
                  <Heading as="h2" size="xl">
                    ${parseFloat(budget).toLocaleString()}
                  </Heading>
                )}
                {Boolean(bottomText) && (
                  <Text fontSize="sm" fontWeight="500" color="gray.400">
                    {bottomText}
                  </Text>
                )}
              </Stack>
            )}
          </CardBody>
        </Card>

        <Card align="center" bg="white" size="md">
          <CardHeader align="center">
            <Heading as="h4" size="md" mb="1">
              Frequently Asked Questions
            </Heading>
            <Text fontSize="md" fontWeight="600" color="gray">
              Have a question? We might have an answer for you.
            </Text>
          </CardHeader>
          <CardBody w="100%">
            <Stack spacing="8" w="100%">
              <Accordion allowMultiple textAlign="left" w="100%">
                <AccordionItem w="100%">
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text fontSize="lg" fontWeight="600">
                          What is Utopia?
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    Utopia helps renters achieve their homeownership goals
                    faster. You select your dream home and we buy and rent it to
                    you for 2 to 3 years while we get you ready for a mortgage.
                    Your monthly payment also earns you profits as home prices
                    appreciate. Our innovative tools, coaching and incentives
                    further improve your credit scores, build up your down
                    payment and get you a home loan on very attractive terms.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text fontSize="lg" fontWeight="600">
                          What is Utopia Wallet?
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    When you rent with us, you have the option but not the
                    obligation to buy your rental home by signing a purchase
                    option agreement. You get a Wallet where your savings and
                    earnings sit and grow every month. Your Utopia Wallet can
                    grow in 3 ways:
                    <br />
                    <br />
                    1) Your contributions: When you sign the lease, you do not
                    make any security deposit but you make an initial
                    contribution of 2 percent of Price of Property (also called
                    purchasing option premium). During the lease, you can make
                    additional monthly or one off contributions so as to save
                    for your down payment to buy the home.
                    <br />
                    <br />
                    2) Profit Sharing in Rent: Every month you earn a share of
                    the rental profits. These rental profits are added to your
                    wallet on a monthly basis and further grow your wallet.
                    <br />
                    <br />
                    3) Home Price Appreciation: With our innovative program, you
                    also earn a portion of the increase in home value during the
                    term of the lease. So, As the value of your rental home
                    increases, the value of your wallet also increases to make
                    you downpayment ready sooner.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text fontSize="lg" fontWeight="600">
                          How can I get started with Utopia?
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    If you have a credit score of at least 550 and earn $35,000
                    or more then we encourage you to apply (It’s free) and get
                    pre-qualified. We also take into account DTI Ratio and
                    timely payment history to ensure that you would get a
                    mortgage in the next 2-3 years. You can find your dream home
                    with our network of agents or your own agent. As we buy the
                    home that you selected, to rent to you, you will contribute
                    2% of its price as initial down payment. This money will go
                    into Utopia Wallet and grow there so that you could be ready
                    with the full down payment required by the Bank.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text fontSize="lg" fontWeight="600">
                          What if I choose NOT to buy the home at the end of the
                          lease agreement?
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    We do understand that sometimes life happens. So, if you
                    choose not to buy the home at the end of your lease
                    agreement then you will get back all your monthly savings
                    contributions in the wallet. You will only lose the initial
                    2% you put down to partially cover the re-selling costs of
                    the home that Utopia purchased for you.
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              <Flex>
                <Spacer />
                <Button
                  rightIcon={<ArrowForwardIcon />}
                  colorScheme="blue"
                  variant="ghost"
                  onClick={() => {
                    window.open(
                      'https://www.utopiahomes.us/faq/general-questions'
                    );
                  }}
                >
                  View More
                </Button>
              </Flex>
            </Stack>
          </CardBody>
        </Card>
        <Card align="center" bg="white" size="md">
          <CardBody align="center">
            <Heading as="h4" size="md" mb="4">
              Still have a question? Just reach out.
            </Heading>
            <Stack spacing="3">
              <Flex>
                <Text fontSize="md" fontWeight="600" color="gray" mr="3">
                  Email:
                </Text>
                <Text fontSize="md" fontWeight="600" color="black">
                  <Link
                    color="blue.400"
                    href="mailto:support@utopia.cx?subject=I have a question"
                  >
                    support@utopia.cx
                  </Link>
                </Text>
              </Flex>
            </Stack>
          </CardBody>
        </Card>
      </Stack>
    </Fragment>
  );
};

export default CoapplicantApplicationSubmitted;
