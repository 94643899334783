import { ArrowForwardIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { useReward } from 'react-rewards';
import { Link, NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import { useAgentAuth } from '../../../../context/AgentContext';
import { useAuth } from '../../../../context/AuthContext';
import AgentDashboardSidebar from '../../../Sidebar';
import { Timestamp } from 'firebase/firestore';
import { handleOfferEmails } from '../../../../utils/services/agent.service';

const InitiateOffer = () => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const {
    approvedApplications,
    updateAllApplicationsData,
    uploadOfferInitiationFiles,
    saveOfferDetails,
    updateOffer,
    conditionallyApprovedApplications,
  } = useAgentAuth();
  const { authUser } = useAuth();

  const [submissionData, setSubmissionDate] = useState({
    client: undefined,
    propertyAddress: undefined,
    propertyListPrice: 0,
    propertyOfferPrice: 0,
    salesDocs: [],
    rentalDocs: [],
  });

  const { client, propertyAddress } = submissionData;
  const toast = useToast();

  const { reward } = useReward('rewardId', 'confetti', {
    elementCount: 600,
  });

  const handleDataSubmission = async values => {
    // console.log(
    //   '🚀 ~ file: index.js:87 ~ handleDataSubmission ~ values:',
    //   values
    // );

    // return;
    setLoading(true);

    try {
      setSubmissionDate(prev => ({
        ...prev,
        propertyListPrice: values.propertyListPrice,
        propertyOfferPrice: values.propertyOfferPrice,
        salesDocs: values.saleDoc,
        rentalDocs: values.rentalDoc,
      }));

      const clientName = client.name
        ? client.name
        : client.user.legalName
        ? client.user?.legalName?.firstName +
          ' ' +
          client?.user?.legalName?.lastName
        : ' ';

      const agentName =
        authUser?.data?.legalName?.firstName +
        ' ' +
        authUser?.data?.legalName?.lastName;

      const applicationStepFive = client.application?.stepFive;
      const hasCoapplicant = applicationStepFive[0]?.answer === 'yes';
      let coapplicantDetails = {};

      if (hasCoapplicant) {
        coapplicantDetails = {
          coapplicantName:
            applicationStepFive[1].answer + ' ' + applicationStepFive[2].answer,
          coapplicantEmail: applicationStepFive[3]?.answer,
          coapplicantPhone: applicationStepFive[4]?.answer,
        };
      }

      const payload = {
        clientName,
        clientEmail: client.email?.toLowerCase(),
        clientId: client?.user?.uid,
        agentName,
        agentEmail: authUser?.email,
        agentId: authUser?.uid,
        propertyListPrice: values.propertyListPrice,
        propertyOfferPrice: values.propertyOfferPrice,
        timeSubmitted: Timestamp.now(),
        propertyAddress,
        hasCoapplicant,
        ...coapplicantDetails,
      };

      //removeLoading ASAP

      const dbOffer = await saveOfferDetails(payload);

      const saleDocUploadResponse = await uploadOfferInitiationFiles(
        values.saleDoc,
        'sales',
        dbOffer.id
      );

      const rentalDocUploadResponse = await uploadOfferInitiationFiles(
        values.rentalDoc,
        'rental',
        dbOffer.id
      );

      await updateOffer(
        {
          saleDocs: saleDocUploadResponse,
          rentalDocs: rentalDocUploadResponse,
        },
        dbOffer.id
      );

      await handleOfferEmails({ offer_id: dbOffer.id });
      setStep(2);
      reward();
    } catch (err) {
      toast({
        title: 'Offer Failed',
        description: 'Unable to send offer at the moment. Please try again!',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateAllApplicationsData();
  }, []);

  return (
    <AgentDashboardSidebar showInvite={false} showHeader={false}>
      <Fragment>
        <Center>
          {step === 0 ? (
            <Step1Component
              clients={[
                ...approvedApplications,
                ...conditionallyApprovedApplications,
              ]}
              setStep={setStep}
              selectedClient={client}
              propertyAddr={propertyAddress}
              OnSubmission={(client, propertyAddress) => {
                setSubmissionDate(prev => ({
                  ...prev,
                  client,
                  propertyAddress,
                }));
              }}
            />
          ) : step === 1 ? (
            <ShareCMAOfferPrice
              onSubmit={async values => {
                await handleDataSubmission(values);
              }}
              onCancel={() => setStep(0)}
              loading={loading}
            />
          ) : step === 2 ? (
            <SubmissionComponent
              onReset={() => {
                setStep(0);
              }}
            />
          ) : (
            <></>
          )}
        </Center>

        <Center>
          <span id="rewardId" />
        </Center>
      </Fragment>
    </AgentDashboardSidebar>
  );
};

const ShareCMAOfferPrice = ({ onCancel, onSubmit, loading }) => {
  const [alertDialog, setAlertDialog] = useState('');
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();

  const rentalInputRef = useRef(null);
  const saleInputRef = useRef(null);
  const cancelRef = useRef();

  const validationSchema = Yup.object().shape({
    saleDoc: Yup.array()
      .min(1, 'At least 1 Sales CMA files is required')
      .max(5, 'Max 5 Sales CMA files are allowed')
      .required('Sales CMA is required'),
    rentalDoc: Yup.array()
      .min(1, 'At least 1 Rental CMA files is required')
      .max(5, 'Max 5 Rental CMA files are allowed')
      .required('Rental CMA is required'),
    propertyListPrice: Yup.string().required('Property List Price is required'),
    propertyOfferPrice: Yup.string().required(
      'Property Offer Price is required'
    ),
  });

  const handleDeleteFile = (setFieldValue, values, type, index) => {
    if (type === 'sale') {
      const updatedFiles = [...values.saleDoc];
      updatedFiles.splice(index, 1);
      setFieldValue('saleDoc', updatedFiles);
    } else {
      const updatedFiles = [...values.rentalDoc];
      updatedFiles.splice(index, 1);
      setFieldValue('rentalDoc', updatedFiles);
    }
  };

  const isValidFileSize = (file, minSizeKB = 5, maxSizeMB = 30) => {
    const fileSizeKB = file.size / 1024;
    const fileSizeMB = fileSizeKB / 1024;
    return fileSizeKB >= minSizeKB && fileSizeMB <= maxSizeMB;
  };

  const isDuplicateFile = (existingFiles, newFile) => {
    return existingFiles.some(
      file => file.name === newFile.name && file.size === newFile.size
    );
  };

  const handleAddMoreFiles = (
    setFieldValue,
    fieldName,
    existingFiles,
    newFiles
  ) => {
    const validAndUniqueFiles = [];

    for (let i = 0; i < newFiles.length; i++) {
      const newFile = newFiles[i];

      if (!isValidFileSize(newFile)) {
        setAlertDialog(
          `${newFile.name} has an invalid file size. File size should be between 5kb and 30mb`
        );
        alertOnOpen();

        continue;
      }

      if (isDuplicateFile(existingFiles, newFile)) {
        setAlertDialog(
          `${newFile.name} is a duplicate file. Please attach a new one`
        );
        alertOnOpen();

        continue;
      }

      validAndUniqueFiles.push(newFile);
    }

    if (validAndUniqueFiles.length > 0) {
      const updatedFiles = [...existingFiles, ...validAndUniqueFiles];
      setFieldValue(fieldName, updatedFiles);
    }
  };

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(2 / 2) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg" maxW={'70vw'}>
          <CardBody>
            <Heading as="h4" size="md" mb="5">
              Share CMA and Offer Price
            </Heading>

            <Stack spacing="5">
              <Box>
                <Text>
                  Please share the Comparative Market Analysis (CMA) and the
                  suggested offer price for the subject property
                </Text>
                <Text my={2}>
                  <b>For Sales CMA:</b> Please choose 3-5 comparable sales from
                  the last 3 months in 1 mile radius of Subject Property; Expand
                  criteria if not enough comps
                </Text>
                <Text>
                  <b>For Rental CMA:</b> Please choose 3-5 comparable rentals
                  from the last 6 months in 3 miles radius of Subject Property;
                  Expand criteria if not enough comps
                </Text>
              </Box>
              <Formik
                initialValues={{
                  saleDoc: [],
                  rentalDoc: [],
                  propertyListPrice: '',
                  propertyOfferPrice: '',
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <FormControl isRequired mb="4">
                      <FormLabel
                        requiredIndicator={false}
                        fontWeight={'semibold'}
                      >
                        Upload Sales CMA
                      </FormLabel>

                      <input
                        type="file"
                        ref={saleInputRef}
                        id="saleDoc"
                        name="saleDoc"
                        multiple
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/jpg,image/*,.heic"
                        style={{ display: 'none' }}
                        onChange={e => {
                          handleAddMoreFiles(
                            setFieldValue,
                            'saleDoc',
                            values.saleDoc,
                            e.target.files
                          );

                          saleInputRef.current.value = '';
                          saleInputRef.current.click();
                        }}
                      />

                      {values.saleDoc.length === 0 ? (
                        <Flex align="center">
                          <Button as="label" htmlFor="saleDoc">
                            <Text>Select Files</Text>
                          </Button>
                        </Flex>
                      ) : (
                        <Box>
                          {values.saleDoc.map((file, index) => (
                            <Flex align="center" key={index} mt={2}>
                              <Button
                                as="label"
                                for="stepEightBankStatementTwo"
                                mr="2"
                              >
                                <Text textOverflow="ellipsis" mr="2">
                                  {file.name}
                                </Text>
                              </Button>
                              <IconButton
                                colorScheme="red"
                                aria-label="delete file"
                                icon={<Icon as={FiTrash2} />}
                                onClick={() =>
                                  handleDeleteFile(
                                    setFieldValue,
                                    values,
                                    'sale',
                                    index
                                  )
                                }
                              />
                            </Flex>
                          ))}

                          <Button
                            colorScheme="blue"
                            as="label"
                            htmlFor="saleDoc"
                            mt={2}
                          >
                            Add More Files
                          </Button>
                        </Box>
                      )}

                      <FormHelperText>
                        Please see the instructions as given above
                      </FormHelperText>
                      {/* <ErrorMessage name="saleDoc" /> */}

                      <ErrorMessage name="saleDoc">
                        {msg => (
                          <Text
                            color="red.500"
                            fontSize="sm"
                            fontStyle="italic"
                          >
                            {msg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>

                    <FormControl isRequired mb="4">
                      <FormLabel
                        requiredIndicator={false}
                        fontWeight={'semibold'}
                      >
                        Upload Rental CMA
                      </FormLabel>

                      <input
                        type="file"
                        ref={rentalInputRef}
                        id="rentalDoc"
                        name="rentalDoc"
                        multiple
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/*,.heic"
                        style={{ display: 'none' }}
                        onChange={e => {
                          handleAddMoreFiles(
                            setFieldValue,
                            'rentalDoc',
                            values.rentalDoc,
                            e.target.files
                          );

                          rentalInputRef.current.value = '';
                          rentalInputRef.current.click();
                        }}
                      />

                      {values.rentalDoc.length === 0 ? (
                        <Flex align="center">
                          <Button as="label" htmlFor="rentalDoc">
                            <Text>Select Files</Text>
                          </Button>
                        </Flex>
                      ) : (
                        <Box>
                          {values.rentalDoc.map((file, index) => (
                            <Flex align="center" key={index} mt={2}>
                              <Button
                                as="label"
                                for="stepEightBankStatementTwo"
                                mr="2"
                              >
                                <Text textOverflow="ellipsis" mr="2">
                                  {file.name}
                                </Text>
                              </Button>
                              <IconButton
                                colorScheme="red"
                                aria-label="delete file"
                                icon={<Icon as={FiTrash2} />}
                                onClick={() =>
                                  handleDeleteFile(
                                    setFieldValue,
                                    values,
                                    'rental',
                                    index
                                  )
                                }
                              />
                            </Flex>
                          ))}

                          <Button
                            colorScheme="blue"
                            as="label"
                            htmlFor="rentalDoc"
                            mt="2"
                          >
                            Add More Files
                          </Button>
                        </Box>
                      )}

                      <FormHelperText>
                        Please see the instructions as given above
                      </FormHelperText>
                      <ErrorMessage name="rentalDoc">
                        {msg => (
                          <Text
                            color="red.500"
                            fontSize="sm"
                            fontStyle="italic"
                          >
                            {msg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>

                    <FormControl isRequired mb="4">
                      <FormLabel
                        requiredIndicator={false}
                        fontWeight={'semibold'}
                      >
                        Property List Price
                      </FormLabel>
                      <Field
                        as={Input}
                        borderWidth="2px"
                        size="lg"
                        // value={values?.propertyListPrice}
                        onChange={e => {
                          // const formatted = e.target?.value?.toLocaleString();
                          const value = e.target.value;
                          const formatted = value
                            .replace(/\D/g, '')
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                          setFieldValue('propertyListPrice', formatted);
                        }}
                        // type="number"
                        name="propertyListPrice"
                      />
                      <FormHelperText>
                        Please provide the most recent list price of the subject
                        property
                      </FormHelperText>

                      <ErrorMessage name="propertyListPrice">
                        {msg => (
                          <Text
                            color="red.500"
                            fontSize="sm"
                            fontStyle="italic"
                          >
                            {msg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>

                    <FormControl isRequired mb="4">
                      <FormLabel
                        requiredIndicator={false}
                        fontWeight={'semibold'}
                      >
                        Property Offer Price
                      </FormLabel>
                      <Field
                        as={Input}
                        borderWidth="2px"
                        size="lg"
                        // type="number"
                        name="propertyOfferPrice"
                        onChange={e => {
                          // const formatted = e.target?.value?.toLocaleString();
                          const value = e.target.value;
                          const formatted = value
                            .replace(/\D/g, '')
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                          setFieldValue('propertyOfferPrice', formatted);
                        }}
                      />
                      <FormHelperText>
                        Please provide the most reasonable offer price (after
                        reviewing CMA) of the subject property
                      </FormHelperText>
                      {/* <ErrorMessage name="propertyOfferPrice" /> */}

                      <ErrorMessage name="propertyOfferPrice">
                        {msg => (
                          <Text
                            color="red.500"
                            fontSize="sm"
                            fontStyle="italic"
                          >
                            {msg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>

                    <Stack justify="right" direction="row" spacing="3">
                      <Button type="button" onClick={onCancel}>
                        Prev
                      </Button>
                      <Button type="submit" colorScheme="blue" mr="4">
                        {loading ? <Spinner /> : 'Submit'}
                      </Button>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Stack>
          </CardBody>
        </Card>
      </Stack>

      <AlertDialog
        isOpen={alertIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={alertOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Oops!
            </AlertDialogHeader>

            <AlertDialogBody>{alertDialog}</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="gray" onClick={alertOnClose} ml={3}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Fragment>
  );
};

const SubmissionComponent = () => {
  return (
    <Fragment>
      <Stack maxW="xl" spacing="5">
        <Card bg="white" w="100%">
          <CardHeader display={'flex'} justifyContent={'center'}>
            <Image
              boxSize="100px"
              objectFit="cover"
              src="https://imgur.com/DDeJsWk.png"
              alt="emails"
            />
          </CardHeader>
          <CardBody>
            <Heading as="h4" size="md" mb="3" textAlign={'center'}>
              Thank you for initiating this offer!
            </Heading>
            <Stack spacing="5" textAlign="left">
              <Text
                fontSize="md"
                fontWeight="600"
                color="gray"
                mb="1"
                textAlign={'center'}
              >
                We will go through your provided information and get back with
                you regarding the next steps.
              </Text>
            </Stack>
          </CardBody>
        </Card>

        <Card bg="white" w="100%">
          <CardBody>
            <Heading as="h4" size="md" mb="3" textAlign={'center'}>
              Invite Clients and Agents to Utopia
            </Heading>
            <Text
              fontSize="md"
              fontWeight="600"
              color="gray"
              mb="1"
              textAlign={'center'}
            >
              Earn full commission and Incentives
            </Text>
            <Stack spacing="5" textAlign="left">
              <Box alignSelf={'center'}>
                <NavLink to={'/agents/incentives'}>
                  <Button
                    // backgroundColor={'#1C2430'}
                    // textColor={'white'}
                    colorScheme="blue"
                    minW={20}
                    fontSize="sm"
                    px={'5'}
                    alignSelf={'center'}
                    mt={2}
                    // _hover={{
                    //   bg: 'black',
                    //   cursor: 'pointer',
                    // }}
                  >
                    Invite & Earn
                  </Button>
                </NavLink>
              </Box>
            </Stack>
          </CardBody>
        </Card>

        <Card align="center" bg="white" size="md">
          <CardHeader align="center">
            <Heading as="h4" size="md" mb="1">
              Frequently Asked Questions
            </Heading>
            <Text fontSize="md" fontWeight="600" color="gray">
              Have a question? We might have an answer for you.
            </Text>
          </CardHeader>
          <CardBody w="100%">
            <Stack spacing="8" w="100%">
              <Accordion allowMultiple textAlign="left" w="100%">
                <AccordionItem w="100%">
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text fontSize="lg" fontWeight="600">
                          What is Utopia?
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    Utopia helps renters achieve their homeownership goals
                    faster. You select your dream home and we buy and rent it to
                    you for 2 to 3 years while we get you ready for a mortgage.
                    Your monthly payment also earns you profits as home prices
                    appreciate. Our innovative tools, coaching and incentives
                    further improve your credit scores, build up your down
                    payment and get you a home loan on very attractive terms.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text fontSize="lg" fontWeight="600">
                          What should I do after the offer is accepted?
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    Step 1: Since, Utopia makes all cash offers with no
                    financing contingency, So, the seller is more likely to
                    accept the Utopia Offer. Once, the offer is accepted, then
                    you as Utopia Agent should do the following: Summarize the
                    exact deadlines/dates for Earnest money deposit, inspection,
                    appraisal and closing Recommend the names of good quality
                    vendors (which charge reasonably) for inspection, appraisal,
                    and escrow company.
                    <br />
                    <br />
                    Step 2: Utopia gets the full home inspection done by a
                    certified and licensed home inspector. We strongly encourage
                    you as well as the future home occupant to be present at
                    subject property at the time of inspection so that all their
                    questions and concerns could be addressed by the home
                    inspector. If any repair work is needed then Utopia gets the
                    repair estimate from a certified general contractor. You
                    work with Utopia to negotiate with the seller about the cost
                    and the timeline for repairs. This way we set our client up
                    for a home in excellent condition.
                    <br />
                    <br />
                    Step 3: Utopia get the full appraisal of the home done by a
                    certified residential home appraiser. This step is critical
                    as we don’t want our customer when they are mortgage ready
                    to run into appraisal issues.
                    <br />
                    <br />
                    Step 4: Once the home passes the inspection and appraisal,
                    Utopia, with your help, removes both the contingencies and
                    prepares for final walk through and closing
                    <br />
                    <br />
                    Step 5: During the final walkthrough, you must walk the
                    customer and Utopia Representative through the empty home.
                    This is the final opportunity for our customer to ensure
                    that home is in excellent condition and if not then document
                    any issues so that we could get them fixed through the
                    Seller.
                    <br />
                    <br />
                    Step 6: Once the home passes the final walkthrough, we
                    proceed and complete the closing with the Escrow Company.
                    And, you as an agent, make the commission for all your hard
                    work and success!
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text fontSize="lg" fontWeight="600">
                          What is Utopia Wallet?
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    When you rent with us, you have the option but not the
                    obligation to buy your rental home by signing a purchase
                    option agreement. You get a Wallet where your savings and
                    earnings sit and grow every month. Your Utopia Wallet can
                    grow in 3 ways:
                    <br />
                    <br />
                    1) Your contributions: When you sign the lease, you do not
                    make any security deposit but you make an initial
                    contribution of 2 percent of Price of Property (also called
                    purchasing option premium). During the lease, you can make
                    additional monthly or one off contributions so as to save
                    for your down payment to buy the home.
                    <br />
                    <br />
                    2) Profit Sharing in Rent: Every month you earn a share of
                    the rental profits. These rental profits are added to your
                    wallet on a monthly basis and further grow your wallet.
                    <br />
                    <br />
                    3) Home Price Appreciation: With our innovative program, you
                    also earn a portion of the increase in home value during the
                    term of the lease. So, As the value of your rental home
                    increases, the value of your wallet also increases to make
                    you downpayment ready sooner.
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              <Flex>
                <Spacer />
                {/* /agents/faq */}

                <NavLink to={'/agents/faq'}>
                  <Button
                    rightIcon={<ArrowForwardIcon />}
                    colorScheme="blue"
                    variant="ghost"
                    // onClick={() => {
                    //   window.open(
                    //     'https://www.utopiahomes.us/faq/general-questions'
                    //   );
                    // }}
                  >
                    View More
                  </Button>
                </NavLink>
              </Flex>
            </Stack>
          </CardBody>
        </Card>
        <Card align="center" bg="white" size="md">
          <CardBody align="center">
            <Heading as="h4" size="md" mb="4">
              Still have a question? Just reach out.
            </Heading>
            <Stack spacing="3">
              <Flex>
                <Text fontSize="md" fontWeight="600" color="gray" mr="3">
                  Email:
                </Text>
                <Text fontSize="md" fontWeight="600" color="black">
                  <Link
                    color="blue.400"
                    href="mailto:support@utopia.cx?subject=I have a question"
                  >
                    support@utopia.cx
                  </Link>
                </Text>
              </Flex>
            </Stack>
          </CardBody>
        </Card>
      </Stack>
    </Fragment>
  );
};

const Step1Component = ({
  clients,
  setStep,
  selectedClient,
  propertyAddr,
  OnSubmission,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [propertyAddress, setPropertyAddress] = useState(propertyAddr); // Added propertyAddress state
  const [client, setClient] = useState(selectedClient);
  const [isValid, setIsValid] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    OnSubmission(client, propertyAddress);
    setStep(1);
  };

  const handleSelectClient = selectedOption => {
    setClient(selectedOption);
    setIsModalOpen(false);
  };

  const handleSearchChange = event => {
    setSearchText(event.target.value);
  };

  const validateForm = () => {
    setIsValid(!!(client && propertyAddress));
  };

  const handlePropertyAddressChange = event => {
    setPropertyAddress(event.target.value);
  };

  const filteredClients = clients.filter(item => {
    const fullName =
      `${item?.user?.legalName?.firstName} ${item?.user?.legalName?.lastName}`.toLowerCase();
    const email = item?.user?.email.toLowerCase();
    return (
      fullName.includes(searchText.toLowerCase()) ||
      email.includes(searchText.toLowerCase())
    );
  });

  useEffect(() => {
    validateForm();
  }, [client, propertyAddress]);

  const modalBody = clients.length ? (
    <Box p="4">
      <Input
        mb="4"
        placeholder="Search for a client"
        value={searchText}
        onChange={handleSearchChange}
      />
      {filteredClients.length ? (
        <Stack>
          {filteredClients.map(item => {
            const applicationStepFive = item.application?.stepFive;
            const hasCoapplicant = applicationStepFive[0]?.answer === 'yes';
            const coapplicantName =
              applicationStepFive[1]?.answer +
              ' ' +
              applicationStepFive[2]?.answer;

            return (
              <Box
                key={item?.user?.uid}
                onClick={() => handleSelectClient({ ...item })}
                justifyContent="flex-start"
                textAlign="left"
                boxShadow="none"
                _hover={{ boxShadow: 'none', bg: 'gray.100' }}
                _active={{ bg: 'gray.200' }}
                display="flex"
                flexDirection={'column'}
                py={3}
                pl={3}
                borderBottomWidth={0.5}
              >
                <Text fontWeight="semibold">
                  {`${item?.user?.legalName?.firstName} ${item?.user?.legalName?.lastName}`}{' '}
                  {hasCoapplicant ? ` & ${coapplicantName}` : ''}
                </Text>

                <Text fontSize="sm" color="gray.500" mt="1">
                  {item?.user?.email}
                </Text>
              </Box>
            );
          })}
        </Stack>
      ) : searchText ? (
        <Text textAlign="center">No clients found</Text>
      ) : (
        <Text textAlign="center">Loading...</Text>
      )}
    </Box>
  ) : (
    <Text textAlign="center">No approved clients yet</Text>
  );
  return (
    <Stack spacing="5">
      <Progress
        colorScheme="blue"
        rounded="full"
        size="md"
        value={(1 / 2) * 100}
        bg="gray.200"
        hasStripe
        isAnimated
      />

      <Card bg="white" size="lg">
        <CardBody>
          <Heading as="h4" size="md" mb="5">
            Select Client and Property
          </Heading>
          <Stack spacing="5">
            <form onSubmit={handleSubmit}>
              <FormControl isRequired mb="4">
                <Text mb="4">
                  Please select the client name & share details of the property
                  for which you are creating an offer
                </Text>

                <FormLabel requiredIndicator={false} fontWeight="semibold">
                  Client
                </FormLabel>
                <InputGroup>
                  <Input
                    borderWidth="2px"
                    size="lg"
                    contentEditable={false}
                    readOnly
                    value={
                      client
                        ? `${client?.user?.legalName?.firstName} ${client?.user?.legalName?.lastName}`
                        : 'Select client'
                    }
                    onClick={() => setIsModalOpen(true)}
                    // placeholder="Mailing address or Zillow/ Redfin Listing URL"
                  />

                  <InputRightElement children={<ChevronDownIcon />} />
                </InputGroup>

                <FormHelperText>
                  Offers could be initiated for approved clients only
                </FormHelperText>
              </FormControl>
              <FormControl isRequired mb="4">
                <FormLabel requiredIndicator={false} fontWeight="semibold">
                  Property
                </FormLabel>
                <Input
                  borderWidth="2px"
                  size="lg"
                  placeholder="Mailing address or Zillow/ Redfin Listing URL"
                  value={propertyAddress} // Added value prop
                  onChange={handlePropertyAddressChange} // Added onChange prop
                />
                <FormHelperText>
                  Share Property Address or Listing URL
                </FormHelperText>
              </FormControl>
              <Stack justify="right" direction="row" spacing="3">
                <Button
                  type="submit"
                  colorScheme="blue"
                  mr="4"
                  isDisabled={!isValid}
                >
                  Next
                </Button>
              </Stack>
            </form>
          </Stack>
        </CardBody>
      </Card>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size={'xl'}
      >
        <ModalOverlay />
        <ModalContent zIndex={1}>
          <ModalHeader>Select client</ModalHeader>
          <ModalBody>{modalBody}</ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              colorScheme="red"
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default InitiateOffer;
