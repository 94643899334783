import React, { useEffect, useState } from 'react';
import { useAgentAuth } from '../../../context/AgentContext';
import { copyToClipboard } from '../../../utils/generalFunctions';
import AgentDashboardSidebar from '../../Sidebar';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import AgentInvitationButton from '../../../components/Agents/AgentInvitation';
import ClientInvitationButton from '../../../components/Agents/ClientInvitation';
import LandlordInvitationButton from '../../../components/Agents/LandlordInvitation';
import { useAuth } from '../../../context/AuthContext';
import ReferralChangeButton from './ReferralChangeButton';

const Invite = () => {
  const [referralCode, setReferralCode] = useState('');

  const { updateAllApplicationsData } = useAgentAuth();
  const { getCurrentUserReferralCode } = useAuth();

  const toast = useToast();

  const handleCopy = userType => {
    let url = ``;
    if (userType === 'client') {
      url = `https://dash.utopiahomes.us/c/${referralCode?.code}`;
    } else if (userType === 'agent') {
      url = `https://dash.utopiahomes.us/a/${referralCode?.code}`;
    } else if (userType === 'landlord') {
      url = `https://dash.utopiahomes.us/l/${referralCode?.code}`;
    }

    copyToClipboard(url);
    toast({
      title: 'Link Copied To Clipboard',
      description: url,
      status: 'success',
      duration: 9000,
      isClosable: true,
      position: 'top-right',
    });
  };

  const getReferral = async () => {
    try {
      const response = await getCurrentUserReferralCode();
      setReferralCode(response);
    } catch (err) {
      console.log('🚀 ~ file: index.js:79 ~ getReferral ~ err:', err);
    }
  };

  useEffect(() => {
    getReferral();
    updateAllApplicationsData();
  }, []);

  return (
    <AgentDashboardSidebar showInvite={false} showHeader={false}>
      <Flex flexDirection={{ md: 'row', base: 'column' }} position="relative">
        <Stack spacing={8} p={6} pt={'10%'} flex={1}>
          <Stack>
            <Flex>
              <NavLink to={'/agents/incentives'}>
                <Button
                  backgroundColor={'red'}
                  textColor={'white'}
                  minW={20}
                  fontSize="sm"
                  px={'5'}
                  variant="outline"
                  _hover={{
                    bg: 'red.700',
                    cursor: 'pointer',
                  }}
                >
                  Check new Incentives ($s)
                </Button>
              </NavLink>
              <NavLink to={'/agents/marketing'}>
                <Button
                  minW={30}
                  px={10}
                  ml={4}
                  textColor={'#1895D7'}
                  fontSize="sm"
                  // ml={5}
                  // px={'10'}
                  variant="outline"
                  // onClick={onOpen}
                  borderColor="gray.300"
                >
                  Client Marketing
                </Button>
              </NavLink>
              <NavLink to={'/agents/landlord/marketing'}>
                <Button
                  minW={30}
                  px={10}
                  ml={4}
                  textColor={'#1895D7'}
                  fontSize="sm"
                  // ml={5}
                  // px={'10'}
                  variant="outline"
                  // onClick={onOpen}
                  borderColor="gray.300"
                >
                  Landlord Marketing
                </Button>
              </NavLink>
            </Flex>

            <Box
              borderWidth={1}
              p={8}
              borderRadius={8}
              shadow="sm"
              bg="#F9FAFB"
            >
              <Heading size="lg" fontWeight="semibold">
                Invite By Email
              </Heading>
              <Flex mt={8} flexDirection="column">
                <ClientInvitationButton />
                <Box mt={6} width="100%">
                  <AgentInvitationButton />
                </Box>
                <Box mt={6} width="100%">
                  <LandlordInvitationButton />
                </Box>
              </Flex>
            </Box>
          </Stack>

          <Stack spacing={8}>
            <Box
              borderWidth={1}
              p={8}
              borderRadius={8}
              shadow="sm"
              bg="#F9FAFB"
            >
              <Flex alignItems="center" mb={5} justifyContent="space-between">
                <Heading size="lg" fontWeight="semibold" mr={10}>
                  Invite By Sharing Your Link
                </Heading>

                {referralCode && (
                  <ReferralChangeButton
                    value={referralCode?.code}
                    onChangeValue={setReferralCode}
                    onSuccess={getReferral}
                    codeId={referralCode?.uid}
                  />
                )}
              </Flex>

              <Flex alignItems="center" justifyContent="space-between" mb={4}>
                <Text size="md" fontWeight="semibold">
                  Invite a client
                </Text>
                <Button
                  backgroundColor={'#1C2430'}
                  textColor={'white'}
                  onClick={() => handleCopy('client')}
                  minW={20}
                  fontSize="sm"
                  px={'10'}
                  variant="outline"
                  borderColor="gray.300"
                  width="25%"
                  _hover={{
                    bg: 'black',
                    cursor: 'pointer',
                  }}
                >
                  Copy Link
                </Button>
              </Flex>

              <Divider my={4} />

              <Flex alignItems="center" justifyContent="space-between" mb={4}>
                <Text size="md" fontWeight="semibold">
                  Invite an agent
                </Text>
                <Button
                  backgroundColor={'#1C2430'}
                  textColor={'white'}
                  onClick={() => handleCopy('agent')}
                  minW={20}
                  fontSize="sm"
                  px={'10'}
                  variant="outline"
                  borderColor="gray.300"
                  width="25%"
                  _hover={{
                    bg: 'black',
                    cursor: 'pointer',
                  }}
                >
                  Copy Link
                </Button>
              </Flex>

              <Divider my={4} />

              <Flex alignItems="center" justifyContent="space-between" mb={4}>
                <Text size="md" fontWeight="semibold">
                  Invite a landlord
                </Text>
                <Button
                  backgroundColor={'#1C2430'}
                  textColor={'white'}
                  onClick={() => handleCopy('landlord')}
                  minW={20}
                  fontSize="sm"
                  px={'10'}
                  variant="outline"
                  borderColor="gray.300"
                  width="25%"
                  _hover={{
                    bg: 'black',
                    cursor: 'pointer',
                  }}
                >
                  Copy Link
                </Button>
              </Flex>
            </Box>
          </Stack>
        </Stack>

        <Flex
          flex={0.5}
          height={'100vh'}
          position="sticky"
          overflow="auto"
          top={0}
        >
          <Stack px={5} py={10} borderRadius={5}>
            <Heading fontSize="2xl" size="lg" mb={5}>
              How It Works for Inviting 'Rent-to-Own' Clients:
            </Heading>
            <Text>1. Invite clients and agents to Utopia</Text>
            <Text>
              2. Clients who sign up for Utopia with your link will be assigned
              to you
            </Text>
            <Text>
              3. Once clients start their application, they'll appear in your
              agent portal
            </Text>
            <Text>
              4. You can track the progress of these applications on the portal
            </Text>
            <Text>
              5. Once the closing is done, your full commission gets sent to
              you.
            </Text>

            <Box mt={5} />

            <Heading fontSize="2xl" size="lg" mb={5}>
              How It Works for Inviting Landlords:
            </Heading>
            <Text>
              1. Invite Landlords, who want to rent out or sell their single
              family rental homes, to Utopia
            </Text>
            <Text>
              2. Landlords who sign up for Utopia with your link will be
              assigned to you
            </Text>
            <Text>
              3. Once invited Landlords list their rental homes, they'll appear
              in your agent portal
            </Text>
            <Text>
              4. You can track the progress of these rental properties on the
              portal
            </Text>
            <Text>
              5. Once these properties are rented out on "rent to own" basis by
              Utopia, your full commission gets sent to you
            </Text>
          </Stack>
        </Flex>
      </Flex>
    </AgentDashboardSidebar>
  );
};

export default Invite;
