import { Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import GenericTable from '../../../../components/GenericTable';
import { useAgentAuth } from '../../../../context/AgentContext';
import AgentDashboardSidebar from '../../../Sidebar';
import moment from 'moment';

const Invited = () => {
  const { invitedUsers, searchResults, updateAllApplicationsData } =
    useAgentAuth();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    updateAllApplicationsData(() => {
      setLoading(false);
    });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 150,
      },
      {
        Header: 'Invite Sent',
        accessor: 'createdAt',
        minWidth: 150,

        Cell: ({ value }) => moment(value).fromNow('DD/MM/YYYY'),
        sortType: (a, b) => {
          const dateA = a.values.createdAt
            ? new Date(a.values.createdAt)
            : undefined;
          const dateB = b.values.createdAt
            ? new Date(b.values.createdAt)
            : undefined;
          return dateA && dateB ? dateA - dateB : 0;
        },
      },
      {
        Header: 'Status',
        accessor: 'applicationStatus',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Times Reminded',
        accessor: 'numReminders',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'action',
        minWidth: 150,
        disableSortBy: true,
        isAction: true,
      },

      {
        Header: 'Notes',
        accessor: 'interaction',
        minWidth: 150,
        disableSortBy: true,
        isInteraction: true,
      },
    ],
    []
  );

  return (
    <AgentDashboardSidebar>
      <Heading size="lg">Invited ({invitedUsers?.length})</Heading>
      <Text fontSize={'lg'}>
        Thanks for email inviting these clients to Utopia. Please help them get
        started with their free application (takes {'<5'} mins)
      </Text>
      <GenericTable
        columns={columns}
        data={searchResults ? searchResults : invitedUsers}
        loading={loading}
      />
    </AgentDashboardSidebar>
  );
};

export default Invited;
