import React, { Fragment } from 'react';
import { isEmptyString } from '../../../../utils/generalFunctions';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Spinner,
} from '@chakra-ui/react';

const HomebuyerStepFifteen = ({
  setActiveStep,
  stepFifteenMoreThanOneSalary,
  setStepFifteenMoreThanOneSalary,
  stepFifteenAdditionalYearlySalary,
  setStepFifteenAdditionalYearlySalary,
  handleNext,
  loading,
}) => {
  const format = val => `$` + val;
  const parse = val => val.replace(/^\$/, '');

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(15 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md">
                    Additional Income
                  </Heading>
                </Box>

                <Stack spacing="5">
                  <FormControl>
                    <FormLabel color="gray">
                      Do you receive paychecks from more than one salaried or
                      hourly job?
                    </FormLabel>
                    <RadioGroup
                      value={stepFifteenMoreThanOneSalary}
                      onChange={setStepFifteenMoreThanOneSalary}
                    >
                      <Stack spacing={3}>
                        <Radio size="lg" value="yes" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            Yes
                          </Text>
                        </Radio>
                        <Radio size="lg" value="no" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            No
                          </Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>

              {stepFifteenMoreThanOneSalary == 'yes' && (
                <Box>
                  <Box mb="3">
                    <Heading as="h4" size="md">
                      Additional Income
                    </Heading>
                  </Box>

                  <Stack spacing="5">
                    <FormControl>
                      <FormLabel color="gray">
                        Your additional yearly salary:
                      </FormLabel>
                      <NumberInput
                        size="lg"
                        value={format(stepFifteenAdditionalYearlySalary)}
                        onChange={valueString =>
                          setStepFifteenAdditionalYearlySalary(
                            parse(valueString)
                          )
                        }
                        min={0}
                      >
                        <NumberInputField borderWidth="2px" size="lg" />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <FormHelperText color="gray.400">
                        Enter pre-tax income
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Box>
              )}
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(14);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              handleNext();
            }}
            isDisabled={
              isEmptyString(stepFifteenMoreThanOneSalary) ||
              (stepFifteenMoreThanOneSalary == 'yes' &&
                isEmptyString(stepFifteenAdditionalYearlySalary))
            }
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default HomebuyerStepFifteen;
