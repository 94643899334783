import { Fragment, useState, useEffect } from 'react';
import {
  sleep,
  isEmptyString,
  validateEmail,
} from '../../../utils/generalFunctions';
import axios from 'axios';

// firebase
import { Timestamp } from 'firebase/firestore';

// utils
import {
  capitalizeFirstLetter,
  getFormattedDate,
} from '../../../utils/generalFunctions';

// context
import { useHomeBuyerAuth } from '../../../context/HomebuyerContext';
import { useAuth } from '../../../context/AuthContext';

// chakra
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  Heading,
  Link,
  Spacer,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

const AddAgentCard = () => {
  const {
    applicationData,
    getUserApplication,
    handleCheckForAgent,
    updateApplicationFields,
  } = useHomeBuyerAuth();
  const { authUser } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [agentPresent, setAgentPresent] = useState(true);

  const [firstNameValue, setFirstNameValue] = useState('');
  const [lastNameValue, setLastNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [phoneNumberValue, setPhoneNumberValue] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const appData = applicationData?.stepFour;

      if (
        appData[1]?.answer === '' &&
        appData[2]?.answer === '' &&
        appData[3]?.answer === '' &&
        appData[4]?.answer === ''
      ) {
        setFirstNameValue('');
        setLastNameValue('');
        setEmailValue('');
        setPhoneNumberValue('');
        setAgentPresent(false);
      } else {
        setFirstNameValue(appData[1]?.answer);
        setLastNameValue(appData[2]?.answer);
        setEmailValue(appData[3]?.answer);
        setPhoneNumberValue(appData[4]?.answer);
        setAgentPresent(true);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const userFirstName = authUser?.data?.legalName?.firstName;
      const userLastName = authUser?.data?.legalName?.lastName;
      const userEmail = authUser?.email;

      const { userExists, isAgent } = await handleCheckForAgent(emailValue);
      if (userExists) {
        console.log({ isAgent });
        if (isAgent) {
          let payload = {
            stepFour: [
              {
                question: 'Are you currently working with a real estate agent?',
                answer: 'yes',
              },
              {
                question:
                  'Please enter the details of your real estate agent - First Name:',
                answer: firstNameValue,
              },
              {
                question:
                  'Please enter the details of your real estate agent - Last Name:',
                answer: lastNameValue,
              },
              {
                question:
                  'Please enter the details of your real estate agent - Email:',
                answer: emailValue,
              },
              {
                question:
                  'Please enter the details of your real estate agent - Phone Number:',
                answer: phoneNumberValue,
              },
              {
                question:
                  'Can we connect you with our Partner real estate agent?',
                answer: 'no',
              },
            ],
          };

          await updateApplicationFields(payload);

          console.log({
            firstNameValue,
            lastNameValue,
            emailValue,
          });

          await axios({
            method: 'post',
            url: `${`https://utopia-server.onrender.com`}/api/v1/email/inform_agent_of_homebuyer_added`,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              agentEmail: emailValue,
              agentFirstName: capitalizeFirstLetter(firstNameValue),
              homebuyerFirstName: capitalizeFirstLetter(userFirstName),
              homebuyerLastName: capitalizeFirstLetter(userLastName),
              homebuyerEmail: userEmail,
              applicationDate: '09/28/2023',
            }),
          });
          console.log('agent email sent');

          toast({
            title: 'Success',
            description: 'Agent has been added successfully',
            position: 'top-right',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });

          setAgentPresent(true);
        } else {
          toast({
            title: 'An error occurred.',
            description: 'The person you provided cannot be a agent',
            position: 'top-right',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });

          setAgentPresent(false);
        }
      } else {
        let payload = {
          stepFour: [
            {
              question: 'Are you currently working with a real estate agent?',
              answer: 'yes',
            },
            {
              question:
                'Please enter the details of your real estate agent - First Name:',
              answer: firstNameValue,
            },
            {
              question:
                'Please enter the details of your real estate agent - Last Name:',
              answer: lastNameValue,
            },
            {
              question:
                'Please enter the details of your real estate agent - Email:',
              answer: emailValue,
            },
            {
              question:
                'Please enter the details of your real estate agent - Phone Number:',
              answer: phoneNumberValue,
            },
            {
              question:
                'Can we connect you with our Partner real estate agent?',
              answer: 'no',
            },
          ],
        };
        await updateApplicationFields(payload);

        await axios({
          method: 'post',
          url: `${`https://utopia-server.onrender.com`}/api/v1/email/agent_email_from_user_added_by_buyer`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            agentFirstName: capitalizeFirstLetter(firstNameValue),
            agentLastName: capitalizeFirstLetter(lastNameValue),
            userFirstName: capitalizeFirstLetter(userFirstName),
            userLastName: capitalizeFirstLetter(userLastName),
            submissionDate: getFormattedDate(
              new Date(Timestamp.now().seconds * 1000)
            ),
            emailTo: emailValue,
          }),
        });
        console.log('agent email sent');

        toast({
          title: 'Success',
          description: 'Agent has been emailed',
          position: 'top-right',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        setAgentPresent(true);
      }

      // setFirstNameValue('');
      // setLastNameValue('');
      // setEmailValue('');
      // setPhoneNumberValue('');

      setLoading(false);
      onClose();
    } catch (err) {
      setFirstNameValue('');
      setLastNameValue('');
      setEmailValue('');
      setPhoneNumberValue('');

      setAgentPresent(false);
      setLoading(false);

      toast({
        title: 'An error occurred.',
        description: 'Unable to add the provided agent. Please try again',
        position: 'top-right',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleRemove = async () => {
    try {
      setLoading(true);

      const userFirstName = authUser?.data?.legalName?.firstName;
      const userLastName = authUser?.data?.legalName?.lastName;
      const userEmail = authUser?.email;

      const { userExists, isAgent } = await handleCheckForAgent(emailValue);
      if (userExists) {
        if (isAgent) {
          let payload = {
            stepFour: [
              {
                question: 'Are you currently working with a real estate agent?',
                answer: 'no',
              },
              {
                question:
                  'Please enter the details of your real estate agent - First Name:',
                answer: '',
              },
              {
                question:
                  'Please enter the details of your real estate agent - Last Name:',
                answer: '',
              },
              {
                question:
                  'Please enter the details of your real estate agent - Email:',
                answer: '',
              },
              {
                question:
                  'Please enter the details of your real estate agent - Phone Number:',
                answer: '',
              },
              {
                question:
                  'Can we connect you with our Partner real estate agent?',
                answer: 'no',
              },
            ],
          };

          await updateApplicationFields(payload);

          await axios({
            method: 'post',
            url: `${`https://utopia-server.onrender.com`}/api/v1/email/inform_agent_of_homebuyer_removed`,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              agentEmail: emailValue,
              agentFirstName: capitalizeFirstLetter(firstNameValue),
              homebuyerFirstName: capitalizeFirstLetter(userFirstName),
              homebuyerEmail: userEmail,
            }),
          });
          console.log('agent email sent');

          toast({
            title: 'Success',
            description: 'Agent has been removed successfully',
            position: 'top-right',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });

          setFirstNameValue('');
          setLastNameValue('');
          setEmailValue('');
          setPhoneNumberValue('');

          setAgentPresent(false);
        } else {
          toast({
            title: 'Error',
            description: 'The person you are trying to remove is not an agent',
            position: 'top-right',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });

          setAgentPresent(true);
        }
      } else {
        let payload = {
          stepFour: [
            {
              question: 'Are you currently working with a real estate agent?',
              answer: 'no',
            },
            {
              question:
                'Please enter the details of your real estate agent - First Name:',
              answer: '',
            },
            {
              question:
                'Please enter the details of your real estate agent - Last Name:',
              answer: '',
            },
            {
              question:
                'Please enter the details of your real estate agent - Email:',
              answer: '',
            },
            {
              question:
                'Please enter the details of your real estate agent - Phone Number:',
              answer: '',
            },
            {
              question:
                'Can we connect you with our Partner real estate agent?',
              answer: 'no',
            },
          ],
        };
        await updateApplicationFields(payload);

        await axios({
          method: 'post',
          url: `${`https://utopia-server.onrender.com`}/api/v1/email/inform_agent_of_homebuyer_removed`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            agentEmail: emailValue,
            agentFirstName: capitalizeFirstLetter(firstNameValue),
            homebuyerFirstName: capitalizeFirstLetter(userFirstName),
            homebuyerEmail: userEmail,
          }),
        });
        console.log('agent email sent');

        toast({
          title: 'Success',
          description: 'Agent has been removed successfully',
          position: 'top-right',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        setFirstNameValue('');
        setLastNameValue('');
        setEmailValue('');
        setPhoneNumberValue('');

        setAgentPresent(false);
      }

      setLoading(false);
    } catch (err) {
      setFirstNameValue('');
      setLastNameValue('');
      setEmailValue('');
      setPhoneNumberValue('');

      setAgentPresent(false);
      setLoading(false);

      toast({
        title: 'An error occurred.',
        description: 'An error occurred.',
        position: 'top-right',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Fragment>
      <Card align="left" bg="white" size="md" mt="5">
        <CardBody align="center">
          <Heading as="h4" size="md" mb={4}>
            Your Agent
          </Heading>
          <Stack>
            {agentPresent ? (
              <Box p="4" bg="gray.100" rounded="md">
                <Flex align="center">
                  <Box align="start">
                    <Text fontSize="md" fontWeight="600" color="black">
                      {firstNameValue} {lastNameValue}
                    </Text>
                    <Text fontSize="sm" fontWeight="500" color="gray">
                      {emailValue}
                    </Text>
                    <Text fontSize="sm" fontWeight="500" color="gray">
                      {phoneNumberValue}
                    </Text>
                  </Box>

                  <Spacer />
                  <Button
                    colorScheme="red"
                    onClick={async () => {
                      await handleRemove();
                    }}
                    isDisabled={loading}
                    isLoading={loading}
                  >
                    Remove
                  </Button>
                </Flex>
              </Box>
            ) : (
              <Box>
                <Button
                  colorScheme="blue"
                  rightIcon={<ArrowForwardIcon />}
                  onClick={async () => {
                    onOpen();
                    // await handleCheckForAgent('avikonduru+949494@gmail.com');
                  }}
                >
                  Add an Agent
                </Button>
              </Box>
            )}
          </Stack>
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add an Agent</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <Box align="center">
                <Spinner />
                <Text>Submitting Agent Email...</Text>
              </Box>
            ) : (
              <Stack>
                <FormControl>
                  <FormLabel>First Name:</FormLabel>
                  <Input
                    type="text"
                    value={firstNameValue}
                    onChange={e => {
                      setFirstNameValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Last Name:</FormLabel>
                  <Input
                    type="text"
                    value={lastNameValue}
                    onChange={e => {
                      setLastNameValue(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Email Address:</FormLabel>
                  <Input
                    type="email"
                    value={emailValue}
                    onChange={e => {
                      if (e.target.value == authUser.email) {
                        toast({
                          position: 'top-right',
                          title: 'Error',
                          description: "Can't use your own email",
                          status: 'error',
                          duration: 3000,
                          isClosable: true,
                        });
                      } else {
                        setEmailValue(e.target.value);
                      }
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Phone Number:</FormLabel>
                  <Input
                    type="tel"
                    placeholder="222 333 4444"
                    pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                    maxLength="10"
                    value={phoneNumberValue}
                    onChange={e => {
                      const value = e.target.value.replace(/\D/g, '');
                      setPhoneNumberValue(value.trim());
                    }}
                  />
                </FormControl>
              </Stack>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              onClick={async () => {
                await handleSubmit();
              }}
              isDisabled={
                loading ||
                isEmptyString(firstNameValue) ||
                isEmptyString(lastNameValue) ||
                isEmptyString(emailValue) ||
                isEmptyString(phoneNumberValue) ||
                phoneNumberValue.length !== 10 ||
                !validateEmail(emailValue) ||
                emailValue === authUser.email
              }
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default AddAgentCard;
