import { Flex, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import GenericTable from '../../../../components/GenericTable';
import { useAgentAuth } from '../../../../context/AgentContext';
import AgentDashboardSidebar from '../../../Sidebar';

function InspectionComplete() {
  const [loading, setLoading] = useState(false);

  const { updateAllApplicationsData, closingsData, searchResults } =
    useAgentAuth();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 150,
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Application Started',
        accessor: 'applicationStartDate',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Notes',
        accessor: 'interaction',
        minWidth: 150,
        disableSortBy: true,
        isInteraction: true,
      },
    ],
    []
  );

  useEffect(() => {
    setLoading(true);
    updateAllApplicationsData(() => {
      setLoading(false);
    });
  }, []);

  return (
    <AgentDashboardSidebar>
      <Flex>
        <Heading size="lg">
          Inspection Complete ({closingsData?.inspectionComplete?.length})
        </Heading>
      </Flex>
      <GenericTable
        columns={columns}
        data={searchResults ?? closingsData?.inspectionComplete ?? []}
        loading={loading}
      />
    </AgentDashboardSidebar>
  );
}

export default InspectionComplete;
