import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import { useAgentAuth } from '../../../context/AgentContext';
import { getNormalizedError } from '../../../utils/generalFunctions';
import { inviteUserByEmail } from '../../../utils/services/agent.service';
import { clientInvitationSchema } from '../../../validations/ClientInviteSchema';
import { USER_TYPES } from '../../../utils/constants';

const ClientInvitationButton = ({
  onInviteSuccess,
  isAgent = false,
  title,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [buttonLoading, setButtonLoading] = useState(false);
  const toast = useToast();

  const { updateAllApplicationsData } = useAgentAuth();

  const handleSubmit = async values => {
    try {
      let payload = {
        ...values,
        isAgent,
        client_email_address: values.client_email_address?.toLowerCase(),
        userType: USER_TYPES.HOME_OWNER,
      };

      // return;
      setButtonLoading(true);

      await inviteUserByEmail(payload);
      await updateAllApplicationsData();
      toast({
        title: 'Client Invited',
        description: `${values.client_name} invited successfully`,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
      setButtonLoading(false);
      onInviteSuccess && onInviteSuccess();
      onClose();
    } catch (err) {
      const error = getNormalizedError(err);

      toast({
        title: 'Request Failed',
        description: error
          ? error
          : 'Unable to invite user at the moment, please try again later',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });

      setButtonLoading(false);
    }
  };

  return (
    <>
      <Button
        backgroundColor={'#1C2430'}
        textColor={'white'}
        minW={30}
        px={10}
        onClick={onOpen}
        _hover={{
          bg: 'black',
          cursor: 'pointer',
        }}
      >
        {title ? title : 'Invite Client'}
      </Button>

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={{ base: 'full', md: 'xl' }}
        zIndex={1}
      >
        <ModalOverlay />
        <ModalContent minH="60vh">
          <ModalHeader>{title ? title : 'Invite Client'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Please invite clients who meet the criteria below:</Text>
            <UnorderedList mt={2}>
              <ListItem>Minimum FICO score of 550</ListItem>
              <ListItem>$3,500 Minimum Monthly Household Income</ListItem>
              <ListItem>
                Less than 50% Debt to Income Ratio (including Rent)
              </ListItem>
              <ListItem>12 Months of timely rent payments</ListItem>
              <ListItem>
                Upfront cash of 1-3% of the Home Purchase Price
              </ListItem>
            </UnorderedList>

            <Formik
              initialValues={{
                client_name: '',
                client_email_address: '',
              }}
              validationSchema={clientInvitationSchema}
              onSubmit={async values => {
                handleSubmit(values);
              }}
            >
              {formik => (
                <>
                  <FormControl
                    isInvalid={
                      formik.errors.client_name && formik.touched.client_name
                    }
                    mt={2}
                  >
                    <FormLabel>Client's Full name</FormLabel>
                    <Field
                      as={Input}
                      borderWidth="2px"
                      id="client_name"
                      name="client_name"
                      size="md"
                      borderRadius="md"
                      disabled={buttonLoading}
                      placeholder="John Doe"
                    />
                    <FormErrorMessage>
                      {formik.errors.client_name}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      formik.errors.client_email_address &&
                      formik.touched.client_email_address
                    }
                    mt={2}
                  >
                    <FormLabel>Client's Email Address</FormLabel>
                    <Field
                      as={Input}
                      borderWidth="2px"
                      id="client_email_address"
                      name="client_email_address"
                      size="md"
                      borderRadius="md"
                      type="email"
                      disabled={buttonLoading}
                      placeholder="client@email.com"
                    />
                    <FormErrorMessage>
                      {formik.errors.client_email_address}
                    </FormErrorMessage>
                  </FormControl>

                  <Flex mt={'10%'} justifyContent="flex-end">
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                      onClick={() => formik.handleSubmit()}
                      backgroundColor={'#1C2430'}
                      textColor={'white'}
                      minW={30}
                      ml={2}
                      isDisabled={buttonLoading}
                      _hover={{
                        bg: 'black',
                        cursor: 'pointer',
                      }}
                    >
                      {buttonLoading ? <Spinner /> : ' Send Invite'}
                    </Button>
                  </Flex>
                </>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ClientInvitationButton;
