import { Field, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import { agentSignUpSchema } from '../validations/AgentSignUpSchema';

// utils
import SEO from '../utils/SEO';

// chakra
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Link,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useAuth } from '../context/AuthContext';
import { USER_TYPES } from '../utils/constants';

const AgentSignUp = () => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [isDesktopScreen, setIsDesktopScreen] = useState(false);
  const [referral, setReferral] = useState();

  const toast = useToast();

  const {
    handleSignUp,
    authLoading,
    authUser,
    setAuthLoading,
    getReferralByCode,
  } = useAuth();
  const { agent } = useParams();

  const handleSubmit = async values => {
    let agentInfo = undefined;

    if (referral) {
      agentInfo = referral.userId;
    }

    const { error } = await handleSignUp(
      values.email?.toLowerCase(),
      values.password,
      USER_TYPES.AGENT,
      values.firstName,
      values.lastName,
      agentInfo,
      Boolean(referral),
      null
    );
    if (error) {
      console.log(error);
      toast({
        title: 'Error on Sign Up',
        description: error,
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const getAndSaveAgentInfo = async () => {
    try {
      const response = await getReferralByCode(agent);
      setReferral(response);
    } catch (err) {
      toast({
        title: 'Failed',
        description: 'Invalid Referral Code',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    setIsDesktopScreen(isDesktop);
  }, [isDesktop]);

  useEffect(() => {
    if (agent) {
      getAndSaveAgentInfo();
    }

    const fetchData = async () => {
      setAuthLoading(true);
      if (authUser) {
        navigate('/');
      }
      setAuthLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Fragment>
      <SEO
        title={`Utopia Homes | Agent Sign Up`}
        description={`Sign up as an agent with Utopia Homes.`}
        name={`Utopia Homes`}
        type={`application page`}
      />

      <Grid h="100vh" templateColumns="repeat(6, 1fr)">
        <GridItem colSpan={isDesktopScreen ? 3 : 6}>
          <Container maxW="container.sm" py="8">
            <Link href="https://www.utopiahomes.us/">
              <Image
                objectFit="cover"
                w="150px"
                src="https://i.imgur.com/avkbaJm.png"
                alt="Utopia Logo"
              />
            </Link>

            <Container maxW="md" py="8">
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  password: '',
                  confirmPassword: '',
                }}
                validationSchema={agentSignUpSchema}
                onSubmit={async values => {
                  handleSubmit(values);
                }}
              >
                {formik => (
                  <form onSubmit={formik.handleSubmit}>
                    <Stack spacing="7">
                      <Box>
                        <Heading mb="2">Sign up as an Agent</Heading>
                        <Text fontSize="md" as="b" color="gray.600">
                          Become a partner agent with Utopia Homes.
                        </Text>
                      </Box>
                      <Stack spacing="7">
                        <Stack spacing="3">
                          <Stack direction="row" spacing="3">
                            <FormControl
                              isInvalid={
                                formik.errors.firstName &&
                                formik.touched.firstName
                              }
                            >
                              <FormLabel color="gray">First Name</FormLabel>
                              <Field
                                as={Input}
                                borderWidth="2px"
                                size="lg"
                                id="firstName"
                                name="firstName"
                                type="text"
                                disabled={authLoading}
                              />
                              <FormErrorMessage>
                                {formik.errors.firstName}
                              </FormErrorMessage>
                            </FormControl>
                            <FormControl
                              isInvalid={
                                formik.errors.lastName &&
                                formik.touched.lastName
                              }
                            >
                              <FormLabel color="gray">Last Name</FormLabel>
                              <Field
                                as={Input}
                                borderWidth="2px"
                                size="lg"
                                id="lastName"
                                name="lastName"
                                type="text"
                                disabled={authLoading}
                              />
                              <FormErrorMessage>
                                {formik.errors.lastName}
                              </FormErrorMessage>
                            </FormControl>
                          </Stack>
                          <FormControl
                            isInvalid={
                              formik.errors.email && formik.touched.email
                            }
                          >
                            <FormLabel color="gray">Email</FormLabel>
                            <Field
                              as={Input}
                              borderWidth="2px"
                              size="lg"
                              id="email"
                              name="email"
                              type="email"
                              disabled={authLoading}
                            />
                            <FormErrorMessage>
                              {formik.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={
                              formik.errors.password && formik.touched.password
                            }
                          >
                            <FormLabel color="gray">Password</FormLabel>
                            <Field
                              as={Input}
                              borderWidth="2px"
                              size="lg"
                              id="password"
                              name="password"
                              type="password"
                              disabled={authLoading}
                            />
                            <FormErrorMessage>
                              {formik.errors.password}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={
                              formik.errors.confirmPassword &&
                              formik.touched.confirmPassword
                            }
                          >
                            <FormLabel color="gray">Confirm Password</FormLabel>
                            <Field
                              as={Input}
                              borderWidth="2px"
                              size="lg"
                              id="confirmPassword"
                              name="confirmPassword"
                              type="password"
                              disabled={authLoading}
                            />
                            <FormErrorMessage>
                              {formik.errors.confirmPassword}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>
                        <Button size="lg" type="submit">
                          Sign Up
                        </Button>
                      </Stack>
                      <Flex align="center" justify="center">
                        <Text fontSize="md" as="b" mr="3">
                          Already have an account?
                        </Text>
                        <Link
                          fontSize="md"
                          as="b"
                          color="blue.500"
                          onClick={() => {
                            navigate('/login');
                          }}
                        >
                          Log In
                        </Link>
                      </Flex>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Container>
          </Container>
        </GridItem>
        {isDesktopScreen && (
          <GridItem colSpan={3}>
            <Image
              src="https://i.imgur.com/pBSvI52.jpg"
              objectFit="cover"
              h="100vh"
            />
          </GridItem>
        )}
      </Grid>
    </Fragment>
  );
};

export default AgentSignUp;
