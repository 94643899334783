import React, { Fragment, useState } from 'react';

// context
import { useCoapplicant } from '../../context/CoapplicantContext';

// components
import StartCoapplicantApplication from './StartCoapplicantApplication';
import CoapplicantApplicationSubmitted from './CoapplicantApplicationSubmitted';
import CoapplicantStepOne from './Onboarding/CoapplicantStepOne';
import CoapplicantStepTwo from './Onboarding/CoapplicantStepTwo';
import CoapplicantStepThree from './Onboarding/CoapplicantStepThree';
import CoapplicantStepFour from './Onboarding/CoapplicantStepFour';
import CoapplicantStepFive from './Onboarding/CoapplicantStepFive';
import CoapplicantStepSix from './Onboarding/CoapplicantStepSix';
import CoapplicantStepSeven from './Onboarding/CoapplicantStepSeven';
import CoapplicantStepEight from './Onboarding/CoapplicantStepEight';
import CoapplicantStepNine from './Onboarding/CoapplicantStepNine';
import CoapplicantStepTen from './Onboarding/CoapplicantStepTen';
import CoapplicantStepEleven from './Onboarding/CoapplicantStepEleven';
import CoapplicantStepTwelve from './Onboarding/CoapplicantStepTwelve';
import CoapplicantStepThirteen from './Onboarding/CoapplicantStepThirteen';
import CoapplicantError from './CoapplicantError';

// chakra
import { Box, Progress } from '@chakra-ui/react';

const CoapplicantOnboardingProcess = () => {
  const { coapplicantData, finishCoapplicationApplication } = useCoapplicant();

  const [activeStep, setActiveStep] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);

  // form answers
  const [stepOnePhoneNumber, setStepOnePhoneNumber] = useState('');

  const [stepTwoMonthlyIncome, setStepTwoMonthlyIncome] = useState('');
  const [stepTwoRent, setStepTwoRent] = useState('');

  const [stepThreeCreditFirstName, setStepThreeCreditFirstName] = useState('');
  const [stepThreeCreditMiddleName, setStepThreeCreditMiddleName] =
    useState('');
  const [stepThreeCreditLastName, setStepThreeCreditLastName] = useState('');
  const [stepThreeCreditStreetAddress, setStepThreeCreditStreetAddress] =
    useState('');
  const [stepThreeCreditZipCode, setStepThreeCreditZipCode] = useState('');
  const [stepThreeCreditSsn, setStepThreeCreditSsn] = useState('');

  const [stepFourPrimaryIncome, setStepFourPrimaryIncome] = useState('');

  const [stepFiveEmployerName, setStepFiveEmployerName] = useState('');
  const [stepFiveStartDate, setStepFiveStartDate] = useState('');
  const [stepFiveEmployerCity, setStepFiveEmployerCity] = useState('');
  const [stepFiveEmployerState, setStepFiveEmployerState] = useState('');

  const [stepSixPayPeriod, setStepSixPayPeriod] = useState('');
  const [stepSixPreTaxIncome, setStepSixPreTaxIncome] = useState('');

  const [stepSevenIncomeDocOne, setStepSevenIncomeDocOne] = useState('');
  const [stepSevenIncomeDocTwo, setStepSevenIncomeDocTwo] = useState('');
  const [stepSevenIncomeDocThree, setStepSevenIncomeDocThree] = useState('');

  const [stepEightBankStatementOne, setStepEightBankStatementOne] =
    useState('');
  const [stepEightBankStatementTwo, setStepEightBankStatementTwo] =
    useState('');
  const [stepEightBankStatementThree, setStepEightBankStatementThree] =
    useState('');

  const [stepNineHasRaise, setStepNineHasRaise] = useState('');

  const [stepTenMoreThanOneSalary, setStepTenMoreThanOneSalary] = useState('');
  const [stepTenAdditionalYearlySalary, setStepTenAdditionalYearlySalary] =
    useState('');

  const [stepElevenIdentityDoc, setStepElevenIdentityDoc] = useState('');

  const [
    stepTwelveBackgroundCheckEviction,
    setStepTwelveBackgroundCheckEviction,
  ] = useState('');
  const [stepTwelveBackgroundCheckDOB, setStepTwelveBackgroundCheckDOB] =
    useState('');

  const [stepThirteenReviewApp, setStepThirteenReviewApp] = useState('');

  const handleNext = async () => {
    try {
      setButtonLoading(true);

      // if (activeStep === 0) {
      // } else if (activeStep === 1) {
      // } else if (activeStep === 2) {
      // } else if (activeStep === 3) {
      // } else if (activeStep === 4) {
      // } else if (activeStep === 5) {
      // } else if (activeStep === 6) {
      // } else if (activeStep === 7) {
      // } else if (activeStep === 8) {
      // } else if (activeStep === 9) {
      // } else if (activeStep === 10) {
      // } else if (activeStep === 11) {
      // } else if (activeStep === 12) {
      // } else if (activeStep === 13) {
      //   await finishCoapplicationApplication(
      //     stepOnePhoneNumber,
      //     stepTwoMonthlyIncome,
      //     // stepTwoRent,
      //     stepThreeCreditFirstName,
      //     stepThreeCreditMiddleName,
      //     stepThreeCreditLastName,
      //     stepThreeCreditStreetAddress,
      //     stepThreeCreditZipCode,
      //     stepThreeCreditSsn,
      //     stepFourPrimaryIncome,
      //     stepFiveEmployerName,
      //     stepFiveStartDate,
      //     stepFiveEmployerCity,
      //     stepFiveEmployerState,
      //     stepSixPayPeriod,
      //     stepSixPreTaxIncome,
      //     stepSevenIncomeDocOne,
      //     stepSevenIncomeDocTwo,
      //     stepSevenIncomeDocThree,
      //     stepEightBankStatementOne,
      //     stepEightBankStatementTwo,
      //     stepEightBankStatementThree,
      //     stepNineHasRaise,
      //     stepTenMoreThanOneSalary,
      //     stepTenAdditionalYearlySalary,
      //     stepElevenIdentityDoc,
      //     stepTwelveBackgroundCheckEviction,
      //     stepTwelveBackgroundCheckDOB,
      //     stepThirteenReviewApp
      //   );
      // }

      if (activeStep === 0) {
      } else if (activeStep === 1) {
      } else if (activeStep === 2) {
      } else if (activeStep === 3) {
      } else if (activeStep === 4) {
      } else if (activeStep === 5) {
      } else if (activeStep === 6) {
      } else if (activeStep === 7) {
      } else if (activeStep === 8) {
      } else if (activeStep === 9) {
      } else if (activeStep === 10) {
      } else if (activeStep === 11) {
        await finishCoapplicationApplication(
          stepOnePhoneNumber,
          stepTwoMonthlyIncome,
          stepTwoRent,
          stepThreeCreditFirstName,
          stepThreeCreditMiddleName,
          stepThreeCreditLastName,
          stepThreeCreditStreetAddress,
          stepThreeCreditZipCode,
          stepThreeCreditSsn,
          stepFourPrimaryIncome,
          stepFiveEmployerName,
          stepFiveStartDate,
          stepFiveEmployerCity,
          stepFiveEmployerState,
          stepSixPayPeriod,
          stepSixPreTaxIncome,
          stepSevenIncomeDocOne,
          stepSevenIncomeDocTwo,
          stepSevenIncomeDocThree,
          stepEightBankStatementOne,
          stepEightBankStatementTwo,
          stepEightBankStatementThree,
          stepNineHasRaise,
          stepTenMoreThanOneSalary,
          stepTenAdditionalYearlySalary,
          stepElevenIdentityDoc,
          stepTwelveBackgroundCheckEviction,
          stepTwelveBackgroundCheckDOB,
          stepThirteenReviewApp
        );
      }

      setActiveStep(activeStep + 1);

      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
    }
  };

  const setInitialData = async () => {
    try {
    } catch (err) {
      console.log('setInitialData() ERROR: ', err);
    }
  };

  const renderStep = activeStep => {
    switch (activeStep) {
      case 0:
        return (
          <StartCoapplicantApplication
            setActiveStep={setActiveStep}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      // case 1:
      //   return (
      //     <CoapplicantStepOne
      //       setActiveStep={setActiveStep}
      //       stepOnePhoneNumber={stepOnePhoneNumber}
      //       setStepOnePhoneNumber={setStepOnePhoneNumber}
      //       handleNext={handleNext}
      //       loading={buttonLoading}
      //     />
      //   );
      // case 2:
      //   return (
      //     <CoapplicantStepTwo
      //       setActiveStep={setActiveStep}
      //       stepTwoMonthlyIncome={stepTwoMonthlyIncome}
      //       setStepTwoMonthlyIncome={setStepTwoMonthlyIncome}
      //       // stepTwoRent={stepTwoRent}
      //       // setStepTwoRent={setStepTwoRent}
      //       handleNext={handleNext}
      //       loading={buttonLoading}
      //     />
      //   );
      case 1:
        return (
          <CoapplicantStepThree
            setActiveStep={setActiveStep}
            stepThreeCreditFirstName={stepThreeCreditFirstName}
            setStepThreeCreditFirstName={setStepThreeCreditFirstName}
            stepThreeCreditMiddleName={stepThreeCreditMiddleName}
            setStepThreeCreditMiddleName={setStepThreeCreditMiddleName}
            stepThreeCreditLastName={stepThreeCreditLastName}
            setStepThreeCreditLastName={setStepThreeCreditLastName}
            stepThreeCreditStreetAddress={stepThreeCreditStreetAddress}
            setStepThreeCreditStreetAddress={setStepThreeCreditStreetAddress}
            stepThreeCreditZipCode={stepThreeCreditZipCode}
            setStepThreeCreditZipCode={setStepThreeCreditZipCode}
            stepThreeCreditSsn={stepThreeCreditSsn}
            setStepThreeCreditSsn={setStepThreeCreditSsn}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 2:
        return (
          <CoapplicantStepFour
            setActiveStep={setActiveStep}
            stepFourPrimaryIncome={stepFourPrimaryIncome}
            setStepFourPrimaryIncome={setStepFourPrimaryIncome}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 3:
        return (
          <CoapplicantStepFive
            setActiveStep={setActiveStep}
            stepFiveEmployerName={stepFiveEmployerName}
            setStepFiveEmployerName={setStepFiveEmployerName}
            stepFiveStartDate={stepFiveStartDate}
            setStepFiveStartDate={setStepFiveStartDate}
            stepFiveEmployerCity={stepFiveEmployerCity}
            setStepFiveEmployerCity={setStepFiveEmployerCity}
            stepFiveEmployerState={stepFiveEmployerState}
            setStepFiveEmployerState={setStepFiveEmployerState}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 4:
        return (
          <CoapplicantStepSix
            setActiveStep={setActiveStep}
            stepFiveEmployerName={stepFiveEmployerName}
            stepSixPayPeriod={stepSixPayPeriod}
            setStepSixPayPeriod={setStepSixPayPeriod}
            stepSixPreTaxIncome={stepSixPreTaxIncome}
            setStepSixPreTaxIncome={setStepSixPreTaxIncome}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 5:
        return (
          <CoapplicantStepSeven
            setActiveStep={setActiveStep}
            stepSevenIncomeDocOne={stepSevenIncomeDocOne}
            setStepSevenIncomeDocOne={setStepSevenIncomeDocOne}
            stepSevenIncomeDocTwo={stepSevenIncomeDocTwo}
            setStepSevenIncomeDocTwo={setStepSevenIncomeDocTwo}
            stepSevenIncomeDocThree={stepSevenIncomeDocThree}
            setStepSevenIncomeDocThree={setStepSevenIncomeDocThree}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 6:
        return (
          <CoapplicantStepEight
            setActiveStep={setActiveStep}
            stepEightBankStatementOne={stepEightBankStatementOne}
            setStepEightBankStatementOne={setStepEightBankStatementOne}
            stepEightBankStatementTwo={stepEightBankStatementTwo}
            setStepEightBankStatementTwo={setStepEightBankStatementTwo}
            stepEightBankStatementThree={stepEightBankStatementThree}
            setStepEightBankStatementThree={setStepEightBankStatementThree}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 7:
        return (
          <CoapplicantStepNine
            setActiveStep={setActiveStep}
            stepNineHasRaise={stepNineHasRaise}
            setStepNineHasRaise={setStepNineHasRaise}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 8:
        return (
          <CoapplicantStepTen
            setActiveStep={setActiveStep}
            stepTenMoreThanOneSalary={stepTenMoreThanOneSalary}
            setStepTenMoreThanOneSalary={setStepTenMoreThanOneSalary}
            stepTenAdditionalYearlySalary={stepTenAdditionalYearlySalary}
            setStepTenAdditionalYearlySalary={setStepTenAdditionalYearlySalary}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 9:
        return (
          <CoapplicantStepEleven
            setActiveStep={setActiveStep}
            stepElevenIdentityDoc={stepElevenIdentityDoc}
            setStepElevenIdentityDoc={setStepElevenIdentityDoc}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 10:
        return (
          <CoapplicantStepTwelve
            setActiveStep={setActiveStep}
            stepTwelveBackgroundCheckEviction={
              stepTwelveBackgroundCheckEviction
            }
            setStepTwelveBackgroundCheckEviction={
              setStepTwelveBackgroundCheckEviction
            }
            stepTwelveBackgroundCheckDOB={stepTwelveBackgroundCheckDOB}
            setStepTwelveBackgroundCheckDOB={setStepTwelveBackgroundCheckDOB}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 11:
        return (
          <CoapplicantStepThirteen
            setActiveStep={setActiveStep}
            // stepOnePhoneNumber={stepOnePhoneNumber}
            // setStepOnePhoneNumber={setStepOnePhoneNumber}
            // stepTwoMonthlyIncome={stepTwoMonthlyIncome}
            // setStepTwoMonthlyIncome={setStepTwoMonthlyIncome}
            // stepTwoRent={stepTwoRent}
            // setStepTwoRent={setStepTwoRent}
            stepThreeCreditFirstName={stepThreeCreditFirstName}
            setStepThreeCreditFirstName={setStepThreeCreditFirstName}
            stepThreeCreditMiddleName={stepThreeCreditMiddleName}
            setStepThreeCreditMiddleName={setStepThreeCreditMiddleName}
            stepThreeCreditLastName={stepThreeCreditLastName}
            setStepThreeCreditLastName={setStepThreeCreditLastName}
            stepThreeCreditStreetAddress={stepThreeCreditStreetAddress}
            setStepThreeCreditStreetAddress={setStepThreeCreditStreetAddress}
            stepThreeCreditZipCode={stepThreeCreditZipCode}
            setStepThreeCreditZipCode={setStepThreeCreditZipCode}
            stepThreeCreditSsn={stepThreeCreditSsn}
            setStepThreeCreditSsn={setStepThreeCreditSsn}
            stepFourPrimaryIncome={stepFourPrimaryIncome}
            setStepFourPrimaryIncome={setStepFourPrimaryIncome}
            stepFiveEmployerName={stepFiveEmployerName}
            setStepFiveEmployerName={setStepFiveEmployerName}
            stepFiveStartDate={stepFiveStartDate}
            setStepFiveStartDate={setStepFiveStartDate}
            stepFiveEmployerCity={stepFiveEmployerCity}
            setStepFiveEmployerCity={setStepFiveEmployerCity}
            stepFiveEmployerState={stepFiveEmployerState}
            setStepFiveEmployerState={setStepFiveEmployerState}
            stepSixPayPeriod={stepSixPayPeriod}
            setStepSixPayPeriod={setStepSixPayPeriod}
            stepSixPreTaxIncome={stepSixPreTaxIncome}
            setStepSixPreTaxIncome={setStepSixPreTaxIncome}
            stepSevenIncomeDocOne={stepSevenIncomeDocOne}
            setStepSevenIncomeDocOne={setStepSevenIncomeDocOne}
            stepSevenIncomeDocTwo={stepSevenIncomeDocTwo}
            setStepSevenIncomeDocTwo={setStepSevenIncomeDocTwo}
            stepSevenIncomeDocThree={stepSevenIncomeDocThree}
            setStepSevenIncomeDocThree={setStepSevenIncomeDocThree}
            stepEightBankStatementOne={stepEightBankStatementOne}
            setStepEightBankStatementOne={setStepEightBankStatementOne}
            stepEightBankStatementTwo={stepEightBankStatementTwo}
            setStepEightBankStatementTwo={setStepEightBankStatementTwo}
            stepEightBankStatementThree={stepEightBankStatementThree}
            setStepEightBankStatementThree={setStepEightBankStatementThree}
            stepNineHasRaise={stepNineHasRaise}
            setStepNineHasRaise={setStepNineHasRaise}
            stepTenMoreThanOneSalary={stepTenMoreThanOneSalary}
            setStepTenMoreThanOneSalary={setStepTenMoreThanOneSalary}
            stepTenAdditionalYearlySalary={stepTenAdditionalYearlySalary}
            setStepTenAdditionalYearlySalary={setStepTenAdditionalYearlySalary}
            stepElevenIdentityDoc={stepElevenIdentityDoc}
            setStepElevenIdentityDoc={setStepElevenIdentityDoc}
            stepTwelveBackgroundCheckEviction={
              stepTwelveBackgroundCheckEviction
            }
            setStepTwelveBackgroundCheckEviction={
              setStepTwelveBackgroundCheckEviction
            }
            stepTwelveBackgroundCheckDOB={stepTwelveBackgroundCheckDOB}
            setStepTwelveBackgroundCheckDOB={setStepTwelveBackgroundCheckDOB}
            stepThirteenReviewApp={stepThirteenReviewApp}
            setStepThirteenReviewApp={setStepThirteenReviewApp}
            handleNext={handleNext}
            loading={buttonLoading}
            setLoading={setButtonLoading}
          />
        );
      case 12:
        return <CoapplicantApplicationSubmitted />;
      default:
        return <Box>ERROR</Box>;
    }
  };

  return (
    <Fragment>
      {console.log({ coapplicantData })}
      {coapplicantData === null ? (
        <Fragment>
          <CoapplicantError />
        </Fragment>
      ) : (
        <Fragment>
          {coapplicantData.applicationCompleted ? (
            <Fragment>
              <CoapplicantApplicationSubmitted />
            </Fragment>
          ) : (
            <Fragment>
              {activeStep > 0 && activeStep < 12 && (
                <Box align="left" maxW="xl" mb="5">
                  <Progress
                    colorScheme={activeStep === 11 ? 'green' : 'blue'}
                    rounded="full"
                    size="md"
                    value={(activeStep / 11) * 100}
                    bg="gray.200"
                    hasStripe
                    isAnimated
                  />
                </Box>
              )}
              {renderStep(activeStep)}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default CoapplicantOnboardingProcess;
