import { Flex, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import GenericTable from '../../../../components/GenericTable';
import { useAgentAuth } from '../../../../context/AgentContext';
import AgentDashboardSidebar from '../../../Sidebar';
import moment from 'moment';

function InitiatedOffers() {
  const [loading, setLoading] = useState(false);

  const { getinitiatedOffersData, initiatedOffers, searchResults } =
    useAgentAuth();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'clientName',
        minWidth: 150,
      },
      {
        Header: 'Email',
        accessor: 'clientEmail',
        minWidth: 150,
      },

      {
        Header: 'List Price',
        accessor: 'propertyListPrice',
        minWidth: 150,
      },

      {
        Header: 'Offer Price',
        accessor: 'propertyOfferPrice',
        minWidth: 150,
      },

      {
        Header: 'Submitted At',
        accessor: 'timeSubmitted',
        minWidth: 150,
        Cell: ({ value }) => {
          const submission = value.toDate();
          return moment(submission).fromNow();
        },
      },

      {
        Header: 'Status',
        accessor: 'status',
        minWidth: 150,
      },
    ],
    []
  );

  useEffect(() => {
    setLoading(true);
    getinitiatedOffersData(() => {
      setLoading(false);
    });
  }, []);

  return (
    <AgentDashboardSidebar>
      <Flex>
        <Heading size="lg">
          Initiated Offers ({initiatedOffers?.length})
        </Heading>
      </Flex>
      <GenericTable
        columns={columns}
        data={searchResults ?? initiatedOffers ?? []}
        loading={loading}
        detailsDisabled={true}
      />
    </AgentDashboardSidebar>
  );
}

export default InitiatedOffers;
