import React, { Fragment } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

// chakra
import {
  Box,
  Button,
  Heading,
  Text,
  Container,
  Flex,
  Spacer,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Stack,
  StackDivider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useMediaQuery,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  Link,
} from '@chakra-ui/react';
import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { authUser } = useAuth();
  const navigate = useNavigate();

  return (
    <Fragment>
      <Container maxW="container.xl">
        <Flex py="5" align="center">
          {/* <Box>
            <Image
              src="https://i.imgur.com/avkbaJm.png"
              alt="Utopia Logo"
              maxW="100px"
            />
          </Box>

          <Spacer />

          <Flex></Flex> */}
          <Link href="/">
            <Image
              src="https://i.imgur.com/avkbaJm.png"
              maxW="130px"
              _hover={{ cursor: 'pointer' }}
            />
          </Link>

          <Spacer />

          <Flex align="center" display={{ base: 'none', md: 'block' }}>
            <Link href="https://www.utopiahomes.us/" target="_blank">
              <Button variant="link" mr="7" color="gray.700">
                <Text fontSize="sm" fontWeight="600">
                  Homebuyers
                </Text>
              </Button>
            </Link>

            <Link href="https://landlords.utopiahomes.us/" target="_blank">
              <Button variant="link" mr="7" color="gray.700">
                <Text fontSize="sm" fontWeight="600">
                  Landlords
                </Text>
              </Button>
            </Link>

            <Link
              onClick={() => {
                navigate('/search-homes');
              }}
            >
              <Button variant="link" mr="7" color="gray.700">
                <Text fontSize="sm" fontWeight="600">
                  Search Homes
                </Text>
              </Button>
            </Link>

            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                variant="link"
                mr="5"
                color="gray.700"
                _active={{ color: 'gray.500' }}
              >
                <Text fontSize="sm" fontWeight="600">
                  Partners
                </Text>
              </MenuButton>
              <MenuList color="gray.600">
                <Link href="https://www.utopiahomes.us/agents" target="_blank">
                  <MenuItem>Agents</MenuItem>
                </Link>

                <Link href="https://www.utopiahomes.us/lenders" target="_blank">
                  <MenuItem>Lenders</MenuItem>
                </Link>

                <Link href="https://investors.utopiahomes.us/" target="_blank">
                  <MenuItem>Investors</MenuItem>
                </Link>
              </MenuList>
            </Menu>

            {/* <Link
								href='https://www.utopiahomes.us/how-it-works'
								target='_blank'>
								<Button
									variant='link'
									mr='7'
									color='white'>
									<Text
										fontSize='sm'
										fontWeight='600'>
										How it works
									</Text>
								</Button>
							</Link> */}

            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                variant="link"
                mr="7"
                color="gray.700"
                _active={{ color: 'gray.500' }}
              >
                <Text fontSize="sm" fontWeight="600">
                  About
                </Text>
              </MenuButton>
              <MenuList color="gray.600">
                <Link
                  href="https://www.utopiahomes.us/about-us"
                  target="_blank"
                >
                  <MenuItem>About Us</MenuItem>
                </Link>

                <Link href="/faq">
                  <MenuItem>FAQ: Investor</MenuItem>
                </Link>

                <Link
                  href="https://www.utopiahomes.us/faq/general-questions"
                  target="_blank"
                >
                  <MenuItem>FAQ: Homebuyer</MenuItem>
                </Link>

                <Link href="/blogs">
                  <MenuItem>Blogs: Investor</MenuItem>
                </Link>

                <Link href="https://blog.utopiahomes.us/" target="_blank">
                  <MenuItem>Blogs: Homebuyer</MenuItem>
                </Link>
              </MenuList>
            </Menu>
          </Flex>

          {authUser ? (
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => {
                navigate('/');
              }}
            >
              Dashboard
            </Button>
          ) : (
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => {
                navigate('/signup/homebuyer');
              }}
            >
              Sign In
            </Button>
          )}

          <IconButton
            display={{ base: 'block', md: 'none' }}
            aria-label="menu"
            icon={<HamburgerIcon />}
            size="sm"
            ml="4"
            variant="outline"
            colorScheme="white"
            onClick={onOpen}
          />
        </Flex>
      </Container>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xs">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>

          <DrawerBody>
            <Stack
              direction="column"
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              align="stretch"
            >
              <Link href="https://www.utopiahomes.us/" target="_blank">
                <Button variant="ghost">
                  <Box w="100%" textAlign="left">
                    <Text fontSize="md" fontWeight="600">
                      Homebuyers
                    </Text>
                  </Box>
                </Button>
              </Link>

              <Link href="https://landlords.utopiahomes.us/" target="_blank">
                <Button variant="ghost">
                  <Box w="100%" textAlign="left">
                    <Text fontSize="md" fontWeight="600">
                      Landlords
                    </Text>
                  </Box>
                </Button>
              </Link>

              <Accordion allowToggle borderColor="transparent">
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box w="100%" textAlign="left">
                        <Text fontSize="md" fontWeight="600">
                          Partners
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Stack
                      direction="column"
                      divider={<StackDivider borderColor="gray.200" />}
                      spacing={3}
                      align="stretch"
                    >
                      <Link
                        href="https://investors.utopiahomes.us/"
                        target="_blank"
                      >
                        <Button variant="ghost" size="sm">
                          <Box w="100%" textAlign="left">
                            <Text fontSize="md" fontWeight="600">
                              Investors
                            </Text>
                          </Box>
                        </Button>
                      </Link>

                      <Link
                        href="https://www.utopiahomes.us/agents"
                        target="_blank"
                      >
                        <Button variant="ghost" size="sm">
                          <Box w="100%" textAlign="left">
                            <Text fontSize="md" fontWeight="600">
                              Agents
                            </Text>
                          </Box>
                        </Button>
                      </Link>

                      <Link
                        href="https://www.utopiahomes.us/lenders"
                        target="_blank"
                      >
                        <Button variant="ghost">
                          <Box w="100%" textAlign="left">
                            <Text fontSize="md" fontWeight="600">
                              Lenders
                            </Text>
                          </Box>
                        </Button>
                      </Link>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              {/* <Link
								href='https://www.utopiahomes.us/how-it-works'
								target='_blank'>
								<Button variant='ghost'>
									<Box
										w='100%'
										textAlign='left'>
										<Text
											fontSize='md'
											fontWeight='600'>
											How it works
										</Text>
									</Box>
								</Button>
							</Link> */}

              <Accordion allowToggle borderColor="transparent">
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box w="100%" textAlign="left">
                        <Text fontSize="md" fontWeight="600">
                          About
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Stack
                      direction="column"
                      divider={<StackDivider borderColor="gray.200" />}
                      spacing={3}
                      align="stretch"
                    >
                      <Link
                        href="https://www.utopiahomes.us/about-us"
                        target="_blank"
                      >
                        <Button variant="ghost" size="sm">
                          <Box w="100%" textAlign="left">
                            <Text fontSize="md" fontWeight="600">
                              About Us
                            </Text>
                          </Box>
                        </Button>
                      </Link>

                      <Link href="/faq">
                        <Button variant="ghost">
                          <Box w="100%" textAlign="left">
                            <Text fontSize="md" fontWeight="600">
                              FAQ: Investor
                            </Text>
                          </Box>
                        </Button>
                      </Link>

                      <Link
                        href="https://www.utopiahomes.us/faq/general-questions"
                        target="_blank"
                      >
                        <Button variant="ghost">
                          <Box w="100%" textAlign="left">
                            <Text fontSize="md" fontWeight="600">
                              FAQ: Homebuyer
                            </Text>
                          </Box>
                        </Button>
                      </Link>

                      <Link href="/blogs">
                        <Button variant="ghost">
                          <Box w="100%" textAlign="left">
                            <Text fontSize="md" fontWeight="600">
                              Blogs: Investor
                            </Text>
                          </Box>
                        </Button>
                      </Link>

                      <Link href="https://blog.utopiahomes.us/" target="_blank">
                        <Button variant="ghost">
                          <Box w="100%" textAlign="left">
                            <Text fontSize="md" fontWeight="600">
                              Blogs: Homebuyer
                            </Text>
                          </Box>
                        </Button>
                      </Link>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Fragment>
  );
};

export default Navbar;
