import React, { Fragment, useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { USER_TYPES } from '../utils/constants';

// utils
import SEO from '../utils/SEO';

// components
import AgentOnboarding from '../components/OnboardingPage/AgentOnboarding/AgentOnboarding';
import HomebuyerOnboarding from '../components/OnboardingPage/HomebuyerOnboarding/HomebuyerOnboarding';
import LandlordOnboarding from '../components/OnboardingPage/LandlordOnboarding/LandlordOnboarding';
import SidebarWithHeader from '../components/SidebarWithHeader';
import VerifyEmail from '../components/OnboardingPage/VerifyEmail';

// chakra
import { Container } from '@chakra-ui/react';

const Onboarding = () => {
  const { authUser, handleNavigation } = useAuth();

  const isEmailVerified = authUser.emailVerified;

  const authedUserType =
    authUser?.data?.type == USER_TYPES.AGENT
      ? 'Agent'
      : authUser?.data?.type == USER_TYPES.LANDLORD
      ? 'Landlord'
      : authUser?.data?.type == USER_TYPES.HOME_OWNER
      ? 'Homebuyer'
      : 'Homebuyer';

  const [isLargeContainer, setIsLargeContainer] = useState(false);

  useEffect(() => {
    // if (authUser) {
    //   handleNavigation(authUser?.data);
    // }
  }, []);

  return (
    <Fragment>
      <SEO
        title={`Utopia Homes | Onboarding`}
        description={`Onboarding page for Utopia Homes. Start your application to become a landlord, agent, or homebuyer.`}
        name={`Utopia Homes`}
        type={`application page`}
      />

      <SidebarWithHeader userMode="onboarding">
        <Container
          maxW={isLargeContainer == true ? 'container.md' : 'container.sm'}
        >
          {isEmailVerified ? (
            <>
              {authedUserType == 'Landlord' ? (
                <LandlordOnboarding />
              ) : authedUserType == 'Homebuyer' ? (
                <HomebuyerOnboarding
                  setIsLargeContainer={setIsLargeContainer}
                />
              ) : authedUserType == 'Agent' ? (
                <AgentOnboarding />
              ) : (
                <HomebuyerOnboarding
                  setIsLargeContainer={setIsLargeContainer}
                />
              )}
            </>
          ) : (
            <VerifyEmail />
          )}
          {/* <HomebuyerApplication /> */}
        </Container>
      </SidebarWithHeader>
    </Fragment>
  );
};

export default Onboarding;
