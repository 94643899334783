import React, { Fragment, useState, useEffect, useRef } from 'react';
import { isEmptyString } from '../../../../utils/generalFunctions';
import { useLoadScript } from '@react-google-maps/api';
import usePlacesAutocomplete from 'use-places-autocomplete';

// chakra
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Progress,
  Stack,
} from '@chakra-ui/react';

const HomebuyerStepOne = ({
  setActiveStep,
  stepOnePhoneNumber,
  setStepOnePhoneNumber,
  handleNext,
  loading,
}) => {
  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(1 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Heading as="h4" size="md" mb="5">
              User Details
            </Heading>
            <Stack spacing="5">
              <FormControl>
                <FormLabel color="gray">What is your phone number?</FormLabel>
                <Input
                  borderWidth="2px"
                  size="lg"
                  type="tel"
                  placeholder="222 333 4444"
                  pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                  maxLength="10"
                  value={stepOnePhoneNumber}
                  onChange={e => {
                    const value = e.target.value.replace(/\D/g, '');
                    setStepOnePhoneNumber(value.trim());
                  }}
                />
                <FormHelperText color="gray.400">
                  Enter 10-digit number
                </FormHelperText>
              </FormControl>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            colorScheme="blue"
            onClick={() => {
              handleNext();
            }}
            isDisabled={
              isEmptyString(stepOnePhoneNumber) ||
              stepOnePhoneNumber.length !== 10
            }
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default HomebuyerStepOne;
