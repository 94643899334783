import React, { Fragment, useRef, useState } from 'react';
import { isEmptyString } from '../../../../utils/generalFunctions';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Spinner,
  useToast,
  IconButton,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { FiTrash2 } from 'react-icons/fi';

const HomebuyerStepTwelve = ({
  setActiveStep,
  stepNinePrimaryIncome,
  stepTwelveIncomeDocOne,
  setStepTwelveIncomeDocOne,
  stepTwelveIncomeDocTwo,
  setStepTwelveIncomeDocTwo,
  stepTwelveIncomeDocThree,
  setStepTwelveIncomeDocThree,
  stepThirteenBankStatementOne,
  stepThirteenBankStatementTwo,
  stepThirteenBankStatementThree,
  stepSixteenIdentityDoc,
  handleNext,
  loading,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const stepTwelveIncomeDocOneRef = useRef(null);
  const stepTwelveIncomeDocTwoRef = useRef(null);
  const stepTwelveIncomeDocThreeRef = useRef(null);
  const cancelRef = useRef();

  const [alertDialog, setAlertDialog] = useState('');

  console.log({ stepNinePrimaryIncome });

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(12 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box>
                  <Heading as="h4" size="md" mb="1">
                    Let's verify your income
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    The more income we can verify, the higher your home shopping
                    budget will be.{' '}
                    {stepNinePrimaryIncome === 'Salary / hourly'
                      ? `For your W-2 salaried job, please upload your most current personal tax return OR W2, if available, and your three (3) most current pay stubs, with the latest being no older than 30 days. Please also ensure all pertinent information is visible (current and year to date gross and net income, pay period, name and employer name).`
                      : stepNinePrimaryIncome === 'Independent Contractor'
                      ? `For your Independent Contractor job, please upload your most current year's 1099, if available. Please also upload your last 3 pay statements,with the latest being no older than 30 days.`
                      : stepNinePrimaryIncome === 'Self-employed'
                      ? `For your Self Employment, please upload proof of business ownership (Articles of Incorporation, Secretary of State documents, or Tax Documents).`
                      : `For all jobs, we require paystubs or any other proof of income for the past 3 months.`}
                  </Text>
                </Box>
              </Box>

              <Box>
                <Box mb="3">
                  <Heading as="h5" size="sm" mb="1">
                    Upload documents manually
                  </Heading>
                  <Text fontSize="sm" fontWeight="600" color="gray">
                    You'll be prompted to upload documents- So, upload directly
                    from your device or take a photo of the document and then
                    upload.
                  </Text>
                </Box>
                <Stack spacing="5">
                  <Box>
                    <Text fontSize="xs" fontWeight="600" color="gray" mb="1">
                      Your recent pay stub 1: <b>(REQUIRED)</b>
                    </Text>
                    <input
                      type="file"
                      ref={stepTwelveIncomeDocOneRef}
                      id="stepTwelveIncomeDocOne"
                      name="stepTwelveIncomeDocOne"
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/*,.heic"
                      style={{ display: 'none' }}
                      onChange={e => {
                        if (e.target.files[0].size > 5242880) {
                          setAlertDialog('File is too big! Must be under 5mb');
                          onOpen();
                        } else if (e.target.files[0].size < 5000) {
                          setAlertDialog(
                            'This file quality is not that great; Please attach a better resolution file.'
                          );
                          onOpen();
                        } else {
                          const fileName = e.target.files[0].name;
                          // if (
                          //   fileName === stepTwelveIncomeDocTwo.name ||
                          //   fileName === stepTwelveIncomeDocThree.name ||
                          //   fileName === stepThirteenBankStatementOne.name ||
                          //   fileName === stepThirteenBankStatementTwo.name ||
                          //   fileName === stepThirteenBankStatementThree.name ||
                          //   fileName === stepSixteenIdentityDoc.name
                          // ) {
                          //   toast({
                          //     title: 'File is already being used',
                          //     position: 'top-right',
                          //     status: 'error',
                          //     isClosable: true,
                          //   });
                          // } else {
                          //   setStepTwelveIncomeDocOne(e.target.files[0]);
                          // }
                          setStepTwelveIncomeDocOne(e.target.files[0]);
                        }
                      }}
                    />
                    {stepTwelveIncomeDocOne == '' ? (
                      <Flex align="center">
                        <Button as="label" for="stepTwelveIncomeDocOne">
                          <Text>Select a File</Text>
                        </Button>
                      </Flex>
                    ) : (
                      <Flex align="center">
                        <Button as="label" for="stepTwelveIncomeDocOne" mr="2">
                          <Text textOverflow="ellipsis">
                            {stepTwelveIncomeDocOne.name}
                          </Text>
                        </Button>
                        <IconButton
                          colorScheme="red"
                          aria-label="delete file"
                          icon={<Icon as={FiTrash2} />}
                          onClick={() => {
                            stepTwelveIncomeDocOneRef.current.value = null;
                            setStepTwelveIncomeDocOne('');
                          }}
                        />
                      </Flex>
                    )}
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="600" color="gray" mb="1">
                      Your recent pay stub 2:
                    </Text>
                    <input
                      type="file"
                      ref={stepTwelveIncomeDocTwoRef}
                      id="stepTwelveIncomeDocTwo"
                      name="stepTwelveIncomeDocTwo"
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/*,.heic"
                      style={{ display: 'none' }}
                      onChange={e => {
                        if (e.target.files[0].size > 5242880) {
                          setAlertDialog('File is too big! Must be under 5mb');
                          onOpen();
                        } else if (e.target.files[0].size < 5000) {
                          setAlertDialog(
                            'This file quality is not that great; Please attach a better resolution file.'
                          );
                          onOpen();
                        } else {
                          const fileName = e.target.files[0].name;
                          // if (
                          //   fileName === stepTwelveIncomeDocOne.name ||
                          //   fileName === stepTwelveIncomeDocThree.name ||
                          //   fileName === stepThirteenBankStatementOne.name ||
                          //   fileName === stepThirteenBankStatementTwo.name ||
                          //   fileName === stepThirteenBankStatementThree.name ||
                          //   fileName === stepSixteenIdentityDoc.name
                          // ) {
                          //   toast({
                          //     title: 'File is already being used',
                          //     position: 'top-right',
                          //     status: 'error',
                          //     isClosable: true,
                          //   });
                          // } else {
                          //   setStepTwelveIncomeDocTwo(e.target.files[0]);
                          // }
                          setStepTwelveIncomeDocTwo(e.target.files[0]);
                        }
                      }}
                    />
                    {stepTwelveIncomeDocTwo == '' ? (
                      <Flex align="center">
                        <Button as="label" for="stepTwelveIncomeDocTwo">
                          <Text>Select a File</Text>
                        </Button>
                      </Flex>
                    ) : (
                      <Flex align="center">
                        <Button as="label" for="stepTwelveIncomeDocTwo" mr="2">
                          <Text textOverflow="ellipsis">
                            {stepTwelveIncomeDocTwo.name}
                          </Text>
                        </Button>
                        <IconButton
                          colorScheme="red"
                          aria-label="delete file"
                          icon={<Icon as={FiTrash2} />}
                          onClick={() => {
                            stepTwelveIncomeDocTwoRef.current.value = null;
                            setStepTwelveIncomeDocTwo('');
                          }}
                        />
                      </Flex>
                    )}
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="600" color="gray" mb="1">
                      Your recent pay stub 3:
                    </Text>
                    <input
                      type="file"
                      ref={stepTwelveIncomeDocThreeRef}
                      id="stepTwelveIncomeDocThree"
                      name="stepTwelveIncomeDocThree"
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/*,.heic"
                      style={{ display: 'none' }}
                      onChange={e => {
                        if (e.target.files[0].size > 5242880) {
                          setAlertDialog('File is too big! Must be under 5mb');
                          onOpen();
                        } else if (e.target.files[0].size < 5000) {
                          setAlertDialog(
                            'This file quality is not that great; Please attach a better resolution file.'
                          );
                          onOpen();
                        } else {
                          const fileName = e.target.files[0].name;
                          // if (
                          //   fileName === stepTwelveIncomeDocOne.name ||
                          //   fileName === stepTwelveIncomeDocTwo.name ||
                          //   fileName === stepThirteenBankStatementOne.name ||
                          //   fileName === stepThirteenBankStatementTwo.name ||
                          //   fileName === stepThirteenBankStatementThree.name ||
                          //   fileName === stepSixteenIdentityDoc.name
                          // ) {
                          //   toast({
                          //     title: 'File is already being used',
                          //     position: 'top-right',
                          //     status: 'error',
                          //     isClosable: true,
                          //   });
                          // } else {
                          //   setStepTwelveIncomeDocThree(e.target.files[0]);
                          // }
                          setStepTwelveIncomeDocThree(e.target.files[0]);
                        }
                      }}
                    />
                    {stepTwelveIncomeDocThree == '' ? (
                      <Flex align="center">
                        <Button as="label" for="stepTwelveIncomeDocThree">
                          <Text>Select a File</Text>
                        </Button>
                      </Flex>
                    ) : (
                      <Flex align="center">
                        <Button
                          as="label"
                          for="stepTwelveIncomeDocThree"
                          mr="2"
                        >
                          <Text textOverflow="ellipsis">
                            {stepTwelveIncomeDocThree.name}
                          </Text>
                        </Button>
                        <IconButton
                          colorScheme="red"
                          aria-label="delete file"
                          icon={<Icon as={FiTrash2} />}
                          onClick={() => {
                            stepTwelveIncomeDocThreeRef.current.value = null;
                            setStepTwelveIncomeDocThree('');
                          }}
                        />
                      </Flex>
                    )}
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(11);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={async () => {
              handleNext();
            }}
            isDisabled={
              isEmptyString(stepTwelveIncomeDocOne) ||
              stepTwelveIncomeDocOne.size > 5242880
            }
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Oops!
            </AlertDialogHeader>

            <AlertDialogBody>{alertDialog}</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="gray" onClick={onClose} ml={3}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Fragment>
  );
};

export default HomebuyerStepTwelve;
