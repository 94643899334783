import React, { Fragment, useState, useEffect } from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

// utils
import {
  states,
  otherStates,
  metroAreas,
} from '../../../../../utils/statesAndMetros';

// chakra
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  NumberInput,
  Progress,
  Stack,
  Flex,
  Select,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Textarea,
} from '@chakra-ui/react';

const AddPropertyStepTwo = ({
  setCurrentStep,
  handleNext,
  isLoading,
  stepTwoState,
  stepTwoMetro,
  stepTwoCity,
  stepTwoPropertyAddress,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [otherStateValue, setOtherStateValue] = useState('');
  const [otherMetroValue, setOtherMetroValue] = useState('');

  return (
    <Fragment>
      <Formik
        initialValues={{
          state: stepTwoState || '',
          otherState: '',
          metro: stepTwoMetro || '',
          otherMetro: '',
          city: stepTwoCity || '',
          propertyAddress: stepTwoPropertyAddress || '',
        }}
        validationSchema={yup.object({
          state: yup.string().required('Required'),
          otherState: yup.string(),
          metro: yup.string().required('Required'),
          otherMetro: yup.string(),
          city: yup.string(),
          propertyAddress: yup
            .string()
            .min(5, 'Must be at least 5 characters long')
            .required('Required'),
        })}
        onSubmit={async values => {
          try {
            if (values.state === 'Other States') {
              setOtherStateValue(values.otherState);
              onOpen();
            }

            if (values.metro === 'Other') {
              setOtherMetroValue(values.otherMetro);
              onOpen();
            }

            if (values.state !== '' && values.state !== 'Other States') {
              await handleNext({
                state: values.state,
                metro:
                  values.metro === 'Other' ? values.otherMetro : values.metro,
                city: values.city,
                propertyAddress: values.propertyAddress,
              });
            }
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing="5">
              <Card bg="white" size="lg">
                <CardBody>
                  <Heading as="h4" size="md" mb="5">
                    Add Property
                  </Heading>
                  <Stack spacing="5">
                    <FormControl
                      isInvalid={formik.errors.state && formik.touched.state}
                      isDisabled={isLoading}
                    >
                      <FormLabel color="gray">State</FormLabel>
                      <Field
                        as={Select}
                        borderWidth="2px"
                        size="lg"
                        id="state"
                        name="state"
                        placeholder="Select a state"
                        onChange={e => {
                          formik.setFieldValue('state', e.target.value);

                          formik.setFieldValue('otherState', '');
                          formik.setFieldValue('metro', '');
                          formik.setFieldValue('otherMetro', '');
                          formik.setFieldValue('city', '');
                        }}
                      >
                        {states?.map(state => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </Field>
                      <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
                    </FormControl>

                    {formik.values.state === 'Other States' && (
                      <FormControl
                        isInvalid={
                          formik.errors.otherState && formik.touched.otherState
                        }
                        isDisabled={isLoading}
                      >
                        <FormLabel color="gray">Other State</FormLabel>
                        <Field
                          as={Select}
                          borderWidth="2px"
                          size="lg"
                          id="otherState"
                          name="otherState"
                          placeholder="Select a state"
                          onChange={e => {
                            formik.setFieldValue('otherState', e.target.value);

                            formik.setFieldValue('state', 'Other States');
                            formik.setFieldValue('metro', '');
                            formik.setFieldValue('otherMetro', '');
                            formik.setFieldValue('city', '');
                          }}
                        >
                          {otherStates?.map(state => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                        </Field>
                        <FormErrorMessage>
                          {formik.errors.otherState}
                        </FormErrorMessage>
                      </FormControl>
                    )}

                    {formik.values.state !== '' &&
                      formik.values.state !== 'Other States' && (
                        <FormControl
                          isInvalid={
                            formik.errors.metro && formik.touched.metro
                          }
                          isDisabled={isLoading}
                        >
                          <FormLabel color="gray">
                            Metro Area of Property
                          </FormLabel>
                          <Field
                            as={Select}
                            borderWidth="2px"
                            size="lg"
                            id="metro"
                            name="metro"
                            placeholder="Select a Metro"
                            onChange={e => {
                              formik.setFieldValue('metro', e.target.value);

                              formik.setFieldValue('otherMetro', '');
                              formik.setFieldValue('city', '');
                            }}
                          >
                            {metroAreas[formik.values.state]?.map(metroArea => (
                              <option key={metroArea} value={metroArea}>
                                {metroArea}
                              </option>
                            ))}
                          </Field>
                          <FormErrorMessage>
                            {formik.errors.metro}
                          </FormErrorMessage>
                        </FormControl>
                      )}

                    {((formik.values.state === 'Other States' &&
                      formik.values.otherState !== '') ||
                      (formik.values.state !== '' &&
                        formik.values.state !== 'Other States' &&
                        formik.values.metro === 'Other')) && (
                      <FormControl
                        isInvalid={
                          formik.errors.otherMetro && formik.touched.otherMetro
                        }
                        isDisabled={isLoading}
                      >
                        <FormLabel color="gray">
                          Metro Area of Property
                        </FormLabel>
                        <Field
                          as={Textarea}
                          borderWidth="2px"
                          size="lg"
                          id="otherMetro"
                          name="otherMetro"
                          type="text"
                          placeholder={`Enter the metro are where your "rent to own" property is located`}
                          onChange={e => {
                            formik.setFieldValue('otherMetro', e.target.value);

                            formik.setFieldValue('metro', 'Other');
                            formik.setFieldValue('city', '');
                          }}
                        />
                        <FormErrorMessage>
                          {formik.errors.otherMetro}
                        </FormErrorMessage>
                      </FormControl>
                    )}

                    {((formik.values.state !== '' &&
                      formik.values.state !== 'Other States') ||
                      (formik.values.state === 'Other States' &&
                        formik.values.otherState !== '')) &&
                      ((formik.values.metro !== '' &&
                        formik.values.metro !== 'Other') ||
                        (formik.values.metro === 'Other' &&
                          formik.values.otherMetro !== '')) && (
                        <FormControl
                          isInvalid={formik.errors.city && formik.touched.city}
                          isDisabled={isLoading}
                        >
                          <FormLabel color="gray">
                            City where Property is Located
                          </FormLabel>
                          <Field
                            as={Input}
                            borderWidth="2px"
                            size="lg"
                            id="city"
                            name="city"
                            type="text"
                            placeholder="Type in a City"
                          />
                          <FormErrorMessage>
                            {formik.errors.city}
                          </FormErrorMessage>
                        </FormControl>
                      )}

                    {((formik.values.state !== '' &&
                      formik.values.state !== 'Other States') ||
                      (formik.values.state === 'Other States' &&
                        formik.values.otherState !== '')) &&
                      ((formik.values.metro !== '' &&
                        formik.values.metro !== 'Other') ||
                        (formik.values.metro === 'Other' &&
                          formik.values.otherMetro !== '')) && (
                        <FormControl
                          isInvalid={
                            formik.errors.propertyAddress &&
                            formik.touched.propertyAddress
                          }
                          isDisabled={isLoading}
                        >
                          <FormLabel color="gray">Property</FormLabel>
                          <Field
                            as={Input}
                            borderWidth="2px"
                            size="lg"
                            id="propertyAddress"
                            name="propertyAddress"
                            type="text"
                            placeholder="Mailing address or Zillow/ redfin Listing URL"
                            min={5}
                          />
                          <FormHelperText>
                            Minimum of 5 characters long
                          </FormHelperText>
                          <FormErrorMessage>
                            {formik.errors.propertyAddress}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                  </Stack>
                </CardBody>
              </Card>
              <Stack justify="right" direction="row" spacing="3">
                <Button
                  onClick={() => {
                    setCurrentStep(1);
                  }}
                  isLoading={isLoading}
                >
                  Prev
                </Button>
                <Button colorScheme="blue" type="submit" isLoading={isLoading}>
                  Next
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Market currently not served</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              We are currently not serving the <b>{otherStateValue}</b> market.
              We will let you know when are are expanding into the region that
              you would like to buy a house in.
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default AddPropertyStepTwo;
