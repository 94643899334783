import React, { Fragment } from 'react';
import { isEmptyString } from '../../../../utils/generalFunctions';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spinner,
} from '@chakra-ui/react';

const HomebuyerStepEight = ({
  setActiveStep,
  stepEightPrequalifiedAmount,
  handleNext,
  loading,
  isAnimating,
}) => {
  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(8 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md" mb="1">
                    Congratulations!
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    You are pre-qualified for:
                  </Text>
                </Box>

                <Stack spacing="5">
                  <Box align="center">
                    <Heading
                      textAlign="center"
                      as="h2"
                      size="2xl"
                      mb="2"
                      color="blue.500"
                    >
                      ${stepEightPrequalifiedAmount}
                    </Heading>
                    <Box maxW="md">
                      <Text
                        fontSize="sm"
                        textAlign="center"
                        fontWeight="600"
                        color="grey"
                      >
                        Your budget could change once we review your documents
                        or once your co-buyer completes the application.
                      </Text>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(7);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              handleNext();
            }}
            isDisabled={isAnimating}
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default HomebuyerStepEight;
