import React, { Fragment, useRef, useState } from 'react';
import { isEmptyString } from '../../../utils/generalFunctions';

// context
import { useCoapplicant } from '../../../context/CoapplicantContext';

// chakra
import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  Progress,
  Spinner,
  Stack,
  Text,
  useToast,
  IconButton,
  Icon,
  Flex,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { FiTrash2 } from 'react-icons/fi';

const CoapplicantStepEight = ({
  setActiveStep,
  stepEightBankStatementOne,
  setStepEightBankStatementOne,
  stepEightBankStatementTwo,
  setStepEightBankStatementTwo,
  stepEightBankStatementThree,
  setStepEightBankStatementThree,
  handleNext,
  loading,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { coapplicantData, isHomebuyer } = useCoapplicant();

  const stepEightBankStatementOneRef = useRef(null);
  const stepEightBankStatementTwoRef = useRef(null);
  const stepEightBankStatementThreeRef = useRef(null);
  const cancelRef = useRef();

  const [alertDialog, setAlertDialog] = useState('');

  return (
    <Fragment>
      <Stack maxW="xl" spacing="5">
        <Card bg="white" w="100%" textAlign="left">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box>
                  <Heading as="h4" size="md" mb="1">
                    Share Bank Statements
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    Please share last 3 months of bank statements from{' '}
                    {isHomebuyer
                      ? coapplicantData
                        ? `${coapplicantData.name.firstName}'s `
                        : ''
                      : 'your '}
                    primary bank account.
                  </Text>
                </Box>
              </Box>

              <Box>
                <Box mb="3">
                  <Heading as="h5" size="sm" mb="1">
                    Upload bank statements manually
                  </Heading>
                  <Text fontSize="sm" fontWeight="600" color="gray">
                    You'll be prompted to upload documents- So, upload directly
                    from your device or take a photo of the document and then
                    upload
                  </Text>
                </Box>
                <Stack spacing="5">
                  <Box>
                    <Text fontSize="xs" fontWeight="600" color="gray" mb="1">
                      {isHomebuyer
                        ? coapplicantData
                          ? `${coapplicantData.name.firstName}'s `
                          : ''
                        : 'Your '}
                      recent bank statement 1: <b>(REQUIRED)</b>
                    </Text>
                    <input
                      type="file"
                      ref={stepEightBankStatementOneRef}
                      id="stepEightBankStatementOne"
                      name="stepEightBankStatementOne"
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/*,.heic"
                      style={{ display: 'none' }}
                      onChange={e => {
                        console.log({ e });
                        if (e.target.files[0].size > 5242880) {
                          setAlertDialog('File is too big! Must be under 5mb');
                          onOpen();
                        } else if (e.target.files[0].size < 5000) {
                          setAlertDialog(
                            'This file quality is not that great; Please attach a better resolution file.'
                          );
                          onOpen();
                        } else {
                          const fileName = e.target.files[0].name;
                          // if (
                          //   fileName === stepTwelveIncomeDocOne.name ||
                          //   fileName === stepTwelveIncomeDocTwo.name ||
                          //   fileName === stepTwelveIncomeDocThree.name ||
                          //   fileName === stepThirteenBankStatementTwo.name ||
                          //   fileName === stepThirteenBankStatementThree.name ||
                          //   fileName === stepSixteenIdentityDoc.name
                          // ) {
                          //   toast({
                          //     title: 'File is already being used',
                          //     position: 'top-right',
                          //     status: 'error',
                          //     isClosable: true,
                          //   });
                          // } else {
                          //   setStepThirteenBankStatementOne(e.target.files[0]);
                          // }
                          setStepEightBankStatementOne(e.target.files[0]);
                        }
                      }}
                    />
                    {stepEightBankStatementOne == '' ? (
                      <Flex align="center">
                        <Button as="label" for="stepEightBankStatementOne">
                          <Text>Select a File</Text>
                        </Button>
                      </Flex>
                    ) : (
                      <Flex align="center">
                        <Button
                          as="label"
                          for="stepEightBankStatementOne"
                          mr="2"
                        >
                          <Text textOverflow="ellipsis">
                            {stepEightBankStatementOne.name}
                          </Text>
                        </Button>
                        <IconButton
                          colorScheme="red"
                          aria-label="delete file"
                          icon={<Icon as={FiTrash2} />}
                          onClick={() => {
                            stepEightBankStatementOneRef.current.value = null;
                            setStepEightBankStatementOne('');
                          }}
                        />
                      </Flex>
                    )}
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="600" color="gray" mb="1">
                      {isHomebuyer
                        ? coapplicantData
                          ? `${coapplicantData.name.firstName}'s `
                          : ''
                        : 'Your '}
                      recent bank statement 2:
                    </Text>
                    <input
                      type="file"
                      ref={stepEightBankStatementTwoRef}
                      id="stepEightBankStatementTwo"
                      name="stepEightBankStatementTwo"
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/*,.heic"
                      style={{ display: 'none' }}
                      onChange={e => {
                        if (e.target.files[0].size > 5242880) {
                          setAlertDialog('File is too big! Must be under 5mb');
                          onOpen();
                        } else if (e.target.files[0].size < 5000) {
                          setAlertDialog(
                            'This file quality is not that great; Please attach a better resolution file.'
                          );
                          onOpen();
                        } else {
                          const fileName = e.target.files[0].name;
                          // if (
                          //   fileName === stepTwelveIncomeDocOne.name ||
                          //   fileName === stepTwelveIncomeDocTwo.name ||
                          //   fileName === stepTwelveIncomeDocThree.name ||
                          //   fileName === stepThirteenBankStatementOne.name ||
                          //   fileName === stepThirteenBankStatementThree.name ||
                          //   fileName === stepSixteenIdentityDoc.name
                          // ) {
                          //   toast({
                          //     title: 'File is already being used',
                          //     position: 'top-right',
                          //     status: 'error',
                          //     isClosable: true,
                          //   });
                          // } else {
                          //   setStepThirteenBankStatementTwo(e.target.files[0]);
                          // }
                          setStepEightBankStatementTwo(e.target.files[0]);
                        }
                      }}
                    />
                    {stepEightBankStatementTwo == '' ? (
                      <Flex align="center">
                        <Button as="label" for="stepEightBankStatementTwo">
                          <Text>Select a File</Text>
                        </Button>
                      </Flex>
                    ) : (
                      <Flex align="center">
                        <Button
                          as="label"
                          for="stepEightBankStatementTwo"
                          mr="2"
                        >
                          <Text textOverflow="ellipsis">
                            {stepEightBankStatementTwo.name}
                          </Text>
                        </Button>
                        <IconButton
                          colorScheme="red"
                          aria-label="delete file"
                          icon={<Icon as={FiTrash2} />}
                          onClick={() => {
                            stepEightBankStatementTwoRef.current.value = null;
                            setStepEightBankStatementTwo('');
                          }}
                        />
                      </Flex>
                    )}
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="600" color="gray" mb="1">
                      {isHomebuyer
                        ? coapplicantData
                          ? `${coapplicantData.name.firstName}'s `
                          : ''
                        : 'Your '}
                      recent bank statement 3:
                    </Text>
                    <input
                      type="file"
                      ref={stepEightBankStatementThreeRef}
                      id="stepEightBankStatementThree"
                      name="stepEightBankStatementThree"
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/*,.heic"
                      style={{ display: 'none' }}
                      onChange={e => {
                        if (e.target.files[0].size > 5242880) {
                          setAlertDialog('File is too big! Must be under 5mb');
                          onOpen();
                        } else if (e.target.files[0].size < 5000) {
                          setAlertDialog(
                            'This file quality is not that great; Please attach a better resolution file.'
                          );
                          onOpen();
                        } else {
                          const fileName = e.target.files[0].name;
                          // if (
                          //   fileName === stepTwelveIncomeDocOne.name ||
                          //   fileName === stepTwelveIncomeDocTwo.name ||
                          //   fileName === stepTwelveIncomeDocThree.name ||
                          //   fileName === stepThirteenBankStatementOne.name ||
                          //   fileName === stepThirteenBankStatementTwo.name ||
                          //   fileName === stepSixteenIdentityDoc.name
                          // ) {
                          //   toast({
                          //     title: 'File is already being used',
                          //     position: 'top-right',
                          //     status: 'error',
                          //     isClosable: true,
                          //   });
                          // } else {
                          //   setStepThirteenBankStatementThree(
                          //     e.target.files[0]
                          //   );
                          // }
                          setStepEightBankStatementThree(e.target.files[0]);
                        }
                      }}
                    />
                    {stepEightBankStatementThree == '' ? (
                      <Flex align="center">
                        <Button as="label" for="stepEightBankStatementThree">
                          <Text>Select a File</Text>
                        </Button>
                      </Flex>
                    ) : (
                      <Flex align="center">
                        <Button
                          as="label"
                          for="stepEightBankStatementThree"
                          mr="2"
                        >
                          <Text textOverflow="ellipsis">
                            {stepEightBankStatementThree.name}
                          </Text>
                        </Button>
                        <IconButton
                          colorScheme="red"
                          aria-label="delete file"
                          icon={<Icon as={FiTrash2} />}
                          onClick={() => {
                            stepEightBankStatementThreeRef.current.value = null;
                            setStepEightBankStatementThree('');
                          }}
                        />
                      </Flex>
                    )}
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(5);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={async () => {
              await handleNext();
            }}
            isLoading={loading}
            isDisabled={
              isEmptyString(stepEightBankStatementOne) ||
              stepEightBankStatementOne.size > 5242880
            }
          >
            Next
          </Button>
        </Stack>
      </Stack>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Oops!
            </AlertDialogHeader>

            <AlertDialogBody>{alertDialog}</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="gray" onClick={onClose} ml={3}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Fragment>
  );
};

export default CoapplicantStepEight;
