import {
  Avatar,
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';

import SignoutButton from '../../../components/SignoutButton';
import { useAgentAuth } from '../../../context/AgentContext';
import { useAuth } from '../../../context/AuthContext';
import AgentDashboardSidebar from '../../Sidebar';
import BrokerageUpdateButton from './BrokerageUpdate';
import OperatingCitiesUpdateButton from './OperatingCitiesUpdate';
import PersonalInfoUpdateButton from './PersonalInfoUpdate';

function Profile() {
  const { authUser } = useAuth();
  const { applicationData } = useAgentAuth();

  const name =
    authUser?.data?.legalName?.firstName +
    ' ' +
    authUser?.data?.legalName?.lastName;

  const OperatingCitiesData = applicationData?.stepTwo;
  const agentBrokerageInfo = applicationData?.stepOne;

  return (
    <AgentDashboardSidebar showHeader={false}>
      <Flex p={10} alignItems="center" flexDirection={'column'}>
        <Box>
          <Heading size="lg">Profile</Heading>
          <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow="hidden"
            variant="outline"
            p={2}
            minWidth={{ base: '80vw', sm: '40vw' }}
            mt={5}
            background="white"
            borderWidth={'0px'}
            shadow="md"
          >
            <Box mt={5} pl={3}>
              <Avatar
                name={name}
                src={authUser?.data?.avatar}
                alt={name}
                size={'lg'}
              />
            </Box>

            <Stack>
              <CardBody>
                <Heading size="md">{name}</Heading>

                <Text pt="2">{authUser?.data?.email}</Text>
                <Text>{agentBrokerageInfo?.[0]?.answer}</Text>
                <PersonalInfoUpdateButton
                  firstName={authUser?.data?.legalName?.firstName}
                  lastName={authUser?.data?.legalName?.lastName}
                  phone={agentBrokerageInfo?.[0]?.answer}
                  brokerageInfo={agentBrokerageInfo}
                />
              </CardBody>
            </Stack>
          </Card>
        </Box>

        <Box>
          <Heading size="md" mt={10}>
            Operating Cities
          </Heading>
          <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow="hidden"
            variant="outline"
            p={2}
            minWidth={{ base: '80vw', sm: '40vw' }}
            mt={5}
            background="white"
            borderWidth={'0px'}
            shadow="md"
          >
            <Stack>
              <CardBody>
                <Text size="md" fontWeight={'semibold'}>
                  Operating Metro
                </Text>

                <Text color={'gray.500'} pt="2">
                  {OperatingCitiesData?.[0]?.answer}
                </Text>

                <Text size="md" fontWeight={'semibold'} mt={4}>
                  Preferred cities
                </Text>
                <Text color={'gray.500'}>
                  {OperatingCitiesData?.[1]?.answer}
                </Text>

                <OperatingCitiesUpdateButton
                  metro={OperatingCitiesData?.[0]?.answer}
                  cities={OperatingCitiesData?.[1]?.answer}
                  OperatingCitiesData={OperatingCitiesData}
                />
              </CardBody>
            </Stack>
          </Card>
        </Box>
        <Box>
          <Heading size="md" mt={10}>
            Brokerage information
          </Heading>
          <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow="hidden"
            variant="outline"
            p={2}
            minWidth={{ base: '80vw', sm: '40vw' }}
            mt={5}
            background="white"
            borderWidth={'0px'}
            shadow="md"
          >
            <Stack>
              <CardBody>
                <Text size="md" fontWeight={'semibold'}>
                  Brokerage name
                </Text>

                <Text color={'gray.500'} pt="2">
                  {agentBrokerageInfo?.[2]?.answer}
                </Text>

                <Text size="md" fontWeight={'semibold'} mt={4}>
                  License #
                </Text>

                <Text color={'gray.500'}>
                  {agentBrokerageInfo?.[1]?.answer}
                </Text>

                <BrokerageUpdateButton
                  name={agentBrokerageInfo?.[2]?.answer}
                  liscence={agentBrokerageInfo?.[1]?.answer}
                  brokerageData={agentBrokerageInfo}
                />
              </CardBody>
            </Stack>
          </Card>
        </Box>
        {/* <Button
          textColor={'blue.800'}
          variant="outline"
          mt={8}
          fontSize="16px"
          //   alignSelf="flex-start"
        >
          Logout
        </Button> */}

        <SignoutButton />
      </Flex>
    </AgentDashboardSidebar>
  );
}

export default Profile;
