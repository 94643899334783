import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useAgentAuth } from '../../context/AgentContext';
import { INVITED_USER_STATUS } from '../../utils/constants';

const InteractionsButton = ({ data }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const toast = useToast();
  const { saveAgentInteraction } = useAgentAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [customInteraction, setCustomInteraction] = useState('');

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const handleSubmit = async status => {
    try {
      setButtonLoading(true);
      await saveAgentInteraction(data?.email, status);

      toast({
        title: 'Status changed successfully',

        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
      setButtonLoading(false);
    } catch (err) {
      toast({
        title: 'Request Failed',
        description:
          'Unable to change status at the moment, please try again later',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });

      setButtonLoading(false);
    }
  };

  const handleCustomNote = async () => {
    if (customInteraction.length > 0) {
      try {
        setButtonLoading(true);
        await saveAgentInteraction(data?.email, customInteraction);

        toast({
          title: 'interaction added successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
        setButtonLoading(false);
        onClose();
      } catch (err) {
        toast({
          title: 'Request Failed',
          description:
            'Unable to change status at the moment, please try again later',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });

        setButtonLoading(false);
      }
    }
  };

  return (
    <>
      <Menu>
        <MenuButton
          _hover={{ bg: 'gray.400' }}
          _expanded={{ bg: '#1C2430', textColor: 'white' }}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          fontSize="sm"
          minW={20}
          // ml={5}
          px={'3'}
          variant="outline"
          borderColor="gray.300"
        >
          {buttonLoading ? <Spinner /> : 'Add'}
        </MenuButton>

        <MenuList>
          <MenuItem
            value={INVITED_USER_STATUS.IN_COMMUNICATION}
            onClick={() => {
              handleSubmit(INVITED_USER_STATUS.IN_COMMUNICATION);
            }}
          >
            We're in Communication
          </MenuItem>
          <MenuItem
            value={INVITED_USER_STATUS.HAS_QUESTION}
            onClick={() => {
              handleSubmit(INVITED_USER_STATUS.HAS_QUESTION);
            }}
          >
            Has Questions
          </MenuItem>
          <MenuItem
            value={INVITED_USER_STATUS.NOT_MOVING_SOON}
            onClick={() => {
              handleSubmit(INVITED_USER_STATUS.NOT_MOVING_SOON);
            }}
          >
            Not Moving Soon
          </MenuItem>
          <MenuItem
            value={INVITED_USER_STATUS.UNRESPONSIVE}
            onClick={() => {
              handleSubmit(INVITED_USER_STATUS.UNRESPONSIVE);
            }}
          >
            Unresponsive
          </MenuItem>
          <MenuItem
            value={INVITED_USER_STATUS.OUTSIDE_AREA}
            onClick={() => {
              handleSubmit(INVITED_USER_STATUS.OUTSIDE_AREA);
            }}
          >
            Outside my area
          </MenuItem>
          <MenuItem
            value={INVITED_USER_STATUS.OTHER}
            onClick={() => {
              // handleSubmit(INVITED_USER_STATUS.OTHER);
              onOpen();
            }}
          >
            Custom Note
          </MenuItem>
        </MenuList>
      </Menu>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: 'full', md: '2xl' }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter Custom Note</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Note</FormLabel>
              <Textarea
                ref={initialRef}
                placeholder="Add a detailed comment"
                value={customInteraction}
                disabled={buttonLoading}
                onChange={e => {
                  setCustomInteraction(e.target?.value);
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              // colorScheme="blue"
              mr={3}
              onClick={handleCustomNote}
              backgroundColor={'#1C2430'}
              textColor={'white'}
              minW={30}
              ml={2}
              isDisabled={buttonLoading}
              _hover={{
                bg: 'black',
                cursor: 'pointer',
              }}
            >
              {buttonLoading ? <Spinner /> : 'Save'}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InteractionsButton;
