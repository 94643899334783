export const USER_TYPES = {
  AGENT: 'agent',
  HOME_OWNER: 'home_owner',
  LANDLORD: 'landlord',
};

export const INVITED_USER_STATUS = {
  IN_COMMUNICATION: "We're in communication",
  HAS_QUESTION: 'Has Questions',
  NOT_MOVING_SOON: 'Not Moving Soon',
  UNRESPONSIVE: 'Unresponsive',
  OUTSIDE_AREA: 'Outside my area',
  OTHER: 'other',
};

export const APPLICATION_STATUS = {
  APPROVED: 'approved',
  CONDITIONALLY_APROVED: 'conditionallyApproved',
  OFFER_EXTENDED: 'offerExtended',
  OFFER_ACCEPTED: 'offerAccepted',

  INACTIVE: 'inactive',
  REJECTED: 'rejected',
  PAUSED: 'paused',

  MORE_INFO_REQUIRED: 'moreInfoRequired',

  IN_REVIEW: 'inReview',
  INSPECTION_COMPLETE: 'inspectionComplete',
  PRECLOSING_COMPLETE: 'preclosingComplete',
  OFFER_CLOSED: 'offerClosed',
};
