import React, { Fragment, useState, useEffect, useRef } from 'react';
import * as dayjs from 'dayjs';

// context
import { useHomeBuyerAuth } from '../../context/HomebuyerContext';

// chakra
import {
  Container,
  Card,
  CardBody,
  Stack,
  Heading,
  Flex,
  Select,
  Spacer,
  Button,
  Box,
  Text,
  IconButton,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Spinner,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Icon,
} from '@chakra-ui/react';
import { DeleteIcon, AddIcon } from '@chakra-ui/icons';
import { FiTrash2 } from 'react-icons/fi';

const CoBuyerDocsCard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();
  const toast = useToast();
  const { getUserDocuments, handleAdditionalFileUpload, handleDocumentDelete } =
    useHomeBuyerAuth();

  const fileSelectedRef = useRef(null);
  const cancelRef = useRef();

  const [fileTypeSelected, setFileTypeSelected] = useState('');
  const [fileSelected, setFileSelected] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [loading, setLoading] = useState(false);
  const [alertDialog, setAlertDialog] = useState('');

  const [fileHelperText, setFileHelperText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fileList = await getUserDocuments('cobuyer_additional_documents');
        setUploadedFiles(fileList);
      } catch (err) {
        console.log('Error: ', err);
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (fileTypeSelected == '1099') {
          setFileHelperText(`Please upload your most current year's 1099.`);
        } else if (fileTypeSelected == 'Business Bank Statement') {
          setFileHelperText(
            `Please upload your three (3) most current bank statements, which show deposit of your business income. Please upload in PDF format, with the latest statement being no older than 30 days.`
          );
        } else if (fileTypeSelected == 'Credit Report') {
          setFileHelperText(
            `Please upload a copy of your credit report which includes a listing of your credit score, account history, balance, and payments.`
          );
        } else if (fileTypeSelected == 'Govt Issued ID') {
          setFileHelperText(
            `Please provide a valid ID (Driver's License, Passport, or State ID) for identity verification to complete your application.`
          );
        } else if (fileTypeSelected == 'Income Tax Return') {
          setFileHelperText(`Please upload your most current tax return.`);
        } else if (fileTypeSelected == 'Paystub') {
          setFileHelperText(
            `Please upload your three (3) most current pay stubs, with the latest being no older than 30 days. Please also ensure all pertinent information is visible (current and year to date gross and net income, pay period, name and employer name).`
          );
        } else if (fileTypeSelected == 'Personal Bank Statement') {
          setFileHelperText(
            `Please upload your three (3) most current bank statements, which show deposit of your salaried or independent contractor income. Please upload in PDF format, with the latest statement being no older than 30 days.`
          );
        } else if (fileTypeSelected == 'Proof of Business Ownership') {
          setFileHelperText(
            `Please upload proof of business ownership (article of incorporation, Secretary of State documents, or tax documents).`
          );
        } else if (fileTypeSelected == 'W-2') {
          setFileHelperText(
            `Please upload your most current W-2, with all pertinent information visible.`
          );
        } else if (fileTypeSelected == 'YTD Business Financials') {
          setFileHelperText(
            `Please upload your year to date business financials, with all pertinent information visible (gross revenues, operating income, taxes, and expenses, net income).`
          );
        } else if (fileTypeSelected == 'Other') {
          setFileHelperText(
            `Please upload other docs as needed to understand your income or employment, or as requested.`
          );
        } else {
          setFileHelperText(``);
        }
      } catch (err) {
        console.log('Error handleRenderHelper(): ', err);
        setFileHelperText(``);
      }
    };

    fetchData().catch(console.error);
  }, [fileTypeSelected]);

  return (
    <Fragment>
      <Card bg="white" size="md">
        <CardBody>
          <Stack spacing="4">
            <Flex align="center">
              <Heading as="h4" size="md" mb="2">
                Co-Buyer Documents
              </Heading>
              <Spacer />
              <IconButton
                aria-label="Add document"
                icon={<AddIcon />}
                onClick={onOpen}
              />
            </Flex>

            {uploadedFiles.length !== 0 && (
              <Stack bg="gray.100" rounded="md" p="4" spacing="4">
                {loading ? (
                  <Fragment>
                    <Flex align="center" justify="center" w="100%">
                      <Spinner />
                    </Flex>
                  </Fragment>
                ) : (
                  <Fragment>
                    {uploadedFiles.map(file => (
                      <Flex
                        bg="gray.300"
                        rounded="md"
                        px="4"
                        py="4"
                        align="center"
                        key={file.id}
                      >
                        <Box>
                          <Flex align="center">
                            <Text fontSize="lg" fontWeight="700" mr="2">
                              {file.fileType}:
                            </Text>
                            <Text fontSize="md" fontWeight="500">
                              {file.name}
                            </Text>
                          </Flex>
                          <Text fontSize="sm" fontWeight="400">
                            {dayjs(file.timeCreated).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )}
                          </Text>
                        </Box>

                        {/* <Spacer />
                        <IconButton
                          colorScheme="red"
                          icon={<DeleteIcon />}
                          size="sm"
                          onClick={async () => {
                            setLoading(true);
                            await handleDocumentDelete(file.ref);
                            const fileList = await getUserDocuments(
                              'cobuyer_additional_documents'
                            );
                            setUploadedFiles(fileList);
                            setLoading(false);
                          }}
                        /> */}
                      </Flex>
                    ))}
                  </Fragment>
                )}
              </Stack>
            )}
          </Stack>
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Co-Buyer Documents</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="5">
              <FormControl isDisabled={loading}>
                <FormLabel>File Type</FormLabel>
                <Select
                  size="lg"
                  placeholder="Select file type"
                  selected={fileTypeSelected}
                  onChange={e => {
                    setFileTypeSelected(e.target.value);
                  }}
                >
                  <option value="1099">1099</option>
                  <option value="Business Bank Statement">
                    Business Bank Statement
                  </option>
                  <option value="Credit Report">Credit Report</option>
                  <option value="Govt Issued ID">Govt Issued ID</option>
                  <option value="Income Tax Return">Income Tax Return</option>
                  <option value="Paystub">Paystub</option>
                  <option value="Personal Bank Statement">
                    Personal Bank Statement
                  </option>
                  <option value="Proof of Business Ownership">
                    Proof of Business Ownership
                  </option>
                  <option value="W-2">W-2</option>
                  <option value="YTD Business Financials">
                    YTD Business Financials
                  </option>
                  <option value="Other">Other</option>
                </Select>
                {fileTypeSelected !== '' && fileTypeSelected !== null && (
                  <FormHelperText fontSize="xs">
                    {fileHelperText}
                  </FormHelperText>
                )}
              </FormControl>

              {fileTypeSelected !== '' && fileTypeSelected !== null && (
                <Fragment>
                  {/* <input
                    type="file"
                    ref={fileSelectedRef}
                    disabled={loading}
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/*"
                    onChange={e => {
                      setFileSelected(e.target.files[0]);
                    }}
                  /> */}
                  <input
                    type="file"
                    ref={fileSelectedRef}
                    id="fileSelected"
                    name="fileSelected"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/*,.heic"
                    style={{ display: 'none' }}
                    onChange={e => {
                      if (e.target.files[0].size > 5242880) {
                        setAlertDialog('File is too big! Must be under 5mb');
                        alertOnOpen();
                      } else if (e.target.files[0].size < 5000) {
                        setAlertDialog(
                          'This file quality is not that great; Please attach a better resolution file.'
                        );
                        alertOnOpen();
                      } else {
                        setFileSelected(e.target.files[0]);
                      }
                    }}
                  />
                  {fileSelected == '' ? (
                    <Flex align="center">
                      <Button as="label" for="fileSelected">
                        <Text>Select a File</Text>
                      </Button>
                    </Flex>
                  ) : (
                    <Flex align="center">
                      <Button as="label" for="fileSelected" mr="2">
                        <Text textOverflow="ellipsis">
                          {fileSelected.name.length > 30
                            ? fileSelected.name.substring(0, 30) + '...'
                            : fileSelected.name}
                        </Text>
                      </Button>
                      <IconButton
                        colorScheme="red"
                        aria-label="delete file"
                        icon={<Icon as={FiTrash2} />}
                        onClick={() => {
                          fileSelectedRef.current.value = null;
                          setFileSelected('');
                        }}
                      />
                    </Flex>
                  )}
                </Fragment>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              mr="4"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              isDisabled={fileSelected === '' || fileSelected === null}
              isLoading={loading}
              onClick={async () => {
                try {
                  setLoading(true);

                  await handleAdditionalFileUpload(
                    fileSelected,
                    fileTypeSelected,
                    'cobuyer_additional_documents'
                  );
                  const fileList = await getUserDocuments(
                    'cobuyer_additional_documents'
                  );
                  setUploadedFiles(fileList);

                  setLoading(false);

                  setFileSelected('');
                  setFileTypeSelected('');

                  onClose();

                  toast({
                    position: 'top-right',
                    title: 'Success',
                    description: `${fileTypeSelected} document has been uploaded`,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                  });
                } catch (err) {
                  setLoading(false);

                  setFileSelected('');
                  setFileTypeSelected('');

                  onClose();

                  toast({
                    position: 'top-right',
                    title: 'Error',
                    description: `An error occurred while uploading the document`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                  });
                }
              }}
            >
              Upload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={alertIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={alertOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Oops!
            </AlertDialogHeader>

            <AlertDialogBody>{alertDialog}</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="gray" onClick={alertOnClose} ml={3}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Fragment>
  );
};

export default CoBuyerDocsCard;
