import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import app from '../firebase';
import { HTTP_CLIENT } from '../interceptor';

const inviteUserByEmail = async params => {
  const response = await HTTP_CLIENT.post('email/invite_client', params);
  return response;
};

const remindUser = async params => {
  const response = await HTTP_CLIENT.post('email/remind_client', params);
  return response;
};

const handleOfferEmails = async params => {
  const response = await HTTP_CLIENT.post('email/offer_emails', params);
  return response;
};

const getInvitedClients = async agentId => {
  const db = getFirestore(app);

  let data = [];

  const clientRef = collection(db, 'agent_invites');
  const q = query(clientRef, where('agent', '==', agentId));
  const querySnapshot = await getDocs(q);
  querySnapshot.docs.forEach(doc => {
    data.push(doc.data());
  });

  return data;
};

export { inviteUserByEmail, getInvitedClients, remindUser, handleOfferEmails };
