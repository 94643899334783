import React, { Fragment } from 'react';

// components
import Navbar from '../components/SearchHomePageComponents/Navbar';
import ShowcaseSection from '../components/SearchHomePageComponents/Showcase/ShowcaseSection';

// utils
import SEO from '../utils/SEO';

// chakra
import {
  Box,
  Flex,
  Text,
  Container,
  SimpleGrid,
  Card,
  CardBody,
} from '@chakra-ui/react';

const SearchHomes = () => {
  return (
    <Fragment>
      <SEO
        title={`Utopia Homes | Search Homes`}
        description={`Search homes page for Utopia Homes. Search for homes to rent-to-own.`}
        name={`Utopia Homes`}
        type={`application page`}
      />

      <Flex direction="column" w="100%" h="100vh">
        <Navbar />
        <ShowcaseSection />
      </Flex>
    </Fragment>
  );
};

export default SearchHomes;
