import React, { Fragment, useState, useEffect } from 'react';

// context
import { useLandlord } from '../../../context/LandlordContext';
// components
import ShowcaseCard from './ShowcaseCard';

// chakra
import {
  Box,
  Flex,
  Text,
  Container,
  SimpleGrid,
  Card,
  CardBody,
  Spinner,
} from '@chakra-ui/react';

const ShowcaseSection = () => {
  const { getAcceptedProperties_Truncated, getAcceptedProperties_Full } =
    useLandlord();

  const [truncatedProperties, setTruncatedProperties] = useState([]);
  const [fullProperties, setFullProperties] = useState([]);
  const [propertiesLoaded, setPropertiesLoaded] = useState(false);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const propertiesList = await getAcceptedProperties_Truncated();
        setTruncatedProperties(propertiesList);

        setPropertiesLoaded(true);
      } catch (err) {
        console.log(err);
        setPropertiesLoaded(true);
      }
    };
    fetchProperties();
  }, []);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const propertiesList = await getAcceptedProperties_Full();
        setFullProperties(propertiesList);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProperties();
  }, []);

  return (
    <Fragment>
      <Flex bg="gray.100" direction="column">
        <Container maxW="container.xl">
          <Box py="5">
            <Text fontSize="md" fontWeight="600" color="gray.600">
              {fullProperties.length === 0
                ? `${truncatedProperties.length} homes across the US`
                : `${fullProperties.length} homes across the US`}
            </Text>
          </Box>
        </Container>
      </Flex>
      {propertiesLoaded ? (
        <Flex bg="gray.100" direction="column" flex={1} overflow="auto">
          <Container maxW="container.xl" mb="8">
            <SimpleGrid columns={2} spacing={10}>
              {fullProperties.length === 0 ? (
                <>
                  {truncatedProperties.map(property => (
                    <div key={property.id}>
                      <ShowcaseCard
                        id={property.id}
                        property={property.data}
                        images={property.images}
                        autoPlay={false}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {fullProperties.map(property => (
                    <div key={property.id}>
                      <ShowcaseCard
                        id={property.id}
                        property={property.data}
                        images={property.images}
                        autoPlay={true}
                      />
                    </div>
                  ))}
                </>
              )}
            </SimpleGrid>
          </Container>
        </Flex>
      ) : (
        <Flex bg="gray.100" justify="center" align="center" flex={1}>
          <Spinner size="lg" />
        </Flex>
      )}
    </Fragment>
  );
};

export default ShowcaseSection;
