import React, { Fragment } from 'react';
import { isEmptyString } from '../../../../utils/generalFunctions';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Spinner,
} from '@chakra-ui/react';

const HomebuyerStepEleven = ({
  setActiveStep,
  stepTenEmployerName,
  stepElevenPayPeriod,
  setStepElevenPayPeriod,
  stepElevenPreTaxIncome,
  setStepElevenPreTaxIncome,
  handleNext,
  loading,
}) => {
  const format = val => `$` + val;
  const parse = val => val.replace(/^\$/, '');

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(11 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md">
                    Payment information for {stepTenEmployerName} job
                  </Heading>
                </Box>

                <Stack spacing="5">
                  <FormControl>
                    <FormLabel color="gray">
                      Average pre-tax income per paycheck
                    </FormLabel>
                    <RadioGroup
                      value={stepElevenPayPeriod}
                      onChange={setStepElevenPayPeriod}
                    >
                      <Stack spacing={3}>
                        <Radio size="lg" value="Weekly" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            Weekly
                          </Text>
                          <Text fontWeight="600" color="gray.400" fontSize="xs">
                            If you're paid once every week
                          </Text>
                        </Radio>
                        <Radio size="lg" value="Bi-weekly" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            Bi-weekly
                          </Text>
                          <Text fontWeight="600" color="gray.400" fontSize="xs">
                            If you're paid every two weeks
                          </Text>
                        </Radio>
                        <Radio
                          size="lg"
                          value="Semi-monthly"
                          colorScheme="blue"
                        >
                          <Text fontWeight="600" color="gray.600">
                            Semi-monthly
                          </Text>
                          <Text fontWeight="600" color="gray.400" fontSize="xs">
                            If you're paid twice per month
                          </Text>
                        </Radio>
                        <Radio size="lg" value="Monthly" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            Monthly
                          </Text>
                          <Text fontWeight="600" color="gray.400" fontSize="xs">
                            If you're paid once every month
                          </Text>
                        </Radio>
                        <Radio size="lg" value="Annually" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            Annually
                          </Text>
                          <Text fontWeight="600" color="gray.400" fontSize="xs">
                            If you're paid once every year
                          </Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">
                      Enter pre-tax income per paycheck:
                    </FormLabel>
                    <NumberInput
                      size="lg"
                      value={format(stepElevenPreTaxIncome)}
                      onChange={valueString =>
                        setStepElevenPreTaxIncome(parse(valueString))
                      }
                      min={0}
                    >
                      <NumberInputField borderWidth="2px" size="lg" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormHelperText color="gray.400">
                      Enter the amount before taxes are taken out
                    </FormHelperText>
                  </FormControl>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(10);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              handleNext();
            }}
            isDisabled={
              isEmptyString(stepElevenPayPeriod) ||
              isEmptyString(stepElevenPreTaxIncome)
            }
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default HomebuyerStepEleven;
