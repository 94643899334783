import React, { Fragment } from 'react';
import { isEmptyString } from '../../../../utils/generalFunctions';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spinner,
} from '@chakra-ui/react';

const HomebuyerStepNine = ({
  setActiveStep,
  stepNinePrimaryIncome,
  setStepNinePrimaryIncome,
  handleNext,
  loading,
}) => {
  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(9 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md" mb="1">
                    Your primary income
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    Depending on your primary income you can get qualified for a
                    loan amount greater than your pre-qualification amount
                  </Text>
                </Box>

                <Stack spacing="5">
                  <FormControl>
                    <FormLabel color="gray">Primary Income</FormLabel>
                    <RadioGroup
                      value={stepNinePrimaryIncome}
                      onChange={setStepNinePrimaryIncome}
                    >
                      <Stack spacing={3}>
                        <Radio
                          size="lg"
                          value="Salary / hourly"
                          colorScheme="blue"
                        >
                          <Text fontWeight="600" color="gray.600">
                            Salary / hourly
                          </Text>
                          <Text fontWeight="600" color="gray.400" fontSize="xs">
                            A W2 Employee
                          </Text>
                        </Radio>
                        <Radio
                          size="lg"
                          value="Independent Contractor"
                          colorScheme="blue"
                        >
                          <Text fontWeight="600" color="gray.600">
                            Independent Contractor
                          </Text>
                          <Text fontWeight="600" color="gray.400" fontSize="xs">
                            A 1099-MISC worker
                          </Text>
                        </Radio>
                        <Radio
                          size="lg"
                          value="Self-employed"
                          colorScheme="blue"
                        >
                          <Text fontWeight="600" color="gray.600">
                            Self-employed
                          </Text>
                          <Text fontWeight="600" color="gray.400" fontSize="xs">
                            An owner of a business
                          </Text>
                        </Radio>
                        {/* <Radio size="lg" value="4" colorScheme="blue">
                <Text fontWeight="600" color="gray.600">
                  Child support / alimony
                </Text>
                <Text fontWeight="600" color="gray.400" fontSize="xs">
                  Receiving court-ordered payments
                </Text>
              </Radio>
              <Radio size="lg" value="5" colorScheme="blue">
                <Text fontWeight="600" color="gray.600">
                  Social Security
                </Text>
                <Text
                  fontWeight="600"
                  color="gray.400"
                  fontSize="xs"
                  isDisabled
                >
                  Receiving social security payments
                </Text>
              </Radio>
              <Radio size="lg" value="5" colorScheme="blue">
                <Text fontWeight="600" color="gray.600">
                  VA Benefits
                </Text>
                <Text fontWeight="600" color="gray.400" fontSize="xs">
                  Receiving veteran pension benefits
                </Text>
              </Radio> */}
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(8);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              handleNext();
            }}
            isDisabled={isEmptyString(stepNinePrimaryIncome)}
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default HomebuyerStepNine;
