import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import * as ReactQR from 'qrcode'; // Import the QRCode component
import { useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { useAgentAuth } from '../../../../context/AgentContext';
import { useAuth } from '../../../../context/AuthContext';
import AgentDashboardSidebar from '../../../Sidebar';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Marketing = () => {
  const [referralCode, setReferralCode] = useState('');
  const ref = useRef(null);
  const componentRef = useRef(null);
  const canvasRef = useRef(null);

  const { authUser, getCurrentUserReferralCode } = useAuth();
  const { applicationData } = useAgentAuth();

  const name =
    authUser?.data?.legalName?.firstName +
    ' ' +
    authUser?.data?.legalName?.lastName;

  const contactNo = applicationData?.stepOne[0]?.answer;

  const brokerage = applicationData?.stepOne[2].answer;

  const getReferral = async () => {
    try {
      const response = await getCurrentUserReferralCode();
      setReferralCode(response);
    } catch (err) {
      console.log('🚀 ~ file: index.js:79 ~ getReferral ~ err:', err);
    }
  };

  const convertPDFToImage = async pdfData => {
    console.log(
      '🚀 ~ file: index.js:50 ~ convertPDFToImage ~ pdfData:',
      pdfData
    );
    try {
      const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
      const page = await pdf.getPage(1); // Get the first page of the PDF
      const scale = 1.5; // Adjust the scale as needed

      const viewport = page.getViewport({ scale });

      // Prepare canvas to render the PDF page as an image
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      // Render the PDF page on the canvas
      await page.render({
        canvasContext: context,
        viewport: viewport,
      }).promise;

      // Convert the canvas content to a data URL (image)
      const dataUrl = canvas.toDataURL('image/png');
      // setImageDataUrl(dataUrl);

      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'UtopiaHomes.png'; // Set the desired filename
      link.click();
    } catch (error) {
      console.error('Error converting PDF to image:', error);
    }
  };

  const imageUrlToDataUri = async imageUrl => {
    try {
      // Fetch the image data using the URL
      const response = await fetch(imageUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch image');
      }

      // Read the image data as a blob
      const blob = await response.blob();

      // Create a FileReader to read the blob as a data URI
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting image URL to data URI:', error);
      throw error;
    }
  };

  const handleMarketingMaterialDownload = async (isImage = false) => {
    const qrCodeLink = `https://dash.utopiahomes.us/c/${referralCode?.code}`;
    const qrCodeDataUrl = await ReactQR.toDataURL(qrCodeLink, { type: 'png' });

    const utopiaLogoImage = await imageUrlToDataUri(
      'https://i.imgur.com/0tsTLdk.png'
    );
    const houseImage = await imageUrlToDataUri(
      'https://images.unsplash.com/photo-1588111817213-1a12f73fbf20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2274&q=80'
    );

    var dd = {
      pageSize: {
        width: 900,
        height: 'auto',
      },
      content: [
        {
          alignment: 'justify',
          columns: [
            [
              {
                text: 'Utopia Homes',
                style: 'header',
              },
              {
                text: 'Looking to own a home but not able to get a mortgage?',
                style: 'normalText',
              },
              {
                text: 'Utopia can help! Our innovative "Rent, Earn, and Own" program is designed to make homeownership accessible to everyone.',
                style: 'normalText',
              },
              {
                text: 'How it works',
                style: 'headerWithMargin',
              },
              {
                ul: [
                  {
                    text: [
                      { text: 'Get Approved:', bold: true },
                      'Apply for Free in less than 3 mins to find your Home buying budget. No impact on your credit score and non-binding to you.',
                    ],
                    style: 'normalText',
                  },
                  {
                    text: [
                      { text: 'Find your Utopia:', bold: true },
                      'Pick your dream home (with our agents or yours). We will buy it with an all-cash offer.',
                    ],
                    style: 'normalText',
                  },
                  {
                    text: [
                      { text: 'Move-In and Earn:', bold: true },
                      'Rent your home while we work with you to make you a Homeowner. Our program turbo-charges your monthly savings for Down Payment by sharing the gains from Home Price Appreciation with you.',
                    ],
                    style: 'normalText',
                  },
                ],
              },
            ],
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 700,
                  lineWidth: 1,
                  lineColor: 'lightgrey',
                },
              ],
              width: 1,
            },
            {
              width: '70%',
              alignment: 'right',
              stack: [
                {
                  image: 'utopiaLogo',
                  width: 150,
                },
                {
                  image: 'houseImage',
                  width: 490,
                  margin: [0, 50, 0, 0],
                },
                {
                  margin: [70, 30, 0, 0],
                  columns: [
                    {
                      stack: [
                        {
                          margin: [70, 30, 0, 10],
                          text: [
                            {
                              text: `${name}\n`,
                              style: 'header',
                            },
                          ],
                        },
                        {
                          margin: [70, 0, 0, 0],
                          columns: [
                            {
                              image: 'briefcaseImage',
                              width: 16,
                            },
                            {
                              text: `${brokerage}\n`,
                              style: 'normalText',
                              margin: [5, 0, 0, 0],
                            },
                          ],
                        },
                        {
                          margin: [70, 0, 0, 0],
                          columns: [
                            {
                              image: 'emailImage',
                              width: 16,
                            },
                            {
                              text: `${authUser.email}\n `,
                              style: 'normalText',
                              margin: [5, 0, 0, 0],
                            },
                          ],
                        },
                        {
                          margin: [70, -18, 0, 0],
                          columns: [
                            {
                              image: 'phoneImage',
                              width: 16,
                            },
                            {
                              text: `${contactNo}\n`,
                              style: 'normalText',
                              margin: [5, 0, 0, 0],
                            },
                          ],
                        },
                      ],
                    },

                    {
                      image: qrCodeDataUrl,
                      width: 180,
                      height: 180,
                      alignment: 'right',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 730,
              y2: 0,
              lineWidth: 1,
              lineColor: 'lightgrey',
            },
          ],
          width: 1,
          margin: [0, 10, 0, 0],
        },

        {
          margin: [0, 10, 0, 0],
          columns: [
            {
              image: 'globeImage',
              width: 14,
              margin: [265, 0, 0, 0],
            },
            {
              text: 'www.utopiahomes.us',
              style: 'webText',
            },
            {
              image: 'emailImage',
              width: 16,
              margin: [16, 0, 0, 0],
            },
            {
              text: 'support@utopia.cx',
              style: 'emailText',
              // margin: [, 0, 0, 0],
            },
          ],
        },
        {
          canvas: [
            {
              type: 'rect',
              x: 730,
              y: 10,
              w: 130,
              h: 40,
              r: 0,
              color: 'red',
            },
          ],
          absolutePosition: { x: 0, y: 720 },
          width: 1,
        },
        {
          canvas: [
            {
              type: 'rect',
              x: 730,
              y: 10,
              w: 60,
              h: 290,
              r: 0,
              color: 'red',
            },
          ],
          absolutePosition: { x: -390, y: 30 },
          width: 1,
        },
      ],
      images: {
        utopiaLogo: utopiaLogoImage,
        houseImage: houseImage,
        qrImage:
          'https://images.unsplash.com/photo-1588111817213-1a12f73fbf20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2274&q=80',
        globeImage: 'https://i.imgur.com/qfxX1wt.jpg',
        phoneImage: 'https://i.imgur.com/azMsryT.png',
        emailImage: 'https://i.imgur.com/CEU9IKK.jpg',
        briefcaseImage: 'https://i.imgur.com/9huUt4q.png',
      },
      styles: {
        header: {
          fontSize: 32,
          bold: true,
          alignment: 'left',
        },
        bigger: {
          fontSize: 15,
          italics: true,
        },
        normalText: {
          fontSize: 16,
          margin: [0, 10, 0, 0],
          alignment: 'justify',
        },
        headerWithMargin: {
          fontSize: 32,
          bold: true,
          margin: [0, 10, 0, 0],
        },
        smallHeader: {
          bold: true,
          fontSize: 18,
        },
        webText: {
          fontSize: 12,
          margin: [0, 0, 0, 0],
          alignment: 'right',
        },
        emailText: {
          fontSize: 12,
          margin: [0, 0, 0, 0],
          alignment: 'left',
        },
      },
      defaultStyle: {
        columnGap: 20,
      },
    };

    if (isImage) {
      pdfMake
        .createPdf(dd, null, null, pdfFonts.pdfMake.vfs)
        .getBuffer(buffer => {
          convertPDFToImage(buffer);
        });
    } else {
      pdfMake
        .createPdf(dd, null, null, pdfFonts.pdfMake.vfs)
        .download('Utopia Homes');
    }
  };

  const formatPhoneNumber = phoneNumber => {
    // Remove all non-digit characters from the phone number
    const digitsOnly = phoneNumber?.replace(/\D/g, '');

    // Check if the phone number is already formatted with dashes
    if (digitsOnly?.length === 10) {
      return digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }

    // If the phone number is not already formatted, add dashes after every 3 digits
    const formattedNumber = digitsOnly?.replace(
      /(\d{3})(\d{3})(\d+)/,
      '$1-$2-$3'
    );

    return formattedNumber;
  };

  useEffect(() => {
    getReferral();
  }, []);

  return (
    <AgentDashboardSidebar showHeader={false} ref={ref}>
      <canvas ref={canvasRef} style={{ display: 'none' }} />

      <Box
        ref={componentRef}
        id="chakra-ui-template"
        p={['10px', '18px']}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        background={'#F4F4F5'}
        flexDirection={'column'}
      >
        <Box
          bg="white"
          minH="90vh"
          pb={4}
          w={['90vw', '70vw']}
          position={'relative'}
          pt={['80px', 0]}
          id="chakra-ui-template"
        >
          <Flex
            maxH="80px"
            // minW="4vw"
            alignItems="center !important"
            bg={'#11161D'}
            mt={'-15px'}
            // mr={'-15px'}
            // justifyContent={'center'}
            px={5}
            position={'absolute'}
            right={[0, '14px']}
            left={[0, '80%']}
            top="18px"
            py={5}
          >
            <Link
              onClick={() => {
                window.location.assign('https://www.utopiahomes.us/agents');
              }}
            >
              <Image
                src="https://uploads-ssl.webflow.com/633f83ae736f7600af642fcf/633f83ae736f76d79a642fed_Group%252052%2520(1)-p-500.png"
                alt="Utopia Logo"
                maxW={['160px', '180px']}
              />
            </Link>
          </Flex>

          <Flex
            bg="white"
            minH="88vh"
            p={4}
            pb={2}
            w={['90vw', '70vw']}
            flexDirection={['column', 'row']}
          >
            <Box
              display="flex"
              flex={1}
              flexDirection={'column'}
              px={4}
              borderRightWidth={'1px'}
              borderBottomWidth={1}
              borderBottomColor={'black'}
            >
              <Heading as="h2" size="lg" color="gray.800" mb={2}>
                Utopia Homes
              </Heading>

              <Text mb={4} fontSize={'21'} textAlign={'justify'}>
                Looking to own a home but not able to get a mortgage?
              </Text>
              <Text fontSize={'21'} textAlign={'justify'}>
                Utopia can help! Our innovative "Rent, Earn, and Own" program is
                designed to make homeownership accessible to everyone.
              </Text>

              <Heading as="h2" size="lg" color="gray.800" mt={6} mb={2}>
                How it works
              </Heading>

              <Stack
                px={5}
                borderRadius={5}
                spacing={'10px'}
                fontSize={'21'}
                textAlign={'justify'}
                pb={4}
              >
                <ul>
                  <li>
                    <b>Get Approved:</b> Apply for Free in less than 3 mins to
                    find your Home buying budget. No impact on your credit score
                    and non-binding to you.
                  </li>
                  <li style={{ marginTop: 10 }}>
                    <b>Find your Utopia:</b> Pick your dream home (with our
                    agents or yours). We will buy it with an all-cash offer.
                  </li>
                  <li style={{ marginTop: 10 }}>
                    <b>Move-In and Earn:</b> Rent your home while we work with
                    you to make you a Homeowner. Our program turbo-charges your
                    monthly savings for Down Payment by sharing the gains from
                    Home Price Appreciation with you.
                  </li>
                </ul>
              </Stack>
            </Box>

            <Flex
              display="flex"
              flex={2.5}
              flexDirection={'column'}
              borderBottomWidth={1}
              borderBottomColor={'black'}
              position="relative"
            >
              <Flex flex={1} justifyContent={'flex-end'}></Flex>

              <Flex
                flex={0.7}
                justifyContent={'flex-end'}
                //   alignItems={'center'}
              >
                <Image
                  src="https://images.unsplash.com/photo-1588111817213-1a12f73fbf20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2274&q=80"
                  alt="house image"
                  height={['200px', '480px']}
                  width={['400px', '40.5vw']}
                />
              </Flex>

              <Box
                top={'-16px'}
                height="50vh"
                width="5vw"
                backgroundColor={'red'}
                position={'absolute'}
                //   left={10}
                left={'10%'}
                display={['none', 'block']}
              />

              <Box
                display={'flex'}
                flexDirection={['column', 'row']}
                flex={1.8}
                justifyContent={'flex-end'}
              >
                <Box
                  display="flex"
                  // flex={1}
                  alignItems={['center', 'flex-start']}
                  justifyContent={'center'}
                  flexDirection={'column'}
                  mr={10}
                >
                  <Text
                    fontWeight={'bold'}
                    fontSize={'3xl'}
                    mb={4}
                    textAlign={['center', 'left']}
                    mt={[4, 0]}
                  >
                    {name}
                  </Text>

                  {/* <Flex alignItems={'center'}> */}
                  {/* <Icon as={MdOutlineBusinessCenter} mr={1} /> */}
                  <Text fontWeight={'bold'}>💼 {brokerage}</Text>
                  {/* </Flex> */}

                  {/* <Flex alignItems={'center'}> */}
                  {/* <Icon as={AiOutlineMail} mr={1} /> */}
                  <Text fontWeight={'bold'}>✉️ {authUser.email}</Text>
                  {/* </Flex> */}

                  {/* <Flex alignItems={'center'}> */}
                  {/* <Icon as={BsPhone} mr={1} /> */}
                  <Text fontWeight={'bold'}>
                    📱 {formatPhoneNumber(contactNo)}
                  </Text>
                  {/* </Flex> */}
                </Box>

                <Box
                  display="flex"
                  // flex={1}
                  alignItems={'center'}
                  justifyContent={'center'}
                  position="relative"
                  py={[10, 0]}
                >
                  <div
                    style={{
                      height: 'auto',
                      margin: '0 auto',
                      maxWidth: 160,
                      width: '100%',
                    }}
                  >
                    <QRCode
                      size={600}
                      style={{
                        height: 'auto',
                        maxWidth: '100%',
                        width: '100%',
                      }}
                      value={`https://dash.utopiahomes.us/c/${referralCode?.code}`}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </Box>
              </Box>

              <Box
                // top={'34px'}
                height="4vh"
                width="10vw"
                backgroundColor={'red'}
                position={'absolute'}
                bottom={'-18px'}
                right={'0'}
              />
            </Flex>
          </Flex>

          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            lineHeight={'0px !important'}
            flexDirection={['column', 'row']}
          >
            <Box
              alignItems={'center'}
              justifyContent={'center'}
              display="flex"
              mr={4}
              lineHeight={'normal'}

              // style={{ margin: 0, padding: 0 }}
            >
              {/* <Icon as={BsGlobe} mr={1} /> */}
              <Text>🌐 www.utopiahomes.us</Text>
            </Box>

            <Box
              justifyContent={'center'}
              alignItems={'center'}
              display="flex"
              lineHeight={'normal'}
            >
              {/* <Icon as={FiMail} mr={1} mb={0} /> */}
              <Text>📨 support@utopia.cx</Text>
            </Box>
          </Flex>
        </Box>
      </Box>

      <Flex alignItems={'center'} justifyContent={'center'}>
        <Button
          minW={20}
          fontSize="sm"
          px={'5'}
          variant="outline"
          // onClick={handleDownloadClick}
          onClick={() => handleMarketingMaterialDownload(true)}
          mr={2}
          backgroundColor={'#1C2430'}
          textColor={'white'}
          _hover={{
            bg: 'black',
            cursor: 'pointer',
          }}
        >
          Download Image
        </Button>

        <Button
          minW={20}
          fontSize="sm"
          px={'5'}
          variant="outline"
          // onClick={handleMarketingMaterialDownload}
          onClick={() => handleMarketingMaterialDownload(false)}
          backgroundColor={'#1C2430'}
          textColor={'white'}
          _hover={{
            bg: 'black',
            cursor: 'pointer',
          }}
        >
          Download Pdf
        </Button>
      </Flex>
    </AgentDashboardSidebar>
  );
};

export default Marketing;
