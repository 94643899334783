import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Heading,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { useState } from 'react';
import AgentDashboardSidebar from '../../../Sidebar';

const questions = [
  'How do I help my clients to make the offer using Utopia?',
  'What should I do after the offer is accepted?',
  'How to change the custom link to invite Home-Buyers / agents?',
];

const answers = [
  [
    {
      step: 'Step 1',
      description:
        'Invite Clients, who fulfil our Home Buyers Criteria, either by sending an email invite or by sharing your customised link, obtained from your agent portal. All your customers who sign up using your invite will be attributed to you.',
    },
    {
      step: 'Step 2',
      description:
        'Check-in and Help the clients to sign-up and complete the free application (takes <5 mins), to get pre-qualified. Once your client gets pre-qualified and confirms you as the agent of choice, you will be able to track the status of their application in your agent portal.',
    },
    {
      step: 'Step 3',
      description:
        ' If there are any missing documents or information, a dedicated relationship manager will reach out to the client and will inform you as well. Help the clients to promptly share the requested documents and or information.',
    },
    {
      step: 'Step 4',
      description:
        'We review the application and let the client and you know of the approval status. If approved, the client is given a certain home buying budget as given in the approval letter. This letter is valid for 30-60 days (varies from case to case) and can be easily extended if the client needs more time to find their preferred home.',
    },
    {
      step: 'Step 5',
      description:
        'Once the client is approved, Please start shopping for Client’s Utopia within the approved home buying budget. Please check our Utopia’s Eligible Homes  Criteria to ensure that the selected home meets the criteria.',
    },
    {
      step: 'Step 6',
      description:
        'Once the client has identified their preferred home, please email support@Utopia.cx the subject property details along with CMA of at least 5 comparable sales from the last 3 months in 2 miles radius of Subject Property and at least 5 comparable rentals from the last 6 months in 2 miles radius of Subject Property, Please expand criteria if not enough comparables are available.',
    },
    {
      step: 'Step 7',
      description:
        'Based on your feedback, client suggestions, CMA and Utopia’s internal analysis, we will discuss the details with you and come up with an offer price.',
    },
    {
      step: 'Step 8',
      description:
        'Once the offer price is finalised and as we initiate signing the offer letter, We ask the client to do 2 things on priority:',
      substeps: [
        ' 1. Review and sign the Utopia Occupant Proposal, stating that they are in agreement with the purchase price and associated payments.',
        '2. Pay an offer initiation deposit prior to our  making the first offer.',
      ],
    },
    {
      step: 'Step 9',
      description:
        'After this, We sign the purchase agreement and share it with you and you can then forward it to the Seller/ Listing Agent. ',
    },
    {
      step: 'Step 10',
      description:
        'You stay in touch with the Seller/ Listing Agent and keep us posted if the offer is accepted, rejected or countered via Email (support@Utopia.cx) or Phone: 844-957-3700',
    },
  ],

  [
    {
      step: 'Step 1',
      description:
        'Since, Utopia makes all cash offers with no financing contingency, So, the seller is more likely to accept the Utopia Offer. Once, the offer is accepted, then you as Utopia Agent should do the following: Summarize the exact deadlines/dates for Earnest money deposit, inspection, appraisal and closing Recommend the names of good quality vendors (which charge reasonably) for inspection, appraisal, and escrow company.',
    },
    {
      step: 'Step 2',
      description:
        'Utopia gets the full home inspection done by a certified and licensed home inspector. We strongly encourage you as well as the future home occupant to be present at subject property at the time of inspection so that all their questions and concerns could be addressed by the home inspector. If any repair work is needed then Utopia gets the repair estimate from a certified general contractor. You work with Utopia to negotiate with the seller about the cost and the timeline for repairs. This way we set our client up for a home in excellent condition.',
    },
    {
      step: 'Step 3',
      description:
        'Utopia get the full appraisal of the home done by a certified residential home appraiser. This step is critical as we don’t want our customer when they are mortgage ready to run into appraisal issues.',
    },
    {
      step: 'Step 4',
      description:
        'Once the home passes the inspection and appraisal, Utopia, with your help, removes both the contingencies and prepares for final walk through and closing',
    },
    {
      step: 'Step 5',
      description:
        'During the final walkthrough, you must walk the customer and Utopia Representative through the empty home. This is the final opportunity for our customer to ensure that home is in excellent condition and if not then document any issues so that we could get them fixed through the Seller.',
    },
    {
      step: 'Step 6',
      description:
        'Once the home passes the final walkthrough, we proceed and complete the closing with the Escrow Company. And, you as an agent, make the commission for all your hard work and success!',
    },
  ],

  [
    {
      step: 'Step 1',
      description:
        "Click on the 'Invite', button which will be on the left hand side of your portal. It will be the third button from the top.",
    },
    {
      step: 'Step 2',
      description:
        'After Clicking, There will be a "Update Code" button on the section marked as "Invite By Sharing Your Link". Please click on the "Update Code" button.',
    },
    {
      step: 'Step 3',
      description:
        'Once you click, a pop-up would appear asking you to enter your preferred name. Please enter your preferred name and click on "Update Code", which will be right below.',
    },
    {
      step: 'Step 4',
      description: 'This will change your Link.',
    },
  ],
];

const Faq = () => {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleAccordionClick = index => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  const handleClick = () => {
    window.open('https://www.utopiahomes.us/faq/general-questions', '_blank');
  };

  return (
    <AgentDashboardSidebar showHeader={false}>
      <Box p={6}>
        <Heading as="h2" size="lg" mb={6} textAlign={'center'}>
          Agent FAQs
        </Heading>
        <Accordion allowMultiple index={expandedIndex}>
          {questions.map((question, index) => (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton onClick={() => handleAccordionClick(index)}>
                  <Box flex="1" textAlign="left" py={3}>
                    <Text fontSize={'lg'}>{question}</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {answers[index].map((answer, subIndex) => (
                  <Box key={subIndex} pl={4}>
                    <Stack>
                      <Stack direction="row" spacing={4} align="center">
                        <Badge colorScheme="blue">{answer.step}</Badge>
                        <Text>{answer.description}</Text>
                      </Stack>

                      <UnorderedList pl={20}>
                        {answer.substeps?.map((step, ind) => {
                          return <Text ke={ind}>{step}</Text>;
                        })}
                      </UnorderedList>
                    </Stack>
                  </Box>
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        <Button size="md" onClick={handleClick} mt={5}>
          See More {'>'}
        </Button>
      </Box>
    </AgentDashboardSidebar>
  );
};

export default Faq;
