import axios from 'axios';
import { getAuth } from 'firebase/auth';
import app from './firebase';

export const HTTP_CLIENT = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1`,
});

HTTP_CLIENT.interceptors.request.use(
  config => {
    const auth = getAuth(app);

    const token = auth.currentUser?.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  err => {
    Promise.reject(err);
  }
);
