const states = [
  'Alabama',
  'Arkansas',
  'Florida',
  'Georgia',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Michigan',
  'Minnesota',
  'Missouri',
  'Nebraska',
  'North Carolina',
  'New Mexico',
  'Ohio',
  'Oklahoma',
  'Pennsylvania',
  'South Carolina',
  'Tennessee',
  'Texas',
  'Utah',
  'West Virginia',
  'Wisconsin',
  'Other States',
];

const otherStates = [
  'Alaska',
  'Arizona',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Hawaii',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Mississippi',
  'Montana',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New York',
  'North Dakota',
  'Oregon',
  'Rhode Island',
  'South Dakota',
  'Vermont',
  'Virginia',
  'Washington',
  'Wyoming',
];

const metroAreas = {
  Alabama: ['Birmingham', 'Huntsville', 'Other'],
  Arkansas: ['Little Rock', 'Fayetteville-Springdale-Rogers', 'Other'],
  Florida: [
    'Jacksonville Metro',
    'Fort Myers Metro',
    'Miami Metro',
    'Orlando Metro',
    'Sarasota Metro',
    'Tampa Metro',
    'Other',
  ],
  Georgia: ['Atlanta', 'Other'],
  Idaho: ['Boise City', 'Other'],
  Illinois: ['Chicago', 'Other'],
  Indiana: ['Chicago', 'Indianapolis', 'Other'],
  Iowa: ['Des Moines', 'Other'],
  Kansas: ['Kansas City', 'Other'],
  Kentucky: ['Louisville', 'Other'],
  Louisiana: ['New Orleans', 'Other'],
  Michigan: ['Detroit', 'Grand Rapids', 'Other'],
  Minnesota: ['Minneapolis-St Paul Metro', 'Other'],
  Missouri: ['Kansas City', 'St Louis', 'Other'],
  Nebraska: ['Omaha', 'Lincoln', 'Other'],
  'North Carolina': ['Charlotte', 'Raleigh', 'Winston-Salem', 'Other'],
  'New Mexico': ['Albuquerque', 'Santa Fe', 'Other'],
  Ohio: ['Colombus', 'Cleveland', 'Other'],
  Oklahoma: ['Oklahama City', 'Tulsa', 'Other'],
  Pennsylvania: ['Philadelphiha', 'Pittsburgh', 'Other'],
  'South Carolina': ['Charlotte', 'Columbia', 'Other'],
  Tennessee: ['Nashville', 'Other'],
  Texas: ['Austin', 'Dallas', 'Houston', 'San Antonio', 'Other'],
  Utah: ['Salt Lake City', 'Other'],
  'West Virginia': ['Charleston', 'Other'],
  Wisconsin: ['Chicago', 'Minneapolis-St Paul Metro', 'Other'],
};

module.exports = {
  states,
  otherStates,
  metroAreas,
};
