import React, { Fragment } from 'react';

// utils
import SEO from '../utils/SEO';

// components
import HeaderOnboarding from '../components/Coapplicant/HeaderOnboarding';
import LandlordOnboardingComponent from '../components/OnboardingPage/LandlordOnboarding/LandlordOnboarding';

// chakra
import { Box } from '@chakra-ui/react';

const LandlordOnboarding = () => {
  return (
    <Fragment>
      <SEO
        title={`Utopia Homes | Landlord Onboarding`}
        description={`Landlord onboarding page for Utopia Homes. Start your landlord application.`}
        name={`Utopia Homes`}
        type={`application page`}
      />

      <HeaderOnboarding>
        <Box align="center">
          <LandlordOnboardingComponent />
        </Box>
      </HeaderOnboarding>
    </Fragment>
  );
};

export default LandlordOnboarding;
