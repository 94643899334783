import React, { Fragment } from 'react';

// chakra
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  MdOutlineCheckCircle,
  MdOutlineMoneyOff,
  MdSupervisorAccount,
} from 'react-icons/md';

const StartHomebuyerApplication = ({ setApplicationStarted }) => {
  return (
    <Fragment>
      <Card align="center" bg="white" size="md">
        <CardHeader>
          <Image
            boxSize="100px"
            objectFit="cover"
            src="https://i.imgur.com/O73TMKn.png"
            alt="emails"
          />
        </CardHeader>
        <CardBody>
          <Box mb="5" align="center">
            <Heading as="h4" size="md" mb="1">
              Your Utopia is just a few clicks away!
            </Heading>
            <Text fontSize="md" fontWeight="600" color="gray">
              You're applying for our 'Rent, Earn, and Own' program.
            </Text>
          </Box>

          <Stack spacing="5">
            <Flex align="center">
              <Box>
                <Center bg="blue.100" p="3" rounded="full" mr="4">
                  <Icon as={MdOutlineMoneyOff} boxSize={5} color="blue.500" />
                </Center>
              </Box>
              <Box>
                <Heading as="h5" size="sm">
                  No application fees
                </Heading>
                <Text fontSize="sm" fontWeight="600" color="gray">
                  Our application process is completely free.
                </Text>
              </Box>
            </Flex>

            <Flex align="center">
              <Box>
                <Center bg="blue.100" p="3" rounded="full" mr="4">
                  <Icon as={MdSupervisorAccount} boxSize={5} color="blue.500" />
                </Center>
              </Box>
              <Box>
                <Heading as="h5" size="sm">
                  Soft credit check
                </Heading>
                <Text fontSize="sm" fontWeight="600" color="gray">
                  Your credit score will not be affected as we only do a soft
                  credit pull.
                </Text>
              </Box>
            </Flex>

            <Flex align="center">
              <Box>
                <Center bg="blue.100" p="3" rounded="full" mr="4">
                  <Icon
                    as={MdOutlineCheckCircle}
                    boxSize={5}
                    color="blue.500"
                  />
                </Center>
              </Box>
              <Box>
                <Heading as="h5" size="sm">
                  Concierge service
                </Heading>
                <Text fontSize="sm" fontWeight="600" color="gray">
                  At Utopia, we are dedicated to helping you through the process
                  of finding your dream home.
                </Text>
              </Box>
            </Flex>
          </Stack>
        </CardBody>
        <CardFooter w="100%">
          <Button
            colorScheme="blue"
            size="lg"
            w="100%"
            onClick={() => {
              setApplicationStarted(true);
            }}
          >
            Start
          </Button>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default StartHomebuyerApplication;
