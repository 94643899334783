import * as yup from 'yup';

const landlordSignUpSchema = yup.object({
  email: yup.string().email('Invalid email').required('Required'),
  password: yup
    .string()
    .min(6, 'Password must be longer that 6 character')
    .required('Required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password do not match')
    .min(6, 'Password must be longer that 6 character')
    .required('Required'),
});

export { landlordSignUpSchema };
