import React, { Fragment } from 'react';
import {
  isEmptyString,
  validateEmail,
} from '../../../../utils/generalFunctions';

// context
import { useAuth } from '../../../../context/AuthContext';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  Spinner,
  useToast,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

const HomebuyerStepFive = ({
  setActiveStep,
  stepFiveCoApplicant,
  setStepFiveCoApplicant,
  stepFiveCoApplicantFirstName,
  setStepFiveCoApplicantFirstName,
  stepFiveCoApplicantLastName,
  setStepFiveCoApplicantLastName,
  stepFiveCoApplicantEmail,
  setStepFiveCoApplicantEmail,
  stepFiveCoApplicantPhone,
  setStepFiveCoApplicantPhone,
  handleNext,
  loading,
}) => {
  const { authUser } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const format = val => `$` + val;
  const parse = val => val.replace(/^\$/, '');

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(5 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Heading as="h4" size="md" mb="5">
                  User Details
                </Heading>
                <Stack spacing="5">
                  <FormControl>
                    <FormLabel color="gray">Do you have a Co-Buyer?</FormLabel>
                    <RadioGroup
                      value={stepFiveCoApplicant}
                      onChange={e => {
                        if (
                          stepFiveCoApplicant === 'yes' &&
                          e === 'no' &&
                          (!isEmptyString(stepFiveCoApplicantFirstName) ||
                            !isEmptyString(stepFiveCoApplicantLastName) ||
                            !isEmptyString(stepFiveCoApplicantEmail) ||
                            !isEmptyString(stepFiveCoApplicantPhone))
                        ) {
                          onOpen();
                        } else {
                          setStepFiveCoApplicant(e);
                        }
                      }}
                    >
                      <Stack spacing={3}>
                        <Radio size="lg" value="yes" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            Yes
                          </Text>
                        </Radio>
                        <Radio size="lg" value="no" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            No
                          </Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>

              {stepFiveCoApplicant == 'yes' && (
                <Box>
                  <Heading as="h5" size="sm" mb="3">
                    Great, Please enter the details of your Co-Buyer:
                  </Heading>
                  <Stack spacing="5">
                    <FormControl>
                      <FormLabel color="gray">First Name:</FormLabel>
                      <Input
                        borderWidth="2px"
                        size="lg"
                        type="text"
                        value={stepFiveCoApplicantFirstName}
                        onChange={e => {
                          setStepFiveCoApplicantFirstName(e.target.value);
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel color="gray">Last Name:</FormLabel>
                      <Input
                        borderWidth="2px"
                        size="lg"
                        type="text"
                        value={stepFiveCoApplicantLastName}
                        onChange={e => {
                          setStepFiveCoApplicantLastName(e.target.value);
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel color="gray">Email:</FormLabel>
                      <Input
                        borderWidth="2px"
                        size="lg"
                        type="email"
                        value={stepFiveCoApplicantEmail}
                        onChange={e => {
                          if (e.target.value == authUser.email) {
                            toast({
                              position: 'top-right',
                              title: 'Error',
                              description: "Can't use your own email",
                              status: 'error',
                              duration: 3000,
                              isClosable: true,
                            });
                          } else {
                            setStepFiveCoApplicantEmail(e.target.value);
                          }
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel color="gray">Phone:</FormLabel>
                      <Input
                        borderWidth="2px"
                        size="lg"
                        type="tel"
                        placeholder="222 333 4444"
                        pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                        maxLength="10"
                        value={stepFiveCoApplicantPhone}
                        onChange={e => {
                          const value = e.target.value.replace(/\D/g, '');
                          setStepFiveCoApplicantPhone(value.trim());
                        }}
                      />
                      <FormHelperText color="gray.400">
                        Enter 10-digit number
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Box>
              )}

              <Box>
                <Text fontSize="sm" color="gray.500">
                  Having a co-buyer makes you more likely to be approved and
                  could also increase your budget. A co-buyer is any person,
                  over 18 yrs of age, who will be living in your home with you.
                </Text>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(4);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              handleNext();
            }}
            isDisabled={
              isEmptyString(stepFiveCoApplicant) ||
              (stepFiveCoApplicant == 'yes' &&
                (isEmptyString(stepFiveCoApplicantFirstName) ||
                  isEmptyString(stepFiveCoApplicantLastName) ||
                  isEmptyString(stepFiveCoApplicantEmail) ||
                  isEmptyString(stepFiveCoApplicantPhone) ||
                  stepFiveCoApplicantPhone.length !== 10 ||
                  !validateEmail(stepFiveCoApplicantEmail) ||
                  stepFiveCoApplicantEmail === authUser.email))
            }
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Wait...</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              You selected <b>“No”</b> to the question: “Do You have a
              Co-Buyer?”.
              <br />
              <br />
              Please proceed only if you want the Co-Buyer related information
              to be deleted; Otherwise, please cancel.
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                setStepFiveCoApplicant('no');
                setStepFiveCoApplicantFirstName('');
                setStepFiveCoApplicantLastName('');
                setStepFiveCoApplicantEmail('');
                setStepFiveCoApplicantPhone('');
                onClose();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default HomebuyerStepFive;
