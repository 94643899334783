import React, { Fragment, useState, useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
} from '@chakra-ui/react';

// components
import StartHomebuyerApplication from './StartHomebuyerApplication';
import HomebuyerApplication from './HomebuyerApplication/HomebuyerApplication';
import HomebuyerApplicationSubmitted from './HomebuyerApplicationSubmitted';

const HomebuyerOnboarding = ({ setIsLargeContainer }) => {
  const [startApplication, setStartApplication] = useState(false);
  const [applicationCompleted, setApplicationCompleted] = useState(null);
  const [applicationStarted, setApplicationStarted] = useState(false);

  const { authUser, setAuthLoading } = useAuth();

  // const applicationCompleted = authUser?.data?.applicationCompleted;
  // const applicationStarted = authUser?.data?.applicationStarted;

  useEffect(() => {
    const fetchData = async () => {
      setAuthLoading(true);
      setApplicationCompleted(authUser?.data?.applicationCompleted);
      setApplicationStarted(authUser?.data?.applicationStarted);
      setAuthLoading(false);
    };

    fetchData().catch(console.error);
  }, [authUser]);

  return (
    <Fragment>
      {applicationCompleted ? (
        <HomebuyerApplicationSubmitted />
      ) : applicationStarted || startApplication ? (
        <HomebuyerApplication setIsLargeContainer={setIsLargeContainer} />
      ) : (
        <StartHomebuyerApplication
          setApplicationStarted={setStartApplication}
        />
      )}
    </Fragment>
  );
};

export default HomebuyerOnboarding;
