import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';

// utils
import SEO from '../utils/SEO';

// components
import Navbar from '../components/SearchHomePageComponents/Navbar';
import ListingSection from '../components/ListingPageComponents/ListingSection';

// chakra
import {
  Box,
  Flex,
  Text,
  Container,
  SimpleGrid,
  Card,
  CardBody,
} from '@chakra-ui/react';

const Listing = () => {
  const { id } = useParams();

  return (
    <Fragment>
      <SEO
        title={`Utopia Homes | Landlord Sign Up`}
        description={`Landlord sign up page for Utopia Homes. Sign up to list your property on Utopia Homes.`}
        name={`Utopia Homes`}
        type={`application page`}
      />

      <Flex direction="column" w="100%" h="100vh">
        <Navbar />
        <ListingSection id={id} />
      </Flex>
    </Fragment>
  );
};

export default Listing;
