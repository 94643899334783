import React, { Fragment } from 'react';

// routes
import MainRoutes from './routes/Main.routes';

// components
import Loading from './components/Loading';
import { useAuth } from './context/AuthContext';
import { useCoapplicant } from './context/CoapplicantContext';

const App = () => {
  // const [isLoading, setIsLoading] = useState(false);
  const { authLoading } = useAuth();
  const { loading: coapplicantLoading } = useCoapplicant();

  return (
    <Fragment>
      {authLoading || coapplicantLoading ? <Loading /> : <MainRoutes />}
    </Fragment>
  );
};

export default App;
