import React, { Fragment } from 'react';
import { isEmptyString } from '../../../utils/generalFunctions';

// context
import { useCoapplicant } from '../../../context/CoapplicantContext';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Spinner,
} from '@chakra-ui/react';

const CoapplicantStepNine = ({
  setActiveStep,
  stepNineHasRaise,
  setStepNineHasRaise,
  handleNext,
  loading,
}) => {
  const { coapplicantData, isHomebuyer } = useCoapplicant();

  return (
    <Fragment>
      <Stack maxW="xl" spacing="5">
        <Card bg="white" w="100%" textAlign="left">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md">
                    Pay Raise
                  </Heading>
                </Box>

                <Stack spacing="5">
                  <FormControl>
                    <FormLabel color="gray">
                      {isHomebuyer
                        ? coapplicantData
                          ? `Has ${coapplicantData.name.firstName} `
                          : ''
                        : 'Have you '}
                      received a raise within the last 6 months?
                    </FormLabel>
                    <RadioGroup
                      value={stepNineHasRaise}
                      onChange={setStepNineHasRaise}
                    >
                      <Stack spacing={3}>
                        <Radio size="lg" value="yes" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            Yes
                          </Text>
                        </Radio>
                        <Radio size="lg" value="no" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            No
                          </Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(6);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={async () => {
              await handleNext();
            }}
            isLoading={loading}
            isDisabled={isEmptyString(stepNineHasRaise)}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default CoapplicantStepNine;
