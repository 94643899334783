import React, { Fragment, useEffect, useState } from 'react';

// components
import AgentStepOne from './AgentStepOne';
import AgentStepTwo from './AgentStepTwo';

// chakra
import { useToast } from '@chakra-ui/react';
import { Timestamp } from 'firebase/firestore';

import { useAgentAuth } from '../../../../context/AgentContext';
import { useAuth } from '../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const AgentApplication = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [phone, setPhone] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [brokerage, setBrokerage] = useState('');
  const [metro, setMetro] = useState('');
  const [preferredCities, setPreferredCities] = useState('');
  const [operatingState, setOperatingState] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const { authUser, updateUserFields, setAuthLoading } = useAuth();

  const { updateApplicationFields, applicationData, updateInvitationStatus } =
    useAgentAuth();

  const handleNext = async () => {
    try {
      setButtonLoading(true);
      let payload = {
        uid: authUser.uid,
      };

      let updateUser = false;

      if (activeStep === 1) {
        payload = {
          uid: authUser.uid,
          stepOne: [
            {
              question: 'Agent Phone',
              answer: phone,
            },
            {
              question: 'Agent License Number:',
              answer: licenseNumber,
            },
            {
              question: 'Agent Brokerage:',
              answer: brokerage,
            },
          ],
          currentStep: 1,
          applicationCompleted: false,
        };

        await updateUserFields({
          applicationStarted: true,
        });

        await updateApplicationFields({
          applicationStarted: true,
          isDeleted: false,
          applicationStartedAt: Timestamp.now(),
          email: authUser.email,
          status: 'started',
        });
      } else if (activeStep == 2) {
        payload = {
          stepTwo: [
            {
              question: 'Agent Operating Metro:',
              answer: metro,
            },
            {
              question: 'Agent Preferred Cities:',
              answer: preferredCities,
            },
            {
              question: 'Agent Operating States:',
              answer: operatingState,
            },
          ],
          currentStep: 2,
          applicationCompleted: true,
          timeSubmitted: Timestamp.now(),
        };
        updateUser = true;
      }

      await updateApplicationFields(payload);

      if (updateUser) {
        // setAuthLoading(true);

        updateInvitationStatus(authUser.email?.toLowerCase(), 'joined');
        await updateUserFields({
          applicationCompleted: true,
        });

        setButtonLoading(false);
        // window.location.reload(false);

        navigate('/agents/home?welcome=true');
      }

      setActiveStep(activeStep + 1);
      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
      toast({
        title: 'Error',
        description: 'Something went wrong. Please try again',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const setInitialData = async () => {
    if (applicationData) {
      if (applicationData.stepOne) {
        setPhone(applicationData.stepOne[0]?.answer);
        setLicenseNumber(applicationData.stepOne[1]?.answer);
        setBrokerage(applicationData.stepOne[2]?.answer);
      }
      if (applicationData.stepTwo) {
        setMetro(applicationData.stepTwo[0]?.answer);
        setPreferredCities(applicationData.stepTwo[1]?.answer);
        setOperatingState(applicationData.stepTwo[2]?.answer);
      }
    }
  };

  useEffect(() => {
    setInitialData();
  }, [applicationData]);

  const renderStep = activeStep => {
    switch (activeStep) {
      case 1:
        return (
          <AgentStepOne
            setActiveStep={setActiveStep}
            setPhone={setPhone}
            phone={phone}
            setLicenseNumber={setLicenseNumber}
            licenseNumber={licenseNumber}
            setBrokerage={setBrokerage}
            brokerage={brokerage}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      case 2:
        return (
          <AgentStepTwo
            setActiveStep={setActiveStep}
            setOperatingState={setOperatingState}
            operatingState={operatingState}
            setMetro={setMetro}
            metro={metro}
            setPreferredCities={setPreferredCities}
            preferredCities={preferredCities}
            handleNext={handleNext}
            loading={buttonLoading}
            updateApplicationFields={updateApplicationFields}
          />
        );
      default:
        return <AgentStepOne setActiveStep={setActiveStep} />;
    }
  };

  return <Fragment>{renderStep(activeStep)}</Fragment>;
};

export default AgentApplication;
