import { AddIcon, DownloadIcon } from '@chakra-ui/icons';
import { Button, Flex, Heading } from '@chakra-ui/react';
import React from 'react';

import GenericTable from '../../../components/GenericTable';
import makeData from '../../../utils/makeData';
import AgentDashboardSidebar from '../../Sidebar';

// import makeData from './makeData';

function Offers() {
  const columns = React.useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
        minWidth: 150,
      },

      {
        Header: 'Last Name',
        accessor: 'lastName',
        minWidth: 150,
      },
      {
        Header: 'Age',
        accessor: 'age',
        minWidth: 150,
      },
      {
        Header: 'Visits',
        accessor: 'visits',
        minWidth: 150,
      },
      {
        Header: 'Status',
        accessor: 'status',
        minWidth: 150,
        disableSortBy: true,
      },
      {
        Header: 'Profile Progress',
        accessor: 'progress',
        minWidth: 150,
        disableSortBy: true,
      },
      {
        Header: 'Actions',
        accessor: 'action',
        minWidth: 150,
        disableSortBy: true,
        isAction: true,
      },
    ],
    []
  );

  const data = React.useMemo(() => makeData(100), []);

  return (
    <AgentDashboardSidebar>
      <Flex>
        <Heading size="lg">All Offers (10)</Heading>
        {/* <Button
          backgroundColor={'#1C2430'}
          textColor={'white'}
          minW={30}
          ml={5}
          px={'10'}
        >
          <DownloadIcon fontSize="0.9em" mr={2} /> Export Data
        </Button> */}
      </Flex>
      <GenericTable columns={columns} data={data} />
    </AgentDashboardSidebar>
  );
}

export default Offers;
