import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// utils
import {
  states,
  otherStates,
  metroAreas,
} from '../../../../utils/statesAndMetros';

// chakra
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  Textarea,
  Spinner,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { isEmptyString } from '../../../../utils/generalFunctions';

const AgentStepTwo = ({
  setActiveStep,
  setOperatingState,
  operatingState,
  setMetro,
  metro,
  setPreferredCities,
  preferredCities,
  handleNext,
  loading,
  updateApplicationFields,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [stateValue, setStateValue] = useState('');
  const [otherStateValue, setOtherStateValue] = useState('');
  const [metroValue, setMetroValue] = useState('');
  const [otherMetroValue, setOtherMetroValue] = useState('');
  const [citiesValue, setCitiesValue] = useState('');

  const [changeAgent, setChangeAgent] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (changeAgent) {
        console.log('hi there');
        console.log({
          operatingState,
          metro,
          preferredCities,
        });

        await handleNext();

        setChangeAgent(false);
      }
    };

    fetchData().catch(console.error);
  }, [changeAgent]);

  useEffect(() => {
    setStateValue('');
    setOtherStateValue('');
    setMetroValue('');
    setOtherMetroValue('');
    setCitiesValue('');

    if (operatingState?.includes('Other - ')) {
      setStateValue('Other States');
      setOtherStateValue(operatingState.replace('Other - ', ''));
    } else {
      setStateValue(operatingState);
      setOtherStateValue('');
    }

    if (metro?.includes('Other - ')) {
      setMetroValue('Other');
      setOtherMetroValue(metro.replace('Other - ', ''));
    } else {
      setMetroValue(metro);
      setOtherMetroValue('');
    }

    setCitiesValue(preferredCities);
  }, []);

  const handleSubmit = async () => {
    console.log('Running handleSubmit()');
    try {
      let finalStateValue = '';
      let finalMetroValue = '';
      let finalCityValue = '';

      if (stateValue === 'Other States') {
        finalStateValue = `Other - ${otherStateValue}`;
      } else {
        finalStateValue = stateValue;
      }

      if (metroValue === 'Other') {
        finalMetroValue = `Other - ${otherMetroValue}`;
      } else {
        finalMetroValue = metroValue;
      }

      finalCityValue = citiesValue;

      await setOperatingState(finalStateValue);
      await setMetro(finalMetroValue);
      await setPreferredCities(finalCityValue);

      // await updateApplicationFields({
      //   stepTwo: [
      //     {
      //       question: 'Agent Operating Metro:',
      //       answer: finalMetroValue,
      //     },
      //     {
      //       question: 'Agent Preferred Cities:',
      //       answer: finalCityValue,
      //     },
      //     {
      //       question: 'Agent Operating States:',
      //       answer: finalStateValue,
      //     },
      //   ],
      //   currentStep: 2,
      // });

      setChangeAgent(true);

      // await handleNext();
    } catch (err) {
      console.log('handleSubmit() Error: ', err);
    }
  };

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="green"
          rounded="full"
          size="sm"
          value={(2 / 2) * 100}
          bg="gray.200"
        />
        <Card bg="white" size="md">
          <CardHeader>
            <Heading as="h4" size="md" mb="1">
              Location Details
            </Heading>
            <Text fontSize="md" fontWeight="600" color="gray">
              Please provide details about the main location(s) that you as the
              agent will be targeting for potential customers.
            </Text>
          </CardHeader>
          <CardBody>
            <Stack spacing="5">
              <FormControl>
                <FormLabel color="gray">State</FormLabel>
                <Select
                  placeholder="Select a State"
                  value={stateValue}
                  onChange={e => {
                    setStateValue(e.target.value);

                    setOtherStateValue('');
                    setMetroValue('');
                    setOtherMetroValue('');
                    setCitiesValue('');
                  }}
                >
                  {states?.map(state => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {stateValue === 'Other States' && (
                <FormControl>
                  <FormLabel color="gray">Other State</FormLabel>
                  <Select
                    placeholder="Select a State"
                    value={otherStateValue}
                    onChange={e => {
                      setOtherStateValue(e.target.value);

                      setStateValue('Other States');
                      setMetroValue('');
                      setOtherMetroValue('');
                      setCitiesValue('');
                    }}
                  >
                    {otherStates?.map(state => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}

              {stateValue !== '' && stateValue !== 'Other States' && (
                <FormControl>
                  <FormLabel color="gray">Metro Area of Interest</FormLabel>
                  <Select
                    placeholder="Select a Metro Area"
                    value={metroValue}
                    onChange={e => {
                      setMetroValue(e.target.value);

                      setOtherMetroValue('');
                      setCitiesValue('');
                    }}
                  >
                    {metroAreas[stateValue]?.map(metroArea => (
                      <option key={metroArea} value={metroArea}>
                        {metroArea}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}

              {((stateValue === 'Other States' && otherStateValue !== '') ||
                (stateValue !== '' &&
                  stateValue !== 'Other States' &&
                  metroValue === 'Other')) && (
                <FormControl>
                  <FormLabel color="gray">Metro Area of Interest</FormLabel>
                  <Textarea
                    borderWidth="2px"
                    size="lg"
                    type="text"
                    placeholder={`Enter the metro area you're interested in living in`}
                    value={otherMetroValue}
                    onChange={e => {
                      setOtherMetroValue(e.target.value);

                      setMetroValue('Other');
                      setCitiesValue('');
                    }}
                  />
                </FormControl>
              )}

              {((stateValue !== '' && stateValue !== 'Other States') ||
                (stateValue === 'Other States' && otherStateValue !== '')) &&
                ((metroValue !== '' && metroValue !== 'Other') ||
                  (metroValue === 'Other' && otherMetroValue !== '')) && (
                  <FormControl>
                    <FormLabel color="gray">
                      Cities of Interest <i>(Optional)</i>:
                    </FormLabel>
                    <Textarea
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={citiesValue}
                      onChange={e => {
                        setCitiesValue(e.target.value);
                      }}
                    />
                  </FormControl>
                )}
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(1);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="green"
            onClick={async () => {
              await handleSubmit();
            }}
            isDisabled={
              stateValue === '' ||
              (stateValue === 'Other States' && otherStateValue === '') ||
              metroValue === '' ||
              (metroValue === 'Other' && otherMetroValue === '')
            }
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Market currently not served</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              We are currently not serving the <b>{otherStateValue}</b> market.
              We will let you know when are are expanding into the region that
              you would like to buy a house in.
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={async () => {
                console.log('hihi');
                await handleSubmit();
              }}
              isLoading={loading}
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default AgentStepTwo;
