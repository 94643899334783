import * as yup from 'yup';

const clientInvitationSchema = yup.object({
  client_name: yup.string().required('Name is required'),
  client_email_address: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),
});

export { clientInvitationSchema };
