import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLandlord } from '../../../context/LandlordContext';
import { useAuth } from '../../../context/AuthContext';
import { numberWithCommas } from '../../../utils/generalFunctions';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// components
import SidebarWithHeader from '../../../components/SidebarWithHeader';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  Spacer,
  Badge,
  Box,
  SimpleGrid,
  Spinner,
  Center,
  StackDivider,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { FaRegTrashAlt } from 'react-icons/fa';

const LandlordHome = () => {
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const {
    landlordProperties,
    getLandlordProperties,
    createPropertyApplication,
    deletePropertyApplication,
  } = useLandlord();
  const toast = useToast();

  const [unFinishedApplications, setUnFinishedApplications] = useState([]);
  const [finishedApplications, setFinishedApplications] = useState([]);
  const [propertiesLoaded, setPropertiesLoaded] = useState(false);

  const handleGetLandlordProperties = async () => {
    try {
      setPropertiesLoaded(false);
      const properties = await getLandlordProperties(authUser?.uid);
      setUnFinishedApplications(
        properties
          .filter(property => property?.data?.status === 'INCOMPLETE')
          .sort(
            (a, b) =>
              new Date(b.data.timeStarted) - new Date(a.data.timeStarted)
          )
      );
      setFinishedApplications(
        properties
          .filter(property => property?.data?.status !== 'INCOMPLETE')
          .sort(
            (a, b) =>
              new Date(b.data.timeSubmitted) - new Date(a.data.timeSubmitted)
          )
      );
      setPropertiesLoaded(true);
    } catch (err) {
      console.log(err);
      setPropertiesLoaded(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleGetLandlordProperties();
    };

    fetchData().catch(console.error);
  }, [authUser]);

  return (
    <Fragment>
      <SidebarWithHeader userMode="home">
        <Container maxW="container.lg">
          <Flex direction={'column'}>
            <Flex align={'center'} mb="5">
              <Text fontSize="2xl" fontWeight="600">
                My Properties:
              </Text>
              <Spacer />
              <Button
                leftIcon={<AddIcon />}
                colorScheme="blue"
                onClick={async () => {
                  try {
                    setPropertiesLoaded(false);
                    const applicationId = await createPropertyApplication();
                    if (applicationId) {
                      navigate(`/landlord/add-property/${applicationId}`);
                    } else {
                      toast({
                        title: 'Error',
                        description: 'Error in creating property application',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                        position: 'top-right',
                      });
                    }
                    setPropertiesLoaded(true);
                  } catch (err) {
                    console.log(err);
                    toast({
                      title: 'Error',
                      description: 'Error in creating property application',
                      status: 'error',
                      duration: 9000,
                      isClosable: true,
                      position: 'top-right',
                    });
                    setPropertiesLoaded(true);
                  }
                }}
                isLoading={!propertiesLoaded}
              >
                Add Property
              </Button>
            </Flex>

            {propertiesLoaded ? (
              <Fragment>
                {unFinishedApplications.length == 0 &&
                finishedApplications.length == 0 ? (
                  <Card>
                    <CardBody>
                      <Text>No properties listed</Text>
                    </CardBody>
                  </Card>
                ) : (
                  <Stack spacing={10}>
                    <Box>
                      <Text fontSize="lg" fontWeight="600" mb="6">
                        Inactive Properties:
                      </Text>
                      <SimpleGrid columns={1} spacing={5}>
                        {unFinishedApplications.map(property => (
                          <Card>
                            <CardBody>
                              <Flex align="center">
                                {property?.data?.stepTwo[3]?.answer ? (
                                  <Text fontWeight="600">
                                    {property?.data?.stepTwo[3]?.answer}
                                  </Text>
                                ) : (
                                  <Flex align="center" gap={2}>
                                    <Text>Listing Started:</Text>
                                    <Text fontWeight="600">
                                      {property?.data?.timeStarted
                                        ?.toDate()
                                        .toLocaleString('en-US', {
                                          month: '2-digit',
                                          day: '2-digit',
                                          year: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hour12: true,
                                        })}
                                    </Text>
                                  </Flex>
                                )}

                                <Spacer />
                                <Flex gap={3}>
                                  <Button
                                    size="sm"
                                    colorScheme="blue"
                                    onClick={() => {
                                      navigate(
                                        `/landlord/add-property/${property.id}`
                                      );
                                    }}
                                  >
                                    Resume
                                  </Button>
                                  <IconButton
                                    size="sm"
                                    colorScheme="red"
                                    aria-label="Delete Application"
                                    icon={<FaRegTrashAlt />}
                                    onClick={async () => {
                                      await deletePropertyApplication(
                                        property.id
                                      );
                                      await handleGetLandlordProperties();
                                    }}
                                  />
                                </Flex>
                              </Flex>
                            </CardBody>
                          </Card>
                        ))}
                      </SimpleGrid>
                    </Box>

                    <Box>
                      <Text fontSize="lg" fontWeight="600" mb="6">
                        Active Properties:
                      </Text>
                      <SimpleGrid columns={2} spacing={10}>
                        {finishedApplications.map(property => (
                          <Card
                            key={property.id}
                            _hover={{ cursor: 'pointer' }}
                            onClick={() => {
                              navigate(
                                `/landlord/property-details/${property.id}/landlord`
                              );
                            }}
                          >
                            <Carousel
                              swipeable={true}
                              infiniteLoop={true}
                              showThumbs={false}
                              autoPlay={true}
                              interval={5000}
                            >
                              {property?.images.map(image => (
                                <div>
                                  {image.imageUrl
                                    .toLowerCase()
                                    .endsWith('.heic') ? (
                                    <div
                                      style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '300px',
                                        objectFit: 'cover',
                                      }}
                                    >
                                      <h3>
                                        HEIC image (preview not available)
                                      </h3>
                                    </div>
                                  ) : (
                                    <img
                                      src={image?.imageUrl}
                                      style={{
                                        width: '100%',
                                        height: '300px',
                                        objectFit: 'cover',
                                      }}
                                    />
                                  )}

                                  {image.imageDescription && (
                                    <p className="legend">
                                      {image?.imageDescription}
                                      <Fragment>
                                        {console.log({ image })}
                                      </Fragment>
                                    </p>
                                  )}
                                </div>
                              ))}
                            </Carousel>
                            <CardBody>
                              <Stack spacing="2">
                                <Stack
                                  direction="row"
                                  divider={
                                    <StackDivider borderColor="gray.200" />
                                  }
                                >
                                  <Flex align="center">
                                    <Text
                                      fontSize="lg"
                                      fontWeight="500"
                                      color="gray.500"
                                      mr="2"
                                    >
                                      Expected Rent:
                                    </Text>
                                    <Text fontSize="lg" fontWeight="700">
                                      $
                                      {property?.data?.stepOne[3]?.answer
                                        ? numberWithCommas(
                                            property?.data?.stepOne[3]?.answer
                                          )
                                        : 'N/A'}
                                      /mo
                                    </Text>
                                  </Flex>

                                  <Flex align="center">
                                    <Text
                                      fontSize="lg"
                                      fontWeight="500"
                                      color="gray.500"
                                      mr="2"
                                    >
                                      Home Price:
                                    </Text>
                                    <Text fontSize="lg" fontWeight="700">
                                      $
                                      {property?.data?.stepThree[0]?.answer
                                        ? numberWithCommas(
                                            property?.data?.stepThree[0]?.answer
                                          )
                                        : 'N/A'}
                                    </Text>
                                  </Flex>
                                </Stack>

                                <Stack
                                  direction="row"
                                  divider={
                                    <StackDivider borderColor="gray.200" />
                                  }
                                >
                                  <Flex align="center">
                                    <Text fontSize="md" fontWeight="700" mr="1">
                                      {property?.data?.stepOne[1]?.answer
                                        ? property?.data?.stepOne[1]?.answer
                                        : 'N/A'}
                                    </Text>
                                    <Text
                                      fontSize="md"
                                      fontWeight="500"
                                      color="gray.500"
                                    >
                                      bds
                                    </Text>
                                  </Flex>

                                  <Flex align="center">
                                    <Text fontSize="md" fontWeight="700" mr="1">
                                      {property?.data?.stepOne[2]?.answer
                                        ? property?.data?.stepOne[2]?.answer
                                        : 'N/A'}
                                    </Text>
                                    <Text
                                      fontSize="md"
                                      fontWeight="500"
                                      color="gray.500"
                                    >
                                      ba
                                    </Text>
                                  </Flex>

                                  <Flex align="center">
                                    <Text fontSize="md" fontWeight="700" mr="1">
                                      {property?.data?.stepOne[4]?.answer
                                        ? numberWithCommas(
                                            property?.data?.stepOne[4]?.answer
                                          )
                                        : 'N/A'}
                                    </Text>
                                    <Text
                                      fontSize="md"
                                      fontWeight="500"
                                      color="gray.500"
                                    >
                                      sqft
                                    </Text>
                                  </Flex>
                                </Stack>

                                <Stack
                                  direction="row"
                                  divider={
                                    <StackDivider borderColor="gray.200" />
                                  }
                                >
                                  <Flex align="center">
                                    <Text
                                      fontSize="md"
                                      fontWeight="500"
                                      color="gray.500"
                                    >
                                      {property?.data?.stepTwo[3]?.answer
                                        ? property?.data?.stepTwo[3]?.answer
                                        : 'N/A'}
                                    </Text>
                                  </Flex>

                                  <Flex align="center">
                                    <Text
                                      fontSize="md"
                                      fontWeight="500"
                                      color="gray.500"
                                    >
                                      {property?.data?.stepTwo[0]?.answer
                                        ? property?.data?.stepTwo[0]?.answer
                                        : 'N/A'}
                                    </Text>
                                  </Flex>

                                  <Flex align="center">
                                    <Text
                                      fontSize="md"
                                      fontWeight="500"
                                      color="gray.500"
                                    >
                                      {property?.data?.stepTwo[1]?.answer
                                        ? property?.data?.stepTwo[1]?.answer
                                        : 'N/A'}
                                    </Text>
                                  </Flex>
                                </Stack>

                                <Flex align="center">
                                  <Text
                                    fontSize="md"
                                    fontWeight="500"
                                    color="gray.500"
                                    mr="1"
                                  >
                                    Status:
                                  </Text>
                                  <Badge fontSize="0.8em" fontWeight="bold">
                                    {property?.data?.status === 'IN_REVIEW'
                                      ? 'In Review'
                                      : property?.data?.status === 'ACCEPTED'
                                      ? 'Accepted'
                                      : 'Rejected'}
                                  </Badge>
                                </Flex>
                              </Stack>
                            </CardBody>
                          </Card>
                        ))}
                      </SimpleGrid>
                    </Box>
                  </Stack>
                )}
              </Fragment>
            ) : (
              <Text>
                <Card>
                  <CardBody>
                    <Center>
                      <Spinner />
                    </Center>
                  </CardBody>
                </Card>
              </Text>
            )}
          </Flex>
        </Container>
      </SidebarWithHeader>
    </Fragment>
  );
};

export default LandlordHome;
