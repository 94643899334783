import React, { Fragment, useRef, useState } from 'react';
import { isEmptyString } from '../../../../utils/generalFunctions';

// chakra
import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  Progress,
  Spinner,
  Stack,
  Text,
  useToast,
  IconButton,
  Icon,
  Flex,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { FiTrash2 } from 'react-icons/fi';

const HomebuyerStepThirteen = ({
  setActiveStep,
  stepNinePrimaryIncome,
  stepThirteenBankStatementOne,
  setStepThirteenBankStatementOne,
  stepThirteenBankStatementTwo,
  setStepThirteenBankStatementTwo,
  stepThirteenBankStatementThree,
  setStepThirteenBankStatementThree,
  stepTwelveIncomeDocOne,
  stepTwelveIncomeDocTwo,
  stepTwelveIncomeDocThree,
  stepSixteenIdentityDoc,
  handleNext,
  loading,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const stepThirteenBankStatementOneRef = useRef(null);
  const stepThirteenBankStatementTwoRef = useRef(null);
  const stepThirteenBankStatementThreeRef = useRef(null);
  const cancelRef = useRef();

  const [alertDialog, setAlertDialog] = useState('');

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(13 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box>
                  <Heading as="h4" size="md" mb="1">
                    Share Bank Statements
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    Please share last 3 months of bank statements for your
                    primary bank account.
                  </Text>
                </Box>
              </Box>

              <Box>
                <Box mb="3">
                  <Heading as="h5" size="sm" mb="1">
                    Upload bank statements manually
                  </Heading>
                  <Text fontSize="sm" fontWeight="600" color="gray">
                    {stepNinePrimaryIncome === 'Salary / hourly'
                      ? `Please upload three most current months of bank statements in PDF format showing deposit of your W2 income.`
                      : stepNinePrimaryIncome === 'Independent Contractor'
                      ? `Please upload three most current months of bank statements in PDF format showing deposit of your independent contractor income.`
                      : stepNinePrimaryIncome === 'Self-employed'
                      ? `Please upload your three most current bank statements in PDF format showing deposit of your business income. Please upload in PDF format, with the latest statement being no older than 30 days.`
                      : `You'll be prompted to upload documents- So, upload directly from your device or take a photo of the document and then upload`}
                  </Text>
                </Box>
                <Stack spacing="5">
                  <Box>
                    <Text fontSize="xs" fontWeight="600" color="gray" mb="1">
                      Your recent bank statement 1: <b>(REQUIRED)</b>
                    </Text>
                    <input
                      type="file"
                      ref={stepThirteenBankStatementOneRef}
                      id="stepThirteenBankStatementOne"
                      name="stepThirteenBankStatementOne"
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/*,.heic"
                      style={{ display: 'none' }}
                      onChange={e => {
                        if (e.target.files[0].size > 5242880) {
                          setAlertDialog('File is too big! Must be under 5mb');
                          onOpen();
                        } else if (e.target.files[0].size < 5000) {
                          setAlertDialog(
                            'This file quality is not that great; Please attach a better resolution file.'
                          );
                          onOpen();
                        } else {
                          const fileName = e.target.files[0].name;
                          // if (
                          //   fileName === stepTwelveIncomeDocOne.name ||
                          //   fileName === stepTwelveIncomeDocTwo.name ||
                          //   fileName === stepTwelveIncomeDocThree.name ||
                          //   fileName === stepThirteenBankStatementTwo.name ||
                          //   fileName === stepThirteenBankStatementThree.name ||
                          //   fileName === stepSixteenIdentityDoc.name
                          // ) {
                          //   toast({
                          //     title: 'File is already being used',
                          //     position: 'top-right',
                          //     status: 'error',
                          //     isClosable: true,
                          //   });
                          // } else {
                          //   setStepThirteenBankStatementOne(e.target.files[0]);
                          // }
                          setStepThirteenBankStatementOne(e.target.files[0]);
                        }
                      }}
                    />
                    {stepThirteenBankStatementOne == '' ? (
                      <Flex align="center">
                        <Button as="label" for="stepThirteenBankStatementOne">
                          <Text>Select a File</Text>
                        </Button>
                      </Flex>
                    ) : (
                      <Flex align="center">
                        <Button
                          as="label"
                          for="stepThirteenBankStatementOne"
                          mr="2"
                        >
                          <Text textOverflow="ellipsis">
                            {stepThirteenBankStatementOne.name}
                          </Text>
                        </Button>
                        <IconButton
                          colorScheme="red"
                          aria-label="delete file"
                          icon={<Icon as={FiTrash2} />}
                          onClick={() => {
                            stepThirteenBankStatementOneRef.current.value =
                              null;
                            setStepThirteenBankStatementOne('');
                          }}
                        />
                      </Flex>
                    )}
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="600" color="gray" mb="1">
                      Your recent bank statement 2:
                    </Text>
                    <input
                      type="file"
                      ref={stepThirteenBankStatementTwoRef}
                      id="stepThirteenBankStatementTwo"
                      name="stepThirteenBankStatementTwo"
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/*,.heic"
                      style={{ display: 'none' }}
                      onChange={e => {
                        if (e.target.files[0].size > 5242880) {
                          setAlertDialog('File is too big! Must be under 5mb');
                          onOpen();
                        } else if (e.target.files[0].size < 5000) {
                          setAlertDialog(
                            'This file quality is not that great; Please attach a better resolution file.'
                          );
                          onOpen();
                        } else {
                          const fileName = e.target.files[0].name;
                          // if (
                          //   fileName === stepTwelveIncomeDocOne.name ||
                          //   fileName === stepTwelveIncomeDocTwo.name ||
                          //   fileName === stepTwelveIncomeDocThree.name ||
                          //   fileName === stepThirteenBankStatementOne.name ||
                          //   fileName === stepThirteenBankStatementThree.name ||
                          //   fileName === stepSixteenIdentityDoc.name
                          // ) {
                          //   toast({
                          //     title: 'File is already being used',
                          //     position: 'top-right',
                          //     status: 'error',
                          //     isClosable: true,
                          //   });
                          // } else {
                          //   setStepThirteenBankStatementTwo(e.target.files[0]);
                          // }
                          setStepThirteenBankStatementTwo(e.target.files[0]);
                        }
                      }}
                    />
                    {stepThirteenBankStatementTwo == '' ? (
                      <Flex align="center">
                        <Button as="label" for="stepThirteenBankStatementTwo">
                          <Text>Select a File</Text>
                        </Button>
                      </Flex>
                    ) : (
                      <Flex align="center">
                        <Button
                          as="label"
                          for="stepThirteenBankStatementTwo"
                          mr="2"
                        >
                          <Text textOverflow="ellipsis">
                            {stepThirteenBankStatementTwo.name}
                          </Text>
                        </Button>
                        <IconButton
                          colorScheme="red"
                          aria-label="delete file"
                          icon={<Icon as={FiTrash2} />}
                          onClick={() => {
                            stepThirteenBankStatementTwoRef.current.value =
                              null;
                            setStepThirteenBankStatementTwo('');
                          }}
                        />
                      </Flex>
                    )}
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="600" color="gray" mb="1">
                      Your recent bank statement 3:
                    </Text>
                    <input
                      type="file"
                      ref={stepThirteenBankStatementThreeRef}
                      id="stepThirteenBankStatementThree"
                      name="stepThirteenBankStatementThree"
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/*,.heic"
                      style={{ display: 'none' }}
                      onChange={e => {
                        if (e.target.files[0].size > 5242880) {
                          setAlertDialog('File is too big! Must be under 5mb');
                          onOpen();
                        } else if (e.target.files[0].size < 5000) {
                          setAlertDialog(
                            'This file quality is not that great; Please attach a better resolution file.'
                          );
                          onOpen();
                        } else {
                          const fileName = e.target.files[0].name;
                          // if (
                          //   fileName === stepTwelveIncomeDocOne.name ||
                          //   fileName === stepTwelveIncomeDocTwo.name ||
                          //   fileName === stepTwelveIncomeDocThree.name ||
                          //   fileName === stepThirteenBankStatementOne.name ||
                          //   fileName === stepThirteenBankStatementTwo.name ||
                          //   fileName === stepSixteenIdentityDoc.name
                          // ) {
                          //   toast({
                          //     title: 'File is already being used',
                          //     position: 'top-right',
                          //     status: 'error',
                          //     isClosable: true,
                          //   });
                          // } else {
                          //   setStepThirteenBankStatementThree(
                          //     e.target.files[0]
                          //   );
                          // }
                          setStepThirteenBankStatementThree(e.target.files[0]);
                        }
                      }}
                    />
                    {stepThirteenBankStatementThree == '' ? (
                      <Flex align="center">
                        <Button as="label" for="stepThirteenBankStatementThree">
                          <Text>Select a File</Text>
                        </Button>
                      </Flex>
                    ) : (
                      <Flex align="center">
                        <Button
                          as="label"
                          for="stepThirteenBankStatementThree"
                          mr="2"
                        >
                          <Text textOverflow="ellipsis">
                            {stepThirteenBankStatementThree.name}
                          </Text>
                        </Button>
                        <IconButton
                          colorScheme="red"
                          aria-label="delete file"
                          icon={<Icon as={FiTrash2} />}
                          onClick={() => {
                            stepThirteenBankStatementThreeRef.current.value =
                              null;
                            setStepThirteenBankStatementThree('');
                          }}
                        />
                      </Flex>
                    )}
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(12);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={async () => {
              handleNext();
            }}
            isDisabled={
              isEmptyString(stepThirteenBankStatementOne) ||
              stepThirteenBankStatementOne.size > 5242880
            }
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Oops!
            </AlertDialogHeader>

            <AlertDialogBody>{alertDialog}</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="gray" onClick={onClose} ml={3}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Fragment>
  );
};

export default HomebuyerStepThirteen;
