import { Field, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { homebuyerSignUpSchema } from '../validations/HomebuyerSignUpSchema';

// utils
import SEO from '../utils/SEO';

// chakra
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Link,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Center,
  Card,
  CardBody,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useAuth } from '../context/AuthContext';
import { USER_TYPES } from '../utils/constants';

const HomebuyerSignUp = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { getUserByDocId } = useAuth();

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [isDesktopScreen, setIsDesktopScreen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [referral, setReferral] = useState();
  const [agentDetails, setAgentDetails] = useState({
    name: '',
    id: null,
  });

  /**
   * Checking if the user has opened url from signup
   */
  const location = useLocation();
  const { agent } = useParams();
  let searchParams = new URLSearchParams(location.search);
  let email = searchParams.get('email');
  let isInvited = searchParams.get('invited');
  let listingInterest = searchParams.get('listingInterest');

  const {
    handleGoogleSignUp,
    handleSignUp,
    authLoading,
    authUser,
    setAuthLoading,
    checkIfUserInvited,
    getReferralByCode,
  } = useAuth();

  const handleDataSubmission = async values => {
    try {
      setButtonLoading(true);
      let hasDetails = false;
      if (referral) {
        const agent = await getUserByDocId(referral?.userId);

        const name =
          agent?.legalName?.firstName + ' ' + agent?.legalName?.lastName;
        setAgentDetails({
          name,
          id: referral?.userId,
        });

        hasDetails = true;
      } else {
        const inv = await checkIfUserInvited(values.email?.toLowerCase());
        if (inv) {
          const name =
            inv?.agent?.legalName?.firstName +
            ' ' +
            inv?.agent?.legalName?.lastName;

          setAgentDetails({
            name,
            id: inv?.invite?.agent,
          });

          hasDetails = true;
        }
      }

      if (hasDetails) {
        setTimeout(() => {
          setButtonLoading(false);
          onOpen();
        }, 500);
      } else {
        setButtonLoading(false);
        handleSubmit(values, null);
      }
    } catch (err) {
      setButtonLoading(false);

      toast({
        title: 'Error on Sign Up',
        description: err,
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleSubmit = async (values, agent) => {
    let agentId = agent;

    // if (referral) {
    //   agentId = referral?.userId;
    // }

    const { error } = await handleSignUp(
      values.email,
      values.password,
      USER_TYPES.HOME_OWNER,
      undefined,
      undefined,
      agentId,
      Boolean(referral),
      listingInterest ? listingInterest : null
    );

    if (error) {
      console.log(error);
      toast({
        title: 'Error on Sign Up',
        description: error,
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const googleSignup = async () => {
    const { error } = await handleGoogleSignUp();
    if (error) {
      toast({
        title: 'Error on Google Log In',
        description: error,
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const getAndSaveAgentInfo = async () => {
    try {
      const response = await getReferralByCode(agent);
      setReferral(response);
    } catch (err) {
      toast({
        title: 'Failed',
        description: 'Invalid Referral Code',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    if (agent) {
      getAndSaveAgentInfo();
    }

    const fetchData = async () => {
      setAuthLoading(true);
      if (authUser) {
        navigate('/');
      }
      setAuthLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    setIsDesktopScreen(isDesktop);
  }, [isDesktop]);

  return (
    <Fragment>
      <SEO
        title={`Utopia Homes | Homebuyer Sign Up`}
        description={`Homebuyer sign up page for Utopia Homes. Create an account to start your homebuyer application.`}
        name={`Utopia Homes`}
        type={`application page`}
      />

      <Grid h="100vh" templateColumns="repeat(6, 1fr)">
        <GridItem colSpan={isDesktopScreen ? 3 : 6}>
          <Container maxW="container.sm" py="8">
            <Link href="https://www.utopiahomes.us/">
              <Image
                objectFit="cover"
                w="150px"
                src="https://i.imgur.com/avkbaJm.png"
                alt="Utopia Logo"
              />
            </Link>

            <Container maxW="md" py="8">
              <Formik
                initialValues={{
                  email: isInvited ? email : '',
                  password: '',
                  confirmPassword: '',
                }}
                validationSchema={homebuyerSignUpSchema}
                onSubmit={async values => {
                  // handleSubmit(values);
                  handleDataSubmission(values);
                }}
              >
                {formik => (
                  <form onSubmit={formik.handleSubmit}>
                    <Stack spacing="7">
                      <Box>
                        <Heading mb="2">Sign up</Heading>
                        <Text fontSize="md" as="b" color="gray.600">
                          Create an account to start your application
                        </Text>
                      </Box>
                      <Stack spacing="7">
                        <Stack spacing="3">
                          <FormControl
                            isInvalid={
                              formik.errors.email && formik.touched.email
                            }
                          >
                            <FormLabel color="gray">Email</FormLabel>
                            <Field
                              as={Input}
                              borderWidth="2px"
                              size="lg"
                              id="email"
                              name="email"
                              type="email"
                              disabled={authLoading}
                            />
                            <FormErrorMessage>
                              {formik.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={
                              formik.errors.password && formik.touched.password
                            }
                          >
                            <FormLabel color="gray">Password</FormLabel>
                            <Field
                              as={Input}
                              borderWidth="2px"
                              size="lg"
                              id="password"
                              name="password"
                              type="password"
                              disabled={authLoading}
                            />
                            <FormErrorMessage>
                              {formik.errors.password}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={
                              formik.errors.confirmPassword &&
                              formik.touched.confirmPassword
                            }
                          >
                            <FormLabel color="gray">Confirm Password</FormLabel>
                            <Field
                              as={Input}
                              borderWidth="2px"
                              size="lg"
                              id="confirmPassword"
                              name="confirmPassword"
                              type="password"
                              disabled={false}
                            />
                            <FormErrorMessage>
                              {formik.errors.confirmPassword}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>
                        <Button size="lg" type="submit">
                          {buttonLoading ? <Spinner /> : 'Sign Up'}
                        </Button>
                      </Stack>
                      {/* <Text fontSize="md" as="b" color="gray.600">
                        Or sign up with your social account
                      </Text>
                      <Stack spacing="4">
                        <Button
                          size="lg"
                          colorScheme="red"
                          onClick={googleSignup}
                        >
                          Sign Up with Google
                        </Button>
                      </Stack> */}
                      <Flex align="center" justify="center">
                        <Text fontSize="md" as="b" mr="3">
                          Already have an account?
                        </Text>
                        <Link
                          fontSize="md"
                          as="b"
                          color="blue.500"
                          onClick={() => {
                            navigate('/login');
                          }}
                        >
                          Log In
                        </Link>
                      </Flex>

                      <AlertDialog
                        isOpen={isOpen}
                        // leastDestructiveRef={cancelRef}
                        onClose={onClose}
                      >
                        <AlertDialogOverlay>
                          <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                              Agent Selection
                            </AlertDialogHeader>

                            <AlertDialogBody>
                              {agentDetails?.name ?? ''} has invited you. Do you
                              want to add this agent as your agent of choice?
                            </AlertDialogBody>

                            <AlertDialogFooter>
                              <Button
                                onClick={() => {
                                  handleSubmit(formik.values, null);
                                }}
                              >
                                No, proceed without adding
                              </Button>
                              <Button
                                colorScheme="red"
                                onClick={() => {
                                  handleSubmit(formik.values, agentDetails?.id);
                                  onClose();
                                }}
                                ml={3}
                              >
                                Add
                              </Button>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialogOverlay>
                      </AlertDialog>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Container>
          </Container>
        </GridItem>
        {isDesktopScreen && (
          <GridItem colSpan={3}>
            <Flex
              w="100%"
              h="100vh"
              backgroundImage={`url("https://firebasestorage.googleapis.com/v0/b/utopia-prod-8c73d.appspot.com/o/website_images%2FYyj6aJH%20(1).jpg?alt=media&token=88516642-7d60-43f3-8b51-d80648db2162")`}
              backgroundPosition="center"
              backgroundSize="cover"
            >
              <Center w="100%">
                <Card
                  color="white"
                  backgroundColor="rgba(61, 61, 61, 0.3)"
                  backdropFilter="blur(3px)"
                  rounded="xl"
                >
                  <CardBody>
                    <Heading size="xl" mb="7">
                      To qualify, you need:
                    </Heading>
                    <Stack spacing="4">
                      <Flex align="center">
                        <CheckCircleIcon boxSize={5} mr="3" color="green.500" />
                        <Text fontWeight="600" fontSize="xl">
                          Minimum FICO score of 550
                        </Text>
                      </Flex>
                      <Flex align="center">
                        <CheckCircleIcon boxSize={5} mr="3" color="green.500" />
                        <Text fontWeight="600" fontSize="xl">
                          $3,500 Minimum Monthly Household Income
                        </Text>
                      </Flex>
                      <Flex align="center">
                        <CheckCircleIcon boxSize={5} mr="3" color="green.500" />
                        <Text fontWeight="600" fontSize="xl">
                          Less than 50% Debt to Income Ratio (including Rent)
                        </Text>
                      </Flex>
                      <Flex align="center">
                        <CheckCircleIcon boxSize={5} mr="3" color="green.500" />
                        <Text fontWeight="600" fontSize="xl">
                          12 Months of timely rent payments
                        </Text>
                      </Flex>
                      <Flex align="center">
                        <CheckCircleIcon boxSize={5} mr="3" color="green.500" />
                        <Text fontWeight="600" fontSize="xl">
                          Upfront cash of 1-3% of the Home Purchase Price
                        </Text>
                      </Flex>
                    </Stack>
                  </CardBody>
                </Card>
              </Center>
            </Flex>
          </GridItem>
        )}
        {/* {isDesktopScreen && (
          <GridItem colSpan={3}>
            <Image
              src="https://firebasestorage.googleapis.com/v0/b/utopia-prod-8c73d.appspot.com/o/website_images%2F9uEAC7t%20(1).jpg?alt=media&token=b0014916-7f0d-4412-846e-5c6856c12d18"
              objectFit="cover"
              h="100vh"
            />
          </GridItem>
        )} */}
      </Grid>
    </Fragment>
  );
};

export default HomebuyerSignUp;
