import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ClientInvitationButton from '../../../components/Agents/ClientInvitation';
import ClientReminderButton from '../../../components/Agents/ClientReminder';
import { useAgentAuth } from '../../../context/AgentContext';
import { useAuth } from '../../../context/AuthContext';
import AgentDashboardSidebar from '../../Sidebar';

const AgentHome = () => {
  const { authUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isLargerThan1280] = useMediaQuery('(min-width: 1100px)');
  const [welcomeModalVisible, setWelcomeModalVisible] = useState(false);
  const location = useLocation();

  let searchParams = new URLSearchParams(location.search);
  let isWelcomed = searchParams.get('welcome');

  const {
    approvedApplications,
    preQualifiedApplications,
    startedApplications,
    invitedUsers,
    updateAllApplicationsData,
    conditionallyApprovedApplications,
    offersData,
    inactive,
    pausedApplications,
    rejectedApplications,
  } = useAgentAuth();

  const initialDataUpdate = () => {
    setLoading(true);
    updateAllApplicationsData(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    initialDataUpdate();

    if (isWelcomed) {
      setWelcomeModalVisible(true);
    }

    let intervalId;
    let prevTime = Date.now();

    const updateInterval = () => {
      const currTime = Date.now();
      const elapsedTime = currTime - prevTime;
      const interval = elapsedTime < 120000 ? 120000 - elapsedTime : 0;
      intervalId = setTimeout(() => {
        initialDataUpdate();
        prevTime = Date.now();
        updateInterval();
      }, interval);
    };

    updateInterval();

    return () => clearTimeout(intervalId);
  }, []);

  const followUpClientsLength = invitedUsers.length;

  return (
    <AgentDashboardSidebar showHeader={false}>
      <Flex
        // flexDirection={{ base: 'column', md: 'row', sm: 'column' }}
        flexDirection={isLargerThan1280 ? 'row' : 'column'}
        alignItems={{ base: 'center', md: 'flex-start' }}
      >
        <Box ml={{ base: 0, md: '12%' }} mt={10} maxW={'80vw'}>
          <Text>Welcome Back, {authUser?.data?.legalName?.firstName}!</Text>
          <Heading fontSize={'26px'}>
            You have {followUpClientsLength} client
            {followUpClientsLength > 1 ? 's' : ''} to follow up with.
          </Heading>
          <Flex mt={2} flexDirection={{ base: 'column', md: 'row' }}>
            <ClientInvitationButton onInviteSuccess={initialDataUpdate} />
            <NavLink to={'/agents/marketing'}>
              <Button
                minW={30}
                px={10}
                ml={4}
                textColor={'#1895D7'}
                fontSize="sm"
                // ml={5}
                // px={'10'}
                variant="outline"
                // onClick={onOpen}
                borderColor="gray.300"
              >
                Marketing
              </Button>
            </NavLink>
          </Flex>

          <FeedComponent
            title="Invited"
            description="Thanks for email inviting these clients to Utopia. Please help them get started with their free application (takes <5 mins)"
            placeholder="No active clients"
            data={invitedUsers}
            loading={loading}
            route="/agents/applications/invited"
          />
          <FeedComponent
            title="Application started"
            description="Your clients just signed up with Utopia. Please help them get pre-qualified to receive their home buying budget."
            placeholder="No active clients"
            data={startedApplications}
            loading={loading}
            route="/agents/applications/application_started"
          />
          <FeedComponent
            title="Prequalified"
            description="Congrats, Your clients are pre-qualified. Please help them verify their details so they can begin home shopping!"
            placeholder="No active clients"
            data={preQualifiedApplications}
            loading={loading}
            buttonTitle="E-Mail Client"
            route="/agents/applications/prequalified"
          />
          <FeedComponent
            title="More Information Required"
            description="We require additonal documents or information for the application from the client. Please help them get that to us to receive their home buying budget"
            placeholder="No clients found"
            data={offersData?.moreInfoRequired}
            loading={loading}
            buttonTitle="E-Mail Client"
            route="/agents/applications/more_info"
          />
          <FeedComponent
            title="In-Review"
            description="Underwriters are reviewing the application as we speak."
            placeholder="No clients found"
            data={offersData?.inreview}
            loading={loading}
            buttonTitle="E-Mail Client"
            route="/agents/applications/inreview"
          />
          <FeedComponent
            title="Conditionally approved"
            description="Almost there! You can now submit an offer on the clients' dream home or Utopia."
            placeholder="No active clients"
            data={conditionallyApprovedApplications}
            loading={loading}
            buttonTitle="E-Mail Client"
            route="/agents/applications/conditionally_approved"
          />
          <FeedComponent
            title="Fully approved"
            description="The client is fully approved to get their Utopia!"
            placeholder="No active clients"
            data={approvedApplications}
            loading={loading}
            buttonTitle="E-Mail Client"
            route="/agents/applications/fully_approved"
          />
          <FeedComponent
            title="Offer extended"
            description="Utopia has extended offers to the sellers and awaiting response."
            placeholder="No active clients"
            data={offersData?.offerExtended}
            loading={loading}
            buttonTitle="E-Mail Client"
            route="/agents/offers/offer_extended"
          />
          <FeedComponent
            title="Offer accepted"
            description="Congratulations, these offers have been accepted! Now it’s time for us to start the inspection negotiation process."
            placeholder="No active clients"
            data={offersData?.offerAccepted}
            loading={loading}
            buttonTitle="E-Mail Client"
            route="/agents/offers/offer_accepted"
          />
          <FeedComponent
            title="Inactive"
            description="These applications have been Inactive."
            placeholder="No active clients"
            data={inactive}
            hasReminder={false}
            loading={loading}
            buttonTitle="E-Mail Client"
            route="/agents/inactive"
          />
          <FeedComponent
            title="Paused"
            description="These applications have been Paused. For privacy reasons, we can't share the reasons. However, feel free to connect with your clients who can share that information."
            placeholder="No active clients"
            data={pausedApplications}
            hasReminder={false}
            loading={loading}
            buttonTitle="E-Mail Client"
            route="/agents/paused"
          />
          <FeedComponent
            title="Rejected"
            description="These applications have been Rejected. For privacy reasons, we can't share the reasons. However, feel free to connect with your clients who can share that information."
            placeholder="No active clients"
            data={rejectedApplications}
            hasReminder={false}
            loading={loading}
            buttonTitle="E-Mail Client"
            route="/agents/rejected"
          />
        </Box>

        <Flex
          w={'100%'}
          alignItems={{ base: 'flex-start', md: 'center' }}
          justifyContent="center"
          maxH={'100vh'}
          mt={{ base: 5, md: 0 }}
          height={'100vh'}
          position="sticky"
          top={0}
        >
          <Box
            backgroundColor={'white'}
            width={{ base: '100vw', md: '25vw' }}
            p={10}
            borderRadius={10}
          >
            <Heading>Agents, Partner with Us & Earn More</Heading>

            <Text mt={8}>
              With Utopia, as a real estate agent, you can have your customers,
              who are not yet mortgage eligible, get their dream home. You will
              earn full commission and have a higher success rate with our
              All-Cash offer.
            </Text>
          </Box>
        </Flex>
      </Flex>

      <WelcomePopup
        visible={welcomeModalVisible}
        closeAction={() => {
          setWelcomeModalVisible(false);
        }}
      />
    </AgentDashboardSidebar>
  );
};

const FeedComponent = ({
  title,
  description,
  placeholder,
  data,
  hasReminder = true,
  loading,
  buttonTitle,
  route,
}) => {
  const navigate = useNavigate();
  return (
    <Box mt={10}>
      <Flex>
        <Text size={'md'} fontWeight="semibold" fontSize="xl">
          {title}
        </Text>
        <NavLink to={route}>
          <Text size={'md'} fontSize="xl" marginLeft={1} color={'blue.400'}>
            <u>({data?.length})</u>
          </Text>
        </NavLink>
      </Flex>

      <Text mb={2}>{description}</Text>

      {data?.length > 0 ? (
        <>
          {data.slice(0, 3).map((item, index) => {
            return (
              <Card
                cursor={'pointer'}
                key={index}
                backgroundColor={'white'}
                mt={2}
              >
                <CardHeader>
                  <Flex
                    spacing="4"
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Flex
                      flex="1"
                      gap="4"
                      alignItems="center"
                      flexDirection={{
                        base: 'column',
                        md: 'row',
                      }}
                    >
                      <Avatar
                        name={item.name}
                        src={item.profileImage}
                        onClick={() => {
                          navigate(
                            `/agents/application_details?client_email=${encodeURIComponent(
                              item?.email
                            )}`
                          );
                        }}
                      />

                      <Flex
                        flexDirection="column"
                        flex={1}
                        alignItems={{
                          base: 'center',
                          md: 'flex-start',
                        }}
                        onClick={() => {
                          navigate(
                            `/agents/application_details?client_email=${encodeURIComponent(
                              item?.email
                            )}`
                          );
                        }}
                      >
                        <Heading size="sm">{item.name}</Heading>
                        <Text fontSize={'sm'}>Client Action Needed</Text>

                        <Text fontSize={'xs'} color={'gray.500'}>
                          E-Mailed: {item.inviteSent}
                        </Text>
                      </Flex>

                      <Flex flex={1}>
                        {hasReminder && (
                          <ClientReminderButton
                            data={item}
                            title={buttonTitle}
                          />
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </CardHeader>
              </Card>
            );
          })}
        </>
      ) : (
        <Box
          backgroundColor="#E8E9EA"
          minHeight={'10vh'}
          borderRadius={10}
          display="flex"
          alignItems="center"
          px={5}
        >
          {loading ? (
            <Flex flex={1} alignItems="center" justifyContent={'center'}>
              <Spinner />
            </Flex>
          ) : (
            <Heading size={'md'} color="gray.400">
              {placeholder}
            </Heading>
          )}
        </Box>
      )}

      {data?.length > 0 && (
        <NavLink to={route}>
          <Text cursor={'pointer'} colorScheme="blue" color={'blue.400'}>
            See More...
          </Text>
        </NavLink>
      )}
    </Box>
  );
};

const WelcomePopup = ({ visible = false, closeAction }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: visible,
    onClose: closeAction,
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const toast = useToast();

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={{ base: 'full', md: 'xl' }}
        zIndex={1}
      >
        <ModalOverlay />
        <ModalContent minH="50vh">
          <ModalHeader>Welcome</ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody
            display="flex"
            alignItems={'center'}
            justifyContent="center"
          >
            {/* <Container maxW="container.sm"> */}
            <Stack spacing="5">
              <Card align="center" bg="white" size="md">
                <CardHeader>
                  <Image
                    boxSize="100px"
                    objectFit="cover"
                    src="https://i.imgur.com/NwzpGQ9.png"
                    alt="Dan Abramov"
                  />
                </CardHeader>
                <CardBody align="center">
                  <Heading as="h4" size="md" mb="1">
                    Thank you for joining Utopia Agent Network.
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    Start inviting clients who are not yet mortgage ready for
                    our innovative "Rent, Earn, and Own" Program. Your clients
                    can move in to their dream home as early as 21 days and you
                    earn your full commissions.
                  </Text>
                </CardBody>
              </Card>
            </Stack>
            {/* </Container> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AgentHome;
