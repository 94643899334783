import React, { createContext, useContext, useEffect, useState } from 'react';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
  deleteDoc,
  writeBatch,
} from 'firebase/firestore';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  listAll,
  getMetadata,
  deleteObject,
  updateMetadata,
} from 'firebase/storage';
import moment from 'moment';
import { APPLICATION_STATUS, USER_TYPES } from '../utils/constants';
import app from '../utils/firebase';

const LandlordContext = createContext();

const LandlordProvider = ({ children }) => {
  const db = getFirestore(app);
  const storage = getStorage(app);

  const [applicationData, setApplicationData] = useState(null);
  const [currentApplicationForm, setCurrentApplicationForm] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);

  const [landlordProperties, setLandlordProperties] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth(app);

      onAuthStateChanged(auth, async user => {
        if (user) {
          const docRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            let data = docSnap.data();

            if (data.type == USER_TYPES.LANDLORD) {
              getUserApplication(user.uid);
            }
          } else {
          }
        } else {
        }
      });
    };

    fetchData().catch(console.error);
  }, []);

  const getUserApplication = async userId => {
    try {
      const docRef = doc(db, 'landlord_application_responses', userId);
      const docSnap = await getDoc(docRef);
      setApplicationData(docSnap.data());
      return docSnap.data();
    } catch (err) {
      console.log(
        '🚀 ~ file: AuthContext.js ~ line 327 ~ getUserApplication ~ err',
        err
      );
    }
  };

  const updateApplicationFields = async updatedField => {
    const auth = getAuth(app);
    const docRef = doc(
      db,
      'landlord_application_responses',
      auth.currentUser.uid
    );
    await setDoc(docRef, updatedField, {
      merge: true,
    });
  };

  const getLandlordApplicationById = async userId => {
    try {
      const docRef = doc(db, 'landlord_application_responses', userId);
      const docSnap = await getDoc(docRef);
      return docSnap.data();
    } catch (err) {
      console.log(
        '🚀 ~ file: AuthContext.js ~ line 327 ~ getUserApplication ~ err',
        err
      );
    }
  };

  const getLandlordProperties = async userId => {
    try {
      const auth = getAuth(app);

      const q = query(
        collection(db, 'landlord_properties'),
        where('userId', '==', userId)
      );
      const querySnapshot = await getDocs(q);
      const properties = querySnapshot.docs.map(doc => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });

      let propertiesData = [];
      for (let i = 0; i < properties.length; i++) {
        const propertyDataId = properties[i].id;

        const propertyImagesRef = ref(
          storage,
          `landlord_property_files/${auth.currentUser.uid}/${propertyDataId}/property_images`
        );

        const docsList = await listAll(propertyImagesRef);

        const propertyImages = [];
        for (const itemRef of docsList.items) {
          const fileMetadata = await getMetadata(itemRef);
          const fileUrl = await getDownloadURL(itemRef);

          propertyImages.push({
            imageUrl: fileUrl,
            imageDescription: fileMetadata?.customMetadata?.propertyDescription,
          });
        }

        const propertyData = properties[i].data;

        propertiesData.push({
          id: propertyDataId,
          data: propertyData,
          images: propertyImages,
        });
      }

      setLandlordProperties(propertiesData);
      return propertiesData;
    } catch (err) {
      console.log('Error in getLandlordProperties');
      console.log(err);
    }
  };

  const addProperty = async (
    userId,
    stepOnePropertyType,
    stepOneNumberOfBedrooms,
    stepOneNumberOfBathrooms,
    stepOneMonthlyRent,
    stepOneSquareFeet,
    stepOneYearBuilt,
    stepOneLotSize,
    stepTwoState,
    stepTwoMetro,
    stepTwoCity,
    stepTwoPropertyAddress,
    stepThreeValueOfProperty,
    stepThreeReasonForValue,
    stepFourPropertyDescription,
    stepFiveSalesCma,
    stepFiveAppraisalReport,
    stepSixPropertyImages,
    stepSevenLandlordFirstName = '',
    stepSevenLandlordLastName = '',
    stepSevenLandlordEmail = '',
    stepSevenLandlordPhone = ''
  ) => {
    console.log('Running addProperty');
    try {
      const auth = getAuth(app);

      const docRef = await addDoc(collection(db, 'landlord_properties'), {
        userId: userId,
        status: 'IN_REVIEW',
        timeSubmitted: Timestamp.now(),
        stepOne: [
          {
            question: 'Property Type',
            answer: stepOnePropertyType,
          },
          {
            question: 'Number of Bedrooms',
            answer: stepOneNumberOfBedrooms,
          },
          {
            question: 'Number of Bathrooms',
            answer: stepOneNumberOfBathrooms,
          },
          {
            question: 'Monthly Rent',
            answer: stepOneMonthlyRent,
          },
          {
            question: 'Square Feet',
            answer: stepOneSquareFeet,
          },
          {
            question: 'Year Built',
            answer: stepOneYearBuilt,
          },
          {
            question: 'Lot Size',
            answer: stepOneLotSize,
          },
        ],
        stepTwo: [
          {
            question: 'Property State',
            answer: stepTwoState,
          },
          {
            question: 'Property Metro',
            answer: stepTwoMetro,
          },
          {
            question: 'Property City',
            answer: stepTwoCity,
          },
          {
            question: 'Property Address or Zillow/Redfin Link',
            answer: stepTwoPropertyAddress,
          },
        ],
        stepThree: [
          {
            question: 'Value of Property',
            answer: stepThreeValueOfProperty,
          },
          {
            question: 'Reason for Value',
            answer: stepThreeReasonForValue,
          },
        ],
        stepFour: [
          {
            question: 'Property Description',
            answer: stepFourPropertyDescription,
          },
        ],
        stepSeven: [
          {
            question: 'Landlord First Name',
            answer: stepSevenLandlordFirstName,
          },
          {
            question: 'Landlord Last Name',
            answer: stepSevenLandlordLastName,
          },
          {
            question: 'Landlord Email',
            answer: stepSevenLandlordEmail,
          },
          {
            question: 'Landlord Phone Number',
            answer: stepSevenLandlordPhone,
          },
        ],
      });
      console.log('Document written with ID: ', docRef.id);

      for (let i = 0; i < stepFiveSalesCma.length; i++) {
        const salesCmaDocRef = ref(
          storage,
          `landlord_property_files/${auth.currentUser.uid}/${docRef.id}/sales_cma_docs/${i}`
        );

        try {
          await uploadBytes(salesCmaDocRef, stepFiveSalesCma[i]);
          console.log(`Sales CMA Doc ${i + 1} Uploaded`);
        } catch (err) {
          console.log(`Error in adding Sales CMA Doc ${i + 1}: ${err}`);
        }
      }

      for (let i = 0; i < stepFiveAppraisalReport.length; i++) {
        const appraisalReportDocRef = ref(
          storage,
          `landlord_property_files/${auth.currentUser.uid}/${docRef.id}/appraisal_report_docs/${i}`
        );

        try {
          await uploadBytes(appraisalReportDocRef, stepFiveAppraisalReport[i]);
          console.log(`Appraisal Report Doc ${i + 1} Uploaded`);
        } catch (err) {
          console.log(`Error in adding Appraisal Report Doc ${i + 1}: ${err}`);
        }
      }

      for (let i = 0; i < stepSixPropertyImages.length; i++) {
        const propertyImageRef = ref(
          storage,
          `landlord_property_files/${auth.currentUser.uid}/${docRef.id}/property_images/${i}`
        );

        try {
          await uploadBytes(propertyImageRef, stepSixPropertyImages[i], {
            customMetadata: {
              propertyDescription: stepSixPropertyImages[i]?.description
                ? stepSixPropertyImages[i]?.description
                : '',
            },
          });
          console.log(`Property Image ${i + 1} Uploaded`);
        } catch (err) {
          console.log(`Error in adding Property Image ${i + 1}: ${err}`);
        }
      }
    } catch (err) {
      console.log('Error in addProperty');
      console.log(err);
    }
  };

  const getAcceptedProperties_Truncated = async () => {
    try {
      console.log('Running getAcceptedProperties_Truncated()');

      const q = query(
        collection(db, 'landlord_properties'),
        where('status', '==', 'ACCEPTED')
      );
      const querySnapshot = await getDocs(q);
      const properties = querySnapshot.docs.map(doc => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });

      let propertiesData = [];
      for (let i = 0; i < properties.length; i++) {
        const propertyDataId = properties[i].id;
        const userId = properties[i].data.userId;

        const propertyImagesRef = ref(
          storage,
          `landlord_property_files/${userId}/${propertyDataId}/property_images`
        );

        const docsList = await listAll(propertyImagesRef);

        const propertyImages = [];
        if (docsList.items.length > 0) {
          const firstItemRef = docsList.items[0];
          const fileMetadata = await getMetadata(firstItemRef);
          const fileUrl = await getDownloadURL(firstItemRef);

          propertyImages.push({
            imageUrl: fileUrl,
            imageDescription: fileMetadata?.customMetadata?.propertyDescription,
          });
        }

        const propertyData = properties[i].data;

        propertiesData.push({
          id: propertyDataId,
          data: propertyData,
          images: propertyImages,
        });
      }

      return propertiesData;
    } catch (err) {
      console.log('Error in getAcceptedProperties_Truncated');
      console.log(err);

      return [];
    }
  };

  const getAcceptedProperties_Full = async () => {
    try {
      console.log('Running getAcceptedProperties_Full()');

      const q = query(
        collection(db, 'landlord_properties'),
        where('status', '==', 'ACCEPTED')
      );
      const querySnapshot = await getDocs(q);
      const properties = querySnapshot.docs.map(doc => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });

      let propertiesData = [];
      for (let i = 0; i < properties.length; i++) {
        const propertyDataId = properties[i].id;
        const userId = properties[i].data.userId;

        const propertyImagesRef = ref(
          storage,
          `landlord_property_files/${userId}/${propertyDataId}/property_images`
        );

        const docsList = await listAll(propertyImagesRef);

        const propertyImages = [];
        for (const itemRef of docsList.items) {
          const fileMetadata = await getMetadata(itemRef);
          const fileUrl = await getDownloadURL(itemRef);

          propertyImages.push({
            imageUrl: fileUrl,
            imageDescription: fileMetadata?.customMetadata?.propertyDescription,
          });
        }

        const propertyData = properties[i].data;

        propertiesData.push({
          id: propertyDataId,
          data: propertyData,
          images: propertyImages,
        });
      }

      return propertiesData;
    } catch (err) {
      console.log('Error in getAcceptedProperties_Full');
      console.log(err);

      return [];
    }
  };

  const getPropertyById_Truncated = async id => {
    try {
      const docRef = doc(db, 'landlord_properties', id);
      const docSnap = await getDoc(docRef);

      const propertyId = docSnap.id;
      const propertyData = docSnap.data();

      const propertyImagesRef = ref(
        storage,
        `landlord_property_files/${propertyData.userId}/${propertyId}/property_images`
      );

      const docsList = await listAll(propertyImagesRef);

      const propertyImages = [];
      // for (const itemRef of docsList.items) {
      //   const fileMetadata = await getMetadata(itemRef);
      //   const fileUrl = await getDownloadURL(itemRef);

      //   propertyImages.push({
      //     imageUrl: fileUrl,
      //     imageDescription: fileMetadata?.customMetadata?.propertyDescription,
      //   });
      // }
      if (docsList.items.length > 0) {
        const firstItemRef = docsList.items[0];
        const fileMetadata = await getMetadata(firstItemRef);
        const fileUrl = await getDownloadURL(firstItemRef);

        propertyImages.push({
          imageUrl: fileUrl,
          imageDescription: fileMetadata?.customMetadata?.propertyDescription,
        });
      }

      return {
        id: propertyId,
        data: propertyData,
        images: propertyImages,
      };
    } catch (err) {
      console.log('Error in getPropertyById_Truncated');
      console.log(err);
      return null;
    }
  };

  const getPropertyById_Full = async (id, internal = false) => {
    try {
      const docRef = doc(db, 'landlord_properties', id);
      const docSnap = await getDoc(docRef);

      const propertyId = docSnap.id;
      const propertyData = docSnap.data();

      const propertyImagesRef = ref(
        storage,
        `landlord_property_files/${propertyData.userId}/${propertyId}/property_images`
      );

      const docsList = await listAll(propertyImagesRef);

      let propertyImages = [];
      for (const itemRef of docsList.items) {
        const fileMetadata = await getMetadata(itemRef);
        const fileUrl = await getDownloadURL(itemRef);

        if (internal) {
          propertyImages.push({
            fileUrl: fileUrl,
            fileMetadata: fileMetadata,
          });
        } else {
          propertyImages.push({
            imageUrl: fileUrl,
            imageDescription: fileMetadata?.customMetadata?.propertyDescription,
          });
        }
      }

      if (internal === false) {
        return {
          id: propertyId,
          data: propertyData,
          images: propertyImages,
        };
      } else {
        const auth = getAuth(app);

        const propertySalesCmaRef = ref(
          storage,
          `landlord_property_files/${auth.currentUser.uid}/${propertyId}/sales_cma_docs`
        );

        const SalesCmaList = await listAll(propertySalesCmaRef);
        let salesCmaDocs = [];
        for (const itemRef of SalesCmaList.items) {
          const fileMetadata = await getMetadata(itemRef);
          const fileUrl = await getDownloadURL(itemRef);

          salesCmaDocs.push({
            fileUrl: fileUrl,
            fileMetadata: fileMetadata,
          });
        }

        const propertyAppraisalReportRef = ref(
          storage,
          `landlord_property_files/${auth.currentUser.uid}/${propertyId}/appraisal_report_docs`
        );

        const AppraisalReportList = await listAll(propertyAppraisalReportRef);
        let appraisalReportDocs = [];
        for (const itemRef of AppraisalReportList.items) {
          const fileMetadata = await getMetadata(itemRef);
          const fileUrl = await getDownloadURL(itemRef);

          appraisalReportDocs.push({
            fileUrl: fileUrl,
            fileMetadata: fileMetadata,
          });
        }

        let fullPropertyImages = [];
        for (let i = 0; i < propertyImages.length; i++) {
          fullPropertyImages.push({
            fileLocation: 'cloud',
            data: propertyImages[i],
          });
        }

        let fullSalesCmaDocs = [];
        for (let i = 0; i < salesCmaDocs.length; i++) {
          fullSalesCmaDocs.push({
            fileLocation: 'cloud',
            data: salesCmaDocs[i],
          });
        }

        let fullAppraisalReportDocs = [];
        for (let i = 0; i < appraisalReportDocs.length; i++) {
          fullAppraisalReportDocs.push({
            fileLocation: 'cloud',
            data: appraisalReportDocs[i],
          });
        }

        return {
          id: propertyId,
          data: propertyData,
          file: {
            propertyImages: fullPropertyImages,
            salesCmaDocs: fullSalesCmaDocs,
            appraisalReportDocs: fullAppraisalReportDocs,
          },
        };
      }
    } catch (err) {
      console.log('Error in getPropertyById_Full');
      console.log(err);
      return null;
    }
  };

  const createPropertyApplication = async () => {
    try {
      const auth = getAuth(app);
      const userId = auth.currentUser.uid;

      const docRef = await addDoc(collection(db, 'landlord_properties'), {
        userId: userId,
        status: 'INCOMPLETE',
        timeStarted: Timestamp.now(),
        applicationCompleted: false,
        timeSubmitted: null,
        currentStep: 1,
        stepOne: [
          {
            question: 'Property Type',
            answer: null,
          },
          {
            question: 'Number of Bedrooms',
            answer: null,
          },
          {
            question: 'Number of Bathrooms',
            answer: null,
          },
          {
            question: 'Monthly Rent',
            answer: null,
          },
          {
            question: 'Square Feet',
            answer: null,
          },
          {
            question: 'Year Built',
            answer: null,
          },
          {
            question: 'Lot Size',
            answer: null,
          },
        ],
        stepTwo: [
          {
            question: 'Property State',
            answer: null,
          },
          {
            question: 'Property Metro',
            answer: null,
          },
          {
            question: 'Property City',
            answer: null,
          },
          {
            question: 'Property Address or Zillow/Redfin Link',
            answer: null,
          },
        ],
        stepThree: [
          {
            question: 'Value of Property',
            answer: null,
          },
          {
            question: 'Reason for Value',
            answer: null,
          },
        ],
        stepFour: [
          {
            question: 'Property Description',
            answer: null,
          },
        ],
        stepSeven: [
          {
            question: 'Landlord First Name',
            answer: null,
          },
          {
            question: 'Landlord Last Name',
            answer: null,
          },
          {
            question: 'Landlord Email',
            answer: null,
          },
          {
            question: 'Landlord Phone Number',
            answer: null,
          },
        ],
      });
      console.log('New Property Application Created with ID: ', docRef.id);

      return docRef.id;
    } catch (err) {
      console.log('Error in createPropertyApplication');
      console.log(err);
      return null;
    }
  };

  const setPropertyApplication = async applicationId => {
    try {
      const fullApplication = await getPropertyById_Full(applicationId, true);

      const newApplicationForm = {
        ...fullApplication,
      };

      if (fullApplication?.data) {
        setCurrentApplicationForm(newApplicationForm);

        return {
          success: true,
          message: 'Property Application Found',
        };
      } else {
        setCurrentApplicationForm(null);
        return {
          success: false,
          message: 'Property Application Not Found',
        };
      }
    } catch (err) {
      console.log('Error in getPropertyApplication');
      console.log(err);

      setCurrentApplicationForm(null);
      return {
        success: false,
        message: 'Error in getPropertyApplication',
      };
    }
  };

  const updatePropertyApplication = async (applicationId, updatedFields) => {
    try {
      const docRef = doc(db, 'landlord_properties', applicationId);
      await updateDoc(docRef, updatedFields);

      const fullApplication = await getPropertyById_Full(applicationId, true);

      const updatedApplication = {
        ...fullApplication,
      };

      setCurrentApplicationForm(updatedApplication);
    } catch (err) {
      console.log('Error in updatePropertyApplication');
      console.log(err);
      return null;
    }
  };

  const updatePropertyApplicationAndFiles = async (
    applicationId,
    updatedFields,
    updatedFileStep,
    updatedFiles
  ) => {
    try {
      const auth = getAuth(app);
      const userId = auth.currentUser.uid;

      const docRef = doc(db, 'landlord_properties', applicationId);
      await updateDoc(docRef, updatedFields);

      if (updatedFileStep === 'five') {
        await updateFileStep(
          'sales_cma_docs',
          updatedFiles.stepFiveSalesCma,
          userId,
          applicationId
        );
        await updateFileStep(
          'appraisal_report_docs',
          updatedFiles.stepFiveAppraisalReport,
          userId,
          applicationId
        );
      } else if (updatedFileStep === 'six') {
        await updateFileStep(
          'property_images',
          updatedFiles.stepSixPropertyImages,
          userId,
          applicationId
        );
      }

      const fullApplication = await getPropertyById_Full(applicationId, true);
      setCurrentApplicationForm(fullApplication);
    } catch (err) {
      console.log('Error in updatePropertyApplicationAndFiles', err);
      return null;
    }
  };

  const updateFileStep = async (folderName, files, userId, applicationId) => {
    const cloudFiles = files.filter(file => file.fileLocation === 'cloud');
    const localFiles = files.filter(file => file.fileLocation === 'local');

    const folderRef = ref(
      storage,
      `landlord_property_files/${userId}/${applicationId}/${folderName}`
    );
    const filesList = await listAll(folderRef);

    await deleteRemovedFiles(filesList, cloudFiles, folderName);
    await updateExistingFiles(filesList, cloudFiles, folderName);
    await uploadNewFiles(localFiles, cloudFiles, folderRef, folderName);
  };

  const deleteRemovedFiles = async (filesList, cloudFiles, folderName) => {
    for (const item of filesList.items) {
      const itemName = item.name;
      const isInUserFiles = cloudFiles.some(
        file => file.data.fileMetadata.name === itemName
      );
      if (!isInUserFiles) {
        try {
          await deleteObject(item);
          console.log(`Deleted ${folderName} file: ${itemName}`);
        } catch (error) {
          console.error(
            `Error deleting ${folderName} file ${itemName}:`,
            error
          );
        }
      }
    }
  };

  const updateExistingFiles = async (filesList, cloudFiles, folderName) => {
    for (const item of filesList.items) {
      const itemName = item.name;
      const matchingFileIndex = cloudFiles.findIndex(
        file => file.data.fileMetadata.name === itemName
      );
      if (matchingFileIndex !== -1) {
        const oldMetadata = await getMetadata(item);
        const newMetadata = {
          customMetadata: {
            order: String(matchingFileIndex),
          },
        };

        if (folderName === 'property_images') {
          newMetadata.customMetadata.propertyDescription =
            cloudFiles[matchingFileIndex].data.fileMetadata.customMetadata
              .propertyDescription || '';
        }

        if (shouldUpdateMetadata(oldMetadata, newMetadata)) {
          try {
            await updateMetadata(item, newMetadata);
            console.log(`Updated metadata for ${folderName} file: ${itemName}`);
          } catch (error) {
            console.error(
              `Error updating metadata for ${folderName} file ${itemName}:`,
              error
            );
          }
        }
      }
    }
  };

  const shouldUpdateMetadata = (oldMetadata, newMetadata) => {
    return (
      oldMetadata.customMetadata?.order !== newMetadata.customMetadata.order ||
      (newMetadata.customMetadata.propertyDescription !== undefined &&
        oldMetadata.customMetadata?.propertyDescription !==
          newMetadata.customMetadata.propertyDescription)
    );
  };

  const uploadNewFiles = async (
    localFiles,
    cloudFiles,
    folderRef,
    folderName
  ) => {
    for (let i = 0; i < localFiles.length; i++) {
      let file = localFiles[i];
      let fileRef = ref(folderRef, file.data.name);
      try {
        const metadata = {
          customMetadata: {
            order: String(cloudFiles.length + i),
          },
        };
        if (folderName === 'property_images') {
          metadata.customMetadata.propertyDescription =
            file.data.description || '';
        }
        await uploadBytes(fileRef, file.data, metadata);
        console.log(`${folderName} file ${i + 1} Uploaded`);
      } catch (err) {
        console.log(`Error in adding ${folderName} file ${i + 1}:`, err);
      }
    }
  };

  const deletePropertyApplication = async applicationId => {
    try {
      const docRef = doc(db, 'landlord_properties', applicationId);
      await deleteDoc(docRef);

      // delete all files in storage
      const auth = getAuth(app);
      const userId = auth.currentUser.uid;

      const propertyImagesRef = ref(
        storage,
        `landlord_property_files/${userId}/${applicationId}`
      );

      const docsList = await listAll(propertyImagesRef);
      for (const itemRef of docsList.items) {
        await deleteObject(itemRef);
      }

      setCurrentApplicationForm(null);
      return {
        success: true,
        message: 'Property Application Deleted',
      };
    } catch (err) {
      console.log('Error in deletePropertyApplication');
      console.log(err);
      return null;
    }
  };

  const value = {
    updateApplicationFields,
    applicationData,
    landlordProperties,
    getLandlordProperties,
    addProperty,
    getAcceptedProperties_Truncated,
    getAcceptedProperties_Full,
    getPropertyById_Truncated,
    getPropertyById_Full,
    currentApplicationForm,
    setCurrentApplicationForm,
    createPropertyApplication,
    setPropertyApplication,
    updatePropertyApplication,
    deletePropertyApplication,
    updatePropertyApplicationAndFiles,
    getLandlordApplicationById,
  };

  return (
    <LandlordContext.Provider value={value}>
      {children}
    </LandlordContext.Provider>
  );
};

const useLandlord = () => {
  return useContext(LandlordContext);
};

export { LandlordProvider, useLandlord };
