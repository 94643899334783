import React, { useState, useEffect, Fragment } from 'react';
import { Timestamp } from 'firebase/firestore';
import axios from 'axios';

// context
import { useLandlord } from '../../../../context/LandlordContext';

// components
import AddPropertyStepOne from './AddPropertyStepOne';
import AddPropertyStepTwo from './AddPropertyStepTwo';
import AddPropertyStepThree from './AddPropertyStepThree';
import AddPropertyStepFour from './AddPropertyStepFour';
import AddPropertyStepFive from './AddPropertyStepFive';
import AddPropertyStepSix from './AddPropertyStepSix';

// chakra
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  Progress,
} from '@chakra-ui/react';

const AddPropertyApplication = ({ currentApplication }) => {
  const {
    addProperty,
    updatePropertyApplication,
    updatePropertyApplicationAndFiles,
  } = useLandlord();

  const [isLoading, setIsLoading] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);

  const [currentStep, setCurrentStep] = useState(
    currentApplication?.data?.currentStep
      ? currentApplication?.data?.currentStep
      : 1
  );

  // form answers
  const [stepOnePropertyType, setStepOnePropertyType] = useState(null);
  const [stepOneNumberOfBedrooms, setStepOneNumberOfBedrooms] = useState(null);
  const [stepOneNumberOfBathrooms, setStepOneNumberOfBathrooms] =
    useState(null);
  const [stepOneMonthlyRent, setStepOneMonthlyRent] = useState(null);
  const [stepOneSquareFeet, setStepOneSquareFeet] = useState(null);
  const [stepOneYearBuilt, setStepOneYearBuilt] = useState(null);
  const [stepOneLotSize, setStepOneLotSize] = useState(null);

  const [stepTwoState, setStepTwoState] = useState(null);
  const [stepTwoMetro, setStepTwoMetro] = useState(null);
  const [stepTwoCity, setStepTwoCity] = useState(null);
  const [stepTwoPropertyAddress, setStepTwoPropertyAddress] = useState(null);

  const [stepThreeValueOfProperty, setStepThreeValueOfProperty] =
    useState(null);
  const [stepThreeReasonForValue, setStepThreeReasonForValue] = useState(null);

  const [stepFourPropertyDescription, setStepFourPropertyDescription] =
    useState(null);

  const [stepFiveSalesCma, setStepFiveSalesCma] = useState([]);
  const [stepFiveAppraisalReport, setStepFiveAppraisalReport] = useState([]);

  const [stepSixPropertyImages, setStepSixPropertyImages] = useState([]);

  useEffect(() => {
    setCurrentStep(
      currentApplication?.data?.currentStep
        ? currentApplication?.data?.currentStep
        : 1
    );

    setStepOnePropertyType(currentApplication?.data?.stepOne[0]?.answer);
    setStepOneNumberOfBedrooms(currentApplication?.data?.stepOne[1]?.answer);
    setStepOneNumberOfBathrooms(currentApplication?.data?.stepOne[2]?.answer);
    setStepOneMonthlyRent(currentApplication?.data?.stepOne[3]?.answer);
    setStepOneSquareFeet(currentApplication?.data?.stepOne[4]?.answer);
    setStepOneYearBuilt(currentApplication?.data?.stepOne[5]?.answer);
    setStepOneLotSize(currentApplication?.data?.stepOne[6]?.answer);

    setStepTwoState(currentApplication?.data?.stepTwo[0]?.answer);
    setStepTwoMetro(currentApplication?.data?.stepTwo[1]?.answer);
    setStepTwoCity(currentApplication?.data?.stepTwo[2]?.answer);
    setStepTwoPropertyAddress(currentApplication?.data?.stepTwo[3]?.answer);

    setStepThreeValueOfProperty(currentApplication?.data?.stepThree[0]?.answer);
    setStepThreeReasonForValue(currentApplication?.data?.stepThree[1]?.answer);

    setStepFourPropertyDescription(
      currentApplication?.data?.stepFour[0]?.answer
    );

    setStepFiveSalesCma(currentApplication?.file?.salesCmaDocs);
    setStepFiveAppraisalReport(currentApplication?.file?.appraisalReportDocs);

    setStepSixPropertyImages(currentApplication?.file?.propertyImages);

    setIsLoading(false);
    setFormLoaded(true);
  }, [currentApplication]);

  const handleNext = async (values, isFinished = true) => {
    try {
      setIsLoading(true);
      if (currentStep === 1) {
        await updatePropertyApplication(currentApplication?.id, {
          currentStep: currentStep + 1,
          stepOne: [
            {
              question: currentApplication?.data?.stepOne[0]?.question,
              answer: values.propertyType,
            },
            {
              question: currentApplication?.data?.stepOne[1]?.question,
              answer: values.numberOfBedrooms,
            },
            {
              question: currentApplication?.data?.stepOne[2]?.question,
              answer: values.numberOfBathrooms,
            },
            {
              question: currentApplication?.data?.stepOne[3]?.question,
              answer: values.monthlyRent,
            },
            {
              question: currentApplication?.data?.stepOne[4]?.question,
              answer: values.squareFeet,
            },
            {
              question: currentApplication?.data?.stepOne[5]?.question,
              answer: values.yearBuilt,
            },
            {
              question: currentApplication?.data?.stepOne[6]?.question,
              answer: values.lotSize,
            },
          ],
        });
      } else if (currentStep === 2) {
        await updatePropertyApplication(currentApplication?.id, {
          currentStep: currentStep + 1,
          stepTwo: [
            {
              question: currentApplication?.data?.stepTwo[0]?.question,
              answer: values.state,
            },
            {
              question: currentApplication?.data?.stepTwo[1]?.question,
              answer: values.metro,
            },
            {
              question: currentApplication?.data?.stepTwo[2]?.question,
              answer: values.city,
            },
            {
              question: currentApplication?.data?.stepTwo[3]?.question,
              answer: values.propertyAddress,
            },
          ],
        });
      } else if (currentStep === 3) {
        await updatePropertyApplication(currentApplication?.id, {
          currentStep: currentStep + 1,
          stepThree: [
            {
              question: currentApplication?.data?.stepThree[0]?.question,
              answer: values.valueOfProperty,
            },
            {
              question: currentApplication?.data?.stepThree[1]?.question,
              answer: values.reasonForValue,
            },
          ],
        });
      } else if (currentStep === 4) {
        await updatePropertyApplication(currentApplication?.id, {
          currentStep: currentStep + 1,
          stepFour: [
            {
              question: currentApplication?.data?.stepFour[0]?.question,
              answer: values.propertyDescription,
            },
          ],
        });
      } else if (currentStep === 5) {
        await updatePropertyApplicationAndFiles(
          currentApplication?.id,
          {
            currentStep: currentStep + 1,
          },
          'five',
          {
            stepFiveSalesCma: values.salesCma,
            stepFiveAppraisalReport: values.appraisalReport,
          }
        );
      } else if (currentStep === 6) {
        if (!isFinished) {
          await updatePropertyApplicationAndFiles(
            currentApplication?.id,
            {},
            'six',
            {
              stepSixPropertyImages: values.propertyImages,
            }
          );
        } else {
          await updatePropertyApplicationAndFiles(
            currentApplication?.id,
            {
              status: 'IN_REVIEW',
              applicationCompleted: true,
              timeSubmitted: Timestamp.now(),
            },
            'six',
            {
              stepSixPropertyImages: values.propertyImages,
            }
          );

          await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/email/landlord/property_added`,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              userId: currentApplication?.data?.userId,
              propertyId: currentApplication?.id,
            }),
          });

          await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/email/landlord/property_added_confirmation_to_agent`,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              userId: currentApplication?.data?.userId,
              propertyId: currentApplication?.id,
            }),
          });

          await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/email/landlord/landlord_property_added_support`,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              propertyId: currentApplication?.id,
            }),
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return formLoaded ? (
          <AddPropertyStepOne
            handleNext={handleNext}
            isLoading={isLoading}
            stepOnePropertyType={stepOnePropertyType}
            stepOneNumberOfBedrooms={stepOneNumberOfBedrooms}
            stepOneNumberOfBathrooms={stepOneNumberOfBathrooms}
            stepOneMonthlyRent={stepOneMonthlyRent}
            stepOneSquareFeet={stepOneSquareFeet}
            stepOneYearBuilt={stepOneYearBuilt}
            stepOneLotSize={stepOneLotSize}
          />
        ) : (
          <div></div>
        );
      case 2:
        return formLoaded ? (
          <AddPropertyStepTwo
            setCurrentStep={setCurrentStep}
            handleNext={handleNext}
            isLoading={isLoading}
            stepTwoState={stepTwoState}
            stepTwoMetro={stepTwoMetro}
            stepTwoCity={stepTwoCity}
            stepTwoPropertyAddress={stepTwoPropertyAddress}
          />
        ) : (
          <div></div>
        );
      case 3:
        return formLoaded ? (
          <AddPropertyStepThree
            setCurrentStep={setCurrentStep}
            handleNext={handleNext}
            isLoading={isLoading}
            stepThreeValueOfProperty={stepThreeValueOfProperty}
            stepThreeReasonForValue={stepThreeReasonForValue}
          />
        ) : (
          <div></div>
        );
      case 4:
        return formLoaded ? (
          <AddPropertyStepFour
            setCurrentStep={setCurrentStep}
            handleNext={handleNext}
            isLoading={isLoading}
            stepFourPropertyDescription={stepFourPropertyDescription}
          />
        ) : (
          <div></div>
        );
      case 5:
        return formLoaded ? (
          <AddPropertyStepFive
            setCurrentStep={setCurrentStep}
            handleNext={handleNext}
            isLoading={isLoading}
            stepFiveSalesCma={stepFiveSalesCma}
            stepFiveAppraisalReport={stepFiveAppraisalReport}
          />
        ) : (
          <div></div>
        );
      case 6:
        return formLoaded ? (
          <AddPropertyStepSix
            setCurrentStep={setCurrentStep}
            handleNext={handleNext}
            isLoading={isLoading}
            stepSixPropertyImages={stepSixPropertyImages}
          />
        ) : (
          <div></div>
        );
      default:
        return (
          <Box>
            <Text>Something went wrong</Text>
          </Box>
        );
    }
  };

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme={currentStep === 6 ? 'green' : 'blue'}
          rounded="full"
          size="sm"
          value={(currentStep / 6) * 100}
          bg="gray.200"
        />

        {renderStep()}
      </Stack>
    </Fragment>
  );
};

export default AddPropertyApplication;
