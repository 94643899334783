import React, { Fragment } from 'react';
import { isEmptyString } from '../../../utils/generalFunctions';

// context
import { useCoapplicant } from '../../../context/CoapplicantContext';

// chakra
import {
  Box,
  Button,
  Card,
  CardBody,
  FormControl,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';

const CoapplicantStepFour = ({
  setActiveStep,
  stepFourPrimaryIncome,
  setStepFourPrimaryIncome,
  handleNext,
  loading,
}) => {
  const { coapplicantData, isHomebuyer } = useCoapplicant();

  return (
    <Fragment>
      <Stack maxW="xl" spacing="5">
        <Card bg="white" w="100%" textAlign="left">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md" mb="1">
                    {isHomebuyer
                      ? coapplicantData
                        ? coapplicantData.name.firstName + `'s `
                        : ''
                      : 'Your '}
                    primary income
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    Depending on{' '}
                    {isHomebuyer
                      ? coapplicantData
                        ? coapplicantData.name.firstName + `'s `
                        : ''
                      : 'your '}
                    primary income you can get qualified for a loan amount
                    greater than the pre-qualification amount
                  </Text>
                </Box>

                <Stack spacing="5">
                  <FormControl>
                    <FormLabel color="gray">Primary Income</FormLabel>
                    <RadioGroup
                      value={stepFourPrimaryIncome}
                      onChange={setStepFourPrimaryIncome}
                    >
                      <Stack spacing={3}>
                        <Radio
                          size="lg"
                          value="Salary / hourly"
                          colorScheme="blue"
                        >
                          <Text fontWeight="600" color="gray.600">
                            Salary / hourly
                          </Text>
                          <Text fontWeight="600" color="gray.400" fontSize="xs">
                            A W2 Employee
                          </Text>
                        </Radio>
                        <Radio
                          size="lg"
                          value="Independent Contractor"
                          colorScheme="blue"
                        >
                          <Text fontWeight="600" color="gray.600">
                            Independent Contractor
                          </Text>
                          <Text fontWeight="600" color="gray.400" fontSize="xs">
                            A 1099-MISC worker
                          </Text>
                        </Radio>
                        <Radio
                          size="lg"
                          value="Self-employed"
                          colorScheme="blue"
                        >
                          <Text fontWeight="600" color="gray.600">
                            Self-employed
                          </Text>
                          <Text fontWeight="600" color="gray.400" fontSize="xs">
                            An owner of a business
                          </Text>
                        </Radio>
                        {/* <Radio size="lg" value="4" colorScheme="blue">
                <Text fontWeight="600" color="gray.600">
                  Child support / alimony
                </Text>
                <Text fontWeight="600" color="gray.400" fontSize="xs">
                  Receiving court-ordered payments
                </Text>
              </Radio>
              <Radio size="lg" value="5" colorScheme="blue">
                <Text fontWeight="600" color="gray.600">
                  Social Security
                </Text>
                <Text
                  fontWeight="600"
                  color="gray.400"
                  fontSize="xs"
                  isDisabled
                >
                  Receiving social security payments
                </Text>
              </Radio>
              <Radio size="lg" value="5" colorScheme="blue">
                <Text fontWeight="600" color="gray.600">
                  VA Benefits
                </Text>
                <Text fontWeight="600" color="gray.400" fontSize="xs">
                  Receiving veteran pension benefits
                </Text>
              </Radio> */}
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(1);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={async () => {
              await handleNext();
            }}
            isLoading={loading}
            isDisabled={isEmptyString(stepFourPrimaryIncome)}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default CoapplicantStepFour;
