import React, {
  Fragment,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import heic2any from 'heic2any';

// context
import { useLandlord } from '../../../../../context/LandlordContext';
import { useAuth } from '../../../../../context/AuthContext';

// chakra
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  NumberInput,
  Progress,
  Stack,
  Flex,
  Select,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  InputGroup,
  InputLeftElement,
  InputLeftAddon,
  Textarea,
  Text,
  IconButton,
  Icon,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  SimpleGrid,
  Image,
  Container,
  Spacer,
  Spinner,
} from '@chakra-ui/react';
import { FiTrash2, FiPlus } from 'react-icons/fi';

const AddPropertyStepSix = ({
  setCurrentStep,
  handleNext,
  isLoading,
  stepSixPropertyImages,
}) => {
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();
  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();

  const { authUser } = useAuth();
  const navigate = useNavigate();
  const { updatePropertyApplicationAndFiles, currentApplicationForm } =
    useLandlord();

  const propertyImagesRef = useRef(null);
  const cancelRef = useRef();

  const [alertDialog, setAlertDialog] = useState('');
  const [imageConvertionLoading, setImageConvertionLoading] = useState(false);
  const [submitValues, setSubmitValues] = useState(null);
  const [imagesLoading, setImagesLoading] = useState(true);

  const [propertyImages, setPropertyImages] = useState([]);

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      setImagesLoading(true);

      console.log(
        'current application form: ',
        currentApplicationForm?.file?.propertyImages
      );

      if (currentApplicationForm?.file?.propertyImages) {
        setPropertyImages(currentApplicationForm?.file?.propertyImages);
      } else {
        setPropertyImages([]);
      }

      setImagesLoading(false);
    };

    fetchData().catch(console.error);
  }, [currentApplicationForm]);

  const isValidFileSize = (file, minSizeKB = 5, maxSizeMB = 30) => {
    const fileSizeKB = file.size / 1024;
    const fileSizeMB = fileSizeKB / 1024;
    return fileSizeKB >= minSizeKB && fileSizeMB <= maxSizeMB;
  };

  const isDuplicateFile = (existingFiles, newFile) => {
    return existingFiles.some(file => {
      if (file.fileLocation === 'cloud') {
        return (
          file.data.fileMetadata.name === newFile.name &&
          file.data.fileMetadata.size === newFile.size
        );
      } else {
        return file.name === newFile.name && file.size === newFile.size;
      }
    });
  };

  const convertHeicToJpeg = useCallback(async file => {
    if (
      file.type === 'image/heic' ||
      file.name.toLowerCase().endsWith('.heic')
    ) {
      try {
        const jpegBlob = await heic2any({
          blob: file,
          toType: 'image/jpeg',
          quality: 0.8,
        });
        return new File([jpegBlob], file.name.replace(/\.heic$/i, '.jpg'), {
          type: 'image/jpeg',
        });
      } catch (error) {
        console.error('Error converting HEIC to JPEG:', error);
        return file;
      }
    }
    return file;
  }, []);

  const handleAddMoreFiles = async (setFieldValue, existingFiles, newFiles) => {
    try {
      const validAndUniqueFiles = [];

      if (existingFiles.length + newFiles.length > 30) {
        setAlertDialog(`You can only upload a maximum of 30 property images`);
        alertOnOpen();

        return existingFiles;
      }

      for (let i = 0; i < newFiles.length; i++) {
        let newFile = newFiles[i];

        newFile = await convertHeicToJpeg(newFile);

        if (!isValidFileSize(newFile)) {
          setAlertDialog(
            `${newFile.name} has an invalid file size. File size should be between 5kb and 30mb`
          );
          alertOnOpen();

          continue;
        }

        if (isDuplicateFile(existingFiles, newFile)) {
          setAlertDialog(
            `${newFile.name} is a duplicate file. Please attach a new one`
          );
          alertOnOpen();

          continue;
        }

        validAndUniqueFiles.push({
          fileLocation: 'local',
          data: newFile,
        });
      }

      if (validAndUniqueFiles.length > 0) {
        const updatedFiles = [...existingFiles, ...validAndUniqueFiles];

        return updatedFiles;
      }

      return existingFiles;
    } catch (err) {
      console.log(err);

      return existingFiles;
    }
  };

  const handleDeleteFile = (existingFiles, index) => {
    const updatedFiles = [...existingFiles.propertyImages];
    updatedFiles.splice(index, 1);

    return updatedFiles;
  };

  const handleDescriptionChange = (existingFiles, index, description) => {
    try {
      const updatedFiles = [...existingFiles];
      if (updatedFiles[index].fileLocation === 'cloud') {
        if (updatedFiles[index]?.data?.fileMetadata?.customMetadata) {
          updatedFiles[
            index
          ].data.fileMetadata.customMetadata.propertyDescription = description;
        }
      } else {
        if (updatedFiles[index]?.data?.description) {
          updatedFiles[index].data.description = description;
        }
      }

      return updatedFiles;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    console.log('Running handleSubmit()');
    try {
      await handleNext({
        propertyImages: propertyImages,
      });
    } catch (err) {
      console.log('Error in handleSubmit():', err);
    }
  };

  return (
    <Fragment>
      <Stack spacing="5">
        <Card bg="white" size="lg">
          <CardBody>
            <Heading as="h4" size="md" mb="5">
              Add Property Photos
            </Heading>

            <Stack spacing="5">
              <input
                type="file"
                ref={propertyImagesRef}
                id="propertyImages"
                name="propertyImages"
                multiple={true}
                accept="image/jpeg,image/gif,image/png,image/x-eps,image/jpg,image/*,.heic"
                style={{ display: 'none' }}
                onChange={async e => {
                  try {
                    setImageConvertionLoading(true);
                    const updatedImages = await handleAddMoreFiles(
                      setPropertyImages,
                      propertyImages,
                      e.target.files
                    );

                    propertyImagesRef.current.value = '';
                    propertyImagesRef.current.click();

                    await handleNext(
                      {
                        propertyImages: updatedImages,
                      },
                      false
                    );
                    setImageConvertionLoading(false);
                  } catch (err) {
                    console.log(err);
                    setAlertDialog(err);
                    alertOnOpen();
                    setImageConvertionLoading(false);
                  }
                }}
              />

              {/* <Flex align="center">
                <Button
                  as="label"
                  htmlFor="propertyImages"
                  leftIcon={<Icon as={FiPlus} />}
                  isDisabled={isLoading || imagesLoading}
                  isLoading={imageConvertionLoading}
                  w="100%"
                >
                  <Text>Upload Property Images</Text>
                </Button>
              </Flex> */}

              {/* {imagesLoading && !propertyImages ? (
                <Spinner />
              ) : (
                propertyImages.map((image, index) => (
                  <>
                    {image.fileLocation === 'cloud' ? (
                      <Flex>
                        <Text>{image?.data?.fileMetadata?.name}</Text>
                        <Textarea
                          isDisabled={
                            isLoading || imageConvertionLoading || imagesLoading
                          }
                          value={
                            image.data.fileMetadata.customMetadata
                              .propertyDescription
                          }
                          onChange={e => {
                            const updatedImages = [...propertyImages];
                            updatedImages[
                              index
                            ].data.fileMetadata.customMetadata.propertyDescription =
                              e.target.value;
                            setPropertyImages(updatedImages);
                          }}
                          onBlur={async e => {
                            try {
                              setImageConvertionLoading(true);
                              const updatedFiles = handleDescriptionChange(
                                propertyImages,
                                index,
                                e.target.value
                              );

                              await handleNext(
                                {
                                  propertyImages: updatedFiles,
                                },
                                false
                              );

                              setImageConvertionLoading(false);
                            } catch (err) {
                              console.log(err);
                              setImageConvertionLoading(false);
                            }
                          }}
                        />
                      </Flex>
                    ) : (
                      <Text>{image.name}</Text>
                    )}
                  </>
                ))
              )} */}

              {propertyImages.length === 0 ? (
                <Flex align="center">
                  <Button
                    as="label"
                    htmlFor="propertyImages"
                    leftIcon={<Icon as={FiPlus} />}
                    isDisabled={isLoading || imagesLoading}
                    isLoading={imageConvertionLoading}
                    w="100%"
                  >
                    <Text>Upload Property Images</Text>
                  </Button>
                </Flex>
              ) : (
                <Box>
                  <Flex align="center" mb="5">
                    <Button
                      as="label"
                      htmlFor="propertyImages"
                      leftIcon={<Icon as={FiPlus} />}
                      isDisabled={isLoading || imagesLoading}
                      isLoading={imageConvertionLoading}
                      w="100%"
                    >
                      <Text>Add More Property Images</Text>
                    </Button>
                  </Flex>

                  <SimpleGrid columns={1} spacing={5}>
                    {propertyImages.map((file, index) => (
                      <Flex
                        boxShadow="md"
                        borderRadius="5px"
                        key={index}
                        w="100%"
                      >
                        <Image
                          objectFit="cover"
                          src={
                            file?.fileLocation === 'cloud'
                              ? file?.data?.fileUrl
                              : URL.createObjectURL(file?.data)
                          }
                          borderRadius="5px 0 0 5px"
                          maxW="200px"
                        />
                        <Flex w="100%" p="4" flexDirection="column">
                          <Text fontWeight="600" mr="2">
                            {file?.fileLocation === 'cloud'
                              ? file?.data?.fileMetadata?.name.length > 30
                                ? file?.data?.fileMetadata?.name.slice(0, 30) +
                                  '..'
                                : file?.data?.fileMetadata?.name
                              : file?.data?.name.length > 30
                              ? file?.data?.name.slice(0, 30) + '..'
                              : file?.data?.name}
                          </Text>

                          <Textarea
                            placeholder="Add a description (optional)"
                            mt="3"
                            mb="3"
                            value={
                              file?.fileLocation === 'cloud'
                                ? file?.data?.fileMetadata?.customMetadata
                                    ?.propertyDescription
                                : file?.data?.description
                            }
                            // onChange={async e => {
                            //   try {
                            //     setImageConvertionLoading(true);
                            //     const updatedImages = [...propertyImages];
                            //     if (file?.fileLocation === 'cloud') {
                            //       updatedImages[
                            //         index
                            //       ].data.fileMetadata.customMetadata.propertyDescription =
                            //         e.target.value;
                            //     } else {
                            //       updatedImages[index].data.description =
                            //         e.target.value;
                            //     }

                            //     await handleNext(
                            //       {
                            //         propertyImages: updatedImages,
                            //       },
                            //       false
                            //     );
                            //     setImageConvertionLoading(false);
                            //   } catch (err) {
                            //     console.log(err);
                            //     setImageConvertionLoading(false);
                            //   }
                            // }}
                            onChange={e => {
                              const updatedImages = [...propertyImages];
                              if (file?.fileLocation === 'cloud') {
                                updatedImages[
                                  index
                                ].data.fileMetadata.customMetadata.propertyDescription =
                                  e.target.value;
                              } else {
                                updatedImages[index].data.description =
                                  e.target.value;
                              }
                              setPropertyImages(updatedImages);
                            }}
                            onBlur={async e => {
                              try {
                                setImageConvertionLoading(true);
                                const updatedFiles = handleDescriptionChange(
                                  propertyImages,
                                  index,
                                  e.target.value
                                );

                                await handleNext(
                                  {
                                    propertyImages: updatedFiles,
                                  },
                                  false
                                );

                                setImageConvertionLoading(false);
                              } catch (err) {
                                console.log(err);
                                setImageConvertionLoading(false);
                              }
                            }}
                            isDisabled={
                              isLoading ||
                              imageConvertionLoading ||
                              imagesLoading
                            }
                            maxLength={255}
                          />

                          {file?.fileLocation === 'cloud'
                            ? file?.data?.fileMetadata?.customMetadata
                                ?.propertyDescription &&
                              file?.data?.fileMetadata?.customMetadata
                                ?.propertyDescription.length === 255 && (
                                <Text fontSize="sm" color="red.500">
                                  Max 255 characters
                                </Text>
                              )
                            : file?.data?.description &&
                              file?.data?.description.length === 255 && (
                                <Text fontSize="sm" color="red.500">
                                  Max 255 characters
                                </Text>
                              )}

                          <Spacer />

                          <Flex>
                            <Spacer />
                            <IconButton
                              colorScheme="red"
                              aria-label="delete file"
                              icon={<Icon as={FiTrash2} />}
                              onClick={async () => {
                                try {
                                  setImageConvertionLoading(true);
                                  const updatedImages = handleDeleteFile(
                                    propertyImages,
                                    index
                                  );

                                  await handleNext(
                                    {
                                      propertyImages: updatedImages,
                                    },
                                    false
                                  );
                                  setImageConvertionLoading(false);
                                } catch (err) {
                                  console.log(err);
                                  setImageConvertionLoading(false);
                                }
                              }}
                              isDisabled={
                                isLoading ||
                                imageConvertionLoading ||
                                imagesLoading
                              }
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    ))}
                  </SimpleGrid>
                </Box>
              )}

              <Flex align="center" mt="2" direction="column">
                <div>
                  <b>
                    The first photo will be used as the main photo for your
                    listing.
                  </b>
                  <br />
                  <br />
                  Add clear and nicely captured photos for all the bedrooms,
                  bathrooms, Kitchen, Garage, frontyard/ backyard with
                  descriptions. The listings with nice pictures get a customer{' '}
                  <b>FIVE</b> times faster.
                </div>
              </Flex>
            </Stack>
          </CardBody>
        </Card>

        <Stack justify="right" direction="row" spacing="3">
          <Button
            isDisabled={isLoading || imagesLoading}
            onClick={() => {
              setCurrentStep(5);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="green"
            // isDisabled={salesCma === '' && appraisalReport === ''}
            isLoading={isLoading || imagesLoading}
            onClick={async () => {
              await handleSubmit();
            }}
          >
            Next
          </Button>
        </Stack>
      </Stack>

      <AlertDialog
        isOpen={alertIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={alertOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Oops!
            </AlertDialogHeader>

            <AlertDialogBody>{alertDialog}</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="gray" onClick={alertOnClose} ml={3}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Fragment>
  );
};

export default AddPropertyStepSix;
