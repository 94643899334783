import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { useState } from 'react';

import { BrokerageInfoSchema } from '../../../../validations/BrokerageInfoSchema';

import { useAgentAuth } from '../../../../context/AgentContext';
import { useAuth } from '../../../../context/AuthContext';

const BrokerageUpdateButton = ({ name, liscence, brokerageData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [buttonLoading, setButtonLoading] = useState(false);
  const toast = useToast();

  const { authUser } = useAuth();
  const { updateApplicationFields, getUserApplication } = useAgentAuth();

  const handleSubmit = async values => {
    try {
      setButtonLoading(true);

      let payload = {
        stepOne: [
          {
            question: 'Agent Phone',
            answer: brokerageData[0]?.answer,
          },
          {
            question: 'Agent License Number:',
            answer: values.liscence,
          },
          {
            question: 'Agent Brokerage:',
            answer: values.name,
          },
        ],
      };

      await updateApplicationFields(payload);

      window.location.reload();

      toast({
        title: 'Successful',
        description: `Agent Info Updated Successfully`,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
      setButtonLoading(false);

      onClose();
    } catch (err) {
      toast({
        title: 'Request Failed',
        description:
          'Unable to update info at the moment, please try again later',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });

      setButtonLoading(false);
    }
  };

  return (
    <>
      <Button
        textColor={'blue.500'}
        mt={2}
        background="white"
        variant={'unstyled'}
        onClick={onOpen}
      >
        Edit
      </Button>

      <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Brokerage information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                name,
                liscence,
              }}
              validationSchema={BrokerageInfoSchema}
              onSubmit={async values => {
                handleSubmit(values);
              }}
            >
              {formik => (
                <>
                  <FormControl
                    isInvalid={formik.errors.name && formik.touched.name}
                    mt={2}
                  >
                    <FormLabel>Brokerage name</FormLabel>
                    <Field
                      as={Input}
                      borderWidth="2px"
                      id="name"
                      name="name"
                      size="md"
                      borderRadius="md"
                      disabled={buttonLoading}
                    />
                    <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={
                      formik.errors.liscence && formik.touched.liscence
                    }
                    mt={2}
                  >
                    <FormLabel>License #</FormLabel>
                    <Field
                      as={Input}
                      borderWidth="2px"
                      id="liscence"
                      name="liscence"
                      size="md"
                      borderRadius="md"
                      disabled={buttonLoading}
                    />
                    <FormErrorMessage>
                      {formik.errors.liscence}
                    </FormErrorMessage>
                  </FormControl>

                  <Flex mt={'10%'} justifyContent="flex-end">
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                      onClick={() => formik.handleSubmit()}
                      backgroundColor={'#1C2430'}
                      textColor={'white'}
                      minW={30}
                      ml={2}
                      isDisabled={buttonLoading}
                      _hover={{
                        bg: 'black',
                        cursor: 'pointer',
                      }}
                    >
                      {buttonLoading ? <Spinner /> : ' Update'}
                    </Button>
                  </Flex>
                </>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BrokerageUpdateButton;
