import React, { Fragment } from 'react';
import { isEmptyString } from '../../../utils/generalFunctions';

// context
import { useCoapplicant } from '../../../context/CoapplicantContext';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Spinner,
} from '@chakra-ui/react';

const CoapplicantStepTen = ({
  setActiveStep,
  stepTenMoreThanOneSalary,
  setStepTenMoreThanOneSalary,
  stepTenAdditionalYearlySalary,
  setStepTenAdditionalYearlySalary,
  handleNext,
  loading,
}) => {
  const { coapplicantData, isHomebuyer } = useCoapplicant();

  const format = val => `$` + val;
  const parse = val => val.replace(/^\$/, '');

  return (
    <Fragment>
      <Stack maxW="xl" spacing="5">
        <Card bg="white" w="100%" textAlign="left">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md">
                    Additional Income
                  </Heading>
                </Box>

                <Stack spacing="5">
                  <FormControl>
                    <FormLabel color="gray">
                      {isHomebuyer
                        ? coapplicantData
                          ? `Does ${coapplicantData.name.firstName}'s `
                          : ''
                        : 'Do you '}
                      receive paychecks from more than one salaried or hourly
                      job?
                    </FormLabel>
                    <RadioGroup
                      value={stepTenMoreThanOneSalary}
                      onChange={setStepTenMoreThanOneSalary}
                    >
                      <Stack spacing={3}>
                        <Radio size="lg" value="yes" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            Yes
                          </Text>
                        </Radio>
                        <Radio size="lg" value="no" colorScheme="blue">
                          <Text fontWeight="600" color="gray.600">
                            No
                          </Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>

              {stepTenMoreThanOneSalary == 'yes' && (
                <Box>
                  <Box mb="3">
                    <Heading as="h4" size="md">
                      Additional Income
                    </Heading>
                  </Box>

                  <Stack spacing="5">
                    <FormControl>
                      <FormLabel color="gray">
                        {isHomebuyer
                          ? coapplicantData
                            ? `${coapplicantData.name.firstName}'s `
                            : ''
                          : 'Your '}
                        additional yearly salary:
                      </FormLabel>
                      <NumberInput
                        size="lg"
                        value={format(stepTenAdditionalYearlySalary)}
                        onChange={valueString =>
                          setStepTenAdditionalYearlySalary(parse(valueString))
                        }
                        min={0}
                      >
                        <NumberInputField borderWidth="2px" size="lg" />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <FormHelperText color="gray.400">
                        Enter pre-tax income
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Box>
              )}
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(7);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={async () => {
              await handleNext();
            }}
            isLoading={loading}
            isDisabled={
              isEmptyString(stepTenMoreThanOneSalary) ||
              (stepTenMoreThanOneSalary == 'yes' &&
                isEmptyString(stepTenAdditionalYearlySalary))
            }
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default CoapplicantStepTen;
