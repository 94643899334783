import React, { Fragment, useState, useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

// components
import StartLandlordApplication from './StartLandlordApplication';
import LandlordApplication from './LandlordApplication/LandlordApplication';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  Box,
} from '@chakra-ui/react';

const LandlordOnboarding = () => {
  const [startApplication, setStartApplication] = useState(false);
  const [applicationCompleted, setApplicationCompleted] = useState(null);
  const [applicationStarted, setApplicationStarted] = useState(false);

  const { authUser, setAuthLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setAuthLoading(true);
      setApplicationCompleted(authUser?.data?.applicationCompleted);
      setApplicationStarted(authUser?.data?.applicationStarted);
      setAuthLoading(false);
    };

    if (authUser?.data?.applicationCompleted) {
      navigate('/landlord/home');
    }
    fetchData().catch(console.error);
  }, [authUser]);

  return (
    <Fragment>
      {applicationCompleted ? (
        <Box>
          <Text>Application Submitted</Text>
        </Box>
      ) : applicationStarted || startApplication ? (
        <LandlordApplication
          setApplicationCompleted={setApplicationCompleted}
        />
      ) : (
        <StartLandlordApplication setApplicationStarted={setStartApplication} />
      )}
    </Fragment>
  );
};

export default LandlordOnboarding;
