import React, { Fragment } from 'react';

// context
import { useCoapplicant } from '../../context/CoapplicantContext';

// chakra
import {
  Card,
  CardBody,
  Box,
  Stack,
  Button,
  Flex,
  CardHeader,
  Image,
  Heading,
  Text,
} from '@chakra-ui/react';

const StartCoapplicantApplication = ({
  setActiveStep,
  handleNext,
  loading,
}) => {
  const { coapplicantData, isHomebuyer } = useCoapplicant();

  return (
    <Fragment>
      <Stack maxW="xl" spacing="5">
        <Card bg="white" w="100%">
          <CardHeader>
            <Image
              boxSize="100px"
              objectFit="cover"
              src="https://i.imgur.com/O73TMKn.png"
              alt="emails"
            />
          </CardHeader>
          <CardBody>
            <Heading as="h4" size="md" mb="5">
              Co-Buyer Application Process
            </Heading>

            <Stack spacing="5" textAlign="left">
              {!isHomebuyer && (
                <Text fontSize="md" fontWeight="600" color="gray" mb="1">
                  Welcome {coapplicantData.name.firstName}{' '}
                  {coapplicantData.name.lastName},<br />
                  {coapplicantData.homebuyerName.firstName}{' '}
                  {coapplicantData.homebuyerName.lastName} invited you to be a
                  Co-buyer on the Utopia’s “Rent, Earn and Own” Program.
                </Text>
              )}
              <Text fontSize="md" fontWeight="700" color="gray">
                Please share the requested details at the earliest so that we
                could get you into your dream home ASAP.
              </Text>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            colorScheme="blue"
            onClick={async () => {
              await handleNext();
            }}
            isLoading={loading}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default StartCoapplicantApplication;
