import React, { Fragment } from 'react';

// chakra
import {
  Card,
  CardBody,
  Box,
  Stack,
  Button,
  Flex,
  CardHeader,
  Image,
  Heading,
  Text,
} from '@chakra-ui/react';

const CoapplicantError = () => {
  return (
    <Fragment>
      <Stack maxW="xl" spacing="5">
        <Card bg="white" w="100%">
          <CardHeader>
            <Image
              boxSize="100px"
              objectFit="cover"
              src="https://i.imgur.com/uxzgSN5.png"
              alt="emails"
            />
          </CardHeader>
          <CardBody>
            <Heading as="h4" size="md" mb="5">
              Coapplicant Error
            </Heading>
            <Stack spacing="5" textAlign="left">
              <Text fontSize="md" fontWeight="700" color="gray">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Text>
            </Stack>
          </CardBody>
        </Card>
      </Stack>
    </Fragment>
  );
};

export default CoapplicantError;
