import React, { Fragment } from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

// context
import { useAuth } from '../../../../../context/AuthContext';

// chakra
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  NumberInput,
  Progress,
  Stack,
  Flex,
  Select,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  InputGroup,
  InputLeftAddon,
  Text,
} from '@chakra-ui/react';

const AddPropertyStepSeven = ({
  setCurrentStep,
  handleNext,
  isLoading,
  stepSevenLandlordFirstName,
  stepSevenLandlordLastName,
  stepSevenLandlordEmail,
  stepSevenLandlordPhone,
}) => {
  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();

  const { authUser } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async values => {
    console.log('RunninghandleSubmit()');
    try {
      await handleNext({
        landlordFirstName: values.landlordFirstName,
        landlordLastName: values.landlordLastName,
        landlordEmail: values.landlordEmail,
        landlordPhone: values.landlordPhoneNumber,
      });
      modalOnClose();
    } catch (err) {
      console.log('Error in handleSubmit():', err);
    }
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          landlordFirstName: stepSevenLandlordFirstName,
          landlordLastName: stepSevenLandlordLastName,
          landlordEmail: stepSevenLandlordEmail,
          landlordPhoneNumber: stepSevenLandlordPhone,
        }}
        validationSchema={yup.object({
          landlordFirstName: yup.string().optional(),
          landlordLastName: yup.string().optional(),
          landlordEmail: yup.string().email('Invalid email address').optional(),
          landlordPhoneNumber: yup
            .string()
            .optional()
            .matches(/^\d{10}$/, 'Phone number must be 10 digits'),
        })}
        onSubmit={async values => {
          try {
            modalOnOpen();
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {formik => (
          <>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing="5">
                <Card bg="white" size="lg">
                  <CardBody>
                    <Heading as="h4" size="md" mb="5">
                      Add Landlord Information
                    </Heading>
                    <Stack spacing="5">
                      <Flex gap="5">
                        <FormControl
                          isInvalid={
                            formik.errors.landlordFirstName &&
                            formik.touched.landlordFirstName
                          }
                          isDisabled={isLoading}
                        >
                          <FormLabel color="gray">
                            First Name (Optional)
                          </FormLabel>
                          <Field
                            as={Input}
                            borderWidth="2px"
                            size="lg"
                            id="landlordFirstName"
                            name="landlordFirstName"
                            type="text"
                          />
                          <FormErrorMessage>
                            {formik.errors.landlordFirstName}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={
                            formik.errors.landlordLastName &&
                            formik.touched.landlordLastName
                          }
                          isDisabled={isLoading}
                        >
                          <FormLabel color="gray">
                            Last Name (Optional)
                          </FormLabel>
                          <Field
                            as={Input}
                            borderWidth="2px"
                            size="lg"
                            id="landlordLastName"
                            name="landlordLastName"
                            type="text"
                          />
                          <FormErrorMessage>
                            {formik.errors.landlordLastName}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>

                      <FormControl
                        isInvalid={
                          formik.errors.landlordEmail &&
                          formik.touched.landlordEmail
                        }
                        isDisabled={isLoading}
                      >
                        <FormLabel color="gray">Email (Optional)</FormLabel>
                        <Field
                          as={Input}
                          borderWidth="2px"
                          size="lg"
                          id="landlordEmail"
                          name="landlordEmail"
                          type="email"
                        />
                        <FormErrorMessage>
                          {formik.errors.landlordEmail}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          formik.errors.landlordPhoneNumber &&
                          formik.touched.landlordPhoneNumber
                        }
                        isDisabled={isLoading}
                      >
                        <FormLabel color="gray">
                          Phone Number (Optional)
                        </FormLabel>
                        <Field
                          as={Input}
                          borderWidth="2px"
                          size="lg"
                          id="landlordPhoneNumber"
                          name="landlordPhoneNumber"
                          type="number"
                        />
                        <FormErrorMessage>
                          {formik.errors.landlordPhoneNumber}
                        </FormErrorMessage>
                      </FormControl>
                    </Stack>
                  </CardBody>
                </Card>

                <Stack justify="right" direction="row" spacing="3">
                  <Button
                    isDisabled={isLoading}
                    onClick={() => {
                      setCurrentStep(6);
                    }}
                  >
                    Prev
                  </Button>
                  <Button
                    colorScheme="green"
                    type="submit"
                    isDisabled={isLoading}
                  >
                    Submit
                  </Button>
                </Stack>
              </Stack>
            </form>

            <Modal
              isOpen={modalIsOpen}
              onClose={isLoading !== true ? modalOnClose : null}
              isLoading={isLoading}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Are you sure you want to submit?</ModalHeader>
                <ModalCloseButton isDisabled={isLoading} />
                <ModalBody>
                  <Box>
                    <Text>
                      Once you submit, you will not be able to edit your
                      property.
                    </Text>
                  </Box>
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="green"
                    size="lg"
                    mr={3}
                    onClick={async () => {
                      const values = formik.values;
                      await handleSubmit(values);
                    }}
                    isLoading={isLoading}
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        )}
      </Formik>
    </Fragment>
  );
};

export default AddPropertyStepSeven;
