import React, { Fragment, useState, useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';

import AgentApplication from './AgentApplication/AgentApplication';
import ApplicationSubmitted from './ApplicationSubmitted';
import StartAgentApplication from './StartAgentApplication';
import { useNavigate } from 'react-router-dom';

const AgentOnboarding = () => {
  const [startApplication, setStartApplication] = useState(false);
  const [applicationCompleted, setApplicationCompleted] = useState(null);
  const [applicationStarted, setApplicationStarted] = useState(false);

  const { authUser, setAuthLoading } = useAuth();
  const navigate = useNavigate();

  // const applicationCompleted = authUser?.data?.applicationCompleted;
  // const applicationStarted = authUser?.data?.applicationStarted;

  useEffect(() => {
    const fetchData = async () => {
      setAuthLoading(true);
      setApplicationCompleted(authUser?.data?.applicationCompleted);
      setApplicationStarted(authUser?.data?.applicationStarted);
      setAuthLoading(false);
    };

    if (authUser?.data?.applicationCompleted) {
      navigate('/agents/home');
    }
    fetchData().catch(console.error);
  }, [authUser]);

  return (
    <Fragment>
      {applicationCompleted ? (
        <ApplicationSubmitted />
      ) : applicationStarted || startApplication ? (
        <AgentApplication />
      ) : (
        <StartAgentApplication setApplicationStarted={setStartApplication} />
      )}
    </Fragment>
  );
};

export default AgentOnboarding;
