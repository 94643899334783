import {
  Box,
  Button,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import AgentDashboardSidebar from '../../../Sidebar';

const Incentives = () => {
  return (
    <AgentDashboardSidebar showHeader={false}>
      <Box p={6}>
        <Box p={6} bg="white" borderRadius={12} boxShadow="lg">
          <Heading as="h2" size="lg" mb={6} textAlign={'center'}>
            Agent Incentives worth Tens of thousands of dollars
          </Heading>

          <Text fontSize="lg" mb={4} textAlign={'justify'}>
            As you know, Utopia is on a mission to make home ownership
            accessible to all. So, to further increase homeownership amongst
            people who are not mortgage ready yet, we recently announced some
            additional incentives.
          </Text>

          <Text fontSize="lg" mb={4} textAlign={'justify'}>
            Here is a summary of these incentives available only for our UAN
            members like you:
          </Text>

          <List spacing={6}>
            <ListItem>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                1. An Uncapped incentive of over $12,000 on referring other
                agents
              </Text>
              <Text fontSize="md" textAlign={'justify'}>
                As you refer other agents to Utopia using "Invite" feature of
                your agent panel, you can make 10% of their sales commission as
                your referred agents close sales with Utopia. So, for example:
                If you refer 10 agents to Utopia and each of them closes 1 Sale
                worth $400,000 with Utopia. Then these agents make full
                commission of 3% from these sales i.e. $12,000 Per sale and
                $120,000 from 10 sales. You get to make 10% of this amount i.e.
                $1,200 per sale and $12,000 from 10 sales done by the agents
                referred by you. This incentive is uncapped and is funded by
                Utopia (Doesn't come out of the commission paid to your referred
                agents). So, More Agents you refer to Utopia, More incentives
                you earn.
              </Text>
            </ListItem>

            <ListItem>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                2. An incentive of $25,000 (plus full sales commission!) for
                closing 5 sales in a 12-month period
              </Text>
              <Text fontSize="md" textAlign={'justify'}>
                This $25,000 is on top of full sales commission! In addition,
                agents who achieve this goal will gain “Preferred Agent Status”
                and will be placed on top of the list for client referrals from
                Utopia. So, Please use the "Invite" feature of your agent panel
                to refer as many clients as possible. You can track their
                application progress on your Agent Portal as well.
              </Text>
            </ListItem>
          </List>

          <Text fontSize="lg" mb={4}>
            Please feel free to let us know if you have further questions or
            comments about these incentives.
          </Text>

          <Flex alignItems={'center'} justifyContent={'center'} mt={10}>
            <NavLink to={'/agents/invite'}>
              <Button
                // minWidth="25vw"
                colorScheme="blue"
                backgroundColor={'#1C2430'}
                textColor={'white'}
                px={14}
                _hover={{
                  bg: 'black',
                  cursor: 'pointer',
                }}
              >
                Invite & Earn
              </Button>
            </NavLink>
          </Flex>
        </Box>
      </Box>
    </AgentDashboardSidebar>
  );
};

export default Incentives;
