import React, { Fragment, useState, useEffect, useRef } from 'react';
import { isEmptyString } from '../../../../utils/generalFunctions';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spinner,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

const HomebuyerStepSeven = ({
  setActiveStep,
  stepSevenCreditFirstName,
  setStepSevenCreditFirstName,
  stepSevenCreditMiddleName,
  setStepSevenCreditMiddleName,
  stepSevenCreditLastName,
  setStepSevenCreditLastName,
  stepSevenCreditStreetAddress,
  setStepSevenCreditStreetAddress,
  stepSevenCreditZipCode,
  setStepSevenCreditZipCode,
  stepSevenCreditSsn,
  setStepSevenCreditSsn,
  handleNext,
  loading,
  reward,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);

  let autoComplete;

  const loadScript = (url, callback) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: 'us' } }
    );
    autoComplete.setFields(['address_components', 'formatted_address']);
    autoComplete.addListener('place_changed', () =>
      handlePlaceSelect(updateQuery)
    );
  };

  const handlePlaceSelect = async updateQuery => {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    setStepSevenCreditStreetAddress(query);

    const addressArray = query.split(',');
    const addressZip = addressArray[2]?.trim().split(' ')[1];

    if (addressZip) {
      setStepSevenCreditZipCode(addressZip);
    }
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${'AIzaSyCGbiIGIjtPn66vJ-x4d8PspahDferywpw'}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(7 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md" mb="1">
                    We need to perform credit verification
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    This won’t affect your credit score. <br /> We will use this
                    information to check your credit profile and help estimate
                    your income.
                  </Text>
                </Box>

                <Stack spacing="5">
                  <FormControl>
                    <FormLabel color="gray">Legal First Name</FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={stepSevenCreditFirstName}
                      onChange={e => {
                        setStepSevenCreditFirstName(e.target.value);
                      }}
                    />
                    <FormHelperText color="gray.400">
                      Must be your actual legal First Name, like the one on your
                      Driver's License
                    </FormHelperText>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">Legal Middle Name</FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={stepSevenCreditMiddleName}
                      onChange={e => {
                        setStepSevenCreditMiddleName(e.target.value);
                      }}
                    />
                    <FormHelperText color="gray.400">
                      Must be your actual legal Middle Name (if you have one),
                      like the one on your Driver's License
                    </FormHelperText>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">Legal Last Name</FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={stepSevenCreditLastName}
                      onChange={e => {
                        setStepSevenCreditLastName(e.target.value);
                      }}
                    />
                    <FormHelperText color="gray.400">
                      Must be your actual legal Last Name, like the one on your
                      Driver's License
                    </FormHelperText>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">Street Address</FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      ref={autoCompleteRef}
                      onChange={event => {
                        setQuery(event.target.value);
                      }}
                      placeholder={stepSevenCreditStreetAddress}
                      value={query}
                    />
                  </FormControl>

                  {/* <FormControl>
                    <FormLabel color="gray">Street Address</FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={stepSevenCreditStreetAddress}
                      onChange={e => {
                        setStepSevenCreditStreetAddress(e.target.value);
                      }}
                    />
                  </FormControl> */}

                  <FormControl>
                    <FormLabel color="gray">Zip Code</FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="number"
                      value={stepSevenCreditZipCode}
                      onChange={e => {
                        if (e.target.value.length <= 5) {
                          setStepSevenCreditZipCode(e.target.value);
                        }
                      }}
                    />
                    <FormHelperText color="gray.400">
                      Enter 5-digit number
                    </FormHelperText>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">
                      Social Security Number or ITIN
                    </FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={stepSevenCreditSsn}
                      onChange={e => {
                        if (e.target.value.length <= 9) {
                          if (
                            e.target.value.length !== 0 &&
                            isNaN(e.target.value[e.target.value.length - 1])
                          ) {
                            let newString = e.target.value.substring(
                              0,
                              e.target.value.length - 1
                            );
                            setStepSevenCreditSsn(newString);
                          } else {
                            setStepSevenCreditSsn(e.target.value);
                          }
                        }
                      }}
                      maxLength="9"
                    />
                    <FormHelperText color="gray.400">
                      Enter 9-digit number
                    </FormHelperText>
                  </FormControl>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(6);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              if (stepSevenCreditFirstName.length === 1) {
                toast({
                  title: 'Error',
                  description: 'First name has to be greater than 1 letter',
                  position: 'top-right',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              } else if (stepSevenCreditLastName.length === 1) {
                toast({
                  title: 'Error',
                  description: 'Last name has to be greater than 1 letter',
                  position: 'top-right',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              } else if (stepSevenCreditStreetAddress.length === 1) {
                toast({
                  title: 'Error',
                  description: 'Street Address has to be greater than 1 letter',
                  position: 'top-right',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              } else {
                onOpen();
              }
            }}
            isDisabled={
              isEmptyString(stepSevenCreditFirstName) ||
              isEmptyString(stepSevenCreditLastName) ||
              isEmptyString(stepSevenCreditStreetAddress) ||
              isEmptyString(stepSevenCreditZipCode) ||
              isEmptyString(stepSevenCreditSsn) ||
              stepSevenCreditZipCode.length !== 5 ||
              stepSevenCreditSsn.length !== 9
            }
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>

      <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) " />
        <ModalContent>
          <ModalHeader>
            <Text fontWeight="700" fontSize="xl">
              Soft credit check and employment & income verification
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="5" mb="10">
              <Text fontWeight="500" fontSize="md" textAlign="justify">
                This will not affect your credit score.
              </Text>
              <Text fontWeight="500" fontSize="md" textAlign="justify">
                You understand that by clicking on the "I Agree" button below,
                you are providing "written instructions" to Revex Technologies
                Inc., its affiliates, subsidiaries, and third-party partners
                (collectively, "Utopia") under the Fair Credit Reporting Act and
                thereby authorize Utopia to obtain information from your
                personal credit profile and other information about you from one
                or more consumer reporting agencies (including, but not limited
                to, TransUnion, Experian, and/or Equifax), on an ongoing and/or
                recurring basis.
              </Text>
              <Text fontWeight="500" fontSize="md" textAlign="justify">
                By clicking "I Agree," you also allow Utopia to obtain
                information about your job, including, but not limited to, your
                salary, through our trusted partners, and agree to Utopia's{' '}
                <Link
                  color="blue.500"
                  onClick={() => {
                    window.open('https://www.utopiahomes.us/terms', '_blank');
                  }}
                >
                  Terms and Conditions
                </Link>{' '}
                and{' '}
                <Link
                  color="blue.500"
                  onClick={() => {
                    window.open('https://www.utopiahomes.us/privacy', '_blank');
                  }}
                >
                  Privacy Policy
                </Link>
                .
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              onClick={() => {
                onClose();
              }}
              mr="4"
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                onClose();

                const creditFirstName = stepSevenCreditFirstName.trim();
                const creditMiddleName = stepSevenCreditMiddleName.trim();
                const creditLastName = stepSevenCreditLastName.trim();
                setStepSevenCreditFirstName(creditFirstName);
                setStepSevenCreditMiddleName(creditMiddleName);
                setStepSevenCreditLastName(creditLastName);

                handleNext();
              }}
            >
              I Agree
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default HomebuyerStepSeven;
