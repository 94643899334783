import React, { Fragment, useState, forwardRef } from 'react';
import {
  isEmptyString,
  isIos,
  isSafari,
} from '../../../utils/generalFunctions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// context
import { useCoapplicant } from '../../../context/CoapplicantContext';

// utils
import states from '../../../utils/states';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Spinner,
  Spacer,
} from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';

const CoapplicantStepFive = ({
  setActiveStep,
  stepFiveEmployerName,
  setStepFiveEmployerName,
  stepFiveStartDate,
  setStepFiveStartDate,
  stepFiveEmployerCity,
  setStepFiveEmployerCity,
  stepFiveEmployerState,
  setStepFiveEmployerState,
  handleNext,
  loading,
}) => {
  const { coapplicantData, isHomebuyer } = useCoapplicant();

  const dateIsValid = date => {
    return date instanceof Date && !isNaN(date);
  };

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      borderWidth="2px"
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
      w="100%"
      size="lg"
      variant="ghost"
    >
      <Flex w="100%" align="center">
        {value === null || value === '' ? (
          <Text color="gray.400">mm/dd/yyyy</Text>
        ) : (
          <Text>{value}</Text>
        )}
        <Spacer />
        <CalendarIcon />
      </Flex>
    </Button>
  ));

  return (
    <Fragment>
      <Stack maxW="xl" spacing="5">
        <Card bg="white" w="100%" textAlign="left">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md" mb="1">
                    {isHomebuyer
                      ? coapplicantData
                        ? `${coapplicantData.name.firstName}'s `
                        : ''
                      : 'Your '}
                    employer's details
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    We need some details about{' '}
                    {isHomebuyer
                      ? coapplicantData
                        ? `${coapplicantData.name.firstName}'s `
                        : ''
                      : 'your '}
                    employer to verify income.
                  </Text>
                </Box>

                <Stack spacing="5">
                  <FormControl>
                    <FormLabel color="gray">Employer's name:</FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={stepFiveEmployerName}
                      onChange={e => {
                        setStepFiveEmployerName(e.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">Start date:</FormLabel>
                    {/* {isIos() ? (
                      <DatePicker
                        selected={stepFiveStartDate}
                        onChange={date => setStepFiveStartDate(date)}
                        minDate={'1950-01-01T04:49:20.366Z'}
                        maxDate={new Date()}
                        placeholderText="mm/dd/yyyy"
                        customInput={<DateCustomInput />}
                      />
                    ) : (
                      <Input
                        id="startDateDateInput"
                        borderWidth="2px"
                        size="lg"
                        type="date"
                        placeholder="mm/dd/yyyy"
                        min="1950-01-01"
                        max={new Date().toISOString().split('T')[0]}
                        onKeyDown={e => e.preventDefault()}
                        value={stepFiveStartDate}
                        onChange={e => {
                          setStepFiveStartDate(e.target.value);
                        }}
                      />
                    )} */}
                    <Input
                      id="startDateDateInput"
                      borderWidth="2px"
                      size="lg"
                      type="date"
                      placeholder="mm/dd/yyyy"
                      min="1950-01-01"
                      max={new Date().toISOString().split('T')[0]}
                      onKeyDown={e => e.preventDefault()}
                      value={stepFiveStartDate}
                      onChange={e => {
                        setStepFiveStartDate(e.target.value);
                      }}
                    />

                    <FormHelperText color="gray.400">
                      If employee has had a job for less than 3 months, they
                      will need to show previous income sources.
                    </FormHelperText>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">
                      Employer location - City:
                    </FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={stepFiveEmployerCity}
                      onChange={e => {
                        setStepFiveEmployerCity(e.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">
                      Employer location - State:
                    </FormLabel>
                    <Select
                      borderWidth="2px"
                      size="lg"
                      placeholder="Select state"
                      value={stepFiveEmployerState}
                      onChange={e => {
                        setStepFiveEmployerState(e.target.value);
                      }}
                    >
                      {states.map(state => {
                        return (
                          <option key={state.abbreviation} value={state.name}>
                            {state.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(2);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={async () => {
              setStepFiveEmployerName(stepFiveEmployerName.trim());
              setStepFiveEmployerCity(stepFiveEmployerCity.trim());
              await handleNext();
            }}
            isLoading={loading}
            isDisabled={
              isEmptyString(stepFiveEmployerName) ||
              isEmptyString(stepFiveStartDate) ||
              !dateIsValid(new Date(stepFiveStartDate)) ||
              isEmptyString(stepFiveEmployerCity) ||
              isEmptyString(stepFiveEmployerState) ||
              stepFiveEmployerName.trim().length === 1 ||
              stepFiveEmployerCity.trim().length === 1
            }
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default CoapplicantStepFive;
